import _ from 'lodash';
import { Action } from '../api/src/models/Action';

export default {
  computed: {
    actionParse() {
      let action;

      if (this.actionId) {
        if (this.isInFocus) {
          action = this.$store.state.actionFocus.action;
        }

        if (!action) {
          action = this.$store.getters.getActionById(this.actionId);
        }
      }

      if (!action) {
        action = {};
      }

      return Action.Model.safeParse({
        userId: this.$store.state.user.uid,
        createdAt: new Date(),
        ...action,
      });
    },
    isActionValid() {
      return this.actionParse.success;
    },
    isActionSimulated() {
      return this.actionId === Action.SimulatedItemId;
    },
    action() {
      return this.isActionValid ? this.actionParse.data : undefined;
    },
    isInFocus() {
      return Boolean(this.$store.state.actionFocus.action) && this.actionId === this.$store.state.actionFocus.action.id;
    },
    isInFocusLocally() {
      return this.isInFocus && this.$store.state.actionFocus.isLocal === true;
    },
    isInActionsPlan() {
      let isIn = false;

      if (this.isActionValid) {
        const ids = this.$store.getters['actionsPlan/itemIds'];
        isIn = ids.includes(this.actionId) || ids.includes(this.action.occurrenceOf) ||
          (this.mostRecentlyCompletedOccurrenceForItem && ids.includes(this.mostRecentlyCompletedOccurrenceForItem.id));
      }

      return isIn;
    },
    isIdea() {
      return this.isActionValid ? this.$store.getters['actionsUser/isItemIdea'](this.action) : false;
    },
    isComplete() {
      return this.isActionValid ? this.$store.getters['actionsUser/isItemComplete'](this.action) : false;
    },
    isRepeating() {
      return this.isActionValid ? Action.isFrequencyRepeating(this.action.frequency) : false;
    },
    isRepeatingItemForVirtualDay() {
      return (this.isActionValid && this.isRepeating) ? this.$store.getters['actionsUser/isRepeatingItemForVirtualDay'](this.action) : false;
    },
    isCompletable() {
      return !this.isComplete && (!this.isRepeating || this.isRepeatingItemForVirtualDay);
    },
    isReminding() {
      return this.isActionValid && !!this.$store.getters['actionsUser/isItemReminding'](this.action);
    },
    valuePotential() {
      let v = 0;

      if (this.isActionValid) {
        v = this.$store.getters.getValuePotentialForItem(this.action);
      }

      return v;
    },
    value() {
      let val = this.valuePotential;

      if (this.isActionValid) {
        if (this.isComplete && _.isFinite(this.action.value)) {
          val = this.action.value;
        } else if (this.isRepeating && this.isMostRecentlyCompletedOccurrenceForItemInVirtualDay) {
          val = this.mostRecentlyCompletedOccurrenceForItem.value;
        }
      }

      return val;
    },
    isOccurrenceOf() {
      return this.isActionValid ? Boolean(this.action.occurrenceOf) : false;
    },
    occurrenceOfItem() {
      return this.isOccurrenceOf ? this.$store.getters['actionsUser/getItemById'](this.action.occurrenceOf) : undefined;
    },
    frequencyDisplay() {
      return this.$store.getters['actionsUser/getItemFrequencyDisplay'](this.action);
    },
    isFrequencyDaily() {
      return this.isActionValid ? Action.isFrequencyDaily(this.action.frequency) : false;
    },
    isFrequencyMonth() {
      return this.isActionValid && this.action.frequency === Action.Frequency.enum.Month;
    },
    createdAtDisplay() {
      return (this.isActionValid && !this.isRepeating) ? this.$dayjs(this.action.createdAt).fromNow() : '';
    },
    lastProgressAtDisplay() {
      let display = '';

      if (this.isActionValid) {
        if (this.action.lastProgressAt) {
          display = this.$dayjs(this.action.lastProgressAt).fromNow();
        } else if (this.isRepeating && this.isMostRecentlyCompletedOccurrenceForItemInVirtualDay) {
          display = this.$dayjs(this.mostRecentlyCompletedOccurrenceForItem.lastProgressAt).fromNow();
        }
      }

      return display;
    },
    maturesAt() {
      let maturesAt;

      if (this.isActionValid && !this.isRepeating) {
        const getter = 'actionsUser/getItemMaturesAt';
        maturesAt = this.$store.getters[getter](this.action);
      }

      return maturesAt;
    },
    expiresAt() {
      let expiresAt;

      if (this.isActionValid) {
        const getter = this.isRepeating ? 'actionsUser/getRepeatingItemNextExpiresAt' : 'actionsUser/getItemExpiresAt';
        expiresAt = this.$store.getters[getter](this.action);
      }

      return expiresAt;
    },
    isExpired() {
      let isExpired = false;

      if (this.isActionValid) {
        isExpired = this.expiresAt ? this.$store.state.now.isAfter(this.expiresAt) : false;
      }

      return isExpired;
    },
    isExpiringVirtualDay() {
      let isExpiringVirtualDay = false;

      if (this.isActionValid) {
        isExpiringVirtualDay = this.$store.getters['actionsUser/isItemExpiringVirtualDay'](this.action);
      }

      return isExpiringVirtualDay;
    },
    expiresAtDisplay() {
      let display;

      if (this.isCompletable) {
        display = 'never expires';

        if (this.expiresAt) {
          if (this.isExpired) {
            display = 'past due';
          } else {
            display = `expires in ~${this.$dayjs(this.expiresAt).fromNow(true)}`;
          }
        }
      }

      return display;
    },
    expiresAtDisplayClass() {
      let c = '';

      if (this.isExpired) {
        c = 'ec-text-danger';
      } else if (this.isExpiringVirtualDay) {
        c = 'ec-text-warning';
      }

      return c;
    },
    expiresAtDateDisplay() {
      let display = '';

      if (this.expiresAt) {
        if (this.isExpired) {
          const daysAgo = Math.ceil(this.$store.state.now.diff(this.expiresAt, 'day', true));
          display = `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
        } else if (this.isExpiringVirtualDay) {
          display = 'by today';
        } else {
          const d = this.$store.getters.dayStartingAtUserResetTime(this.expiresAt);
          display = `by ${d.format('MM/DD/YYYY')}`;
        }
      }

      return display;
    },
    remindAtDisplay() {
      let display = '';

      if (this.isActionValid && this.action.remindAt) {
        const remindAtSplit = this.action.remindAt.split(':');
        const remindAt = this.$dayjs().hour(remindAtSplit[0]).minute(remindAtSplit[1]).second(0);
        display = remindAt.format(Action.RemindAtFormatDisplay);
      }

      return display;
    },
    timeSpent() {
      let timeSpent = 0;

      if (this.isActionValid) {
        timeSpent = this.action.timeSpent;

        if (this.isRepeating && !this.$store.getters['actionsUser/isRepeatingItemTimeSpentValid'](this.action)) {
          timeSpent = 0;
        }

        if (this.isInFocus) {
          timeSpent = this.$store.state.actionFocus.action.timeSpent;
        }
      }

      return timeSpent;
    },
    timeSpentDisplay() {
      let display = !this.isCollapsed ? '00:00' : '';

      if (this.timeSpent) {
        const format = this.timeSpent >= 3600 ? 'HH:mm:ss' : 'mm:ss';

        // this could probably be more efficient only using this.$dayjs for the formatting and not the math
        display = this.$dayjs().startOf('day').add(this.timeSpent, 'seconds').format(format);
      }

      return display;
    },
    importanceToEffortRatio() {
      return this.isActionValid ? Math.round((this.action.importance / (this.action.importance + this.action.effort)) * 100) : 0;
    },
    recentlyCompletedOccurrencesForItem() {
      let recentCompletions;

      if (this.isActionValid) {
        if (this.isRepeating) {
          recentCompletions = this.$store.getters['actionsUser/getItemRecentlyCompletedOccurrences'](this.action);
        } else if (this.isOccurrenceOf) {
          recentCompletions = this.$store.getters['actionsUser/getItemRecentlyCompletedOccurrences'](this.occurrenceOfItem);
        }

        if (recentCompletions) {
          recentCompletions.reverse();
        }
      }

      return recentCompletions;
    },
    mostRecentlyCompletedOccurrenceForItem() {
      const recent = this.recentlyCompletedOccurrencesForItem;
      let c;

      if (Array.isArray(recent)) {
        recent.forEach((d) => {
          if (Array.isArray(d) && d.length > 0) {
            c = d[d.length - 1];
          }
        });
      }

      return c;
    },
    isMostRecentlyCompletedOccurrenceForItemInVirtualDay() {
      return this.mostRecentlyCompletedOccurrenceForItem ? this.mostRecentlyCompletedOccurrenceForItem.lastProgressAt >= this.$store.getters.virtualDay.toDate() : false;
    },
    isShowingFutureValue() {
      return this.isRepeating && !this.isRepeatingItemForVirtualDay;
    },
    accentColors() {
      const colors = [];

      if (this.isActionValid && this.action.interest && this.action.interest.length > 0) {
        this.action.interest.forEach((id) => {
          colors.push(this.$store.getters['interests/getColorForItem']({ id }));
        });
      }

      return colors;
    },
  },
  methods: {
    getMostRecentCompletionTagClass(completions) {
      const cls = [];

      if (Array.isArray(completions)) {
        cls.push(completions.length > 0 ? 'action-occurrence-tag-success' : 'action-occurrence-tag-missing');
      } else {
        cls.push('action-occurrence-tag-skipped');
      }

      return cls;
    },
    getMostRecentCompletionTagIcon(completions) {
      const icon = ['fal'];

      if (Array.isArray(completions)) {
        icon.push(completions.length > 0 ? 'circle-check' : 'circle');
      } else {
        icon.push('minus');
      }

      return icon;
    },
    doFocus() {
      if (this.isActionValid) {
        this.$store.dispatch('ui/hideAllModals');
        this.$store.dispatch('actionFocus/startLocally', this.action.id);
      }
    },
  },
};
