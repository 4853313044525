
import { App } from '../api/src/models/App';

export default {
  name: 'ToastsView',
  computed: {
    Type() {
      return this.$store.state.ui.ToastType;
    },
    messages() {
      return this.$store.state.ui.toastMessages
        .map((m) => {
          const resource = this.$store.getters['resources/getResourceByName'](m.payload?.resourceName);
          const conceptName = m.type === 'ActionCompletion' ? App.Concept.ConceptName.enum.Value : m.conceptName;
          const icon = resource?.icon || m.icon || ['fas', 'circle-info'];
          const iconStyle = {};

          if (resource && resource?.color) {
            iconStyle.color = resource.color;
          }

          return {
            ...m,
            conceptName,
            icon,
            iconStyle,
            resource,
          };
        });
    },
  },
  methods: {
    handleDismiss(messageId) {
      this.$store.commit('ui/removeToastMessageById', messageId);
    },
  },
};
