
import _ from 'lodash';
import capitalizeTitle from '../lib/capitalize-title';

import { App } from '../api/src/models/App';

export default {
  name: 'AppConcept',
  props: {
    name: {
      type: String,
      default: undefined,
    },
    customLabel: {
      type: String,
      default: undefined,
    },
    isCapitalized: {
      type: Boolean,
      default: false,
    },
    isCapitalizedFirst: {
      type: Boolean,
      default: false,
    },
    isPlural: {
      type: Boolean,
      default: false,
    },
    isHighlighted: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    labelResult() {
      let label;

      if (App.Concept.ConceptName.options.includes(this.name)) {
        if (this.customLabel) {
          label = this.customLabel;
        } else if (App.Concept.Setting[this.name].label) {
          label = this.isPlural
            ? App.Concept.Setting[this.name].labelPlural
            : App.Concept.Setting[this.name].label;
        }
      }

      if (label) {
        if (this.isCapitalized) {
          label = capitalizeTitle(label);
        } else if (this.isCapitalizedFirst) {
          label = _.upperFirst(label);
        }
      }

      return label;
    },
  },
};
