import { z } from 'zod';
import { convertTimestampToDate } from './util';

export namespace Suggestion {
  export const DataTableName = 'suggestions';

  export const Model = z.object({
    userId: z.string().min(1),
    userName: z.string().nullable(),
    text: z.string().min(1),
    createdAt: z.preprocess(
      convertTimestampToDate,
      z.date(),
    ),
  });
}
