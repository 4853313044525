import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      actionsPlanDynamic: {
        progressPercent: 0,
        valueTotalVirtualDay: 0,
        valueExpectedAdjusted: 0,
      },
    };
  },
  computed: {
    ...mapGetters('actionsPlan', [
      'items',
      'valueOfItems',
      'valueTotalVirtualDay',
      'valueExpectedAdjusted',
      'isValueHigherThanExpected',
      'isValueLowerThanExpected',
      'isComplete',
      'progressPercent',
      'progress',
    ]),
    actionsPlanVariant() {
      let variant = 'primary';

      if (this.progress >= 1) {
        variant = 'success';
      } else if (this.progress < 0.5) {
        variant = 'danger';
      } else if (this.progress < 0.8) {
        variant = 'warning';
      }

      return variant;
    },
  },
  watch: {
    valueTotalVirtualDay() {
      this.actionsPlanDynamicUpdate();
    },
    valueExpectedAdjusted() {
      this.actionsPlanDynamicUpdate();
    },
  },
  mounted() {
    this.actionsPlanDynamic = {
      progressPercent: this.progressPercent,
      valueTotalVirtualDay: this.valueTotalVirtualDay,
      valueExpectedAdjusted: this.valueExpectedAdjusted,
    };
  },
  methods: {
    actionsPlanDynamicUpdate() {
      const actionsPlanDynamic = { ...this.actionsPlanDynamic };

      this.$anime({
        targets: actionsPlanDynamic,
        progressPercent: this.progressPercent,
        valueTotalVirtualDay: this.valueTotalVirtualDay,
        valueExpectedAdjusted: this.valueExpectedAdjusted,
        round: 1,
        easing: 'linear',
        update: () => {
          this.actionsPlanDynamic = actionsPlanDynamic;
        },
      });
    },
  },
};
