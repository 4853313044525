import _typeof from "@babel/runtime/helpers/esm/typeof";
export var isDate = function isDate(d) {
  return d instanceof Date;
};
export var isFirestoreTimestamp = function isFirestoreTimestamp(ts) {
  return ts && _typeof(ts) === 'object' && typeof ts.toDate === 'function';
};
export var getTimestampFromDateOrNull = function getTimestampFromDateOrNull(d) {
  return isDate(d) ? Math.round(d.getTime() / 1000) : null;
};
export var getDateFromTimestampOrUndefined = function getDateFromTimestampOrUndefined(ts) {
  return ts ? new Date(ts * 1000) : undefined;
};
export var convertTimestampToDate = function convertTimestampToDate(val) {
  if (isFirestoreTimestamp(val)) {
    return val.toDate();
  } else if (typeof val === 'number') {
    return new Date(val * 1000);
  } else {
    return val;
  }
};
export var getFirestoreIdFromRef = function getFirestoreIdFromRef(ref) {
  if (typeof ref === 'string') {
    return ref.split('/')[1];
  } else {
    return ref.id;
  }
};
export var preprocessFirestoreIdFromRef = function preprocessFirestoreIdFromRef(ref) {
  // is there a firestore instanceof we can check instead?
  if (_typeof(ref) === 'object' && ref !== null && Boolean(ref.id)) {
    return getFirestoreIdFromRef(ref);
  } else {
    return ref;
  }
};