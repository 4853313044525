import _ from 'lodash';
import { Objective } from '../api/src/models/Objective';
import debug from '../lib/debug';

export default ({ route, store }) => {
  if (route?.name === 'terms') {
    return;
  }

  const dbg = debug('objectives');
  const { isUserInitialized } = store.getters;
  const canEdit = store.getters['userSettings/canEdit'];

  let queuedEdits = [];
  let editProm = Promise.resolve();

  const edit = _.debounce(() => {
    if (queuedEdits.length > 0) {
      dbg('dispatch queuedEdits', queuedEdits);
      editProm = store.dispatch('userSettings/editObjectives', queuedEdits);
      queuedEdits = [];
    }
  }, 500);

  const editObjective = async (obj) => {
    const i = queuedEdits.findIndex(o => o.name === obj.name);

    if (i >= 0) {
      queuedEdits[i] = {
        ...queuedEdits[i],
        ...obj,
      };
    } else {
      queuedEdits.push(obj);
    }

    await editProm;
    dbg('edit');
    edit();
  };

  Object.values(Objective.Setting).forEach((obj) => {
    const resolveUnlock = () => {
      if (!store.getters['objectives/getItemFromUserSettings'](obj.name)?.isUnlocked) {
        dbg('resolveUnlock', obj.name);
        editObjective({
          name: obj.name,
          isUnlocked: true,
        });
      }
    };

    if (typeof obj.unlockCondition === 'string') {
      const unlockDays = obj.unlockActionsPlanMinValueDaysCount;
      const hasDays = store.getters['conditions/actionsPlanMinValueDaysWithVirtualDayCount'] >= unlockDays;
      dbg(`watching unlockCondition for ${obj.name}`, `days: ${unlockDays}`, obj.unlockCondition);

      if (isUserInitialized && canEdit && store.getters[obj.unlockCondition] && hasDays) {
        resolveUnlock();
      } else {
        store.watch(
          (_state, getters) => {
            const hasDays = store.getters['conditions/actionsPlanMinValueDaysWithVirtualDayCount'] >= unlockDays;
            return getters.isUserInitialized && getters['userSettings/canEdit'] && getters[obj.unlockCondition] && hasDays;
          },
          resolveUnlock,
        );
      }
    }

    const resolveSuccess = () => {
      if (!store.getters['objectives/getItemFromUserSettings'](obj.name)?.isSuccessful) {
        dbg('resolveSuccess', obj.name);
        editObjective({
          name: obj.name,
          isSuccessful: true,
        });
      }
    };

    if (typeof obj.successCondition === 'string') {
      dbg(`watching successCondition for ${obj.name}`, obj.successCondition);

      if (isUserInitialized && canEdit && store.getters[obj.successCondition]) {
        resolveSuccess();
      } else {
        store.watch(
          (_state, getters) => {
            return getters.isUserInitialized && getters['userSettings/canEdit'] && getters[obj.successCondition];
          },
          resolveSuccess,
        );
      }
    }
  });
};
