import { z } from 'zod';
import { defineStore } from 'pinia';

// todo: create typescript-compatible wrapGetters
// import { wrapGetters } from '../lib/util';

const QuestionModel = z.object({
  question: z.string().min(1),
  answer: z.string().min(1),
});

const StateModel = z.object({
  questions: QuestionModel.array(),
});

export const useFaqStore = defineStore('faq', {
  state: (): z.infer<typeof StateModel> => ({
    questions: [
      {
        question: 'How do I get an eclipse, and what does it mean?',
        answer: 'When your day\'s mission is complete, you\'ll see that you\'ve created one of nature\'s greatest marvels: an eclipse. Not only is it beautiful, but it represents being at peace with your day. This may mean that you felt productive, or maybe that you were organized and felt a sense of control. Whatever it means to you, remember to strive for consistency and set realistic expectations. If you\'re not getting an eclipse regularly, go easier on yourself!',
      },
      {
        question: 'What is an "Action"?',
        answer: 'Actions are the things you want to do, because they bring your life value and fulfillment. They should be as brief and low effort as possible; split large actions into as many pieces as you can. They should also be actionable, and never ambiguous.',
      },
      {
        question: 'What is a "Repeating Action"?',
        answer: 'Repeating actions are just actions that you do regularly--daily, weekly, monthly, etc. By adding these actions, you won\'t have to keep recreating them!',
      },
      {
        question: 'What is an "Interest"?',
        answer: 'Interests are one way of organizing your actions. An action can belong to as many interests as it needs. This practice of categorization helps you reflect on your efforts, and narrow your focus if necessary.',
      },
      {
        question: 'What kinds of things should I add to %appName%?',
        answer: 'Everything! %appName% is not going to judge you. The goal is to better understand how you\'re spending your time and effort. You should add anything that brings positive value to you (and the people around you).',
      },
      {
        question: 'What can I do with the "points" I earn (the value of each action)?',
        answer: 'Nothing! An action\'s value should be representative of the real world value, and reinforce a feeling of accomplishment. It\'s a means of reflection.',
      },
      {
        question: 'I have an idea for %appName%, how can I tell you about it?',
        answer: 'Drop us a suggestion in the "Suggestion Box"!',
      },
    ],
  }),

  getters: {
    faq(state) {
      return state.questions.map(q => ({
        ...q,
        question: q.question.replace('%appName%', this.$nuxt.store.state.app.name),
        answer: q.answer.replace('%appName%', this.$nuxt.store.state.app.name),
      }));
    },
  },

  actions: {},
});
