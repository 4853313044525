import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faApple as freeFabFaApple,
  faGoogle as freeFabFaGoogle
} from '@fortawesome/free-brands-svg-icons'

import {
  fas as proFasFas
} from '@fortawesome/pro-solid-svg-icons'

import {
  fal as proFalFal
} from '@fortawesome/pro-light-svg-icons'

import {
  fad as proFadFad
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  freeFabFaApple,
  freeFabFaGoogle,
  proFasFas,
  proFalFal,
  proFadFad
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
