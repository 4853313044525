
import initMixin from '../mixins/init';
import uiMixin from '../mixins/ui';

import Navbar from '../components/navbar';
import NavbarBottom from '../components/navbar-bottom';
import SceneSpace from '../components/scene-space';
import ui from '../components/ui';

export default {
  name: 'DefaultLayout',
  components: {
    Navbar,
    NavbarBottom,
    SceneSpace,
    ui,
  },
  mixins: [
    initMixin,
    uiMixin,
  ],
  computed: {
    isWatchingSpaceScene() {
      return this.$store.state.ui.allowForegroundHiddenOnSceneWatch &&
        this.$store.getters['wait/is']('scene-watch') &&
        (!this.$store.getters.isAuthenticated || this.$store.state.scene.didAnimateOnce);
    },
    isModalVisible() {
      return this.uiTopModalName;
    },
    contentClasses() {
      const cls = [];
      cls.push(this.isDesktop ? 'content-desktop' : 'content-mobile');
      cls.push(this.$store.state.ui.isForegroundHidden ? 'content-hidden' : '');

      if (this.isWatchingSpaceScene) {
        cls.push('content-watching-scene');

        if (!this.$store.getters.isAuthenticated) {
          cls.push('content-watching-scene-no-auth');
        }
      }

      if (this.isModalVisible) {
        cls.push('modal-visible');
      }

      return cls;
    },
  },
};
