import Vue from 'vue';
import _ from 'lodash';
import { Capacitor } from '@capacitor/core';

import Seedrandom from 'seedrandom';
import sum from 'hash-sum';

import dayjs from '../lib/dayjs';
import { wrapGetters } from '../lib/util';
import { SyncState } from '../lib/store-helpers';

import { Action } from '../api/src/models/Action';
import { AuthUser } from '../api/src/models/AuthUser';
import { Entitlement } from '../api/src/models/Entitlement';

let Visibility;

const DebuggerUserIds = [
  'BoFWcwtxuRMdQhO2wNhA6Qkm3Y22', // me
  '0iUIGQ6JFJ5qtuJjr0a3Rxrnc210', // cypress
  'WkzbMWdLrkhkXaG0YHAoDwi5gLt2', // caudexia@gmail.com
  'tolsqG7HELZHqPbDzHyR7Uzp3792', // t4arcadia@gmail.com
  // 'fL1W8LIrWXPPFvrbhqwswjxpvXE3', // cari
];

const DemoUserIds = [
  '0iUIGQ6JFJ5qtuJjr0a3Rxrnc210', // cypress
  'WkzbMWdLrkhkXaG0YHAoDwi5gLt2', // caudexia@gmail.com
];

const ONE_MINUTE = 60000;

const HOUR_AFTERNOON_START = 12;
const HOUR_EVENING_START = 17;

export const state = () => ({
  version: '1.1.0',
  build: 7,
  now: dayjs(),
  user: _.cloneDeep(AuthUser.ModelDefaults),
  recentDaysCount: 6,
  lookaheadDaysCount: 3,
  isAuthenticationRequested: false,
  isDataInitialized: false,
  dataSources: {
    app: {
      isInitialized: false,
      syncState: SyncState.NotInitialized,
      module: 'app',
      initAction: 'init',
      requiresAuth: false,
    },
    quotes: {
      isInitialized: false,
      syncState: SyncState.NotInitialized,
      module: 'quotes',
      initAction: 'getQuotes',
      requiresAuth: false,
    },
    userSettings: {
      isInitialized: false,
      syncState: SyncState.NotInitialized,
      module: 'userSettings',
      initAction: 'init',
      resetAction: 'reset',
      requiresAuth: true,
    },
    interests: {
      isInitialized: false,
      syncState: SyncState.NotInitialized,
      module: 'interests',
      initAction: 'init',
      resetAction: 'reset',
      requiresAuth: true,
    },
    actionsUser: {
      isInitialized: false,
      syncState: SyncState.NotInitialized,
      module: 'actionsUser',
      initAction: 'init',
      resetAction: 'reset',
      requiresAuth: true,
    },
    actionFocus: {
      isInitialized: false,
      syncState: SyncState.NotInitialized,
      module: 'actionFocus',
      initAction: 'init',
      resetAction: 'reset',
      requiresAuth: true,
    },
    purchases: {
      isInitialized: false,
      syncState: SyncState.NotInitialized,
      module: 'purchases',
      initAction: 'init',
      resetAction: 'reset',
      requiresAuth: true,
    },
  },
});

export const mutations = {
  isAuthenticationRequested(localState, is) {
    localState.isAuthenticationRequested = is === true;
  },
  isDataInitialized(localState, didInit) {
    localState.isDataInitialized = didInit === true;
  },
  now(localState, val) {
    localState.now = val;
  },
  user(localState, { authUser }) {
    /*
     * Ideally, nuxt+firebase would also include the authResult in onAuthStateChangedAction
     * Since it is not available now ("@nuxtjs/firebase": "^7.6.1"), we'll determine isNewUser
     * when writing userSettings.
     *
     * Fortunately, using userSettings for the event also handles native login (cfaSignIn)
     *
    const info = authResult.getAdditionalUserInfo();
    logEvent((info && info.isNewUser()) ? 'sign_up' : 'login');
    */
    const user = _.cloneDeep(AuthUser.ModelDefaults);

    if (authUser) {
      user.displayName = authUser.displayName;
      user.email = authUser.email;
      user.emailVerified = authUser.emailVerified;
      user.phoneNumber = authUser.phoneNumber;
      user.photoUrl = authUser.photoUrl;
      user.providerData = authUser.providerData;
      user.uid = authUser.uid;
    }

    const result = AuthUser.Model.safeParse(user);

    // If the data is invalid, we simply won't process it and they won't be authenticated.
    /*
    if (result.error) {
      // eslint-disable-next-line no-console
      console.log('Invalid model provided for authUser', user);
      // throw new Error('Invalid model provided for authUser');
      // throw result.error;
    }
    */

    if (result.success) {
      localState.user = result.data;
    } else {
      localState.user = {};
    }
  },
  userReset(localState) {
    localState.user = {};
  },
  dataSourceSyncState(localState, { name, state }) {
    Vue.set(localState.dataSources[name], 'syncState', state);
  },
};

export const getters = wrapGetters('index', {
  versionString: (state, getters) => {
    return getters.isDebugger ? `v${state.version}-${state.build}` : `v${state.version}`;
  },
  gotInitialData: (state) => {
    return state.isDataInitialized &&
      Object.values(state.dataSources).filter(ds => ds.syncState === SyncState.NotInitialized).length < 1;
  },

  isAuthenticated: state => Boolean(state.user.uid),
  isDebugger: state => DebuggerUserIds.includes(state.user.uid),
  isDemoAccount: state => DemoUserIds.includes(state.user.uid),
  isNewUser: (_state, _getters, rootState) => {
    return rootState.actionsUser.all.length <= 10 && rootState.interests.all.length <= 10;
  },
  isUserInitialized: (_state, getters) => {
    return getters.isAuthenticated && getters.gotInitialData;
  },
  needsDataRepair: (_state, getters, rootState) => {
    return getters.isUserInitialized && !_.isDate(rootState.userSettings.createdAt);
  },

  hasEntitlement: (_state, getters, _rootState, rootGetters) => (entitlement) => {
    let has = false;

    if (entitlement === Entitlement.EntitlementName.enum.Debug) {
      has = getters.isDebugger;
    } else if (entitlement === Entitlement.EntitlementName.enum.Authenticated) {
      has = getters.isAuthenticated;
    } else if (entitlement === Entitlement.EntitlementName.enum.Initialized) {
      has = getters.isUserInitialized;
    } else if (getters.isUserInitialized) {
      if (entitlement === Entitlement.EntitlementName.enum.Basic && getters.isNewUser) {
        has = true;
      } else {
        // We should only check for purchased entitlements if the above conditions are met
        has = rootGetters['purchases/hasEntitlement'](entitlement) ||
          rootGetters['userSettings/hasEntitlement'](entitlement);
      }
    }

    return has;
  },

  isAllowedToUseApp: (_state, getters) => {
    return getters.hasEntitlement(Entitlement.EntitlementName.enum.Basic);
  },

  getSeedForDay: () => (d) => {
    const t = d.unix();
    const tDiff = (t - (t % 86400)) * Math.PI;
    return sum(tDiff);
  },
  getSeedForDayTime: () => (d) => {
    const t = d.unix();
    const tDiff = t * Math.PI;
    return sum(tDiff);
  },

  getRandom: () => (seed) => {
    // this reconstructs and always returns the first pseudo-random value for this seed
    return (new Seedrandom(seed))();
  },
  getRandomSet: () => (seed, size) => {
    // returns a predictable set of pseudo-random numbers for the given seed.
    const prng = new Seedrandom(seed);
    return Array.apply(null, Array(size)).map(() => prng());
  },
  getRandomSetForDay: (_state, getters) => (d, size) => {
    return getters.getRandomSet(getters.getSeedForDay(d), size);
  },
  getRandomSetForDayTime: (_state, getters) => (d, size) => {
    return getters.getRandomSet(getters.getSeedForDayTime(d), size);
  },
  getRandomInt: (_state, getters) => (seed, max = 100) => {
    return Math.floor(getters.getRandom(seed) * max);
  },
  getRandomIntForDay: (_state, getters) => (d, max = 100) => {
    return getters.getRandomInt(getters.getSeedForDay(d), max);
  },
  getRandomIntDate: (_state, getters) => (max = 100) => {
    return getters.getRandomIntForDay(getters.virtualDay, max);
  },
  getRandomIntUser: (state, getters) => (max = 100) => {
    const seed = getters.isAuthenticated ? state.user.uid : '0';
    return getters.getRandomInt(seed, max);
  },
  getRandomIntDateUser: (state, getters) => (max = 100) => {
    let seed = getters.virtualDay.format('DDMMYYYY');
    seed = `${seed}${getters.isAuthenticated ? state.user.uid : '0'}`;
    return getters.getRandomInt(seed, max);
  },

  dayStartingAtUserResetTime: (_state, _getters, rootState) => (dateObj) => {
    const d = dayjs(dateObj);
    let dateReturn = d.startOf('day');

    if (rootState.userSettings.dayResetTime) {
      const dayResetTimeParts = rootState.userSettings.dayResetTime.split(':');
      dateReturn = dateReturn
        .hour(Number(dayResetTimeParts[0]))
        .minute(Number(dayResetTimeParts[1]))
        .second(0);

      if (dateReturn.isAfter(d)) {
        dateReturn = dateReturn.subtract(1, 'day');
      }
    }

    return dateReturn;
  },
  virtualDay: (state, getters) => {
    const d = getters.dayStartingAtUserResetTime(state.now);
    return d.isAfter(state.now) ? d.subtract(1, 'day') : d;
  },
  virtualDayMorningEnd: (_state, getters) => getters.virtualDay.hour(HOUR_AFTERNOON_START),
  virtualDayAfternoonEnd: (_state, getters) => getters.virtualDay.hour(HOUR_EVENING_START),
  virtualDayEnd: (_state, getters) => getters.virtualDay.add(1, 'day').subtract(1, 'second'),
  virtualTomorrow: (_state, getters) => getters.virtualDay.add(1, 'day'),
  virtualYesterday: (_state, getters) => getters.virtualDay.subtract(1, 'day'),
  isMorning: (state, getters) => state.now.isBefore(getters.virtualDayMorningEnd),
  isAfternoon: (state, getters) => state.now.isBetween(getters.virtualDayMorningEnd, getters.virtualDayAfternoonEnd),
  isEvening: (state, getters) => state.now.isBetween(getters.virtualDayAfternoonEnd, getters.virtualTomorrow),
  dayOfTheWeek: (_state, getters) => getters.virtualDay.day(),
  dateOfTheMonth: (_state, getters) => getters.virtualDay.date(),
  dateEndOfTheMonth: (_state, getters) => getters.virtualDay.endOf('month').date(),

  actionsIncomplete: (_state, _getters, _rootState, rootGetters) => {
    return [
      ...rootGetters['actionsUser/itemsByRankIncomplete'],
      ...rootGetters['actionsUser/itemsRepeating'],
    ];
  },
  ideas: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/ideas'];
  },
  ideasFresh: (_state, getters) => {
    return getters.ideas.filter(i => dayjs().diff(i.createdAt, 'day') < 30);
  },
  hasActions: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/items'].length > 0;
  },
  hasInterests: (_state, _getters, rootState) => {
    return rootState.interests.all.length > 0;
  },

  getVirtualDateForDate: (_state, getters) => (d) => {
    let vd = getters.dayStartingAtUserResetTime(d);

    // The provided date is the same date, but before the reset time
    if (vd.isAfter(d)) {
      vd = vd.subtract(1, 'day');
    }

    return vd;
  },

  getActionById: (_state, _getters, _rootState, rootGetters) => (id) => {
    return rootGetters['actionsUser/getItemById'](id);
  },
  getDaysRemainingForExpiresAt: (_state, getters) => (expiresAt) => {
    let days;

    if (expiresAt) {
      days = Math.ceil(Math.round(dayjs(expiresAt).diff(getters.virtualDay) / 1000) / 86400);
    }

    return days;
  },
  getValuePotentialForItem: (_state, getters) => (item) => {
    let val = 0;
    let r = 0;

    if (item && item.id) {
      const importance = Number(item.importance || 0);
      const effort = Number(item.effort || 0);

      // A hack for rewarding more points for critically important actions that require more effort
      // (but still discouraging high-effort actions)
      if (importance === Action.EffortImportance.ImportanceMaxValue) {
        val = Math.max(26, importance + effort);
      } else {
        val = Math.max(1, importance - effort);
      }

      // Make the value fuzzy for less uniformity
      const randAdd3 = getters.getRandomInt(item.id, 3)
      const randMoreOrLess = getters.getRandom(item.id) > 0.5 ? 1 : -1;
      r = randAdd3 + randMoreOrLess;
    }

    return Math.max(1, val + r);
  },
  getValueAdditionalForItem: (_state, getters) => (item) => {
    let additional = 0;

    if (item && item.id) {
      const r = getters.getRandom(item.id);

      if (r > 1) { // disabled
        additional = Math.ceil(Action.ValueAddedMax * getters.getRandom());
      }
    }

    return additional;
  },
  interestsWithRecentActionsCompleted: (_state, _getters, rootState, rootGetters) => {
    const recent = {};

    rootGetters['actionsUser/itemsWithRecentProgress'].forEach((i) => {
      if (i.interest && i.interest.length > 0) {
        i.interest.forEach((id) => {
          const interest = rootState.interests.all.find(ii => ii.id === id);

          if (interest) {
            if (!Object.keys(recent).includes(id)) {
              recent[id] = {
                ...interest,
                count: 0,
                value: 0,
              };
            }

            recent[id].count = recent[id].count + 1;
            recent[id].value = i.value ? recent[id].value + i.value : recent[id].value;
          }
        });
      }
    });

    return _.sortBy(Object.values(recent), r => -1 * r.count);
  },
});

export const actions = {
  logEvent(_context, { name, params }) {
    if (this.$analytics && this.$analytics.logEvent) {
      this.$analytics.logEvent(name, params);
    }
  },
  updateNowValue({ commit }) {
    commit('now', this.$dayjs());
  },
  async initNowUpdater({ dispatch }) {
    if (process.client && !Visibility) {
      Visibility = await import('visibilityjs');
    }

    if (Visibility) {
      Visibility.every(ONE_MINUTE, () => {
        dispatch('updateNowValue');
      });
    }
  },

  setUser({ commit }, { authUser }) {
    if (authUser && authUser.uid) {
      commit('user', { authUser });
    }
  },

  getData({ state, dispatch, getters, commit }) {
    const waitStart = key => dispatch('wait/start', key, { root: true });
    const waitEnd = key => dispatch('wait/end', key, { root: true });

    if (!state.isDataInitialized) {
      this.$dbg('store:index:getData')(true);
      waitStart('init.getData')
      commit('isDataInitialized', true);

      dispatch('initNowUpdater');

      Object.values(state.dataSources).filter(ds => !ds.requiresAuth).forEach((ds) => {
        waitStart(`init.${ds.module}`);
        dispatch(`${ds.module}/${ds.initAction}`);
        waitEnd(`init.${ds.module}`);
      });

      if (getters.isAuthenticated) {
        Object.values(state.dataSources).filter(ds => ds.requiresAuth).forEach((ds) => {
          waitStart(`init.${ds.module}`);
          dispatch(`${ds.module}/${ds.initAction}`);
          waitEnd(`init.${ds.module}`);
        });
      }

      waitEnd('init.getData');
    }
  },

  async resetData({ state, commit, dispatch }) {
    this.$dbg('store:index:resetData')(true);

    // We're trusting the modules to unsubscribe from the Firestore collections before resetting.
    // By deleting the user object here, we can at least be sure we don't accidentally wipe the user's data
    commit('user', {});

    const promResets = [];

    Object.values(state.dataSources).filter(ds => ds.resetAction).forEach((ds) => {
      promResets.push(dispatch(`${ds.module}/${ds.resetAction}`));
    });

    await Promise.all(promResets);

    this.$dbLocalHelper.setHasSession(false);
    commit('isDataInitialized', false);
  },

  setDataSourceSyncLocal({ commit }, name) {
    commit('dataSourceSyncState', { name, state: SyncState.Local });
    this.$dbg('store:index:setDataSourceSyncLocal')(name);
  },

  setDataSourceSyncRemote({ commit }, name) {
    commit('dataSourceSyncState', { name, state: SyncState.Remote });
    this.$dbg('store:index:setDataSourceSyncRemote')(name);
  },

  setDataSourceSync({ commit }, { name, isRemote = true }) {
    commit('dataSourceSyncState', { name, state: isRemote ? SyncState.Remote : SyncState.Local });
    this.$dbg('store:index:setDataSourceSync')(name, isRemote);
  },

  displaySubscriptions({ dispatch }) {
    if (Capacitor.isNativePlatform()) {
      dispatch('purchases/displayPlacement', undefined, { root: true });
    } else {
      dispatch('ui/showModal', { name: 'userSubscriptions' }, { root: true });
    }
  },
};
