import { z } from 'zod';

import Forgiveness from './effect/Forgiveness';
import Postponement from './effect/Postponement';
import Recovery from './effect/Recovery';
import Resolve from './effect/Resolve';
import ReminderDelay from './effect/ReminderDelay';
import { Resource } from './Resource';

export namespace SystemUpgrade {
  export const Name = z.enum([
    'RadiaCreation',
    'AetherCreation',
    'ShadeCreation',
    'ForgivenessRange',
    'ResolveRange',
    'RecoveryRange',
    'PostponementRange',
    'ReminderDelayRange',
    'QuoteSelectCount',
  ]);

  export const Setting = {
    [Name.enum.RadiaCreation]: {
      label: 'Radia Capture',
      costFactor: (index: number) => (index + 1) * 10,
      values: [
        1,
        2,
        3,
      ],
      valueDisplay: (value: number) => value,
      description: () => 'at start of day (mission bonus)',
      ref: Resource.ResourceName.enum.Radia,
      icon: Resource.Setting[Resource.ResourceName.enum.Radia].icon,
      color: Resource.Setting[Resource.ResourceName.enum.Radia].color,
    },

    [Name.enum.AetherCreation]: {
      label: 'Aether Creation',
      costFactor: (index: number) => index * 1,
      values: [
        0,
        0.01,
        0.03,
        0.05,
        0.07,
        0.09,
        0.1,
        0.11,
        0.12,
        0.13,
        0.14,
        0.15,
        0.16,
        0.17,
        0.18,
        0.19,
        0.2,
      ],
      valueDisplay: (value: number) => `${Math.floor(value * 100)}%`,
      description: () => 'chance on action completion',
      ref: Resource.ResourceName.enum.Aether,
      icon: Resource.Setting[Resource.ResourceName.enum.Aether].icon,
      color: Resource.Setting[Resource.ResourceName.enum.Aether].color,
    },

    [Name.enum.ShadeCreation]: {
      label: 'Shade Creation',
      costFactor: (index: number) => index * 1,
      values: [
        0,
        0.01,
        0.02,
        0.03,
        0.04,
        0.05,
        0.06,
        0.07,
        0.08,
        0.09,
        0.1,
      ],
      valueDisplay: (value: number) => `${value * 100}%`,
      description: () => 'chance when a forged effect is used',
      ref: Resource.ResourceName.enum.Shade,
      icon: Resource.Setting[Resource.ResourceName.enum.Shade].icon,
      color: Resource.Setting[Resource.ResourceName.enum.Shade].color,
    },

    [Name.enum.ForgivenessRange]: {
      label: 'Forgiveness Strength',
      costFactor: (index: number) => index * 1,
      values: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
      ],
      valueDisplay: (value: number) => value === 1 ? '1 day' : `${value} days`,
      description: () => 'range of effect',
      ref: Forgiveness.name,
      icon: Forgiveness.icon,
      color: Forgiveness.color,
    },

    [Name.enum.ResolveRange]: {
      label: 'Resolve Strength',
      costFactor: (index: number) => index * 1,
      values: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
      ],
      valueDisplay: (value: number) => value === 1 ? '1 day' : `${value} days`,
      description: () => 'range of effect',
      ref: Resolve.name,
      icon: Resolve.icon,
      color: Resolve.color,
    },

    [Name.enum.RecoveryRange]: {
      label: 'Recovery Strength',
      costFactor: (index: number) => (index + 1) * 1,
      values: [
        1,
        2,
        3,
        4,
        5,
        6,
      ],
      valueDisplay: (value: number) => value === 1 ? '1 day' : `${value} days`,
      description: () => 'range of effect',
      ref: Recovery.name,
      icon: Recovery.icon,
      color: Recovery.color,
    },

    [Name.enum.PostponementRange]: {
      label: 'Postponement Strength',
      costFactor: (index: number) => (index + 1) * 1,
      values: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
      ],
      valueDisplay: (value: number) => value === 1 ? '1 day' : `${value} days`,
      description: () => 'range of effect',
      ref: Postponement.name,
      icon: Postponement.icon,
      color: Postponement.color,
    },

    [Name.enum.ReminderDelayRange]: {
      label: 'Reminder Snooze Strength',
      costFactor: (index: number) => (index + 1) * 1,
      values: [
        30,
        60,
        180,
        360,
      ],
      valueDisplay: (value: number) => {
        let display = value === 1 ? '1 minute' : `${value} minutes`;

        if (value >= 60) {
          display = value === 60 ? '1 hour' : `${value / 60} hours`;
        }

        return display;
      },
      description: () => 'range of effect',
      ref: ReminderDelay.name,
      icon: ReminderDelay.icon,
      color: ReminderDelay.color,
    },

    [Name.enum.QuoteSelectCount]: {
      label: 'Quotes',
      costFactor: (index: number) => (index + 1) * 3,
      values: [
        1,
        2,
        3,
        4,
        5,
      ],
      valueDisplay: (value: number) => value,
      description: () => 'number available to choose from',
      ref: undefined,
      icon: ['fal', 'quotes'],
      color: 'var(--sl-color-indigo-600)',
    },
  };
}
