import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var _storeHelperMutations;
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import Vue from 'vue';
export var SyncState = {
  NotInitialized: 0,
  Local: 1,
  Remote: 2
};
export var StoreHelperMutation = {
  SetOnRoot: 'setOnRoot',
  SetOnAll: 'setOnAll',
  RemoveFromAll: 'removeFromAll',
  ResetAll: 'resetAll'
};
export var storeHelperMutations = (_storeHelperMutations = {}, _defineProperty(_storeHelperMutations, StoreHelperMutation.SetOnRoot, function (localState) {
  var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  Object.keys(obj).forEach(function (k) {
    Vue.set(localState, k, obj[k]);
  });
}), _defineProperty(_storeHelperMutations, StoreHelperMutation.SetOnAll, function (localState, all) {
  all.forEach(function (item) {
    var index = localState.all.findIndex(function (i) {
      return i.id === item.id;
    });
    if (index >= 0) {
      Vue.set(localState.all, index, item);
    } else {
      localState.all.push(item);
    }
  });
}), _defineProperty(_storeHelperMutations, StoreHelperMutation.RemoveFromAll, function (localState, item) {
  var index = localState.all.findIndex(function (i) {
    return i.id === item.id;
  });
  if (index >= 0) {
    Vue.delete(localState.all, index);
  }
}), _defineProperty(_storeHelperMutations, StoreHelperMutation.ResetAll, function (localState) {
  localState.all = [];
}), _storeHelperMutations);
export var getRefForValue = function getRefForValue(db, refCollection, id) {
  return db.doc("".concat(refCollection, "/").concat(id));
};
export var getRefsFromArray = function getRefsFromArray(db, refCollection, refIds) {
  return refIds.map(function (id) {
    return getRefForValue(db, refCollection, id);
  });
};
export var getIdFromRef = function getIdFromRef(ref) {
  if (typeof ref === 'string') {
    return ref.split('/')[1];
  } else {
    return ref.id;
  }
};