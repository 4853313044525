
import { Action } from '../api/src/models/Action';

const rangeValuePercent = 100 / (Action.EffortImportance.RangeValues.length + 1);
const rangeValuesMax = Action.EffortImportance.RangeValues[Action.EffortImportance.RangeValues.length - 1];
const rangeValuesMid = 4;

export default {
  name: 'ActionImportanceEffort',
  props: {
    importance: {
      type: Number,
      default: 1,
    },
    effort: {
      type: Number,
      default: 1,
    },
    isComplete: {
      type: Boolean,
      default: false,
    },
    isIdea: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    effortRangeValue() {
      return Action.EffortImportance.FibonacciToRangeValueMap[this.effort];
    },
    effortPercent() {
      // 0% is least effort (the ideal)
      return (rangeValuePercent * (this.effortRangeValue - 1)) / 100;
    },
    importanceRangeValue() {
      return Action.EffortImportance.FibonacciToRangeValueMap[this.importance];
    },
    importancePercent() {
      // 100% is most important (the ideal)
      return (rangeValuePercent * (this.importanceRangeValue + 1)) / 100;
    },
    styles() {
      const style = {};

      style.width = `${25 + (this.importancePercent * 75)}%`;
      style.height = `${25 + (this.importancePercent * 75)}%`;
      const boxShadowRadius = this.importancePercent * 10;
      const boxShadowSpread = this.importancePercent * 1;

      let color = 'var(--sl-color-primary-600)';

      if (this.isComplete) {
        color = 'var(--sl-color-success-700)';
      } else if (this.isIdea) {
        color = 'var(--sl-color-warning-700)';
      } else if (this.effortRangeValue === rangeValuesMax) {
        color = 'var(--sl-color-danger-700)';
      } else if (this.effortRangeValue >= rangeValuesMid) {
        color = 'var(--sl-color-warning-700)';
      }

      style.borderColor = color;
      style.boxShadow = `0 0 ${boxShadowRadius}px ${boxShadowSpread}px ${color}`;

      return style;
    },
    stylesLeft() {
      return {
        ...this.styles,
        left: `${50 - (this.effortPercent * 20)}%`,
      };
    },
    stylesRight() {
      return {
        ...this.styles,
        left: `${50 + (this.effortPercent * 20)}%`,
      };
    },
  },
};
