import Vue from 'vue';

export const state = () => ({
  list: [],
  indexStart: 0,
});

export const mutations = {
  list(localState, itemIds) {
    if (itemIds && Array.isArray(itemIds)) {
      localState.list = itemIds;
    } else {
      localState.list = [];
    }
  },
  deleteFromList(localState, actionId) {
    const index = localState.list.findIndex(id => id === actionId);

    if (index >= 0) {
      Vue.delete(localState.list, index);
    }
  },
  indexStart(localState, i) {
    localState.indexStart = i;
  },
};
