
import Vue, { VueConstructor } from 'vue';
import { mapState } from 'pinia';

import uiMixin from '~~/mixins/ui';
import { useFaqStore } from '~~/stores/faq';

export default (Vue as VueConstructor<Vue & InstanceType<typeof uiMixin>>).extend({
  name: 'HelpView',
  mixins: [
    uiMixin,
  ],
  computed: {
    ...mapState(useFaqStore, ['faq']),
  },
});
