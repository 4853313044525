
import { Effect } from '../api/src/models/effect';

import actionMixin from '../mixins/action';
import effectsMixin from '../mixins/effects';
import uiMixin from '../mixins/ui';

import ActionListItem from './action-list-item';

export default {
  name: 'ActionRemindMessage',
  components: {
    ActionListItem,
  },
  mixins: [
    actionMixin,
    effectsMixin,
    uiMixin,
  ],
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultEffectName: Effect.EffectName.enum.Completion,
    };
  },
  computed: {
    actionId() {
      return this.id;
    },
    defaultEffect() {
      return this.effects.find(sk => sk.name === this.defaultEffectName);
    },
  },
  methods: {
    handleActionSelected(id) {
      this.uiShowActionSelector([id]);
    },
  },
};
