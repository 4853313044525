

export default {
  name: 'CompanionView',
  props: {
    name: {
      type: String,
      default: undefined,
    },
    size: {
      type: Number,
      default: 50,
    },
    isInactive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    companion() {
      return this.$store.getters['companions/getCompanionByName'](this.name);
    },
    style() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        filter: this.isInactive ? 'grayscale(100%)' : 'none',
        opacity: this.isInactive ? 0.5 : 1,
      };
    },
  },
};
