import { render, staticRenderFns } from "./default.vue?vue&type=template&id=7f1d8aea&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=7f1d8aea&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SceneSpace: require('/opt/build/repo/components/scene-space.vue').default,Navbar: require('/opt/build/repo/components/navbar.vue').default,NavbarBottom: require('/opt/build/repo/components/navbar-bottom.vue').default,Ui: require('/opt/build/repo/components/ui.vue').default})
