import _ from 'lodash';
import { wrapGetters } from '../lib/util';
import { Effect } from '../api/src/models/effect';
import { EffectCustom } from '../api/src/models/effect/EffectCustom';

export const state = () => ({
});

export const mutations = {};

export const getters = wrapGetters('effects', {
  items: () => {
    return Object.values(Effect.Setting).map(i => ({
      ...i,
      isCustom: false,
    }));
  },

  getItemByName: (_state, getters) => (name) => {
    return getters.items.find(i => i.name === name);
  },

  getItemsForTargets: (_state, getters) => (targets = []) => {
    return getters.items.filter((i) => {
      let isIntersecting = false;

      if (Array.isArray(targets) && Array.isArray(i.targets)) {
        isIntersecting = _.intersection(i.targets, targets).length > 0;
      }

      return isIntersecting;
    });
  },

  getLabelForCustom: () => (c) => {
    return [
      _.upperFirst(c.degree.label),
      _.upperFirst(c.what.label),
      _.upperFirst(c.how.label),
    ].join(' ');
  },

  getExplanationForCustom: () => (c) => {
    const expl = [
      c.target.explanation,
    ];

    if (c.what.explanation.includes('%val%') && c.randomValueDisplay) {
      expl.push(c.what.explanation.replace('%val%', c.randomValueDisplay));
    } else {
      expl.push(c.what.explanation);

      if (c.randomValueDisplay) {
        expl.push(c.randomValueDisplay);
      }
    }

    expl.push(c.how.explanation.replace('%degree%', c.degree.explanation));

    return _.upperFirst(`${expl.join(' ')}.`);
  },

  getHashForCustom: () => (c) => {
    return JSON.stringify([
      // must be same order as getCustomFromHash
      c.target.name,
      c.what.name,
      c.how.name,
      c.degree.name,
      c.randomValue,
    ]);
  },

  getCustom: (_state, getters) => ({ targetName, what, how, degree, randomValue }) => {
    const setting = EffectCustom.TargetSetting[targetName];
    const randomValueDisplay = typeof what.getRandomValueDisplay === 'function'
      ? what.getRandomValueDisplay(randomValue)
      : randomValue;

    const item = {
      name: Effect.EffectName.enum.Custom,
      target: setting.props,
      what,
      how,
      degree,
      randomValue,
      randomValueDisplay,
      cost: how.cost + degree.cost,
      spriteOptions: degree.spriteOptions,
    };

    const hash = getters.getHashForCustom(item);
    const label = getters.getLabelForCustom(item);
    const explanation = getters.getExplanationForCustom(item);

    return {
      ...item,
      hash,
      label,
      explanation,
      isCustom: true,
    };
  },

  getCustomFromHash: (_state, getters) => (hash) => {
    // must be same order as getHashForCustom
    const [
      targetName,
      whatName,
      howName,
      degreeName,
      randomValue,
    ] = JSON.parse(hash);

    const setting = EffectCustom.TargetSetting[targetName];
    const what = setting.what.find(i => i.name === whatName);
    const how = setting.how.find(i => i.name === howName);
    const degree = setting.degree.find(i => i.name === degreeName);

    return getters.getCustom({
      targetName,
      what,
      how,
      degree,
      randomValue,
    });
  },

  getCustomRandom: (_state, getters, rootState, rootGetters) => (seed = '') => {
    const targetName = EffectCustom.Target.enum.Action;
    const setting = EffectCustom.TargetSetting[targetName];
    const seedDay = rootGetters.getSeedForDay(rootGetters.virtualDay);
    const r = rootGetters.getRandomSet(`${seedDay}${seed}`, 4);

    const iWhat = Math.floor(setting.what.length * r[0]);
    const iMod = Math.floor(setting.how.length * r[1]);
    const iDegree = Math.floor(setting.degree.length * r[2]);

    const what = setting.what[iWhat];
    const how = setting.how[iMod];
    const degree = setting.degree[iDegree];
    const randomValue = (what && typeof what.getRandomValue === 'function')
      ? what.getRandomValue(rootState, r[3])
      : undefined;

    return getters.getCustom({
      targetName,
      what,
      how,
      degree,
      randomValue,
    });
  },

  itemsCustom: (_state, getters, rootState) => {
    return rootState.userSettings.effectsCustom
      .filter(e => !e.isDiscarded)
      .map(e => ({
        ...getters.getCustomFromHash(e.hash),
        isPurchased: true,
        isDiscarded: false,
      }));
  },

  itemsCustomDiscarded: (_state, getters, rootState) => {
    return rootState.userSettings.effectsCustom
      .filter(e => e.isDiscarded)
      .map(e => ({
        ...getters.getCustomFromHash(e.hash),
        isPurchased: true,
        isDiscarded: true,
      }));
  },
});

export const actions = {};
