
import _ from 'lodash';

import uiMixin from '../mixins/ui';
import ActionsDataView from './actions-data-view';

export default {
  name: 'IdeaQuickEntry',
  components: {
    ActionsDataView,
  },
  mixins: [
    uiMixin,
  ],
  props: {
    inputOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: 'idea-quick-entry',
      includesStale: false,
      newIdea: undefined,
      wasSubmitted: false,
    };
  },
  computed: {
    isValid() {
      return typeof this.newIdea === 'string' && this.newIdea.length >= 3;
    },
    ideasAll() {
      return this.$store.getters.ideas;
    },
    ideas() {
      return this.includesStale ? this.ideasAll : this.$store.getters.ideasFresh;
    },
  },
  mounted() {
    this.id = _.uniqueId(this.id);
    this.$bindKeys.bind('idea-quick-entry', {}, true);
  },
  destroyed() {
    this.$bindKeys.unbind('idea-quick-entry');
  },
  methods: {
    getActionIds(actions) {
      return actions.map(a => a.id);
    },
    async handleAddIdea() {
      if (!this.isValid) {
        return;
      }

      this.wasSubmitted = true;

      // rudimentary URL check
      const isUrl = this.newIdea.indexOf('http') === 0;

      const id = undefined;
      const mods = {
        description: isUrl ? `[do something at] ${this.newIdea}` : this.newIdea,
        link: isUrl ? this.newIdea : null,
      };

      try {
        await this.$store.dispatch('actionsUser/editItem', { id, mods });

        this.uiNotify('New idea added', {
          severity: 'success',
          icon: ['fas', 'lightbulb'],
          logMessage: true,
        });

        this.newIdea = undefined;
      } catch (e) {
        this.uiNotify('Failed to add new idea', {
          severity: 'warning',
          icon: ['fas', 'exclamation'],
        });
      } finally {
        this.wasSubmitted = false;
      }
    },
    handleActionSelected(items, actionId) {
      // pass all items and set activeIndex to actionId index
      this.uiShowActionSelector(items.map(i => i.id), actionId);
    },
  },
};
