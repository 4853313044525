import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find-index.js";
import Vue from 'vue';
import tinykeys from 'tinykeys';
export default (function (context, inject) {
  var onlyAllow = [];
  var unsubs = {};
  var bindKeys = {
    bind: function bind(viewName, viewBindings, isExclusive) {
      if (context.$dbg) {
        context.$dbg('bind-keys')('bind', viewName, Object.keys(viewBindings).join('|'), "isExclusive=".concat(isExclusive === true));
      }
      if (viewName && !Object.keys(unsubs).includes(viewName)) {
        if (isExclusive) {
          onlyAllow.push(viewName);
        }
        var bindings = {};
        Object.keys(viewBindings).forEach(function (k) {
          bindings[k] = function (e) {
            if (onlyAllow.length < 1 || onlyAllow.includes(viewName)) {
              viewBindings[k](e);
            }
          };
        });
        unsubs[viewName] = tinykeys(window, bindings);
      }
    },
    unbind: function unbind(viewName) {
      if (context.$dbg) {
        context.$dbg('bind-keys')('unbind', viewName);
      }
      if (viewName && Object.keys(unsubs).includes(viewName)) {
        unsubs[viewName]();
        delete unsubs[viewName];
        var onlyAllowIndex = onlyAllow.findIndex(function (name) {
          return name === viewName;
        });
        if (onlyAllowIndex >= 0) {
          Vue.delete(onlyAllow, onlyAllowIndex);
        }
      }
    }
  };
  inject('bindKeys', bindKeys);

  // For Nuxt <= 2.12, also add 👇
  context.$bindKeys = bindKeys;
});