
import _ from 'lodash';

import uiMixin from '../mixins/ui';

export default {
  name: 'ModalFull',
  mixins: [
    uiMixin,
  ],
  props: {
    uiName: {
      type: String,
      default: null,
    },
    isVisibleOverride: {
      type: Boolean,
      default: undefined,
    },
    title: {
      type: String,
      default: null,
    },
    conceptName: {
      type: String,
      default: null,
    },
    conceptsRelated: {
      type: Array,
      default() {
        return [];
      },
    },
    isConceptPlural: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Array,
      default() {
        return [];
      },
    },
    contentComponentName: {
      type: String,
      default: null,
    },
    contentComponentProps: {
      type: Object,
      default() {
        return {};
      },
    },
    contentSize: {
      type: String,
      default: 'md',
    },
    contentPaddingRemove: {
      type: Boolean,
      default: false,
    },
    contentAlignCenter: {
      type: Boolean,
      default: false,
    },
    contentScrollToBottom: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      return `${_.kebabCase(this.uiName)}-modal`;
    },
    uiNameHelp() {
      return `${this.uiName}Help`;
    },
    hasHelp() {
      return Boolean(this.$store.state.ui.modal[this.uiNameHelp]);
    },
    modalClasses() {
      const cls = [
        this.$store.getters['ui/modalClass'][this.uiName]
      ];

      if (this.hideHeader) {
        cls.push('modal-full-no-header');
        cls.push('modal-full-no-footer');
      }

      return cls;
    },
    isVisible() {
      return typeof this.isVisibleOverride === 'boolean' ? this.isVisibleOverride : this.$store.state.ui.modal[this.uiName].isVisible;
    },
    concepts() {
      let c = [];

      if (this.conceptName) {
        c.push(this.conceptName);
      }

      if (Array.isArray(this.conceptsRelated) && this.conceptsRelated.length > 0) {
        c = c.concat(this.conceptsRelated);
      }

      return c;
    },
    hasConcepts() {
      return this.concepts.length > 0 && this.conceptName !== this.appConceptName.Objective;
    },
  },
  watch: {
    isVisible() {
      if (this.contentScrollToBottom) {
        this.$nextTick(() => {
          if (this.isVisible) {
            const $el = this.$refs['modal-full-content'];
            $el.scrollTop = $el.scrollHeight;
          }
        });
      }

      if (this.isVisible) {
        this.$bindKeys.bind(`modal-full-${this.uiName}`, {
          Escape: () => {
            if (this.uiTopModalName === this.uiName) {
              this.handleModalVisibilityUpdate(false);
            }
          },
        });
      } else {
        this.$bindKeys.unbind(`modal-full-${this.uiName}`);
      }
    },
  },
  methods: {
    tryContainerClose(e) {
      const allowedClasses = ['modal'];

      if (!this.$store.state.ui.modal[this.uiName].isBackdropDisabled && allowedClasses.find(c => e.target.classList.contains(c))) {
        e.stopPropagation();
        this.handleModalVisibilityUpdate(false);
      }
    },
    handleModalVisibilityUpdate(isVisible) {
      if (this.uiName && !isVisible) {
        if (this.$store.state.ui.modal[this.uiName].payload && typeof this.$store.state.ui.modal[this.uiName].payload.onClose === 'function') {
          this.$store.state.ui.modal[this.uiName].payload.onClose();
        }

        if (typeof this.isVisibleOverride !== 'boolean') {
          this.$store.dispatch('ui/hideModal', this.uiName);
        }

        this.$emit('close');
      }
    },
  },
};
