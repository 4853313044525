import { z } from 'zod';

import { convertTimestampToDate } from './util';

export namespace ActionFocus {
  export const DataTableName: string = 'actionFocus';

  export const Model = z.object({
    itemId: z.optional(z.string().min(1)),
    startedAt: z.preprocess(
      convertTimestampToDate,
      z.date(),
    ),
  });
}
