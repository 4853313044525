
export default {
  name: 'ActionsPlanCycle',
  props: {
    isStatic: {
      type: Boolean,
      default: false,
    },
    diagramWidth: {
      type: String,
      default: '175px',
    },
    diagramHeight: {
      type: String,
      default: '150px',
    },
    diagramFontSize: {
      type: String,
      default: '1.5rem',
    },
  },
  data() {
    return {
      steps: [
        {
          iconName: 'ActionAdd',
          description: 'Add an action',
        },
        {
          iconName: 'ActionComplete',
          description: 'Complete an action',
        },
        {
          iconName: 'Value',
          description: 'Get value',
        },
        {
          iconName: 'ActionsPlan',
          description: 'Make progress towards eclipse',
        },
      ],
      stepActiveIndex: undefined,
      stepInterval: undefined,
    };
  },
  computed: {
    stepActive() {
      return typeof this.stepActiveIndex === 'number' ? this.steps[this.stepActiveIndex] : undefined;
    },
  },
  mounted() {
    if (!this.isStatic) {
      this.stepActiveIndex = 0;

      this.stepInterval = setInterval(() => {
        this.stepActiveIndex = this.stepActiveIndex >= this.steps.length - 1 ? 0 : this.stepActiveIndex + 1;
      }, 2000);
    }
  },
  destroyed() {
    if (this.stepInterval) {
      clearInterval(this.stepInterval);
      this.stepInterval = undefined;
    }
  },
};
