
import Vue, { VueConstructor } from 'vue';
import uiMixin from '../mixins/ui';

export default (Vue as VueConstructor<Vue & InstanceType<typeof uiMixin>>).extend({
  name: 'SuggestionBox',
  mixins: [
    uiMixin,
  ],
  data() {
    return {
      suggestion: undefined,
    };
  },
  mounted() {
    this.$bindKeys.bind('suggestion-box', {}, true);
  },
  destroyed() {
    this.$bindKeys.unbind('suggestion-box');
  },
  methods: {
    async handleSuggestion() {
      if (this.suggestion && this.suggestion.length >= 5) {
        await this.$store.dispatch('app/addSuggestion', this.suggestion);

        this.uiNotify('Suggestion sent, thanks for your help!', {
          severity: 'success',
          logMessage: 'suggestion submitted',
        });

        this.suggestion = undefined;
        return true;
      } else {
        this.uiNotify('Sorry, this suggestion is not valid.', {
          severity: 'warning',
          icon: ['fas', 'exclamation'],
        });
        return false;
      }
    },
  },
});
