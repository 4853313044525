/* global Purchasely */
import _ from 'lodash';
import { Capacitor } from '@capacitor/core';
import { wrapGetters } from '../lib/util';

export const state = () => ({
  /*
  [
    {
      "nextRenewalDate": "2022-12-04T11:28:17-0500",
      "product": {
        "plans": [
          {
            "productId": "EC_1m999_1W0",
            "name": "Monthly Basic (1 Week Trial)",
            "hasFreeTrial": true,
            "introPrice": "free",
            "period": "month",
            "amount": 9.99,
            "introDuration": "1 week",
            "introPeriod": "week",
            "type": 2,
            "localizedAmount": "$9.99",
            "price": "$9.99 / month",
            "currencySymbol": "$",
            "isEligibleForIntroOffer": true,
            "introAmount": 0,
            "vendorId": "01M01WT",
            "currencyCode": "USD"
          },
          {
            "productId": "EC_1y9999_1M0",
            "name": "Annual Basic (1 Month Trial)",
            "hasFreeTrial": true,
            "introPrice": "free",
            "period": "year",
            "amount": 99.99,
            "introDuration": "1 month",
            "introPeriod": "month",
            "type": 2,
            "localizedAmount": "$99.99",
            "price": "$99.99 / year",
            "currencySymbol": "$",
            "isEligibleForIntroOffer": true,
            "introAmount": 0,
            "vendorId": "01A01MT",
            "currencyCode": "USD"
          }
        ],
        "vendorId": "001",
        "name": "Basic"
      },
      "subscriptionSource": 0,
      "plan": {
        "productId": "EC_1y9999_1M0",
        "name": "Annual Basic (1 Month Trial)",
        "hasFreeTrial": true,
        "introPrice": "free",
        "period": "year",
        "amount": 99.99,
        "introDuration": "1 month",
        "introPeriod": "month",
        "type": 2,
        "localizedAmount": "$99.99",
        "price": "$99.99 / year",
        "currencySymbol": "$",
        "isEligibleForIntroOffer": true,
        "introAmount": 0,
        "vendorId": "01A01MT",
        "currencyCode": "USD"
      }
    }
  ]
  */
  purchaselySubscription: undefined,
});

export const mutations = {
  purchaselySubscription: (localState, val) => {
    localState.purchaselySubscription = val;
  },
};

export const getters = wrapGetters('purchases', {
  isSubscribed: (state) => {
    return _.isPlainObject(state.purchaselySubscription);
  },
  hasEntitlement: state => (entitlement) => {
    return _.isPlainObject(state.purchaselySubscription) &&
      state.purchaselySubscription.product?.name === entitlement;
  },
});

export const actions = {
  fetchSubscriptions({ commit }) {
    return new Promise((resolve, reject) => {
      if (Capacitor.isNativePlatform()) {
        Purchasely.userSubscriptions((subscriptions) => {
          // eslint-disable-next-line no-console
          // console.log('Subscriptions', subscriptions);

          if (typeof subscriptions[0] !== 'undefined') {
            commit('purchaselySubscription', subscriptions[0]);
          }

          resolve(subscriptions);
        }, (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          reject(error);
        });
      } else {
        resolve();
      }
    });
  },

  async init({ dispatch }) {
    if (Capacitor.isNativePlatform()) {
      const userId = await this.$auth.getUserId();

      // Purchasely available globally from the cordova plugin
      Purchasely.startWithAPIKey(
        'bf26332f-af28-4813-9d03-0f68c2f09da7',
        [],
        userId,
        Purchasely.LogLevel.DEBUG,
        Purchasely.RunningMode.full,
      );

      Purchasely.isReadyToPurchase(true);
      dispatch('fetchSubscriptions');

      Purchasely.purchasedSubscription(() => {
        dispatch('fetchSubscriptions');
      });
    }

    dispatch('setDataSourceSyncRemote', 'purchases', { root: true });
  },

  displaySubscriptions() {
    if (Capacitor.isNativePlatform()) {
      Purchasely.presentSubscriptions();
    }
  },

  displayPlacement({ dispatch }) {
    return new Promise((resolve, reject) => {
      if (Capacitor.isNativePlatform()) {
        Purchasely.presentPresentationForPlacement(
          'standard_interrupt',
          null, // contentId
          false, // fullscreen
          (result) => {
            if (result.result === Purchasely.PurchaseResult.PURCHASED) {
              // console.log("User purchased " + result.plan.name);
              dispatch('fetchSubscriptions');
              resolve(true);
            } else if (result.result === Purchasely.PurchaseResult.RESTORED) {
              // console.log("User restored " + result.plan.name);
              dispatch('fetchSubscriptions');
              resolve(true);
            } else if (result.result === Purchasely.PurchaseResult.CANCELLED) {
              // console.log("User cancelled purchased");
              resolve(false);
            }
          },
          (error) => {
            // console.log("Error with purchase : " + error);
            reject(error);
          },
        );
      }
    });
  },

  reset() {
    this.$dbg('store:purchases')('reset');

    if (Capacitor.isNativePlatform()) {
      Purchasely.userLogout();
    }
  },
};
