
import uiMixin from '../mixins/ui';

export default {
  name: 'NavBottom',
  mixins: [
    uiMixin,
  ],
  computed: {
    ideas() {
      return this.$store.getters.ideasFresh;
    },
  },
};
