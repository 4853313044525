
import _ from 'lodash';
import { z } from 'zod';

import effectsMixin from '../mixins/effects';
import uiMixin from '../mixins/ui';

import { Action } from '../api/src/models/Action';
import { App } from '../api/src/models/App';
import { Effect } from '../api/src/models/effect';
import { isDate } from '../api/src/models/util';

// Beyond the basic Action.Model, editing & saving an action has additional qualifications.
const ActionEditModel = Action.Model.omit({
  createdAt: true,
  userId: true,
}).extend({
  interest: Action.Model.shape.interest.refine(val => val.length > 0),
  effort: z.preprocess(
    val => Action.EffortImportance.RangeValueToFibonacciMap[val],
    Action.Model.shape.effort,
  ),
  importance: z.preprocess(
    val => Action.EffortImportance.RangeValueToFibonacciMap[val],
    Action.Model.shape.importance,
  ),
  remindAt: z.preprocess(
    val => (val || undefined),
    Action.Model.shape.remindAt,
  ),
});

const BY_TODAY = 'by today';
const BY_TOMORROW = 'by tomorrow';
const ANYTIME = 'anytime';

const effortImportanceMin = Action.EffortImportance.RangeValues[0];
const effortImportanceMid = 4;
const effortImportanceMax = Action.EffortImportance.RangeValues[Action.EffortImportance.RangeValues.length - 1];

const clamp = function(min, val, max) {
  return Math.max(min, Math.min(val, max));
};

export default {
  name: 'ActionEdit',
  mixins: [
    effectsMixin,
    uiMixin,
  ],
  props: {
    actionId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    const interestDefault = this.$store.getters['interests/defaultItem'];

    const actionDefault = _.cloneDeep({
      ...Action.ModelDefault,
      interest: interestDefault ? [interestDefault.id] : [],
      effort: Action.EffortImportance.FibonacciToRangeValueMap[Action.ModelDefault.effort],
      importance: Action.EffortImportance.FibonacciToRangeValueMap[Action.ModelDefault.importance],
      timeframe: [this.$store.getters.virtualDay.toDate()],
      // sl-select expects a string
      remindAt: '',
    });

    return {
      actionDefault,
      action: _.cloneDeep(actionDefault),

      isReady: false,
      isDirty: false,
      isSubmitting: false,

      isShowingMonthDateSelect: false,
      effortImportanceMin,
      effortImportanceMid,
      effortImportanceMax,
      effortDescriptorMap: Action.EffortImportance.EffortDescriptorMap,
      importanceDescriptorMap: Action.EffortImportance.ImportanceDescriptorMap,
      slidersDrag: {
        isDragging: false,
        start: {
          importance: undefined,
          effort: undefined,
        },
      },
      minDate: this.$store.getters.virtualDay.startOf('day').toDate(),
      isLinkPromptVisible: false,
      effectSelected: undefined,

      frequencyTimeframeIcon: ['fal', App.Concept.Setting[App.Concept.ConceptName.enum.ActionTimeframe].icon[1]],
      frequencyRepeatingIcon: ['fal', App.Concept.Setting[App.Concept.ConceptName.enum.ActionFrequencyRepeating].icon[1]],
    };
  },
  computed: {
    actionExisting() {
      return this.$store.getters.getActionById(this.actionId);
    },
    validationsPure() {
      const v = {};

      Object.keys(this.action).forEach((k) => {
        if (ActionEditModel.shape[k]) {
          v[k] = ActionEditModel.shape[k].safeParse(this.action[k]).success;
        }
      });

      return v;
    },
    validations() {
      const v = {};

      Object.keys(this.validationsPure).forEach((k) => {
        v[k] = !this.isDirty || this.validationsPure[k];
      });

      return v;
    },
    isAnyInvalid() {
      return Object.values(this.validationsPure).filter(i => !i).length > 0;
    },
    isTimeframeInvalid() {
      return !this.validations.timeframe;
    },
    hasActionInFocus() {
      return Boolean(this.$store.state.actionFocus.action);
    },
    itemsRepeatingOnVirtualDay() {
      return this.$store.getters['actionsUser/itemsRepeatingOnVirtualDay'];
    },
    descriptionPlaceholder() {
      return Action.DescriptionExamples[Math.floor(Math.random() * Action.DescriptionExamples.length)];
    },
    interests() {
      return this.$store.state.interests.all;
    },
    interestsOptions() {
      const interestsWithRecentActionsCompleted = this.$store.getters.interestsWithRecentActionsCompleted;
      const interestsRecentTotalVal = interestsWithRecentActionsCompleted.reduce((prevVal, i) => {
        return prevVal + i.value;
      }, 0);

      const getInterestRecentValuePercent = (i) => {
        let percent = 0;
        const recent = interestsWithRecentActionsCompleted.find(i2 => i2.id === i.id);

        if (recent) {
          percent = Math.min(1, Math.max(0, recent.value / interestsRecentTotalVal));
        }

        return percent;
      };

      const selectedInterests = this.interests
        .filter(i => this.action.interest.includes(i.id))
        .map(i => ({
          label: i.name,
          icon: i.icon,
          id: i.id,
          percentOfValue: getInterestRecentValuePercent(i),
          color: this.$store.getters['interests/getColorForItem'](i),
        }));

      const recentInterests = interestsWithRecentActionsCompleted
        // Filter out selected interests
        .filter(i => !this.action.interest.includes(i.id))
        .map(i => ({
          label: i.name,
          icon: i.icon,
          id: i.id,
          percentOfValue: getInterestRecentValuePercent(i),
          color: this.$store.getters['interests/getColorForItem'](i),
        }));

      const otherInterests = this.interests
        .filter((i) => {
          // Filter out recent interests
          return !recentInterests.find(ii => ii.id === i.id) &&
            // Filter out selected interests
            !this.action.interest.includes(i.id);
        })
        .map(i => ({
          label: i.name,
          icon: i.icon,
          id: i.id,
          percentOfValue: 0,
          color: '#ffffff',
        }));

      // sort by name
      otherInterests.sort(function(a, b) {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      return selectedInterests.concat(recentInterests).concat(otherInterests);
    },
    interestsSelected() {
      let interests = [];

      if (Array.isArray(this.action.interest) && this.action.interest.length > 0 && this.interestsOptions.length > 0) {
        interests = this.interestsOptions.filter(i => this.action.interest.includes(i.id));
      }

      return interests.map(i => i.id);
    },
    hasEffortImportance() {
      return !!this.action.effort && !!this.action.importance;
    },
    isEffortImportanceValid() {
      return this.validations.effort && this.validations.importance;
    },
    effortImportanceSlidersClasses() {
      return {
        'action-edit-sliders-clean': !this.hasEffortImportance,
        'action-edit-sliders-invalid': !this.isEffortImportanceValid,
      };
    },
    importanceActual() {
      return Action.EffortImportance.RangeValueToFibonacciMap[this.action.importance];
    },
    effortActual() {
      return Action.EffortImportance.RangeValueToFibonacciMap[this.action.effort];
    },
    isEffortHigh() {
      return !!this.action.effort && this.action.effort > this.effortImportanceMid;
    },
    isFrequencyRepeating() {
      return Action.isFrequencyRepeating(this.action.frequency);
    },
    isFrequencyMonth() {
      return this.action.frequency === Action.Frequency.enum.Month;
    },
    isFrequencyMonthDateGuaranteed() {
      return this.isFrequencyMonth && Number(this.action.frequencyMonthDate) <= 28;
    },
    hasTimeframe() {
      return Array.isArray(this.action.timeframe) && isDate(this.action.timeframe[0]);
    },
    hasTimeframeStartAndEnd() {
      return this.hasTimeframe && isDate(this.action.timeframe[1]);
    },
    timeframeMaturesAtDay() {
      return this.hasTimeframeStartAndEnd ? this.$dayjs(this.action.timeframe[0]) : undefined;
    },
    timeframeExpiresAt() {
      return this.hasTimeframe
        ? (this.hasTimeframeStartAndEnd ? this.action.timeframe[1] : this.action.timeframe[0])
        : undefined;
    },
    timeframeExpiresAtDay() {
      return this.hasTimeframe ? this.$dayjs(this.timeframeExpiresAt) : undefined;
    },
    hasSpecificDay() {
      return this.hasTimeframe || this.isFrequencyRepeating;
    },
    timeframeText() {
      return this.hasTimeframe ? this.getTimeframeText(this.timeframeMaturesAtDay, this.timeframeExpiresAtDay) : '';
    },
    frequencyTimeframeOptions() {
      const opts = [];

      opts.push({
        valueTimeframeSet: undefined,
        valueTimeframeTest: val => !val,
        value: ANYTIME,
        label: 'Anytime',
      });
      opts.push({
        valueTimeframeSet: [this.$store.getters.virtualDay.toDate()],
        // will automatically push overdue items to today when editing
        valueTimeframeTest: val => Array.isArray(val) && this.$store.getters.virtualDay.isSameOrAfter(val[0], 'day'),
        value: BY_TODAY,
        label: 'Today',
      });
      opts.push({
        valueTimeframeSet: [this.$store.getters.virtualTomorrow.toDate()],
        valueTimeframeTest: val => Array.isArray(val) && this.$store.getters.virtualTomorrow.isSame(val[0], 'day'),
        value: BY_TOMORROW,
        label: 'Tomorrow',
      });

      return opts;
    },
    frequencyTimeframeOptionSelected() {
      let opt;

      if (!this.isFrequencyRepeating) {
        opt = this.frequencyTimeframeOptions.find(opt => opt.valueTimeframeTest(this.action.timeframe));
      }

      return opt;
    },
    isTimeframeCustom() {
      return Array.isArray(this.action.timeframe) && this.$store.getters.virtualTomorrow.isBefore(this.action.timeframe[0], 'day');
    },
    timeframeModel: {
      get() {
        return this.timeframeExpiresAt;
      },
      set(val) {
        if (val instanceof Date) {
          this.handleChange('timeframe', [val]);
          this.handleChange('frequency', Action.Frequency.enum.Once);
        }
      },
    },
    frequencyMonthDateOptions() {
      const opts = [];
      const d = this.$dayjs().month(12); // have to use a month with 31 days

      for (let i = 1; i <= 31; i++) {
        opts.push({
          text: d.date(i).format('Do'),
          value: i,
        });
      }

      return opts;
    },
    frequencyMonthDateOptionSelected() {
      return this.frequencyMonthDateOptions.find(o => o.value === this.action.frequencyMonthDate);
    },
    frequencyRepeatingOptions() {
      const opts = [];

      Object.keys(Action.FrequencyDisplayMap).forEach((text) => {
        const value = Action.FrequencyDisplayMap[text];

        if (value !== Action.Frequency.enum.Once) {
          opts.push({
            value,
            text,
          });
        }
      });

      return opts;
    },
    frequencyRepeatingOptionSelected() {
      let opt;

      if (this.isFrequencyRepeating) {
        const optFound = this.frequencyRepeatingOptions.find(o => o.value === this.action.frequency);

        if (optFound) {
          opt = {
            ...optFound,
            textHelp: undefined,
          };

          if (opt.value === Action.Frequency.enum.Month && this.frequencyMonthDateOptionSelected) {
            opt = {
              ...opt,
              text: `${opt.text} on the ${this.frequencyMonthDateOptionSelected.text}`,
            };

            if (!this.isFrequencyMonthDateGuaranteed) {
              opt.textHelp = this.action.frequencyMonthDate === 31
                ? '* or the last day of the month'
                : '* February: day 28 or 29';
            }
          }
        }
      }

      return opt;
    },
    remindAtOptions() {
      const d = this.$store.getters.dayStartingAtUserResetTime().startOf('hour');
      const dHour = d.hour();
      const opts = [];
      let i;
      let ii;

      for (i = 0; i < 24; i++) {
        for (ii = 0; ii < 4; ii++) {
          const val = d.hour(dHour + i).minute(ii * 15);
          opts.push({
            label: val.format(Action.RemindAtFormatDisplay),
            value: val.format(Action.RemindAtFormatData),
          });
        }
      }

      return opts;
    },
    addAnother: {
      get() {
        return this.$store.state.ui.modal.actionEdit.payload.addAnother === true;
      },
      set(addAnother) {
        this.$store.commit('ui/setModalPayload', {
          name: 'actionEdit',
          payload: {
            addAnother,
          },
        });
      },
    },
    effectTargets() {
      let targets = [Effect.Target.enum.ActionOnce];

      if (this.isFrequencyRepeating) {
        targets = [Effect.Target.enum.ActionRepeating];
      }

      return targets;
    },
  },
  watch: {
    isDirty() {
      this.$emit('update:isDirty', this.isDirty);
    },
    actionExisting: {
      handler() {
        if (this.actionExisting) {
          const a = _.cloneDeep({
            ...this.actionDefault,
            ...this.actionExisting,
          });

          a.effort = Action.EffortImportance.FibonacciToRangeValueMap[a.effort];
          a.importance = Action.EffortImportance.FibonacciToRangeValueMap[a.importance];

          // This doesn't correct the old legacy format of [0]="maturesAt" and [1]="expiresAt"
          if (Array.isArray(a.timeframe) && isDate(a.timeframe[0]) && this.$store.getters.virtualDay.isAfter(a.timeframe[0], 'day')) {
            a.timeframe[0] = this.$store.getters.virtualDay.toDate();
            this.isDirty = true;
          }

          this.action = a;
        }
      },
      immediate: true,
    },
    hasSpecificDay() {
      this.handleChange('remindAt', null);
    },
    interests() {
      this.handleChange('interest', this.action.interest.filter(i => this.interests.find(ii => ii.id === i)));
    },
  },
  async mounted() {
    this.$bindKeys.bind('action-edit', {}, true);

    if (!this.$store.getters.hasInterests) {
      this.$dbg('action-edit')('mounted', 'addDefaultItems');
      await this.$store.dispatch('interests/addDefaultItems');
      const interestDefault = this.$store.getters['interests/defaultItem'];
      this.action.interest = interestDefault ? [interestDefault.id] : [];
      this.uiNotify('Added default interests', {
        logMessage: true,
        conceptName: App.Concept.ConceptName.enum.Interest,
      });
    }

    this.isReady = true;
  },
  destroyed() {
    this.$bindKeys.unbind('action-edit');
  },
  methods: {
    handleChange(key, val) {
      /* tc-range-slider fires some change events before mounted(). This prevents us from
       * setting isDirty=true before the user has actually changed anything.
       */
      if (this.isReady) {
        this.$dbg('action-edit')('handleChange', key, val);

        if (Object.keys(this.action).includes(key)) {
          this.$set(this.action, key, val);
          this.isDirty = true;
        }
      }
    },
    handleFrequencyTimeframeOptionSelected(option) {
      if (option) {
        this.handleChange('frequency', Action.Frequency.enum.Once);
        this.handleChange('timeframe', option.valueTimeframeSet);
      }
    },
    handleFrequencyRepeatingOptionSelected(option) {
      if (option) {
        this.handleChange('frequency', option.value);
        this.handleChange('timeframe', undefined);

        if (option.value === Action.Frequency.enum.Month) {
          this.isShowingMonthDateSelect = true;

          if (!this.action.frequencyMonthDate) {
            this.handleChange('frequencyMonthDate', 1);
          }
        }
      }
    },
    getInterestPercentPath(p) {
      function getCoordinatesForPercent(percent) {
        const x = Math.cos(2 * Math.PI * percent);
        const y = Math.sin(2 * Math.PI * percent);
        return [x, y];
      }

      // destructuring assignment sets the two variables at once
      const [startX, startY] = getCoordinatesForPercent(0);
      const [endX, endY] = getCoordinatesForPercent(p);

      // if the slice is more than 50%, take the large arc (the long way around)
      const largeArcFlag = p > 0.5 ? 1 : 0;

      // create an array and join it just for code readability
      const pathData = [
        `M ${startX} ${startY}`, // Move
        `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
        'L 0 0', // Line
      ].join(' ');

      return pathData;
    },
    getDaysUntilDay(d) {
      return Math.ceil(d.diff(this.$store.getters.virtualDay, 'day', true));
    },
    getLabelForTimeframeDay(d, includeYear = true) {
      const diff = this.getDaysUntilDay(d);
      return (diff >= 0 && diff <= 1) ? (diff === 1 ? 'tomorrow' : 'today') : (includeYear ? d.format('MM/DD/YYYY') : d.format('MM/DD'));
    },
    getTimeframeText(maturesAtDay, expiresAtDay) {
      let txt;

      if (expiresAtDay) {
        const exp = this.getLabelForTimeframeDay(expiresAtDay);
        txt = `by ${exp}`;

        if (maturesAtDay && expiresAtDay) {
          const diff = expiresAtDay.diff(maturesAtDay, 'days');

          if (diff === 0) {
            const daysFromNow = this.getDaysUntilDay(maturesAtDay);

            // essentially predicting 'today' or 'tomorrow' from getLabelForTimeframeDay()
            txt = [0, 1].includes(daysFromNow) ? exp : `on ${exp}`;
          } else {
            txt = `between ${this.getLabelForTimeframeDay(maturesAtDay, false)} and ${exp}`;
          }
        }
      }

      return txt;
    },
    getEffortImportanceTextJoin(effortText, importanceText) {
      const text = [];
      text.push(effortText);
      text.push(importanceText);
      return text.join(' and ');
    },
    handleSlidersTouch() {
      if (!this.validations.effort) {
        this.handleChange('effort', Action.EffortImportance.FibonacciToRangeValueMap[Action.ModelDefault.effort]);
      }

      if (!this.validations.importance) {
        this.handleChange('importance', Action.EffortImportance.FibonacciToRangeValueMap[Action.ModelDefault.importance]);
      }
    },
    handleSlidersDrag({ movement: [x, y], dragging }) {
      if (!this.hasEffortImportance) {
        this.handleSlidersTouch();
      }

      this.$set(this.slidersDrag, 'isDragging', dragging);

      let startImportance = this.slidersDrag.start.importance;
      let startEffort = this.slidersDrag.start.effort;

      if (!startImportance) {
        this.$set(this.slidersDrag.start, 'importance', this.action.importance);
        startImportance = this.action.importance;
      }

      if (!startEffort) {
        this.$set(this.slidersDrag.start, 'effort', this.action.effort);
        startEffort = this.action.effort;
      }

      if (dragging) {
        const stepThreshold = 30; // px in drag area
        const stepX = Math.floor(x / stepThreshold);
        const stepY = Math.floor(y / stepThreshold);

        this.handleChange('effort', clamp(effortImportanceMin, startEffort - stepY, effortImportanceMax));
        this.handleChange('importance', clamp(effortImportanceMin, startImportance + stepX, effortImportanceMax));
      } else {
        this.$set(this.slidersDrag, 'start', { importance: undefined, effort: undefined });
      }
    },
    handleRemindAtShown() {
      if (!this.action.remindAt) {
        const val = this.$dayjs().startOf('hour').add(1, 'hour');
        this.handleChange('remindAt', val.format(Action.RemindAtFormatData));
      }
    },
    getVirtualDateForDate(val) {
      if (val instanceof Date) {
        let d = this.$store.getters.dayStartingAtUserResetTime(val);

        // if we jumped back a day due to midnight being before the user reset time...
        if (this.$dayjs(val).date() !== d.date()) {
          d = d.add(1, 'day');
        }

        return d.toDate();
      }
    },
    showEffects() {
      this.$store.dispatch('ui/showModal', {
        name: 'effectsSelect',
        payload: {
          effectTargets: this.effectTargets,
          description: `save & apply to "${this.action.description}"`,
          onSelect: (effect) => {
            this.$store.dispatch('ui/hideModal', 'effectsSelect');
            this.effectSelected = effect;
            this.handleSubmit();
          },
        },
      });
    },
    handleInvalid() {
      const msg = this.isTimeframeInvalid ? 'Invalid action date' : 'Invalid action';
      this.uiNotify(msg, {
        severity: 'warning',
        icon: ['fas', 'triangle-exclamation'],
      });
    },
    async submit() {
      if (this.isAnyInvalid) {
        this.handleInvalid();
        return;
      }

      const mods = {
        ...this.action,
        importance: this.importanceActual,
        effort: this.effortActual,
        remindAt: this.action.remindAt || null,
      };

      if (this.hasTimeframe) {
        mods.timeframe = [
          this.getVirtualDateForDate(this.action.timeframe[0]),
          (this.hasTimeframeStartAndEnd ? this.getVirtualDateForDate(this.action.timeframe[1]) : null),
        ];
      }

      if (this.isFrequencyRepeating) {
        mods.status = Action.Status.enum.Todo;
        mods.timeframe = undefined;
        mods.frequencyMonthDate = this.isFrequencyMonth ? Math.max(1, Math.min(31, Number(this.action.frequencyMonthDate))) : null;
        mods.occurrenceOf = undefined;
        mods.value = undefined;
      }

      const { id } = await this.$store.dispatch('actionsUser/editItem', { id: this.actionId, mods });

      if (id) {
        const msg = `Action ${this.actionId ? ' edited' : ' added'}`;
        const icon = this.actionId ? ['fas', 'pen'] : undefined;
        const conceptName = !this.actionId ? App.Concept.ConceptName.enum.ActionAdd : undefined;

        this.uiNotify(msg, {
          icon,
          conceptName,
          logMessage: true,
        });
      } else {
        this.uiNotify('An error occurred while saving. Please try again.', {
          severity: 'warning',
          icon: ['fas', 'triangle-exclamation'],
        });
      }

      return id;
    },
    async handleSubmit() {
      if (this.isAnyInvalid) {
        this.handleInvalid();
        return;
      }

      this.isSubmitting = true;
      const itemId = await this.submit();

      if (itemId) {
        const payloadAddAnother = {
          itemId: undefined,
          addAnother: this.addAnother,
        };

        this.$emit('close', true);
        await this.waitForScene();

        if (this.effectSelected) {
          await this.effectPrompt(this.effectSelected, { itemId });
          await this.waitForScene();
        }

        if (this.effectSelected?.name !== Effect.EffectName.enum.Focus && payloadAddAnother.addAnother) {
          window.setTimeout(() => {
            this.$store.dispatch('ui/showModal', { name: 'actionEdit', payload: payloadAddAnother });
          }, 500);
        }
      }

      this.isSubmitting = false;
    },
    waitForScene() {
      let wait = Promise.resolve();

      if (this.$store.getters['wait/is']('scene-watch')) {
        wait = new Promise((resolve) => {
          let count = 0;
          const int = setInterval(() => {
            count += 1;

            if (!this.$store.getters['wait/is']('scene-watch') || count === 10) {
              clearInterval(int);
              resolve();
            }
          }, 500);
        });
      }

      return wait;
    },
  },
};
