
export default {
  name: 'EcDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    classes: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: 'Alert',
    },
    titleIcon: {
      type: Array,
      default() {
        return [];
      },
    },
    conceptName: {
      type: String,
      default: undefined,
    },
    message: {
      type: String,
      default: '',
    },
    messages: {
      type: Array,
      default() {
        return [];
      },
    },
    asTemplate: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    footerOkLabel: {
      type: String,
      default: 'OK',
    },
    footerOkVariant: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    open() {
      return this.isOpen;
    },
  },
  methods: {
    handleHide() {
      this.$emit('hide');
    },
    handleOk() {
      this.$emit('ok');
      this.handleHide();
    },
  },
};
