
import AppUpdateMessage from './app-update-message';
import ActionFocusMessage from './action-focus-message';
import ActionRemindMessage from './action-remind-message';
import SubscribeMessage from './subscribe-message';

export default {
  name: 'MessagesView',
  components: {
    AppUpdateMessage,
    ActionFocusMessage,
    ActionRemindMessage,
    SubscribeMessage,
  },
  computed: {
    MessageTypeComponentMap() {
      return {
        [this.$store.state.ui.MessageType.AppUpdate]: 'app-update-message',
        [this.$store.state.ui.MessageType.Focus]: 'action-focus-message',
        [this.$store.state.ui.MessageType.Reminder]: 'action-remind-message',
        [this.$store.state.ui.MessageType.Subscribe]: 'subscribe-message',
      };
    },
    messages() {
      return this.$store.getters['ui/messages'];
    },
  },
  watch: {
    messages(newVal, oldVal) {
      if (newVal.length === 0 && oldVal.length > 0) {
        this.$emit('close');
      }
    },
  },
  methods: {
    dismissMessageByIndex(index) {
      this.$store.commit('ui/dismissMessageByIndex', index);
    },
    getComponentNameFromMessageType(type) {
      return this.MessageTypeComponentMap[type];
    },
  },
};
