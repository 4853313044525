import { z } from 'zod';

import { EffectStandard } from './EffectStandard';
import Completion from './Completion';
import Focus from './Focus';
import Resolve from './Resolve';
import Division from './Division';
import Recovery from './Recovery';
import Forgiveness from './Forgiveness';
import Postponement from './Postponement';
import ReminderDelay from './ReminderDelay';

export namespace Effect {
  export const EffectName = EffectStandard.EffectName;
  export const Target = EffectStandard.Target;

  export const Setting: Partial<Record<z.infer<typeof EffectStandard.EffectName>, z.infer<typeof EffectStandard.Model>>> = {
    [EffectStandard.EffectName.enum.Completion]: Completion,
    [EffectStandard.EffectName.enum.Focus]: Focus,
    [EffectStandard.EffectName.enum.Resolve]: Resolve,
    [EffectStandard.EffectName.enum.Postponement]: Postponement,
    [EffectStandard.EffectName.enum.Division]: Division,
    [EffectStandard.EffectName.enum.Forgiveness]: Forgiveness,
    [EffectStandard.EffectName.enum.Recovery]: Recovery,
    [EffectStandard.EffectName.enum.ReminderDelay]: ReminderDelay,
  };
}
