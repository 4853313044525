import { z } from 'zod';
import { EffectStandard } from './EffectStandard';

export default {
  name: EffectStandard.EffectName.enum.Forgiveness,
  label: 'Forgiveness',
  verb: 'Forgive',
  targets: [EffectStandard.Target.enum.ActionRepeating],
  icon: ['fal', 'dove'],
  color: 'var(--sl-color-cyan-600)',
  description: 'Forgive yourself for missing a repeating action completion.',
  effectCheck: EffectStandard.isAction,
  getEffectData({ props }) {
    return Promise.resolve({
      itemId: props.itemId,
    });
  },
  effect({ $store, effectData, effect }) {
    return new Promise((resolve) => {
      $store.dispatch('ui/showModal', {
        name: 'actionLogCompletion',
        payload: {
          itemId: effectData.itemId,
          effectName: EffectStandard.EffectName.enum.Forgiveness,
          titleIcon: effect.icon,
          verb: effect.verb,
          promptText: 'What day would you like to forgive?',
        },
      });

      EffectStandard.logEvent({ $store, effectName: effect.name });
      resolve(true);
    });
  },
} as z.infer<typeof EffectStandard.Model>;
