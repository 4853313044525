
import Completion from '~/api/src/models/effect/Completion';

export default {
  name: 'ActionRecentCompletions',
  props: {
    recentCompletions: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      effectColor: Completion.color,
    };
  },
};
