
import { OrbitSpinner } from 'epic-spinners';
import { App } from '../api/src/models/App';

export default {
  name: 'IconView',
  components: {
    OrbitSpinner,
  },
  props: {
    name: {
      type: String,
      default: undefined,
    },
    icon: {
      type: Array,
      default: () => {
        return [];
      },
    },
    overrideIconSet: {
      type: String,
      default: undefined,
    },
    classes: {
      type: String,
      default: '',
    },
    callingType: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    iconResult() {
      let icon;

      if (App.Concept.ConceptName.options.includes(this.name) && App.Concept.Setting[this.name].icon) {
        icon = App.Concept.Setting[this.name].icon;
      } else if (Array.isArray(this.icon) && this.icon.length === 2) {
        icon = this.icon;
      }

      if (icon && this.overrideIconSet) {
        icon = [this.overrideIconSet, icon[1]];
      }

      return icon;
    },
  },
};
