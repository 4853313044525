import { render, staticRenderFns } from "./actions-data-view.vue?vue&type=template&id=db1f471a&"
import script from "./actions-data-view.vue?vue&type=script&lang=js&"
export * from "./actions-data-view.vue?vue&type=script&lang=js&"
import style0 from "./actions-data-view.vue?vue&type=style&index=0&id=db1f471a&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ActionListItem: require('/opt/build/repo/components/action-list-item.vue').default})
