import _ from 'lodash';
import Vue from 'vue';
import dayjs from '../lib/dayjs';
import { wrapGetters } from '../lib/util';

import {
  StoreHelperMutation,
  storeHelperMutations,
} from '../lib/store-helpers';

import {
  upsertDoc,
  bindToDoc,
} from '../lib/data';

import { Avatar } from '../api/src/models/Avatar';
import { Entitlement } from '../api/src/models/Entitlement';
import { Objective } from '../api/src/models/Objective';
import { Resource } from '../api/src/models/Resource';
import { UserSettings } from '../api/src/models/UserSettings';

let unsub;

export const state = () => ({
  ...UserSettings.ModelDefault,
});

export const mutations = {
  ...storeHelperMutations,
  didAcceptCookiePolicy(localState, didAccept) {
    localState.didAcceptCookiePolicy = didAccept === true;
  },
};

export const getters = wrapGetters('userSettings', {
  canEdit: state => _.isDate(state.createdAt),
  getSettingForEntitlement: state => (entitlement) => {
    let setting;

    if (Array.isArray(state.entitlements)) {
      setting = state.entitlements.find(e => e.name === entitlement);
    }

    return setting;
  },
  getEntitlementExpiresAtWithGracePeriod: () => (expiresAtDay) => {
    return expiresAtDay.add(Entitlement.GracePeriodHours, 'hours');
  },
  getEntitlementStatus: (_state, getters, rootState, rootGetters) => (entitlement) => {
    let status = rootGetters['purchases/isSubscribed']
      ? Entitlement.EntitlementStatus.enum.Active
      : Entitlement.EntitlementStatus.enum.Missing;

    const setting = getters.getSettingForEntitlement(entitlement);

    if (setting && _.isDate(setting.expiresAt)) {
      const expiresAt = dayjs(setting.expiresAt);

      if (setting.isLifetime || dayjs(0).isSameOrAfter(expiresAt)) {
        status = Entitlement.EntitlementStatus.enum.Lifetime;
      } else if (rootState.now.isBefore(expiresAt)) {
        status = Entitlement.EntitlementStatus.enum.Active;
      } else {
        const expiresAtGrace = getters.getEntitlementExpiresAtWithGracePeriod(expiresAt);

        if (rootState.now.isBetween(expiresAt.subtract(1, 'day'), expiresAt)) {
          status = Entitlement.EntitlementStatus.enum.ExpiringSoon;
        } else if (rootState.now.isBetween(expiresAt, expiresAtGrace)) {
          status = Entitlement.EntitlementStatus.enum.ExpiredGrace;
        } else if (rootState.now.isAfter(expiresAtGrace)) {
          status = Entitlement.EntitlementStatus.enum.Expired;
        }
      }
    }

    return status;
  },
  hasEntitlement: (_state, getters) => (entitlement) => {
    return ![Entitlement.EntitlementStatus.enum.Missing, Entitlement.EntitlementStatus.enum.Expired].includes(getters.getEntitlementStatus(entitlement));
  },
  isEntitlementExpiringSoon: (_state, getters) => (entitlement) => {
    return [Entitlement.EntitlementStatus.enum.ExpiringSoon].includes(getters.getEntitlementStatus(entitlement));
  },
  isEntitlementExpiredGrace: (_state, getters) => (entitlement) => {
    return [Entitlement.EntitlementStatus.enum.ExpiredGrace].includes(getters.getEntitlementStatus(entitlement));
  },

  journeyStartedAtDay: (state) => {
    return state.journeyStartedAt ? dayjs(state.journeyStartedAt) : undefined;
  },
  dayStartedAtDay: (state) => {
    return state.dayStartedAt ? dayjs(state.dayStartedAt) : undefined;
  },
  shouldPromptDayStart: (state, getters, _rootState, rootGetters) => {
    return !getters.journeyStartedAtDay || !getters.dayStartedAtDay || rootGetters.virtualDay.isAfter(state.dayStartedAt);
  },
  daysCount: (_state, getters) => {
    const { journeyStartedAtDay, dayStartedAtDay } = getters;
    let days = 0;

    if (journeyStartedAtDay && dayStartedAtDay) {
      if (journeyStartedAtDay.isSame(dayStartedAtDay, 'day')) {
        days = 1;
      } else if (journeyStartedAtDay.isBefore(dayStartedAtDay, 'day')) {
        days = Math.ceil(dayStartedAtDay.diff(journeyStartedAtDay, 'days', true));
      }
    }

    return days;
  },
  shouldPromptEffectsView: (state, _getters, _rootState, rootGetters) => {
    // random custom effects are based on virtualDay (see store/effects and effects-list).
    const effectsViewedAtDay = state.effectsViewedAt ? dayjs(state.effectsViewedAt) : undefined;
    return !effectsViewedAtDay || effectsViewedAtDay.isBefore(rootGetters.virtualDay);
  },

  avatarSelected: (state) => {
    return Array.isArray(state.avatars)
      ? state.avatars.find(a => a.isSelected)
      : undefined;
  },
  companionActive: (state, _getters, rootState) => {
    return Array.isArray(state.avatars)
      ? state.companions.find(c => dayjs(c.expiresAt).isAfter(rootState.now))
      : undefined;
  },
});

export const actions = {
  async init({ commit, dispatch }) {
    const userId = await this.$auth.getUserId();

    if (!userId) {
      throw new Error('Could not bind user settings for invalid userId');
    }

    this.$dbg('store:userSettings')('init');

    unsub = bindToDoc(UserSettings.Model, UserSettings.DataTableName, userId, {
      onInit: ({ isEmpty }) => {
        this.$dbg('store:userSettings')('bindToDoc:onInit');
        dispatch('setDataSourceSync', { name: 'userSettings' }, { root: true });

        // This could be falsely triggered if somehow all of the user's settings were deleted
        if (isEmpty) {
          dispatch('logEvent', { name: 'sign_up' }, { root: true });
        } else {
          dispatch('logEvent', { name: 'login' }, { root: true });
        }
      },
      onUpdate: ({ data }) => {
        // Upon signup, this won't fire until the backend primes the userSettings doc
        this.$dbg('store:userSettings')('bindToDoc:onUpdate');
        commit(StoreHelperMutation.SetOnRoot, data);
      },
    });
  },

  async edit({ getters, commit, dispatch, rootGetters }, mods) {
    if (!rootGetters.isUserInitialized) {
      return;
    }

    // Get userId directly from auth to ensure they still have a session
    const userId = await this.$auth.getUserId();

    if (!userId) {
      throw new Error('Could not put user settings for invalid userId');
    }

    if (!getters.canEdit && !mods.createdAt) {
      return;
    }

    this.$dbg('store:userSettings')('edit', mods);

    /* This works except when setting the entitlement itself!
    // The rootGetters check includes isNewUser
    if (!rootGetters.hasEntitlement(Entitlement.EntitlementName.enum.Basic)) {
      throw new Error('Entitlement required.');
    }
    */

    /* We don't want to send the whole state, which risks overwriting back to default in some race conditions.
     * The upsert is a merge, so it's safe to only send the mods. That means we have to manualy parse before
     * calling upsertDoc and we cannot send UserSettings.Model to upsertDoc.
     */
    const writeMods = {};

    Object.keys(mods).forEach((k) => {
      if (UserSettings.Model.shape[k]) {
        const result = UserSettings.Model.shape[k].safeParse(mods[k]);

        if (result.success) {
          writeMods[k] = result.data;
        } else {
          this.$dbg('store:userSettings')('edit', 'PARSE FAILED', mods, result.error);
        }
      }
    });

    const data = upsertDoc(undefined, UserSettings.DataTableName, userId, writeMods);

    delete data.id;

    // because bindToDoc.onUpdate only fires if online?
    commit(StoreHelperMutation.SetOnRoot, data);
    dispatch('logEvent', { name: 'user_settings_edit' }, { root: true });
  },

  async editObjectives({ state, dispatch }, objectivesMods = []) {
    this.$dbg('store:userSettings')('editObjectives', objectivesMods);
    const objectivesWrite = [];

    Object.keys(Objective.Setting).forEach((name) => {
      const userSetting = state.objectives?.find(i => i.name === name);
      const objMod = objectivesMods.find(i => i.name === name) || {};
      const result = UserSettings.ObjectiveSchema.safeParse({
        isUnlocked: userSetting?.isUnlocked === true,
        didView: userSetting?.didView === true,
        isSuccessful: userSetting?.isSuccessful === true,
        ...objMod,
        name,
      });

      if (!result.success) {
        this.$dbg('store:userSettings')('editObjectives', `skipping write for invalid objective ${name}`, result.error);
      } else {
        objectivesWrite.push(result.data);
      }
    });

    if (JSON.stringify(objectivesWrite) !== JSON.stringify(state.objectives)) {
      await dispatch('edit', {
        objectives: objectivesWrite,
      });

      dispatch('logEvent', { name: 'user_settings_edit_objectives' }, { root: true });
      return true;
    }
  },

  async editResources({ state, dispatch }, resourcesMods = []) {
    this.$dbg('store:userSettings')('editResources', resourcesMods);
    const resourcesWrite = [];

    Object.keys(Resource.Setting).forEach((name) => {
      const userSetting = state.resources?.find(i => i.name === name);
      const rMod = resourcesMods.find(i => i.name === name) || {};
      const result = UserSettings.ResourceSchema.safeParse({
        count: userSetting?.count,
        ...rMod,
        name,
      });

      if (!result.success) {
        this.$dbg('store:userSettings')('editResources', `skipping write for invalid resource ${name}`, result.error);
      } else {
        resourcesWrite.push(result.data);
      }
    });

    if (JSON.stringify(resourcesWrite) !== JSON.stringify(state.resources)) {
      await dispatch('edit', {
        resources: resourcesWrite,
      });

      dispatch('logEvent', { name: 'user_settings_edit_resources' }, { root: true });
      return true;
    }
  },

  async setSystemUpgradeValueIndex({ state, dispatch }, { name, valueIndex }) {
    this.$dbg('store:userSettings')('setSystemUpgradeValueIndex', name, valueIndex);
    const result = UserSettings.SystemUpgradeSchema.safeParse({
      name,
      valueIndex,
    });

    if (result.error) {
      this.$dbg('store:userSettings')(
        'setSystemUpgradeValueIndex',
        'failed to set system upgrade',
        name,
        valueIndex,
        result.error,
      );
      return false;
    }

    const existing = [...state.systemUpgrades];
    const existingIndex = existing.findIndex(i => i.name === name);

    if (existingIndex >= 0) {
      existing[existingIndex] = result.data;
    } else {
      existing.push(result.data);
    }

    await dispatch('edit', {
      systemUpgrades: existing,
    });

    dispatch('logEvent', { name: 'user_settings_set_system_upgrades' }, { root: true });
    return true;
  },

  async addEffectCustom({ state, dispatch }, props) {
    this.$dbg('store:userSettings')('addEffectCustom', props);
    const result = UserSettings.EffectsCustomSchema.safeParse(props);

    if (result.error) {
      this.$dbg('store:userSettings')('addEffectCustom', 'failed to add custom effect', props, result.error);
      return false;
    }

    const existing = state.effectsCustom;

    await dispatch('edit', {
      effectsCustom: [
        ...existing,
        result.data,
      ],
    });

    dispatch('logEvent', { name: 'user_settings_add_effect_custom' }, { root: true });
    return true;
  },

  async discardEffectCustom({ state, dispatch, rootGetters }, hash) {
    this.$dbg('store:userSettings')('discardEffectCustom', hash);
    const existing = [...state.effectsCustom];

    if (Array.isArray(existing) && existing.length > 0) {
      const index = existing.findIndex(i => i.hash === hash);

      if (index >= 0) {
        const result = UserSettings.EffectsCustomSchema.safeParse({
          ...existing[index],
          isDiscarded: true,
          deleteAt: rootGetters.virtualTomorrow.toDate(),
        });

        if (result.success) {
          existing[index] = result.data;
          await dispatch('edit', {
            effectsCustom: existing,
          });

          dispatch('logEvent', { name: 'user_settings_discard_effect_custom' }, { root: true });
          return true;
        }
      }
    }

    return false;
  },

  async deleteEffectCustom({ state, dispatch }, hash) {
    this.$dbg('store:userSettings')('deleteEffectCustom', hash);
    const existing = [...state.effectsCustom];

    if (Array.isArray(existing) && existing.length > 0) {
      const index = existing.findIndex(i => i.hash === hash);

      if (index >= 0) {
        Vue.delete(existing, index);
        await dispatch('edit', {
          effectsCustom: existing,
        });

        dispatch('logEvent', { name: 'user_settings_delete_effect_custom' }, { root: true });
        return true;
      }
    }

    return false;
  },

  async cleanupEffectCustomCollection({ state, rootState, dispatch }) {
    const keep = state.effectsCustom
      .filter(e => !e.deleteAt || dayjs(e.deleteAt).isAfter(rootState.now));

    this.$dbg('store:userSettings')('cleanupEffectCustomCollection', `keep ${keep.length}`);

    if (keep.length !== state.effectsCustom.length) {
      await dispatch('edit', {
        effectsCustom: keep,
      });

      dispatch('logEvent', { name: 'user_settings_cleanup_effect_custom_collection' }, { root: true });
    }

    return true;
  },

  async selectQuote({ rootState, rootGetters, dispatch }, { id }) {
    this.$dbg('store:userSettings')('selectQuote', id);

    const q = rootState.quotes.quotes.find(q => q.id === id);

    if (!q) {
      this.$dbg('store:userSettings')('selectQuote', `quote ${id} not found.`);
      return false;
    }

    const expiresAt = rootGetters.virtualDayEnd.toDate();
    const result = UserSettings.QuoteSchema.safeParse({
      id,
      expiresAt,
    });

    if (result.error) {
      this.$dbg('store:userSettings')('selectQuote', `failed to select ${id}`, result.error);
      return false;
    }

    await dispatch('edit', {
      quote: result.data,
    });

    dispatch('logEvent', { name: 'user_settings_select_quote' }, { root: true });
    return true;
  },

  async selectAvatar({ state, dispatch }, name) {
    this.$dbg('store:userSettings')('selectAvatar', name);

    let avatars = Array.isArray(state.avatars)
      ? [...state.avatars]
      : [...UserSettings.ModelDefault.avatars];

    // first, unset all
    avatars = avatars.map(a => ({
      ...a,
      isSelected: false,
    }));

    if (name !== Avatar.NameNoSelection) {
      // the avatar must already be in the user's settings (is already available/unlocked)
      const existingIndex = avatars.findIndex(a => a.name === name);

      if (existingIndex < 0) {
        this.$dbg('store:userSettings')('selectAvatar', `${name} was not found in user settings`);
        return false;
      }

      const result = UserSettings.AvatarSchema.safeParse({
        ...state.avatars[existingIndex],
        isSelected: true,
      });

      if (result.error) {
        this.$dbg('store:userSettings')('selectAvatar', `failed to select ${name}`, result.error);
        return false;
      }

      avatars[existingIndex] = result.data;
    }

    await dispatch('edit', {
      avatars,
    });

    dispatch('logEvent', { name: 'user_settings_select_avatar' }, { root: true });
    return true;
  },

  async setCompanion({ state, dispatch }, { name, expiresAt }) {
    this.$dbg('store:userSettings')('setCompanion', name, expiresAt);

    let companions = Array.isArray(state.companions)
      ? [...state.companions]
      : [...UserSettings.ModelDefault.companions];

    // first, unset all
    companions = companions.map(c => ({
      ...c,
      expiresAt: null,
    }));

    const existingIndex = companions.findIndex(c => c.name === name);

    const result = UserSettings.CompanionSchema.safeParse({
      name,
      expiresAt,
    });

    if (result.error) {
      this.$dbg('store:userSettings')('setCompanion', `failed to set ${name}`, result.error);
      return false;
    }

    if (existingIndex >= 0) {
      companions[existingIndex] = result.data;
    } else {
      companions.push(result.data);
    }

    await dispatch('edit', {
      companions,
    });

    dispatch('logEvent', { name: 'user_settings_set_companion' }, { root: true });
    return true;
  },

  async reset({ commit }) {
    this.$dbg('store:userSettings')('reset');

    if (unsub) {
      unsub();
    }

    // Get userId directly from auth to ensure they DO NOT still have a session!
    // Otherwise, we will risk wiping the user's data
    const userId = await this.$auth.getUserId();

    if (!userId) {
      commit(StoreHelperMutation.SetOnRoot, {
        ...UserSettings.ModelDefault,
      });
    }
  },

  setEntitlementIsLifetime({ rootGetters, dispatch }) {
    if (!rootGetters.isDebugger) {
      return Promise.reject(new Error('User is not permitted to run this function.'));
    }

    return dispatch('edit', {
      entitlements: [{
        name: Entitlement.EntitlementName.enum.Basic,
        expiresAt: dayjs(0),
        isLifetime: true,
      }],
    });
  },
};
