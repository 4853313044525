
import uiMixin from '../mixins/ui';

export default {
  name: 'ActionFocusMessage',
  mixins: [
    uiMixin,
  ],
  computed: {
    action() {
      let action;

      if (this.hasActionFocus) {
        const id = this.$store.state.actionFocus.action.id;
        action = this.$store.getters.getActionById(id);
      }

      return action;
    },
    hasActionFocus() {
      return Boolean(this.$store.state.actionFocus.action);
    },
  },
  methods: {
    handleView() {
      if (this.hasActionFocus) {
        this.$store.dispatch('actionFocus/makeLocal');
      }
    },
  },
};
