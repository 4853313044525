
import { mapState, mapGetters } from 'vuex';
import regl from 'regl';
import { shaders } from '../lib/action-card-background';

const LAST_COMPLETION_MAX_SECONDS = 3600;

const WIDTH = 445;
const HEIGHT = 445;
const SHADER = 'warpSpeed2';

export default {
  name: 'SceneSpace',
  data() {
    return {
      isSceneShown: false,
      isSceneLoading: true,
      width: WIDTH,
      height: HEIGHT,
    };
  },
  computed: {
    ...mapState('ui', {
      isAdvancedUIEnabled: 'isAdvancedUIEnabled',
      isBackgroundEnabled: 'isBackgroundEnabled',
      isUIForegroundHidden: 'isForegroundHidden',
    }),
    ...mapState('scene', [
      'didAnimateOnce',
    ]),
    ...mapGetters('actionsPlan', [
      'valueExpectedAdjusted',
    ]),
    ...mapGetters('actionsUser', [
      'itemsCompletedVirtualDay',
      'valueTotalVirtualDay',
    ]),
    isEnabled() {
      return this.isAdvancedUIEnabled && this.isBackgroundEnabled;
    },
    valueCompletedFraction() {
      // this should not cause division by zero
      return this.valueExpectedAdjusted ? Math.min(1, this.valueTotalVirtualDay / this.valueExpectedAdjusted) : 0;
    },
    secondsSinceLastCompletion() {
      let s = LAST_COMPLETION_MAX_SECONDS;

      const items = this.itemsCompletedVirtualDay;
      const now = this.$store.state.now.toDate().getTime();

      if (Array.isArray(items) && items.length > 0) {
        const i = items[items.length - 1];
        s = Math.min(LAST_COMPLETION_MAX_SECONDS, Math.round((now - i.lastProgressAt.getTime()) / 1000));
      }

      return s;
    },
    sunColor() {
      return this.$store.getters['gen/sunColor'];
    },
    sunGamma() {
      const min = 3;
      const max = 6;

      if (this.secondsSinceLastCompletion === LAST_COMPLETION_MAX_SECONDS) {
        return min;
      }

      const p = Math.max(0, LAST_COMPLETION_MAX_SECONDS - this.secondsSinceLastCompletion) / LAST_COMPLETION_MAX_SECONDS;

      return min + (p * (max - min));
    },
    sunRayBrightness() {
      const min = 0.8;
      const max = 1.5;

      if (this.secondsSinceLastCompletion === LAST_COMPLETION_MAX_SECONDS) {
        return min;
      }

      const p = Math.max(0, LAST_COMPLETION_MAX_SECONDS - this.secondsSinceLastCompletion) / LAST_COMPLETION_MAX_SECONDS;

      return min + (p * (max - min));
    },
    rand() {
      return this.$store.getters.getRandom('test');
    },
  },
  watch: {
    isUIForegroundHidden() {
      if (!this.isUIForegroundHidden) {
        this.setSceneProps();
        this.animateToCurrentStatus();
      }
    },
    valueExpectedAdjusted() {
      if (this.didAnimateOnce) {
        this.setSceneProps();
        this.animateToCurrentStatus(true);
      }
    },
    valueTotalVirtualDay() {
      if (this.didAnimateOnce) {
        this.setSceneProps();
        this.animateToCurrentStatus(true);
      }
    },
    sunColor() {
      this.setSceneProps();
    },
    isSceneLoading() {
      if (!this.isSceneLoading) {
        this.removeShader();
      }
    },
  },
  created() {
    this._reglInstance = undefined;
  },
  mounted() {
    this.$root.$once('scene-ready', this.setSceneProps);
    this.$root.$once('scene-animate-end', () => {
      this.$store.commit('scene/didAnimateOnce');
      this.animateToCurrentStatus();
    });

    this.$root.$on('scene-animate-to-progress', () => this.animateToCurrentStatus(true));
    this.$root.$on('scene-demo-to', this.demoTo);
    this.$root.$on('scene-demo-progress', this.demoProgress);
    this.$root.$on('scene-demo-eclipse-in', this.demoEclipseIn);
    this.$root.$on('scene-demo-eclipse-out', this.demoEclipseOut);
    this.$root.$on('scene-demo-eclipse-out-forced', this.demoEclipseOutForced);
    this.$root.$on('scene-demo-eclipse', this.demoEclipse);

    if (!this.isEnabled) {
      this.$nextTick(() => {
        this.initShader();
      });
    }
  },
  beforeDestroy() {
    this.removeShader();
  },
  destroyed() {
    this.$store.commit('scene/reset');
    this.$root.$off('scene-animate-end', this.animateToCurrentStatus)
    this.$root.$off('scene-demo-to', this.demoTo);
    this.$root.$off('scene-demo-progress', this.demoProgress);
    this.$root.$off('scene-demo-eclipse-in', this.demoEclipseIn);
    this.$root.$off('scene-demo-eclipse-out', this.demoEclipseOut);
    this.$root.$off('scene-demo-eclipse-out-forced', this.demoEclipseOutForced);
    this.$root.$off('scene-demo-eclipse', this.demoEclipse);
  },
  methods: {
    setSceneProps() {
      if (this.isEnabled) {
        this.$dbg('scene-space')('setSceneProps', this.sunColor, this.sunGamma, this.sunRayBrightness);
        this.$root.$emit('call-scene', 'set_props', this.sunColor, this.sunGamma, this.sunRayBrightness);
        this.isSceneShown = true;

        // match css transition
        setTimeout(() => {
          this.isSceneLoading = false;
        }, 2000);
      }
    },
    animateToCurrentStatus(setWaitFlag = false) {
      if (!this.didAnimateOnce) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        if (setWaitFlag) {
          this.$wait.start('scene-watch');
        }

        this.$root.$once('scene-animate-end', () => {
          this.$wait.end('scene-watch');
          this.$store.commit('scene/didAnimateOnce');
          this.$store.commit('scene/didAnimateToCurrentStatusOnce');
          resolve();
        });

        this.$root.$emit('call-scene', 'progress_updated', this.valueTotalVirtualDay, this.valueExpectedAdjusted);
      });
    },
    demoTo(progressPercent = 0) {
      return new Promise((resolve) => {
        this.$wait.start('scene-watch');
        this.$root.$emit('call-scene', 'progress_updated', progressPercent, 100);
        this.$root.$once('scene-animate-end', () => {
          this.$wait.end('scene-watch');
          resolve();
        });
      });
    },
    demoProgress() {
      return new Promise((resolve) => {
        this.$wait.start('scene-watch');
        this.$root.$emit('call-scene', 'progress_updated', 0, this.valueExpectedAdjusted);
        this.$root.$once('scene-animate-end', () => {
          this.$root.$emit('call-scene', 'progress_updated', this.valueTotalVirtualDay, this.valueExpectedAdjusted);
          this.$root.$once('scene-animate-end', () => {
            this.$wait.end('scene-watch');
            resolve();
          });
        });
      });
    },
    demoEclipseIn(endWait = true) {
      return new Promise((resolve) => {
        this.$wait.start('scene-watch');
        this.$root.$emit('call-scene', 'progress_updated', this.valueExpectedAdjusted, this.valueExpectedAdjusted);
        this.$root.$once('scene-animate-end', () => {
          if (endWait) {
            this.$wait.end('scene-watch');
          }

          resolve();
        });
      });
    },
    demoEclipseOut() {
      return new Promise((resolve) => {
        this.$wait.start('scene-watch');
        this.$root.$emit('call-scene', 'progress_updated', this.valueTotalVirtualDay, this.valueExpectedAdjusted);
        this.$root.$once('scene-animate-end', () => {
          this.$wait.end('scene-watch');
          resolve();
        });
      });
    },
    demoEclipseOutForced() {
      return new Promise((resolve) => {
        this.$wait.start('scene-watch');
        this.$root.$emit('call-scene', 'progress_updated', 0, this.valueExpectedAdjusted);
        this.$root.$once('scene-animate-end', () => {
          this.$wait.end('scene-watch');
          resolve();
        });
      });
    },
    async demoEclipse() {
      await this.demoEclipseIn(false);
      await this.demoEclipseOut();
    },
    initShader() {
      const glCanvas = this.$refs['gl-canvas'];

      if (this._reglInstance || !glCanvas) {
        return;
      }

      this.$dbg('scene-space')('initShader');

      const fragmentShader = shaders[SHADER].frag;
      const start = `// #version 300 es
      precision mediump float;

      uniform vec2 iResolution;
      uniform vec4 iMouse;
      uniform float iTime;
      uniform float rand;
      uniform float importanceMultiplier;
      uniform float completeness;
      `;

      const end = `
      void main() {
        mainImage(gl_FragColor.rgba, gl_FragCoord.xy);
      }
      `;

      this._reglInstance = regl(glCanvas);
      const draw = this._reglInstance({
        // Shaders in regl are just strings.  You can use glslify or whatever you want
        // to define them.  No need to manually create shader objects.
        frag: `${start}${fragmentShader}${end}`,

        vert: `
        precision mediump float;
        attribute vec2 position;
        varying vec2 uv;
        void main () {
          uv = position;
          gl_Position = vec4(2.0 * position - 1.0, 0, 1);
        }`,

        // This tells regl the number of vertices to draw in this command
        count: 3,

        attributes: {
          position: [
            -2, 0,
            0, -2,
            2, 2]
        },

        uniforms: {
          iResolution: [WIDTH, HEIGHT],
          iMouse: [0, 0, 0, 0],
          iTime: ({ tick }) => 0.01 * tick,
          rand: this._reglInstance.prop('rand'),
          importanceMultiplier: this._reglInstance.prop('importanceMultiplier'),
          completeness: this._reglInstance.prop('completeness'),
        },
      });

      this._reglInstance.frame(() => {
        if (this.isSceneLoading) {
          // clear contents of the drawing buffer
          this._reglInstance.clear({
            color: [0, 0, 0, 0],
            depth: 1
          })

          draw({
            rand: this.rand,
            importanceMultiplier: 0.5,
            completeness: 1.0,
          });
        }
      });
    },
    removeShader() {
      if (this._reglInstance) {
        this.$dbg('scene-space')('removeShader');
        this._reglInstance.destroy();
      }
    },
  },
};
