import { z } from 'zod';
import { EffectStandard } from './EffectStandard';

export default {
  name: EffectStandard.EffectName.enum.Postponement,
  label: 'Postponement',
  verb: 'Postpone',
  targets: [EffectStandard.Target.enum.ActionOnce],
  icon: ['fal', 'calendar-plus'],
  color: 'var(--sl-color-pink-800)',
  description: 'Add time to a scheduled action\'s due date.',
  effectCheck: EffectStandard.checkActionIsCompletable,
  getEffectData({ props }) {
    return Promise.resolve({
      itemId: props.itemId,
    });
  },
  effect({ $store, effectData, effect }) {
    return new Promise((resolve) => {
      $store.dispatch('ui/showModal', {
        name: 'actionPostponement',
        payload: {
          itemId: effectData.itemId,
          effectName: EffectStandard.EffectName.enum.Postponement,
          titleIcon: effect.icon,
          verb: effect.verb,
          promptText: 'Until when would you like to postpone this action?',
        },
      });

      EffectStandard.logEvent({ $store, effectName: effect.name });
      resolve(true);
    });
  },
} as z.infer<typeof EffectStandard.Model>;
