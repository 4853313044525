import _ from 'lodash';
import { z } from 'zod';
import dayjs, { extend as dayjsExtend } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { Action } from '../Action';
import { Sprite } from '../Sprite';
import { EffectCustomSprite } from './EffectCustomSprite';
import Completion from './Completion';
import { EffectStandard } from './EffectStandard';

// const ToastType: z.ZodType<typeof Toast> = z.any();
// const Store = z.any(); // : z.ZodType<typeof Store> = z.any();

dayjsExtend(isSameOrAfter);
dayjsExtend(isSameOrBefore);

type ActionType = z.infer<typeof Action.Model>;

const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';

const getRandomInt = (r: number, min: number, max: number) => {
  return Math.floor(r * (max - min) + min);
};

const getRemindAtOptions = () => {
  const d = dayjs().startOf('day');
  const dHour = d.hour();
  const opts = [];

  for (let i = 0; i < 24; i++) {
    for (let ii = 0; ii < 4; ii++) {
      opts.push(d.hour(dHour + i).minute(ii * 15));
    }
  }

  return opts;
};

export namespace EffectCustom {
  export const Target = z.enum([
    'Action',
  ]);

  export const TargetWhat = z.enum([
    'ActionCreatedAtLessThan',
    'ActionCreatedAtGreaterThan',
    'ActionDescriptionStartingLetter',
    'ActionInterestStartingLetter',
    'ActionTimeframe',
    'ActionTimeframeNone',
    'ActionTimeframeLessThan',
    'ActionTimeframeGreaterThan',
    'ActionTimeframeNegative',
    'ActionFrequency',
    'ActionFrequencyDaily',
    'ActionFrequencyWeekly',
    'ActionFrequencyMonthly',
    'ActionFrequencyDailyCompletionsLessThan',
    'ActionFrequencyDailyCompletionsGreaterThan',
    'ActionFrequencyDailyCompletionStreakLessThan',
    'ActionFrequencyDailyCompletionStreakGreaterThan',
    'ActionEffortLessThan',
    'ActionEffortGreaterThan',
    'ActionImportanceLessThan',
    'ActionImportanceGreaterThan',
    'ActionReminder',
    'ActionReminderLessThan',
    'ActionReminderGreaterThan',
    'ActionTimeSpentLessThan',
    'ActionTimeSpentGreaterThan',
    'ActionValueLessThan',
    'ActionValueGreaterThan',
  ]);

  export const TargetHow = z.enum([
    'ActionCompleteValueAddWhole',
    'ActionCompleteValueAddFraction',
    'ActionCompleteValueMultiplyWhole',
    'ActionCompleteValueMultiplyFraction',
  ]);

  export const TargetDegree = z.enum([
    'ActionPositiveSmall',
    'ActionPositiveMedium',
    'ActionPositiveLarge',
    'ActionPositiveRandom',
    'ActionNegativeSmall',
    'ActionNegativeMedium',
    'ActionNegativeLarge',
    'ActionNegativeRandom',
  ]);

  const SettingModel = z.object({
    props: z.object({
      name: Target,
      explanation: z.string().min(1),
    }),
    what: z.array(
      z.object({
        name: TargetWhat,
        label: z.string().min(1),
        explanation: z.string().min(1),
        getRandomValue: z.optional(z.function().args(z.any(), z.number().nonnegative()).returns(z.any())),
        getRandomValueDisplay: z.optional(z.function().args(z.any()).returns(z.string().min(1))),
        test: z.function().args(z.any(), z.any()).returns(z.boolean()),
        $store: z.any(), // typescript workaround to allow test() functions to use this.$store
      }),
    ),

    how: z.array(
      z.object({
        name: TargetHow,
        label: z.string().min(1),
        explanation: z.string().min(1),
        cost: z.number().nonnegative(),
        mask: z.object({}),
        spriteName: Sprite.Name,
        applyEffect: EffectStandard.Model,
      }),
    ),

    degree: z.array(
      z.object({
        name: TargetDegree,
        label: z.string().min(1),
        explanation: z.string().min(1),
        isPositive: z.boolean(),
        cost: z.number().nonnegative(),
        spriteOptions: z.optional(z.object({
          saturation: z.optional(z.number()),
          colorVariations: z.optional(z.number()),
        })),
        modifier: z.function().returns(z.number()),
      }),
    ),
  })

  export const TargetSetting: Partial<Record<z.infer<typeof Target>, z.infer<typeof SettingModel>>> = {
    [Target.enum.Action]: {
      props: {
        name: Target.enum.Action,
        explanation: 'action',
      },

      what: [
        {
          name: TargetWhat.enum.ActionCreatedAtLessThan,
          label: 'neutrino',
          explanation: 'created on or before',
          getRandomValue(rootState: any, r: number) {
            // The intent is to resurface older, incomplete actions
            const days = getRandomInt(r, 7, 30);
            return rootState.now.subtract(days, 'days').toDate();
          },
          getRandomValueDisplay(val: Date) {
            return dayjs(val).format('YYYY-MM-DD');
          },
          test(item: ActionType, val: Date): boolean {
            let result = false;

            if (item && !Action.isFrequencyRepeating(item.frequency) && val) {
              result = dayjs(item.createdAt).isSameOrBefore(val);
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionCreatedAtGreaterThan,
          label: 'neutron',
          explanation: 'created on or after',
          getRandomValue(rootState: any, r: number) {
            const days = getRandomInt(r, 1, 7);
            return rootState.now.subtract(days, 'days').toDate();
          },
          getRandomValueDisplay(val: Date) {
            return dayjs(val).format('YYYY-MM-DD');
          },
          test(item: ActionType, val: Date): boolean {
            let result = false;

            if (item && !Action.isFrequencyRepeating(item.frequency) && val) {
              result = dayjs(item.createdAt).isSameOrAfter(val);
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionDescriptionStartingLetter,
          label: 'atom',
          explanation: 'whose description starts with the letter',
          getRandomValue(_rootState: any, r: number) {
            const i = getRandomInt(r, 0, ALPHABET.length - 1);
            return ALPHABET[i];
          },
          getRandomValueDisplay(val: string) {
            return `"${val.toUpperCase()}"`;
          },
          test(item: ActionType, val: string): boolean {
            let result = false;

            if (item && val && ALPHABET.includes(val)) {
              result = item.description[0].toLowerCase() === val;
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionInterestStartingLetter,
          label: 'molecule',
          explanation: 'with an interest starting with the letter',
          getRandomValue(_rootState: any, r: number) {
            const i = getRandomInt(r, 0, ALPHABET.length - 1);
            return ALPHABET[i];
          },
          getRandomValueDisplay(val: string) {
            return `"${val.toUpperCase()}"`;
          },
          test(item: ActionType, val: string): boolean {
            let result = false;

            if (item && Array.isArray(item.interest) && val && ALPHABET.includes(val)) {
              // get all interest names

              item.interest.forEach((i) => {
                if (!result) {
                  const interest = this.$store.getters['interests/getInterestById'](i);
                  result = !!interest && _.isString(interest.name) && interest.name.toLowerCase()[0] === val;
                }
              });
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionTimeframe,
          label: 'tachyon',
          explanation: 'that is scheduled',
          test(item: ActionType): boolean {
            let result = false;

            if (item) {
              result = Array.isArray(item.timeframe) && _.isDate(item.timeframe[0]);
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionTimeframeNone,
          label: 'tachyon',
          explanation: 'that is not scheduled',
          test(item: ActionType): boolean {
            let result = false;

            if (item && !Action.isFrequencyRepeating(item.frequency)) {
              result = !Array.isArray(item.timeframe) || !_.isDate(item.timeframe[0]);
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionTimeframeLessThan,
          label: 'boson',
          explanation: 'that is scheduled before',
          getRandomValue(rootState: any, r: number) {
            const days = getRandomInt(r, 0, 3);
            return rootState.now.add(days, 'days').toDate();
          },
          getRandomValueDisplay(val: Date) {
            return dayjs(val).format('YYYY-MM-DD');
          },
          test(item: ActionType, val: Date): boolean {
            let result = false;

            if (item) {
              result = Array.isArray(item.timeframe) &&
                _.isDate(item.timeframe[0]) &&
                dayjs(item.timeframe[0]).isSameOrBefore(val);
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionTimeframeGreaterThan,
          label: 'preon',
          explanation: 'that is scheduled after',
          getRandomValue(rootState: any, r: number) {
            const days = getRandomInt(r, 4, 14);
            return rootState.now.add(days, 'days').toDate();
          },
          getRandomValueDisplay(val: Date) {
            return dayjs(val).format('YYYY-MM-DD');
          },
          test(item: ActionType, val: Date): boolean {
            let result = false;

            if (item) {
              result = Array.isArray(item.timeframe) &&
                _.isDate(item.timeframe[0]) &&
                dayjs(item.timeframe[0]).isSameOrAfter(val);
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionTimeframeNegative,
          label: 'fermion',
          explanation: 'that is scheduled and overdue',
          test(item: ActionType): boolean {
            let result = false;

            if (item) {
              result = Array.isArray(item.timeframe) &&
                _.isDate(item.timeframe[0]) &&
                // does this need to start at userResetTime?
                dayjs(item.timeframe[0]).isBefore(dayjs().startOf('day'));
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionFrequency,
          label: 'positron',
          explanation: 'that repeats',
          test(item: ActionType): boolean {
            let result = false;

            if (item) {
              result = item.frequency !== Action.Frequency.enum.Once;
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionFrequencyDaily,
          label: 'photon',
          explanation: 'that repeats daily',
          test(item: ActionType): boolean {
            let result = false;

            if (item) {
              result = Action.isFrequencyDaily(item.frequency);
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionFrequencyWeekly,
          label: 'hadron',
          explanation: 'that repeats weekly',
          test(item: ActionType): boolean {
            let result = false;

            if (item) {
              result = Action.isFrequencyWeekly(item.frequency);
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionFrequencyMonthly,
          label: 'axion',
          explanation: 'that repeats monthly',
          test(item: ActionType): boolean {
            let result = false;

            if (item) {
              result = item.frequency === Action.Frequency.enum.Month;
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionFrequencyDailyCompletionStreakGreaterThan,
          label: 'graviton',
          explanation: 'with more than %val% daily completions in a row',
          getRandomValue(_rootState: any, r: number) {
            return getRandomInt(r, 2, 6);
          },
          test(item: ActionType, val: number): boolean {
            let result = false;

            if (item && Action.isFrequencyDaily(item.frequency)) {
              const recentCompletions = this.$store.getters['actionsUser/getItemRecentlyCompletedOccurrences'](item);
              let isConsecutive = true;
              let count = 0;

              recentCompletions.forEach((dayOccurences: ActionType[][], i: number) => {
                if (i === 0) {
                  return;
                }

                if (isConsecutive && Array.isArray(dayOccurences) && dayOccurences.length > 0) {
                  count = i;
                } else {
                  isConsecutive = false;
                }
              });

              result = count > val;
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionFrequencyDailyCompletionStreakLessThan,
          label: 'lepton',
          explanation: 'with fewer than %val% daily completions in a row',
          getRandomValue(_rootState: any, r: number) {
            return getRandomInt(r, 2, 6);
          },
          test(item: ActionType, val: number): boolean {
            let result = false;

            if (item && Action.isFrequencyDaily(item.frequency)) {
              const recentCompletions = this.$store.getters['actionsUser/getItemRecentlyCompletedOccurrences'](item);
              let isConsecutive = true;
              let count = 0;

              recentCompletions.forEach((dayOccurences: ActionType[][], i: number) => {
                if (i === 0) {
                  return;
                }

                if (isConsecutive && Array.isArray(dayOccurences) && dayOccurences.length > 0) {
                  count = i;
                } else {
                  isConsecutive = false;
                }
              });

              result = count < val;
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionFrequencyDailyCompletionsGreaterThan,
          label: 'baryon',
          explanation: 'with more than %val% recent daily completions',
          getRandomValue(_rootState: any, r: number) {
            return getRandomInt(r, 2, 6);
          },
          test(item: ActionType, val: number): boolean {
            let result = false;

            if (item && Action.isFrequencyDaily(item.frequency)) {
              const recentCompletions = this.$store.getters['actionsUser/getItemRecentlyCompletedOccurrences'](item);
              const days = recentCompletions.filter((dayOccurences: ActionType[][]) => {
                return Array.isArray(dayOccurences) && dayOccurences.length > 0;
              });

              result = days.length > val;
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionFrequencyDailyCompletionsLessThan,
          label: 'tau',
          explanation: 'with fewer than %val% recent daily completions',
          getRandomValue(_rootState: any, r: number) {
            return getRandomInt(r, 2, 6);
          },
          test(item: ActionType, val: number): boolean {
            let result = false;

            if (item && Action.isFrequencyDaily(item.frequency)) {
              const recentCompletions = this.$store.getters['actionsUser/getItemRecentlyCompletedOccurrences'](item);
              const days = recentCompletions.filter((dayOccurences: ActionType[][]) => {
                return Array.isArray(dayOccurences) && dayOccurences.length > 0;
              });

              result = days.length < val;
            }

            return result;
          },
        },
        {
          name: TargetWhat.enum.ActionEffortGreaterThan,
          label: 'flux',
          explanation: 'with effort greater than',
          getRandomValue(_rootState: any, r: number) {
            const i = getRandomInt(r, 0, Action.EffortImportance.FibonacciValues.length - 2);
            return Action.EffortImportance.FibonacciValues[i];
          },
          getRandomValueDisplay(val: number) {
            return `"${Action.EffortImportance.EffortDescriptorMap[val]}"`;
          },
          test(item: ActionType, val: number): boolean {
            return !!item.effort && item.effort > val;
          },
        },
        {
          name: TargetWhat.enum.ActionEffortLessThan,
          label: 'electron',
          explanation: 'with effort less than',
          getRandomValue(_rootState: any, r: number) {
            const i = getRandomInt(r, 1, Action.EffortImportance.FibonacciValues.length - 1);
            return Action.EffortImportance.FibonacciValues[i];
          },
          getRandomValueDisplay(val: number) {
            return `"${Action.EffortImportance.EffortDescriptorMap[val]}"`;
          },
          test(item: ActionType, val: number): boolean {
            return !!item.effort && item.effort < val;
          },
        },
        {
          name: TargetWhat.enum.ActionImportanceGreaterThan,
          label: 'muon',
          explanation: 'with importance greater than',
          getRandomValue(_rootState: any, r: number) {
            const i = getRandomInt(r, 0, Action.EffortImportance.FibonacciValues.length - 2);
            return Action.EffortImportance.FibonacciValues[i];
          },
          getRandomValueDisplay(val: number) {
            return `"${Action.EffortImportance.ImportanceDescriptorMap[val]}"`;
          },
          test(item: ActionType, val: number): boolean {
            return !!item.importance && item.importance > val;
          },
        },
        {
          name: TargetWhat.enum.ActionImportanceLessThan,
          label: 'negatron',
          explanation: 'with importance less than',
          getRandomValue(_rootState: any, r: number) {
            const i = getRandomInt(r, 1, Action.EffortImportance.FibonacciValues.length - 1);
            return Action.EffortImportance.FibonacciValues[i];
          },
          getRandomValueDisplay(val: number) {
            return `"${Action.EffortImportance.ImportanceDescriptorMap[val]}"`;
          },
          test(item: ActionType, val: number): boolean {
            return !!item.importance && item.importance < val;
          },
        },
        {
          name: TargetWhat.enum.ActionReminder,
          label: 'quark',
          explanation: 'with a reminder set',
          test(item: ActionType): boolean {
            return !!item.remindAt;
          },
        },
        {
          name: TargetWhat.enum.ActionReminderGreaterThan,
          label: 'axion',
          explanation: 'with a reminder set to after',
          getRandomValue(_rootState: any, r: number) {
            const values = getRemindAtOptions();
            const i = getRandomInt(r, 0, values.length - 2);
            return values[i].format(Action.RemindAtFormatData);
          },
          getRandomValueDisplay(val: string) {
            const valSplit = val.split(':');
            return dayjs().hour(Number(valSplit[0])).minute(Number(valSplit[1])).second(0)
              .format(Action.RemindAtFormatDisplay);
          },
          test(item: ActionType, val: string): boolean {
            if (!item || !item.remindAt) {
              return false;
            }

            const remindAtSplit = item.remindAt.split(':');
            const remindAt = dayjs().hour(Number(remindAtSplit[0])).minute(Number(remindAtSplit[1])).second(0)

            const valSplit = val.split(':');
            const valTest = dayjs().hour(Number(valSplit[0])).minute(Number(valSplit[1])).second(0)

            return remindAt.isAfter(valTest);
          },
        },
        {
          name: TargetWhat.enum.ActionReminderLessThan,
          label: 'meson',
          explanation: 'with a reminder set to before',
          getRandomValue(_rootState: any, r: number) {
            const values = getRemindAtOptions();
            const i = getRandomInt(r, 1, values.length - 1);
            return values[i].format(Action.RemindAtFormatData);
          },
          getRandomValueDisplay(val: string) {
            const valSplit = val.split(':');
            return dayjs().hour(Number(valSplit[0])).minute(Number(valSplit[1])).second(0)
              .format(Action.RemindAtFormatDisplay);
          },
          test(item: ActionType, val: string): boolean {
            if (!item || !item.remindAt) {
              return false;
            }

            const remindAtSplit = item.remindAt.split(':');
            const remindAt = dayjs().hour(Number(remindAtSplit[0])).minute(Number(remindAtSplit[1])).second(0)

            const valSplit = val.split(':');
            const valTest = dayjs().hour(Number(valSplit[0])).minute(Number(valSplit[1])).second(0)

            return remindAt.isBefore(valTest);
          },
        },
        // would ActionTimeSpentLessThan even be useful?
        {
          name: TargetWhat.enum.ActionTimeSpentGreaterThan,
          label: 'proton',
          explanation: 'with time spent in focus greater than',
          getRandomValue(_rootState: any, r: number) {
            return getRandomInt(r, 1, 15);
          },
          getRandomValueDisplay(val: number) {
            return `${val} minutes`;
          },
          test(item: ActionType, val: number): boolean {
            return !!item && _.isFinite(item.timeSpent) && item.timeSpent > 0 && item.timeSpent < (val * 60);
          },
        },
        {
          name: TargetWhat.enum.ActionValueLessThan,
          label: 'phonon',
          explanation: 'with value less than',
          getRandomValue(_rootState: any, r: number) {
            return getRandomInt(r, 2, 21);
          },
          getRandomValueDisplay(val: number) {
            return `${val}`;
          },
          test(item: ActionType, val: number): boolean {
            return !!item &&
              this.$store.getters.getValuePotentialForItem(item) < val;
          },
        },
        {
          name: TargetWhat.enum.ActionValueGreaterThan,
          label: 'orbiton',
          explanation: 'with value greater than',
          getRandomValue(_rootState: any, r: number) {
            return getRandomInt(r, 1, 21);
          },
          getRandomValueDisplay(val: number) {
            return `${val}`;
          },
          test(item: ActionType, val: number): boolean {
            return !!item &&
              this.$store.getters.getValuePotentialForItem(item) > val;
          },
        },
      ],

      how: [
        {
          name: TargetHow.enum.ActionCompleteValueAddFraction,
          label: 'accelerator',
          explanation: 'gets completed with %degree% in value',
          cost: 0,
          mask: EffectCustomSprite.Spaceship,
          spriteName: Sprite.Name.enum.Swirl01,
          applyEffect: {
            ...Completion,
            async getEffectData({ $store, props }) {
              const data = await Completion.getEffectData!({ $store, props });

              if (_.isFinite(data.value) && _.isFinite(props.modifierValue)) {
                const remainder = data.value - (data.value / data.modifierValue);
                data.valueAdd = Math.max(1, Math.ceil(remainder));
              }

              return data;
            },
          },
        },
        {
          name: TargetHow.enum.ActionCompleteValueAddWhole,
          label: 'attractor',
          explanation: 'gets completed with %degree% in value',
          cost: 0,
          mask: {
            ...EffectCustomSprite.Spaceship,
            mirrorY: true,
          },
          spriteName: Sprite.Name.enum.Particles02,
          applyEffect: {
            ...Completion,
            async getEffectData({ $store, props }) {
              const data = await Completion.getEffectData!({ $store, props });

              if (_.isFinite(data.value) && _.isFinite(props.modifierValue)) {
                data.valueAdd = Math.max(1, props.modifierValue);
              }

              return data;
            },
          },
        },
        {
          name: TargetHow.enum.ActionCompleteValueMultiplyFraction,
          label: 'jumper',
          explanation: 'gets completed with %degree% in value',
          cost: 1,
          mask: EffectCustomSprite.Axe,
          spriteName: Sprite.Name.enum.Particles01,
          applyEffect: {
            ...Completion,
            async getEffectData({ $store, props }) {
              const data = await Completion.getEffectData!({ $store, props });

              if (_.isFinite(data.value) && _.isFinite(props.modifierValue)) {
                const remainder = 1 - (1 / props.modifierValue);
                data.valueAdd = Math.max(1, data.value - Math.ceil(data.value * remainder));
              }

              return data;
            },
          },
        },
        {
          name: TargetHow.enum.ActionCompleteValueMultiplyWhole,
          label: 'duplicator',
          explanation: 'gets completed with %degree% in value',
          cost: 2,
          mask: {
            ...EffectCustomSprite.Axe,
            mirrorX: true,
          },
          spriteName: Sprite.Name.enum.Swirl02,
          applyEffect: {
            ...Completion,
            async getEffectData({ $store, props }) {
              const data = await Completion.getEffectData!({ $store, props });

              if (_.isFinite(data.value) && _.isFinite(props.modifierValue)) {
                data.valueAdd = Math.max(1, data.value - Math.ceil(data.value * props.modifierValue));
              }

              return data;
            },
          },
        },
      ],

      degree: [
        {
          name: TargetDegree.enum.ActionPositiveRandom,
          label: 'erratic',
          explanation: 'a random increase',
          isPositive: true,
          cost: 1,
          spriteOptions: {
            saturation: 1,
            colorVariations: 1,
          },
          modifier() {
            return getRandomInt(Math.random(), 1, 5);
          },
        },
        {
          name: TargetDegree.enum.ActionPositiveSmall,
          label: 'weak',
          explanation: 'a small increase',
          isPositive: true,
          cost: 1,
          spriteOptions: {
            saturation: 0.2,
            colorVariations: 0.2,
          },
          modifier() {
            return 2;
          },
        },
        {
          name: TargetDegree.enum.ActionPositiveMedium,
          label: 'standard',
          explanation: 'a moderate increase',
          isPositive: true,
          cost: 2,
          spriteOptions: {
            saturation: 0.5,
            colorVariations: 0.3,
          },
          modifier() {
            return 3;
          },
        },
        {
          name: TargetDegree.enum.ActionPositiveLarge,
          label: 'strong',
          explanation: 'a large increase',
          isPositive: true,
          cost: 3,
          spriteOptions: {
            saturation: 1,
            colorVariations: 0.5,
          },
          modifier() {
            return 4;
          },
        },
        /*
        {
          name: TargetDegree.enum.ActionNegativeSmall,
          label: 'special',
          explanation: 'a small decrease',
          isNegative: true,
        },
        {
          name: TargetDegree.enum.ActionNegativeMedium,
          label: 'radioactive',
          explanation: 'a moderate decrease',
          isNegative: true,
        },
        {
          name: TargetDegree.enum.ActionNegativeLarge,
          label: 'supernatural',
          explanation: 'a large decrease',
          isNegative: true,
        },
        {
          name: TargetDegree.enum.ActionNegativeRandom,
          label: 'psyonic',
          explanation: 'a random decrease',
          isNegative: true,
        },
        */
      ],
    },
  };
}
