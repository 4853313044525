import { z } from 'zod';
import { EffectStandard } from './EffectStandard';

export default {
  name: EffectStandard.EffectName.enum.Resolve,
  label: 'Resolve',
  verb: 'Resolve',
  targets: [EffectStandard.Target.enum.ActionOnce, EffectStandard.Target.enum.ActionRepeating],
  icon: ['fal', 'calendar-check'],
  color: 'var(--sl-color-orange-700)',
  description: 'Record an action completion on a previous day.',
  effectCheck: EffectStandard.isAction,
  getEffectData({ props }) {
    return Promise.resolve({
      itemId: props.itemId,
    });
  },
  effect({ $store, effectData, effect }) {
    $store.dispatch('ui/showModal', {
      name: 'actionLogCompletion',
      payload: {
        itemId: effectData.itemId,
        effectName: EffectStandard.EffectName.enum.Resolve,
        titleIcon: effect.icon,
        verb: effect.verb,
        promptText: 'On what day did you complete this action?',
      },
    });

    EffectStandard.logEvent({ $store, effectName: effect.name });
    return Promise.resolve(true);
  },
} as z.infer<typeof EffectStandard.Model>;
