
import uiMixin from '../mixins/ui';

import ActionListItem from './action-list-item';

export default {
  name: 'ActionsDataView',
  components: {
    ActionListItem,
  },
  mixins: [
    uiMixin,
  ],
  props: {
    actions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    layout: {
      type: String,
      default: 'list',
    },
    sortField: {
      type: String,
      default: null,
    },
    sortOrder: {
      type: Number,
      default: null,
    },
    showIndex: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleActionSelected(actionId) {
      this.$emit('select', actionId);
    },
  },
};
