import { Effect } from '../api/src/models/effect';
import { Resource } from '../api/src/models/Resource';

export default {
  data() {
    return {
      EffectName: Effect.EffectName.enum,
    };
  },

  computed: {
    effects() {
      return this.$store.getters['effects/items'];
    },
    effectsCustom() {
      return this.$store.getters['effects/itemsCustom'];
    },
  },

  methods: {
    isEffectCustomApplicable(ef, item) {
      let isApplicable = false;

      if (ef.isCustom && item) {
        try {
          isApplicable = typeof ef.what.test === 'function' && !!ef.what.test.call(this, item, ef.randomValue);
        } catch (e) {
          $dbg(`Error testing item ${item.id} for custom effect test ${ef.what.name}`);
        }
      }

      return isApplicable;
    },
    isAnyEffectCustomApplicable(item) {
      let isApplicable = false;

      this.effectsCustom.forEach((ef) => {
        if (!isApplicable && item) {
          isApplicable = this.isEffectCustomApplicable(ef, item);
        }
      });

      return isApplicable;
    },
    effectConfirm(message, opts = {}) {
      return new Promise((resolve) => {
        this.$store.dispatch('ui/showModal', {
          name: 'dialogConfirm',
          payload: {
            message,
            cancelLabel: opts.cancelLabel || opts.rejectLabel || 'Cancel',
            acceptLabel: opts.acceptLabel || opts.okTitle || 'OK',
            acceptVariant: opts.acceptVariant || opts.okVariant,
            onCancel: () => {
              resolve(false);
            },
            onAccept: () => {
              resolve(true);
            },
          }
        });
      });
    },

    /* Only for applying an effect directly, with no prompts or confirmation, no error handling, etc.
     * This assumes validity has already been determined!
     */
    async effectApply(effect, { effectLabel, effectDataCustom = {}, itemId, modifierValue }) {
      if (!effect) {
        this.$dbg('effectApply: invalid effect');
        return false;
      }

      this.$dbg(`Applying effect ${effect.name}`);
      let effectData;

      if (_.isFunction(effect.getEffectData)) {
        effectData = await effect.getEffectData({
          $store: this.$store,
          props: {
            itemId,
            modifierValue,
          },
        });
      }

      const effectResult = await effect.effect({
        $store: this.$store,
        effectData: {
          ...effectData,
          ...effectDataCustom,
          label: effectLabel ?? effect?.label,
        },
        effect,
      });

      this.$dbg(`Applied effect ${effect.name}`);

      if (effectResult && _.isFunction(effect.success)) {
        effect.success({
          $store: this.$store,
          effectData: {
            ...effectData,
            ...effectDataCustom,
            label: effectLabel ?? effect?.label,
          },
          effectResult,
        });
      } else if (!effectResult && _.isFunction(effect.error)) {
        $dbg(`Failed to apply effect ${effect.name}`);
        effect.error({ $store: this.$store });
      }

      return effectResult;
    },

    async effectPrompt(ef, options) {
      const opts = options || {};
      const {
        itemId,
        effectData: effectDataCustom = {},
      } = opts;
      const isConfirming = !opts.skipConfirm;
      const $dbg = this.$dbg('effectPrompt');

      if (!this.$store.getters.isAllowedToUseApp) {
        this.$store.dispatch('displaySubscriptions');
        return false;
      }

      if (!itemId) {
        $dbg(`Missing itemId; got ${itemId}`);
        return false;
      }

      const item = this.$store.getters.getActionById(itemId);

      if (!item) {
        $dbg(`Item not found; got ${item}`);
        return false;
      }

      const $store = this.$store;

      let result = false;
      let effect = Effect.Setting[ef.name];
      let effectLabel = effect?.label;
      let isApplying = true;
      let effectResult;
      let modifierValue;

      if (ef.isCustom) {
        $dbg(`Found custom effect ${ef.hash}`);
        effect = { ...ef.how.applyEffect };
        effectLabel = ef.label;
        modifierValue = ef.degree.modifier();
        $dbg(`Using custom effect modifierValue ${modifierValue}`);

        if (!this.isEffectCustomApplicable(ef, item)) {
          $dbg(`Item ${itemId} does not pass custom effect test ${ef.what.name}`);
          return false;
        }

        if (effect && effect.confirmWithProps) {
          effect.confirmWithProps = {
            ...effect.confirmWithProps,
            header: ef.label,
            message: `Special effect: "${ef.explanation}" ${effect.confirmWithProps.message}`,
          };
        }
      }

      if (effect && _.isFunction(effect.effect)) {
        $dbg(`Attempting effect ${effect.name}`);

        try {
          if (_.isFunction(effect.effectCheck)) {
            isApplying = effect.effectCheck({ $store, itemId });
          }

          if (isApplying) {
            if (effect.confirmWithProps && isConfirming) {
              isApplying = await this.effectConfirm(effect.confirmWithProps.message, effect.confirmWithProps);
            }

            if (isApplying) {
              effectResult = await this.effectApply(effect, {
                effectLabel,
                effectDataCustom,
                itemId,
                modifierValue,
              });

              if (effectResult) {
                result = true;

                if (ef.isCustom) {
                  this.handleUseOfCustomEffect(ef);
                }
              }
            }
          }
        } catch (e) {
          $dbg(`Error for effect ${effect.name}`, e);
          return;
        }
      }

      return result;
    },

    handleUseOfCustomEffect(ef) {
      this.$store.dispatch('userSettings/discardEffectCustom', ef.hash);
      const upgrade = this.$store.getters['systems/getUpgradeByName'](this.$store.state.systems.upgradeName.ShadeCreation);

      if (upgrade && upgrade.value > 0 && upgrade.value <= 1) {
        const isGranted = this.$store.getters.getRandomInt(ef.hash, 100) <= upgrade.value * 100;

        if (isGranted) {
          const count = 1;
          const name = Resource.ResourceName.enum.Shade;
          this.$store.dispatch('resources/grant', { name, count });
          this.$store.dispatch('ui/addToast', {
            type: 'ResourceGrant',
            text: `${count} effect bonus`,
            payload: {
              resourceName: name,
            },
            severity: 'secondary',
          });
        }
      }
    },
  },
};
