import { z } from 'zod';
import { EffectStandard } from './EffectStandard';

export default {
  name: EffectStandard.EffectName.enum.ReminderDelay,
  label: 'Snooze',
  verb: 'Snooze',
  targets: [EffectStandard.Target.enum.ActionReminding],
  icon: ['fal', 'alarm-snooze'],
  color: 'var(--sl-color-purple-600)',
  description: 'Delay an active reminder by a given amount of time.',
  effectCheck: EffectStandard.checkActionIsReminding,
  getEffectData({ props }) {
    return Promise.resolve({
      itemId: props.itemId,
    });
  },
  effect({ $store, effectData, effect }) {
    return new Promise((resolve) => {
      $store.dispatch('ui/showModal', {
        name: 'actionReminderDelay',
        payload: {
          itemId: effectData.itemId,
          effectName: EffectStandard.EffectName.enum.ReminderDelay,
          titleIcon: effect.icon,
          verb: effect.verb,
          promptText: 'When would you like to be reminded again?',
        },
      });

      EffectStandard.logEvent({ $store, effectName: effect.name });
      resolve(true);
    });
  },
} as z.infer<typeof EffectStandard.Model>;
