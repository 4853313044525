import { Resource } from '../api/src/models/Resource';
import { wrapGetters } from '../lib/util';

export const state = () => ({
  resources: Resource.Setting,
});

export const mutations = {};

const resourceGetters = {};

Object.keys(Resource.Setting).forEach((name) => {
  resourceGetters[`perDay${name}`] = () => {
    return 1;
  };
  resourceGetters[`count${name}`] = (_state, getters) => {
    return getters.getCountForResource(name);
  };
});

export const getters = wrapGetters('resources', {
  getResourceByName: state => (name) => {
    return Object.values(state.resources).find(r => r.name === name);
  },
  getSettingForResource: (_state, _getters, rootState) => (name) => {
    return rootState.userSettings.resources.find(i => i.name === name);
  },
  getCountForResource: (_state, getters) => (name) => {
    let count = 0;

    const r = getters.getSettingForResource(name);

    if (r) {
      ({ count } = r);
    }

    return count;
  },
  ...resourceGetters,
});

export const actions = {
  async grant({ getters, dispatch }, { name, count }) {
    const setting = Resource.Setting[name];
    const userSetting = getters.getSettingForResource(name);

    await dispatch('userSettings/editResources', [{
      count: Math.max(0, userSetting?.count || 0) + count,
      name,
    }], { root: true })

    dispatch('ui/logMessage', `${setting.label} +${count}`, { root: true });
  },

  grantDayStart({ rootGetters, dispatch }) {
    Object.keys(Resource.Setting)
      .filter(name => Resource.Setting[name].grantType === Resource.GrantType.enum.DayStart)
      .forEach((name) => {
        let count = 1;
        const upgrade = rootGetters['systems/upgrades'].find(i => i.ref === name);

        if (upgrade) {
          count = upgrade.value;
        }

        if (count > 0) {
          dispatch('grant', { name, count })
            .then(() => {
              dispatch('ui/addToast', {
                type: 'ResourceGrant',
                text: `${count} mission bonus`,
                payload: {
                  resourceName: name,
                },
                severity: 'secondary',
              }, { root: true });
            });
        }
      });
  },
};
