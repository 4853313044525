import { SystemUpgrade } from '../api/src/models/SystemUpgrade';

export const state = () => ({
  quotes: [],
  quotesForDay: [],
});

export const mutations = {
  quotes(localState, val) {
    localState.quotes = val;
  },
  quotesForDay(localState, quotes) {
    localState.quotesForDay = quotes;
  },
};

export const actions = {
  async getQuotes({ commit, dispatch }) {
    try {
      const response = await fetch('/json/quotes2.json');
      const data = await response.json();

      if (data) {
        commit('quotes', data);
      }

      dispatch('setForDay');
      dispatch('setDataSourceSyncRemote', 'quotes', { root: true });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },

  setForDay({ state, rootGetters, commit }) {
    const upgrade = rootGetters['systems/upgrades']
      .find(u => u.name === SystemUpgrade.Name.enum.QuoteSelectCount);

    // always get the max, but only display what is unlocked
    const r = rootGetters.getRandomSetForDay(rootGetters.virtualDay, upgrade.values[upgrade.values.length - 1])
      .map(val => Math.floor(val * state.quotes.length))
      .map(val => ({
        ...state.quotes[val],
      }));

    commit('quotesForDay', r);
  },
};
