import dayjs from 'dayjs';
import { z } from 'zod';

import { UserSettings } from './UserSettings';
import { convertTimestampToDate, preprocessFirestoreIdFromRef } from './util';

const isDate = (val: any) => {
  return z.date().safeParse(val).success === true;
};

export namespace Action {
  export const DataTableName: string = 'tasks';
  export const ValueAddedMax = 5;

  export const DescriptionExamples = [
    'Take out the trash',
    'Walk the dog',
    'Reply to Aunt Vickie\'s email',
    'Compile TPS reports for Q3',
    'Read for 15 minutes',
    'Take vitamins',
    'Do one load of laundry',
    'Make the bed',
    'Do 20 pushups or squats',
    'Call Brad about flights',
    'Pack lunches for the kids',
    'Fix Mom\'s printer',
    'Ask Elrond about the ring',
    'Restart the forge at Nidavellir',
    'Return the Time Stone to the Ancient One',
    'Tell Bran about Jon\'s parents',
    'Buy rollerskating outfit for Ben\'s birthday party',
  ];

  export namespace EffortImportance {
    export const FibonacciValues = [1, 2, 3, 5, 8, 13, 21];
    export const RangeValues = [1, 2, 3, 4, 5, 6, 7];

    export const FibonacciToRangeValueMap = FibonacciValues.reduce((obj, key, i) => Object.assign({}, obj, { [key]: RangeValues[i] }), {});
    export const RangeValueToFibonacciMap = RangeValues.reduce((obj, key, i) => Object.assign({}, obj, { [key]: FibonacciValues[i] }), {});

    export const EffortDescriptorMap: { [key: number]: string } = {
      1: 'super easy',
      2: 'very easy',
      3: 'easy',
      5: 'manageable',
      8: 'hard',
      13: 'very hard',
      21: 'super hard',
    };

    export const ImportanceDescriptorMap: { [key: number]: string } = {
      1: 'immaterial',
      2: 'trivial',
      3: 'minor',
      5: 'valuable',
      8: 'important',
      13: 'very important',
      21: 'critical',
    };

    export const EffortMaxValue = FibonacciValues[FibonacciValues.length - 1];
    export const ImportanceMaxValue = FibonacciValues[FibonacciValues.length - 1];
  }

  export const Frequency = z.enum([
    'Once',
    'Day',
    'Morning',
    'Afternoon',
    'Evening',
    'Weekday',
    'Weekend',
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Month',
  ]);

  const FrequencyOld: { [key: string]: z.infer<typeof Frequency> } = {
    0: 'Once',
    1: 'Day',
    2: 'Morning',
    3: 'Afternoon',
    4: 'Evening',
    5: 'Sunday',
    6: 'Monday',
    7: 'Tuesday',
    8: 'Wednesday',
    9: 'Thursday',
    10: 'Friday',
    11: 'Saturday',
    12: 'Month',
  };

  export const RemindAtFormatData = 'HH:mm:00';
  export const RemindAtFormatDisplay = 'hh:mm A';

  export const Status = z.enum([
    'Todo',
    'Done',
  ]);

  export const ModelDefault = {
    id: null,
    userId: undefined,
    createdAt: undefined,
    status: Status.enum.Todo,
    description: '',
    effort: 1,
    importance: 13,
    frequency: Frequency.enum.Once,
    timeSpent: 0,
    interest: [],
    occurrenceOf: null,
    remindAt: null,
    lastRemindedAt: null,
    value: null,
    link: null,
    timeframe: null,
    lastProgressAt: null,
    lastModifiedAt: null,
    frequencyMonthDate: null,
  };

  export const Model = z.object({
    // id is nullable so Firestore can create new ids
    id: z.nullable(z.string().min(1)).default(ModelDefault.id),

    userId: z.string().min(1),

    createdAt: z.preprocess(
      convertTimestampToDate,
      z.date(),
    ),

    status: Status.default(ModelDefault.status as z.infer<typeof Status>),

    description: z.string().min(3),

    effort: z.number().positive().default(ModelDefault.effort)
      .refine((val: number) => EffortImportance.FibonacciValues.includes(val)),

    importance: z.number().positive().default(ModelDefault.importance)
      .refine((val: number) => EffortImportance.FibonacciValues.includes(val)),

    frequency: z.preprocess(
      (val) => {
        if (typeof val === 'number' && Object.keys(FrequencyOld).includes(`${val}`)) {
          return FrequencyOld[`${val}`];
        } else {
          return val;
        }
      },
      Frequency.default(ModelDefault.frequency as z.infer<typeof Frequency>)
    ),

    timeSpent: z.preprocess(
      val => typeof val === 'number' ? Math.max(0, val) : 0,
      z.number().nonnegative().default(ModelDefault.timeSpent),
    ),

    interest: z.preprocess(
      (val) => {
        if (Array.isArray(val)) {
          return val.map(v => preprocessFirestoreIdFromRef(v));
        }

        return undefined;
      },
      z.string().min(1).array().default(ModelDefault.interest)
    ),

    occurrenceOf: z.preprocess(
      val => preprocessFirestoreIdFromRef(val),
      z.nullable(z.string().min(1)).default(ModelDefault.occurrenceOf)
    ),

    remindAt: z.nullable(z.string().length(RemindAtFormatData.length)).default(ModelDefault.remindAt), // regex

    lastRemindedAt: z.preprocess(
      convertTimestampToDate,
      z.nullable(z.date()).default(ModelDefault.lastRemindedAt),
    ),

    value: z.nullable(z.number().nonnegative()).default(ModelDefault.value),

    link: z.preprocess(
      (val) => {
        if (typeof val !== 'string' || !val) {
          return null;
        }

        return val;
      },
      z.nullable(z.string().url()).default(ModelDefault.link),
    ),

    timeframe: z.preprocess(
      (val) => {
        if (!Array.isArray(val)) {
          return null;
        }

        const modVal: unknown[] = [];

        val.forEach((v) => {
          modVal.push(convertTimestampToDate(v));
        });

        // force timeframe to only have a due date
        const expiresAt = modVal[1] ?? modVal[0];
        return [expiresAt, null];
      },
      z.nullable(
        z.array(
          z.nullable(z.date())
        )
      ).default(ModelDefault.timeframe),
    ),

    lastProgressAt: z.preprocess(
      convertTimestampToDate,
      z.nullable(z.date()).default(ModelDefault.lastProgressAt),
    ),

    lastModifiedAt: z.preprocess(
      convertTimestampToDate,
      z.nullable(z.date()).default(ModelDefault.lastModifiedAt),
    ),

    frequencyMonthDate: z.nullable(z.number().min(1).max(31)).default(ModelDefault.frequencyMonthDate),
  });

  export const FrequencyDisplayMap = {
    once: Frequency.enum.Once,
    'every day': Frequency.enum.Day,
    'every morning': Frequency.enum.Morning,
    'every afternoon': Frequency.enum.Afternoon,
    'every evening': Frequency.enum.Evening,
    'on weekdays': Frequency.enum.Weekday,
    'on weekends': Frequency.enum.Weekend,
    'on Sundays': Frequency.enum.Sunday,
    'on Mondays': Frequency.enum.Monday,
    'on Tuesdays': Frequency.enum.Tuesday,
    'on Wednesdays': Frequency.enum.Wednesday,
    'on Thursdays': Frequency.enum.Thursday,
    'on Fridays': Frequency.enum.Friday,
    'on Saturdays': Frequency.enum.Saturday,
    'every month': Frequency.enum.Month,
  };

  export const FrequenciesDaily = [
    Frequency.enum.Day,
    Frequency.enum.Morning,
    Frequency.enum.Afternoon,
    Frequency.enum.Evening,
  ];

  export const FrequenciesWeekly = [
    Frequency.enum.Sunday,
    Frequency.enum.Monday,
    Frequency.enum.Tuesday,
    Frequency.enum.Wednesday,
    Frequency.enum.Thursday,
    Frequency.enum.Friday,
    Frequency.enum.Saturday,
  ];

  // Match dayjs().day()
  export const FrequencyDayOfWeekMap: { [key: string]: number[] } = {
    [Frequency.enum.Day]: [0, 1, 2, 3, 4, 5, 6],
    [Frequency.enum.Morning]: [0, 1, 2, 3, 4, 5, 6],
    [Frequency.enum.Afternoon]: [0, 1, 2, 3, 4, 5, 6],
    [Frequency.enum.Evening]: [0, 1, 2, 3, 4, 5, 6],
    [Frequency.enum.Weekday]: [1, 2, 3, 4, 5],
    [Frequency.enum.Weekend]: [0, 6],
    [Frequency.enum.Sunday]: [0],
    [Frequency.enum.Monday]: [1],
    [Frequency.enum.Tuesday]: [2],
    [Frequency.enum.Wednesday]: [3],
    [Frequency.enum.Thursday]: [4],
    [Frequency.enum.Friday]: [5],
    [Frequency.enum.Saturday]: [6],
  };

  export const isFrequencyRepeating = (freq: z.infer<typeof Frequency>) => {
    return Frequency.safeParse(freq).success && freq !== Frequency.enum.Once;
  };

  export const isFrequencyDaily = (freq: z.infer<typeof Frequency>) => {
    // This is a TypeScript workaround for Array.includes()
    return (FrequenciesDaily as z.infer<typeof Frequency>[]).includes(freq);
  };

  export const isFrequencyWeekly = (freq: z.infer<typeof Frequency>) => {
    // This is a TypeScript workaround for Array.includes()
    return (FrequenciesWeekly as z.infer<typeof Frequency>[]).includes(freq);
  };

  export const isFrequencyForDayOfWeek = (freq: z.infer<typeof Frequency>, day: number) => {
    return Object.keys(FrequencyDayOfWeekMap).includes(freq) && FrequencyDayOfWeekMap[freq].includes(day);
  };

  export const getFrequencyDisplay = (freq: z.infer<typeof Frequency>) => {
    const displayVals = Object.keys(FrequencyDisplayMap);
    const freqVals = Object.values(FrequencyDisplayMap);
    return displayVals[freqVals.indexOf(freq)];
  }

  export const getFrequencyDisplayForItem = (item: z.infer<typeof Model>) => {
    let display = '';

    if (item.frequency !== Frequency.enum.Once) {
      display = getFrequencyDisplay(item.frequency);

      if (item.frequency === Frequency.enum.Month) {
        display = `${display} on day ${item.frequencyMonthDate}`;
      }
    }

    return display;
  };

  export const isItemFrequencyMonthDateForVirtualDay = (item: z.infer<typeof Model>, virtualDay: dayjs.Dayjs) => {
    if (!item.frequencyMonthDate) {
      return false;
    }

    const dateOfTheMonth = virtualDay.date();
    const dateEndOfTheMonth = virtualDay.endOf('month').date();

    return item.frequency === Frequency.enum.Month && Model.shape.frequencyMonthDate.safeParse(item.frequencyMonthDate).success &&
      (dateOfTheMonth === item.frequencyMonthDate ||
        (dateOfTheMonth > dateEndOfTheMonth && item.frequencyMonthDate > dateEndOfTheMonth)
      );
  };

  export const isRepeatingItemForVirtualDate = (item: z.infer<typeof Model>, virtualDay: dayjs.Dayjs) => {
    if (item.frequency === Frequency.enum.Month) {
      return isItemFrequencyMonthDateForVirtualDay(item, virtualDay);
    } else {
      const dayOfTheWeek = virtualDay.day();
      return isFrequencyForDayOfWeek(item.frequency, dayOfTheWeek);
    }
  };

  export const getItemExpiresAt = (userSettings: z.infer<typeof UserSettings.Model>, item: z.infer<typeof Model>) => {
    let expiresAt;

    if (Array.isArray(item.timeframe) && isDate(item.timeframe[0])) {
      expiresAt = isDate(item.timeframe[1]) ? item.timeframe[1] : item.timeframe[0];
      expiresAt = UserSettings.getDayStartingAtUserResetTime(
        userSettings,
        dayjs(expiresAt).tz(UserSettings.TZ).subtract(1, 'second'),
      );
    }

    return expiresAt;
  };

  export const isItemExpiringVirtualDay = (
    userSettings: z.infer<typeof UserSettings.Model>,
    virtualDay: dayjs.Dayjs,
    item: z.infer<typeof Model>
  ) => {
    const expiresAt = getItemExpiresAt(userSettings, item);
    return expiresAt ? expiresAt.isSame(virtualDay, 'day') : false;
  };
}
