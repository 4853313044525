
import { App } from '../api/src/models/App';

export default {
  name: 'ModalHeader',
  props: {
    title: {
      type: String,
      default: null,
    },
    conceptName: {
      type: String,
      default: null,
    },
    conceptsRelated: {
      type: Array,
      default() {
        return [];
      },
    },
    isConceptPlural: {
      type: Boolean,
      default: false,
    },
    hideObjectives: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    appName() {
      return this.$store.state.app.name;
    },
    appConceptName() {
      return App.Concept.ConceptName.enum;
    },
    concepts() {
      let c = [];

      if (this.conceptName) {
        c.push(this.conceptName);
      }

      if (Array.isArray(this.conceptsRelated) && this.conceptsRelated.length > 0) {
        c = c.concat(this.conceptsRelated);
      }

      return c;
    },
    hasConcepts() {
      return !this.hideObjectives && this.concepts.length > 0 && this.conceptName !== this.appConceptName.Objective;
    },
  },
  methods: {
    displayMessagesForConcepts(concepts = []) {
      this.$store.dispatch('ui/showModal', {
        name: 'objectivesList',
        payload: {
          appConcepts: concepts,
        },
      });
    },
  },
};
