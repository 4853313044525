import _ from 'lodash';

import {
  StoreHelperMutation,
  storeHelperMutations,
} from '../lib/store-helpers';

import {
  bindToDoc,
  upsertDoc,
} from '../lib/data';

import { wrapGetters } from '../lib/util';

import { App } from '../api/src/models/App';
import { Suggestion } from '../api/src/models/Suggestion';
import { isDate } from '../api/src/models/util';

const defaultState = _.cloneDeep(App.ModelDefault);

export const state = () => ({
  ...defaultState,
  initLastUpdatedAt: undefined,
});

export const mutations = {
  ...storeHelperMutations,
  initLastUpdatedAt(localState, val) {
    localState.initLastUpdatedAt = val;
  },
};

export const getters = wrapGetters('app', {
  shouldPromptToUpdate: (state) => {
    return state.lastUpdatedAt > state.initLastUpdatedAt;
  },
});

export const actions = {
  init({ state, commit, dispatch }) {
    bindToDoc(App.Model, App.DataTableName, App.DbDocName, {
      onInit: () => {
        this.$dbg('store:app')('init data');
        dispatch('setDataSourceSync', { name: 'app' }, { root: true });
      },
      onUpdate: ({ data }) => {
        if (!isDate(state.initLastUpdatedAt) && isDate(data.lastUpdatedAt)) {
          commit('initLastUpdatedAt', data.lastUpdatedAt);
        }

        commit(StoreHelperMutation.SetOnRoot, data);
        dispatch('setDataSourceSync', { name: 'app' }, { root: true });
      },
    });
  },

  async addSuggestion({ rootState }, suggestionText) {
    // Get userId directly from auth to ensure they still have a session
    const userId = await this.$auth.getUserId();

    if (userId) {
      upsertDoc(Suggestion.Model, Suggestion.DataTableName, undefined, {
        userId,
        userName: rootState.user.displayName,
        text: `${suggestionText}`,
        createdAt: Math.round((new Date()).getTime() / 1000),
      });
    }
  },
};
