
import { Avatar } from '../api/src/models/Avatar';
import Icon from './icon';

export default {
  name: 'UserAvatar',
  components: {
    Icon,
  },
  props: {
    name: {
      type: String,
      default: undefined,
    },
    iconInheritDimensions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    item() {
      let item;

      if (this.name === Avatar.NameNoSelection) {
        item = undefined;
      } else if (this.name) {
        item = Avatar.Setting[this.name];
      } else if (this.$store.getters['userSettings/avatarSelected']) {
        item = this.$store.getters['userSettings/avatarSelected'];
      }

      return item;
    },
  },
};
