import { wrapGetters } from '../lib/util';
import { Objective } from '../api/src/models/Objective';

export const state = () => ({});

export const mutations = {};

export const getters = wrapGetters('objectives', {
  getItemFromUserSettings: (_state, _getters, rootState) => (itemName) => {
    return rootState.userSettings.objectives?.find(i => i.name === itemName);
  },
  items: (_state, getters) => {
    let isUnlockedPrev = true;
    let didViewPrev = true;
    let isSuccessfulPrev = true;

    return Object.keys(Objective.Setting).map((name) => {
      const item = {
        ...Objective.Setting[name],
        isUnlocked: false,
        didView: false,
        isSuccessful: false,
      };

      const userSetting = getters.getItemFromUserSettings(name);

      if (isUnlockedPrev || isSuccessfulPrev) {
        item.isUnlocked = userSetting?.isUnlocked === true;
        isUnlockedPrev = item.isUnlocked;
      }

      if (didViewPrev) {
        item.didView = userSetting?.didView === true;
        didViewPrev = item.didView;
      }

      if (isSuccessfulPrev) {
        item.isSuccessful = userSetting?.isSuccessful === true;
        isSuccessfulPrev = item.isSuccessful;
      }

      return item;
    });
  },
  itemsReversed: (_state, getters) => {
    const items = [...getters.items];
    items.reverse();
    return items;
  },
  itemActiveIndex: (_state, getters) => {
    const isComplete = (item) => {
      return item.didView && item.isSuccessful;
    };

    // get the highest objective that is complete, and show the next one.
    let index = getters.itemsReversed.findIndex(i => isComplete(i)) - 1;

    if (index < 0) {
      index = getters.itemsReversed.length - 1;
    }

    const canBeActive = index >= 0 &&
      index < getters.itemsReversed.length &&
      !isComplete(getters.itemsReversed[index]) &&
      getters.itemsReversed[index].isUnlocked;

    return canBeActive ? Math.max(0, getters.itemsReversed.length - 1 - index) : undefined;
  },
  itemActive: (_state, getters) => {
    return getters.items[getters.itemActiveIndex];
  },
});

export const actions = {
};
