
import { Effect } from '../api/src/models/effect';
import { SystemUpgrade } from '../api/src/models/SystemUpgrade';

import actionMixin from '../mixins/action';
import effectsMixin from '../mixins/effects';
import uiMixin from '../mixins/ui';

export default {
  name: 'ActionLogCompletion',
  mixins: [
    actionMixin,
    effectsMixin,
    uiMixin,
  ],
  props: {
    itemId: {
      type: String,
      default: undefined,
    },
    effectName: {
      type: String,
      default: Effect.EffectName.enum.Completion,
    },
    promptText: {
      type: String,
      default: '',
    },
    verb: {
      type: String,
      default: Effect.Setting[Effect.EffectName.enum.Completion].verb,
    },
  },
  computed: {
    actionId() {
      return this.itemId;
    },
    effect() {
      return (this.effectName && Effect.EffectName.options.includes(this.effectName))
        ? Effect.Setting[this.effectName]
        : Effect.Setting[Effect.EffectName.enum.Completion];
    },
    upgradeName() {
      let name;

      switch (this.effect?.name) {
        case Effect.EffectName.enum.Forgiveness:
          name = this.$store.state.systems.upgradeName.ForgivenessRange;
          break;
        case Effect.EffectName.enum.Recovery:
          name = this.$store.state.systems.upgradeName.RecoveryRange;
          break;
        case Effect.EffectName.enum.Resolve:
          name = this.$store.state.systems.upgradeName.ResolveRange;
          break;
      }

      return name;
    },
    upgrade() {
      let upgrade;

      if (this.isDebugger && this.upgradeName) {
        upgrade = this.$store.getters['systems/upgrades'].find(i => i.name === this.upgradeName);
      }

      return upgrade;
    },
    helpText() {
      return this.effect ? this.effect.description : undefined;
    },
    minValue() {
      let val = 0;

      if (this.effect.name === Effect.EffectName.enum.Recovery) {
        val = 1;
      }

      if (this.upgrade) {
        val = this.upgrade.values[0];
      }

      return val;
    },
    maxValue() {
      let val = this.$store.state.recentDaysCount;

      if (this.upgrade) {
        val = this.upgrade.value;
      }

      return val;
    },
    maxValueDisplay() {
      let display = `${this.maxValue} days`;

      if (this.upgrade) {
        display = this.maxValue === 0 ? 'Today' : this.upgrade.valueDisplay;
      }

      return display;
    },
    invalidDaysAgo() {
      const days = [];

      if (this.recentlyCompletedOccurrencesForItem && this.recentlyCompletedOccurrencesForItem.length > 0) {
        const daysOfOccurrences = [...this.recentlyCompletedOccurrencesForItem].reverse();

        daysOfOccurrences.forEach((d, di) => {
          const daysAgo = di;

          if (Array.isArray(d) && d.length > 0) {
            days.push(daysAgo);
          }
        });
      }

      return days;
    },
    dateOptions() {
      const opts = [];

      if (Array.isArray(this.upgrade?.values)) {
        this.upgrade.values.filter(v => v <= this.maxValue).forEach((v) => {
          const d = this.$store.getters.virtualDay.subtract(v, 'days');
          let label;

          if (v === 0) {
            label = 'Today';
          } else if (v === 1) {
            label = 'Yesterday';
          } else {
            label = `${d.format('dddd')} ${d.format('MM/DD')}`;
          }

          opts.push({
            label,
            value: v,
            isDisabled: this.invalidDaysAgo.includes(v),
          });
        });
      }

      if (!this.isDebugger && !this.upgrade) {
        const { values } = SystemUpgrade.Setting[this.upgradeName];
        values.forEach((v) => {
          const d = this.$store.getters.virtualDay.subtract(v, 'days');
          let label;

          if (v === 0) {
            label = 'Today';
          } else if (v === 1) {
            label = 'Yesterday';
          } else {
            label = `${d.format('dddd')} ${d.format('MM/DD')}`;
          }

          opts.push({
            label,
            value: v,
            isDisabled: this.invalidDaysAgo.includes(v),
          });
        });
      }

      return opts;
    },
    dateOptionsEnabled() {
      return this.dateOptions.filter(i => !i.isDisabled);
    },
  },
  methods: {
    toggleDateOptionsMenu($event) {
      this.$refs['date-options-menu'].toggle($event);
    },
    async tryCompleteWithDay(option) {
      let value;
      let label;

      if (option instanceof Event) {
        value = Number(option.detail?.item?.value);
        label = this.dateOptions.find(o => o.value === value)?.label;
      } else {
        ({ value, label } = option);
      }

      if (isNaN(value) || !label || value < this.minValue || value > this.maxValue) {
        return false;
      }

      let msg = `Are you sure you want to ${this.verb.toLowerCase()} this action on ${label}?`

      if (value <= 1) {
        msg = `Are you sure you want to ${this.verb.toLowerCase()} this action for ${label.toLowerCase()}?`
      }

      const isCompleting = await this.uiMsgBoxConfirm(msg);

      if (!isCompleting) {
        return false;
      }

      // This is only the end of the actual day, not the virtual day
      let d = this.$dayjs().subtract(value, 'days').endOf('day').subtract(1, 'seconds');

      if (d.isAfter(this.$store.state.now)) {
        d = this.$store.state.now;
      }

      const effect = Effect.Setting[Effect.EffectName.enum.Completion];
      const result = await this.effectApply(effect, {
        itemId: this.itemId,
        effectDataCustom: {
          lastProgressAt: d.toDate(),
        },
      });

      if (result) {
        this.$emit('close');
      }
    },
    handleViewUpgrades() {
      this.$store.dispatch('ui/showModal', { name: 'systemUpgrades' });
      this.$emit('close');
    },
  },
};
