import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
export var About = function About() {
  return import('../../components/about.vue' /* webpackChunkName: "components/about" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AcceptableUsePolicy = function AcceptableUsePolicy() {
  return import('../../components/acceptable-use-policy.vue' /* webpackChunkName: "components/acceptable-use-policy" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionCardBackground = function ActionCardBackground() {
  return import('../../components/action-card-background.vue' /* webpackChunkName: "components/action-card-background" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionCard = function ActionCard() {
  return import('../../components/action-card.vue' /* webpackChunkName: "components/action-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionCompletedMessage = function ActionCompletedMessage() {
  return import('../../components/action-completed-message.vue' /* webpackChunkName: "components/action-completed-message" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionDescription = function ActionDescription() {
  return import('../../components/action-description.vue' /* webpackChunkName: "components/action-description" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionEdit = function ActionEdit() {
  return import('../../components/action-edit.vue' /* webpackChunkName: "components/action-edit" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionFocusMessage = function ActionFocusMessage() {
  return import('../../components/action-focus-message.vue' /* webpackChunkName: "components/action-focus-message" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionFocus = function ActionFocus() {
  return import('../../components/action-focus.vue' /* webpackChunkName: "components/action-focus" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionImportanceEffort = function ActionImportanceEffort() {
  return import('../../components/action-importance-effort.vue' /* webpackChunkName: "components/action-importance-effort" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionListItem = function ActionListItem() {
  return import('../../components/action-list-item.vue' /* webpackChunkName: "components/action-list-item" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionLogCompletion = function ActionLogCompletion() {
  return import('../../components/action-log-completion.vue' /* webpackChunkName: "components/action-log-completion" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionPostponement = function ActionPostponement() {
  return import('../../components/action-postponement.vue' /* webpackChunkName: "components/action-postponement" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionRecentCompletions = function ActionRecentCompletions() {
  return import('../../components/action-recent-completions.vue' /* webpackChunkName: "components/action-recent-completions" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionRemindMessage = function ActionRemindMessage() {
  return import('../../components/action-remind-message.vue' /* webpackChunkName: "components/action-remind-message" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionReminderDelay = function ActionReminderDelay() {
  return import('../../components/action-reminder-delay.vue' /* webpackChunkName: "components/action-reminder-delay" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionSelector = function ActionSelector() {
  return import('../../components/action-selector.vue' /* webpackChunkName: "components/action-selector" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionsDataView = function ActionsDataView() {
  return import('../../components/actions-data-view.vue' /* webpackChunkName: "components/actions-data-view" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionsPlanCycle = function ActionsPlanCycle() {
  return import('../../components/actions-plan-cycle.vue' /* webpackChunkName: "components/actions-plan-cycle" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionsPlanProgress = function ActionsPlanProgress() {
  return import('../../components/actions-plan-progress.vue' /* webpackChunkName: "components/actions-plan-progress" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ActionsPlan = function ActionsPlan() {
  return import('../../components/actions-plan.vue' /* webpackChunkName: "components/actions-plan" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Actions = function Actions() {
  return import('../../components/actions.vue' /* webpackChunkName: "components/actions" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AppConceptButton = function AppConceptButton() {
  return import('../../components/app-concept-button.vue' /* webpackChunkName: "components/app-concept-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AppConcept = function AppConcept() {
  return import('../../components/app-concept.vue' /* webpackChunkName: "components/app-concept" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AppUpdateMessage = function AppUpdateMessage() {
  return import('../../components/app-update-message.vue' /* webpackChunkName: "components/app-update-message" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Auth = function Auth() {
  return import('../../components/auth.vue' /* webpackChunkName: "components/auth" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ChartsHighlights = function ChartsHighlights() {
  return import('../../components/charts-highlights.vue' /* webpackChunkName: "components/charts-highlights" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ChartsView = function ChartsView() {
  return import('../../components/charts-view.vue' /* webpackChunkName: "components/charts-view" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CompanionCardBackground = function CompanionCardBackground() {
  return import('../../components/companion-card-background.vue' /* webpackChunkName: "components/companion-card-background" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CompanionCard = function CompanionCard() {
  return import('../../components/companion-card.vue' /* webpackChunkName: "components/companion-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CompanionListItem = function CompanionListItem() {
  return import('../../components/companion-list-item.vue' /* webpackChunkName: "components/companion-list-item" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CompanionStatus = function CompanionStatus() {
  return import('../../components/companion-status.vue' /* webpackChunkName: "components/companion-status" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CompanionView = function CompanionView() {
  return import('../../components/companion-view.vue' /* webpackChunkName: "components/companion-view" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CompanionsList = function CompanionsList() {
  return import('../../components/companions-list.vue' /* webpackChunkName: "components/companions-list" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CookiePolicy = function CookiePolicy() {
  return import('../../components/cookie-policy.vue' /* webpackChunkName: "components/cookie-policy" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DataRepair = function DataRepair() {
  return import('../../components/data-repair.vue' /* webpackChunkName: "components/data-repair" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DayStart = function DayStart() {
  return import('../../components/day-start.vue' /* webpackChunkName: "components/day-start" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DemoData = function DemoData() {
  return import('../../components/demo-data.vue' /* webpackChunkName: "components/demo-data" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DialogConfirm = function DialogConfirm() {
  return import('../../components/dialog-confirm.vue' /* webpackChunkName: "components/dialog-confirm" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DialogPrompt = function DialogPrompt() {
  return import('../../components/dialog-prompt.vue' /* webpackChunkName: "components/dialog-prompt" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EffectApplyButton = function EffectApplyButton() {
  return import('../../components/effect-apply-button.vue' /* webpackChunkName: "components/effect-apply-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EffectCustomListItem = function EffectCustomListItem() {
  return import('../../components/effect-custom-list-item.vue' /* webpackChunkName: "components/effect-custom-list-item" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EffectCustomSpark = function EffectCustomSpark() {
  return import('../../components/effect-custom-spark.vue' /* webpackChunkName: "components/effect-custom-spark" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EffectTile = function EffectTile() {
  return import('../../components/effect-tile.vue' /* webpackChunkName: "components/effect-tile" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EffectsButton = function EffectsButton() {
  return import('../../components/effects-button.vue' /* webpackChunkName: "components/effects-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EffectsList = function EffectsList() {
  return import('../../components/effects-list.vue' /* webpackChunkName: "components/effects-list" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EffectsSelect = function EffectsSelect() {
  return import('../../components/effects-select.vue' /* webpackChunkName: "components/effects-select" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GenLocationNames = function GenLocationNames() {
  return import('../../components/gen-location-names.vue' /* webpackChunkName: "components/gen-location-names" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Help = function Help() {
  return import('../../components/help.vue' /* webpackChunkName: "components/help" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Icon = function Icon() {
  return import('../../components/icon.vue' /* webpackChunkName: "components/icon" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var IdeaQuickEntry = function IdeaQuickEntry() {
  return import('../../components/idea-quick-entry.vue' /* webpackChunkName: "components/idea-quick-entry" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Insight = function Insight() {
  return import('../../components/insight.vue' /* webpackChunkName: "components/insight" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var InterestEdit = function InterestEdit() {
  return import('../../components/interest-edit.vue' /* webpackChunkName: "components/interest-edit" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var InterestsTextDisplay = function InterestsTextDisplay() {
  return import('../../components/interests-text-display.vue' /* webpackChunkName: "components/interests-text-display" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Interests = function Interests() {
  return import('../../components/interests.vue' /* webpackChunkName: "components/interests" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Intro = function Intro() {
  return import('../../components/intro.vue' /* webpackChunkName: "components/intro" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var LocalNotifications = function LocalNotifications() {
  return import('../../components/local-notifications.vue' /* webpackChunkName: "components/local-notifications" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var MessagesAlert = function MessagesAlert() {
  return import('../../components/messages-alert.vue' /* webpackChunkName: "components/messages-alert" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var MessagesView = function MessagesView() {
  return import('../../components/messages-view.vue' /* webpackChunkName: "components/messages-view" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var MilestonesList = function MilestonesList() {
  return import('../../components/milestones-list.vue' /* webpackChunkName: "components/milestones-list" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ModalFull = function ModalFull() {
  return import('../../components/modal-full.vue' /* webpackChunkName: "components/modal-full" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ModalHeader = function ModalHeader() {
  return import('../../components/modal-header.vue' /* webpackChunkName: "components/modal-header" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var NavBottom = function NavBottom() {
  return import('../../components/nav-bottom.vue' /* webpackChunkName: "components/nav-bottom" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var NavbarBottom = function NavbarBottom() {
  return import('../../components/navbar-bottom.vue' /* webpackChunkName: "components/navbar-bottom" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Navbar = function Navbar() {
  return import('../../components/navbar.vue' /* webpackChunkName: "components/navbar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ObjectiveAndLog = function ObjectiveAndLog() {
  return import('../../components/objective-and-log.vue' /* webpackChunkName: "components/objective-and-log" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ObjectiveButton = function ObjectiveButton() {
  return import('../../components/objective-button.vue' /* webpackChunkName: "components/objective-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Objective = function Objective() {
  return import('../../components/objective.vue' /* webpackChunkName: "components/objective" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ObjectivesList = function ObjectivesList() {
  return import('../../components/objectives-list.vue' /* webpackChunkName: "components/objectives-list" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PrivacyPolicy = function PrivacyPolicy() {
  return import('../../components/privacy-policy.vue' /* webpackChunkName: "components/privacy-policy" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var QuoteSelect = function QuoteSelect() {
  return import('../../components/quote-select.vue' /* webpackChunkName: "components/quote-select" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Quote = function Quote() {
  return import('../../components/quote.vue' /* webpackChunkName: "components/quote" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ResourceGranted = function ResourceGranted() {
  return import('../../components/resource-granted.vue' /* webpackChunkName: "components/resource-granted" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ResourceInfo = function ResourceInfo() {
  return import('../../components/resource-info.vue' /* webpackChunkName: "components/resource-info" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ResourceTransform = function ResourceTransform() {
  return import('../../components/resource-transform.vue' /* webpackChunkName: "components/resource-transform" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ResourceValueButtonFull = function ResourceValueButtonFull() {
  return import('../../components/resource-value-button-full.vue' /* webpackChunkName: "components/resource-value-button-full" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ResourceValueButton = function ResourceValueButton() {
  return import('../../components/resource-value-button.vue' /* webpackChunkName: "components/resource-value-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ResourceValue = function ResourceValue() {
  return import('../../components/resource-value.vue' /* webpackChunkName: "components/resource-value" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SceneSpace = function SceneSpace() {
  return import('../../components/scene-space.vue' /* webpackChunkName: "components/scene-space" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SpriteCollection = function SpriteCollection() {
  return import('../../components/sprite-collection.vue' /* webpackChunkName: "components/sprite-collection" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SpriteView = function SpriteView() {
  return import('../../components/sprite-view.vue' /* webpackChunkName: "components/sprite-view" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SubscribeMessage = function SubscribeMessage() {
  return import('../../components/subscribe-message.vue' /* webpackChunkName: "components/subscribe-message" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SuggestionBox = function SuggestionBox() {
  return import('../../components/suggestion-box.vue' /* webpackChunkName: "components/suggestion-box" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SystemUpgrades = function SystemUpgrades() {
  return import('../../components/system-upgrades.vue' /* webpackChunkName: "components/system-upgrades" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TermsOfService = function TermsOfService() {
  return import('../../components/terms-of-service.vue' /* webpackChunkName: "components/terms-of-service" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ToastsView = function ToastsView() {
  return import('../../components/toasts-view.vue' /* webpackChunkName: "components/toasts-view" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Ui = function Ui() {
  return import('../../components/ui.vue' /* webpackChunkName: "components/ui" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UserAvatar = function UserAvatar() {
  return import('../../components/user-avatar.vue' /* webpackChunkName: "components/user-avatar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UserSettingsAvatar = function UserSettingsAvatar() {
  return import('../../components/user-settings-avatar.vue' /* webpackChunkName: "components/user-settings-avatar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UserSettings = function UserSettings() {
  return import('../../components/user-settings.vue' /* webpackChunkName: "components/user-settings" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UserSubscriptions = function UserSubscriptions() {
  return import('../../components/user-subscriptions.vue' /* webpackChunkName: "components/user-subscriptions" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Value = function Value() {
  return import('../../components/value.vue' /* webpackChunkName: "components/value" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ChartDoughnut = function ChartDoughnut() {
  return import('../../components/chart/chart-doughnut.vue' /* webpackChunkName: "components/chart-doughnut" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ChartLine = function ChartLine() {
  return import('../../components/chart/chart-line.vue' /* webpackChunkName: "components/chart-line" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EcDialog = function EcDialog() {
  return import('../../components/ec/ec-dialog.vue' /* webpackChunkName: "components/ec-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EcDrawer = function EcDrawer() {
  return import('../../components/ec/ec-drawer.vue' /* webpackChunkName: "components/ec-drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options;
  }
  var propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {});
  return {
    render: function render(h) {
      var attrs = {};
      var props = {};
      for (var key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key];
        } else {
          attrs[key] = this.$attrs[key];
        }
      }
      return h(options, {
        on: this.$listeners,
        attrs: attrs,
        props: props,
        scopedSlots: this.$scopedSlots
      }, this.$slots.default);
    }
  };
}