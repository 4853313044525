export default async function() {
  if (process.client) {
    await import('@shoelace-style/shoelace/dist/components/badge/badge.js');
    await import('@shoelace-style/shoelace/dist/components/button/button.js');
    await import('@shoelace-style/shoelace/dist/components/button-group/button-group.js');
    await import('@shoelace-style/shoelace/dist/components/checkbox/checkbox.js');
    await import('@shoelace-style/shoelace/dist/components/details/details.js');
    await import('@shoelace-style/shoelace/dist/components/dialog/dialog.js');
    await import('@shoelace-style/shoelace/dist/components/divider/divider.js');
    await import('@shoelace-style/shoelace/dist/components/drawer/drawer.js');
    await import('@shoelace-style/shoelace/dist/components/dropdown/dropdown.js');
    await import('@shoelace-style/shoelace/dist/components/input/input.js');
    await import('@shoelace-style/shoelace/dist/components/menu/menu.js');
    await import('@shoelace-style/shoelace/dist/components/menu-item/menu-item.js');
    await import('@shoelace-style/shoelace/dist/components/option/option.js');
    await import('@shoelace-style/shoelace/dist/components/progress-bar/progress-bar.js');
    await import('@shoelace-style/shoelace/dist/components/radio-button/radio-button.js');
    await import('@shoelace-style/shoelace/dist/components/radio-group/radio-group.js');
    await import('@shoelace-style/shoelace/dist/components/range/range.js');
    await import('@shoelace-style/shoelace/dist/components/select/select.js');
    await import('@shoelace-style/shoelace/dist/components/skeleton/skeleton.js');
    await import('@shoelace-style/shoelace/dist/components/switch/switch.js');
    await import('@shoelace-style/shoelace/dist/components/tab/tab.js');
    await import('@shoelace-style/shoelace/dist/components/tab-group/tab-group.js');
    await import('@shoelace-style/shoelace/dist/components/tab-panel/tab-panel.js');
    await import('@shoelace-style/shoelace/dist/components/tag/tag.js');
    await import('@shoelace-style/shoelace/dist/components/textarea/textarea.js');
    await import('@shoelace-style/shoelace/dist/components/tooltip/tooltip.js');
  }
}
