import { z } from 'zod';

import dayjs, { extend as dayjsExtend } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Avatar } from './Avatar';
import { Companion } from './Companion';
import { Entitlement } from './Entitlement';
import { Objective } from './Objective';
import { Resource } from './Resource';
import { SystemUpgrade } from './SystemUpgrade';
import { convertTimestampToDate } from './util';

dayjsExtend(isSameOrAfter);
dayjsExtend(minMax);
dayjsExtend(utc);
dayjsExtend(timezone);

export namespace UserSettings {
  export const DataTableName: string = 'userSettings';
  export const TZ = 'America/New_York';

  export const EntitlementStatusSchema = z.object({
    name: z.preprocess(
      (val: unknown) => {
        if (Entitlement.EntitlementPurchaseNameMap[val as string]) {
          return Entitlement.EntitlementPurchaseNameMap[val as string];
        } else {
          return val;
        }
      },
      Entitlement.EntitlementName,
    ),
    expiresAt: z.preprocess(
      convertTimestampToDate,
      z.date(),
    ),
    isLifetime: z.boolean().default(false),
  });

  export const ObjectiveSchema = z.object({
    name: Objective.ObjectiveName,
    isUnlocked: z.boolean().default(false),
    didView: z.boolean().default(false),
    isSuccessful: z.boolean().default(false),
  });

  export const ResourceSchema = z.object({
    name: Resource.ResourceName,
    count: z.number().nonnegative().default(0),
  });

  export const SystemUpgradeSchema = z.object({
    name: SystemUpgrade.Name,
    valueIndex: z.number().nonnegative().default(0),
  });

  export const EffectsCustomSchema = z.object({
    hash: z.string().min(1),
    isDiscarded: z.boolean().default(false),
    deleteAt: z.preprocess(
      convertTimestampToDate,
      z.nullable(z.date()).default(null),
    ),
  });

  export const QuoteSchema = z.object({
    id: z.string().min(1),
    expiresAt: z.preprocess(
      convertTimestampToDate,
      z.date(),
    ),
  }).nullable();

  export const CompanionSchema = z.object({
    name: Companion.Name,
    expiresAt: z.preprocess(
      convertTimestampToDate,
      z.nullable(z.date()).default(null),
    ),
  }).nullable();

  export const AvatarSchema = z.object({
    name: Avatar.Name,
    img: z.string().min(1),
    isSelected: z.boolean().default(false),
  });

  const ThemeName = z.enum([
    'eclpsr-dark',
  ]);

  export const ModelDefault = {
    createdAt: null,
    entitlements: [],
    didAcceptCookiePolicy: false,
    notificationTokens: [],
    dayResetTime: '05:00:00',
    dayStartedAt: null,
    journeyStartedAt: null,
    effectsViewedAt: null,
    theme: ThemeName.enum['eclpsr-dark'],
    quote: null,
    objectives: [],
    resources: [],
    systemUpgrades: [],
    effectsCustom: [],
    companions: [],
    avatars: Object.values(Avatar.Setting).map((a) => {
      return {
        ...a,
        isSelected: false,
      } as z.infer<typeof AvatarSchema>;
    }),
  };

  export const Model = z.object({
    id: z.optional(z.string().min(1)),
    createdAt: z.preprocess(
      convertTimestampToDate,
      z.nullable(z.date()).default(ModelDefault.createdAt),
    ),
    entitlements: z.array(EntitlementStatusSchema).default(ModelDefault.entitlements),
    didAcceptCookiePolicy: z.boolean().default(ModelDefault.didAcceptCookiePolicy),
    notificationTokens: z.array(z.string()).default(ModelDefault.notificationTokens),
    dayResetTime: z.string().default(ModelDefault.dayResetTime), // todo regex
    dayStartedAt: z.preprocess(
      convertTimestampToDate,
      z.nullable(z.date()).default(ModelDefault.dayStartedAt),
    ),
    journeyStartedAt: z.preprocess(
      convertTimestampToDate,
      z.nullable(z.date()).default(ModelDefault.journeyStartedAt),
    ),
    effectsViewedAt: z.preprocess(
      convertTimestampToDate,
      z.nullable(z.date()).default(ModelDefault.effectsViewedAt),
    ),
    theme: z.string().default(ModelDefault.theme),
    objectives: z.array(ObjectiveSchema).default(ModelDefault.objectives),
    resources: z.array(ResourceSchema).default(ModelDefault.resources),
    systemUpgrades: z.array(SystemUpgradeSchema).default(ModelDefault.systemUpgrades),
    effectsCustom: z.array(EffectsCustomSchema).default(ModelDefault.effectsCustom),
    quote: QuoteSchema.default(ModelDefault.quote),
    companions: z.array(CompanionSchema).default(ModelDefault.companions),
    avatars: z.preprocess(
      (val: unknown) => {
        // if left undefined, it should use ModelDefault.avatars
        let returnVal: z.infer<typeof AvatarSchema>[] | undefined;

        if (Array.isArray(val)) {
          returnVal = val.filter(a => Avatar.Name.options.includes(a.name));

          ModelDefault.avatars.forEach((a) => {
            const index = returnVal!.findIndex(a2 => a.name === a2.name);

            if (index >= 0) {
              // clean up schema, just in case
              returnVal![index] = {
                ...a,
                isSelected: returnVal![index].isSelected === true,
              };
            } else {
              returnVal!.push(a);
            }
          });
        }

        return returnVal;
      },
      z.array(AvatarSchema).default(ModelDefault.avatars),
    ),
  });

  export const getDayStartingAtResetTime = (dayResetTime: z.infer<typeof Model.shape.dayResetTime>, day: dayjs.Dayjs) => {
    let dateReturn = day.startOf('day');

    const dayResetTimeParts = dayResetTime.split(':');
    dateReturn = dateReturn
      .hour(Number(dayResetTimeParts[0]))
      .minute(Number(dayResetTimeParts[1]))
      .second(0);

    return dateReturn;
  };

  export const getDayStartingAtUserResetTime = (userSettings: z.infer<typeof Model>, day: dayjs.Dayjs) => {
    let dateReturn = day.startOf('day');

    if (userSettings && userSettings.dayResetTime) {
      dateReturn = getDayStartingAtResetTime(userSettings.dayResetTime, day);
    }

    return dateReturn;
  };

  export const getVirtualDay = (userSettings: z.infer<typeof Model>) => {
    const d = getDayStartingAtUserResetTime(userSettings, dayjs().tz(TZ));
    return d.isAfter(dayjs().tz(TZ)) ? d.subtract(1, 'day') : d;
  };
}
