import { z } from 'zod';

export namespace AuthUser {
  export const ModelDefaults = {
    displayName: undefined,
    email: undefined,
    emailVerified: false,
    phoneNumber: null,
    photoUrl: null,
    providerData: [],
    uid: undefined,
  };

  export const Model = z.object({
    displayName: z.nullable(z.string()),
    email: z.string().email(),
    emailVerified: z.boolean().default(ModelDefaults.emailVerified),
    phoneNumber: z.nullable(z.string()).default(ModelDefaults.phoneNumber),
    photoUrl: z.nullable(z.string().url()).default(ModelDefaults.photoUrl),
    providerData: z.array(
      z.object({})
    ).default(ModelDefaults.providerData),
    uid: z.string(),
  });
}
