import { wrapGetters } from '../lib/util';

import tracery from '../lib/tracery/js/tracery/tracery';
import baseEngModifiers from '../lib/tracery/js/tracery/mods-eng-basic';
import TraceryJSON from '~/assets/tracery/locationName.json';

const grammar = tracery.createGrammar(TraceryJSON);
grammar.addModifiers(baseEngModifiers);

export const state = () => ({
});

export const mutations = {};

export const getters = wrapGetters('gen', {
  getLocationNameForDay: (_state, _getters, _rootState, rootGetters) => (d) => {
    // setRng needs to return a different value on every iteration, and these values should look
    // different for every day, otherwise duplicates are very likely.
    let iteration = 0;

    // should be big enough for all traversals of the tracery tree
    const prng = rootGetters.getRandomSetForDay(d, 5);

    tracery.setRng(function() {
      const r = prng[iteration];
      iteration++;
      return r;
    });

    return grammar.flatten('#origin#');
  },
  locationName: (_state, getters, _rootState, rootGetters) => {
    return getters.getLocationNameForDay(rootGetters.virtualDay);
  },
  locationOdds: () => {
    const origins = [];
    const originsCount = grammar.raw.origin.length;

    grammar.raw.origin.forEach((o) => {
      const origin = [];
      const parts = o.split(' ')

      parts.forEach((p) => {
        const partMatches = p.match(/#.*#/g);

        if (partMatches && partMatches.length > 0) {
          partMatches.forEach((m) => {
            const match = m.replace('#', '').split('.')[0];

            if (typeof match === 'string' && match) {
              origin.push({ key: match, count: grammar.raw[match].length });
            }
          });
        }
      });

      const count = origin.reduce((prev, curr) => prev * curr.count, 1);
      const allCount = count * originsCount;
      origins.push({
        origin: o,
        originsCount,
        count,
        allCount,
        pFormula: `(1/${originsCount}) * (1/${count}) = 1/${allCount}`,
        p: (1 / originsCount) * (1 / count),
      });
    });

    return origins;
  },
  getSunColorForDay: (_state, _getters, _rootState, rootGetters) => (d) => {
    const min = 0.8;
    const max = 1.5;

    const countColors = 3;
    const prng = rootGetters.getRandomSetForDay(d, countColors);
    const c = Array.apply(null, Array(countColors)).map((_null, i) => min + ((max - min) * prng[i]));

    return c;
  },
  sunColor: (_state, getters, _rootState, rootGetters) => {
    return getters.getSunColorForDay(rootGetters.virtualDay);
  },
});
