
export default {
  name: 'DialogConfirm',
  props: {
    message: {
      type: String,
      default: 'Are you sure?',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    acceptLabel: {
      type: String,
      default: 'OK',
    },
    acceptVariant: {
      type: String,
      default: 'primary',
    },
  },
  methods: {
    setFocus() {
      this.$nextTick(() => {
        this.$refs.acceptButton.focus();
      });
    },
    handleCancel() {
      this.$emit('cancel');
      this.$refs.slDialog.hide();
    },
    handleAccept() {
      this.$emit('accept');
      this.$refs.slDialog.hide();
    },
  },
};
