
import effectsMixin from '../mixins/effects';

export default {
  name: 'EffectApplyButton',
  mixins: [
    effectsMixin,
  ],
  props: {
    name: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: 'medium',
    },
    classes: {
      type: String,
      default: '',
    },
    isIconOnly: {
      type: Boolean,
      default: false,
    },
    isTextButton: {
      type: Boolean,
      default: false,
    },
    isPastTense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconOverride: {
      type: Array,
      default() {
        return undefined;
      },
    },
    colorOverride: {
      type: String,
      default: undefined,
    },
    itemId: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    effect() {
      return this.effects.find(i => i.name === this.name);
    },
  },
  methods: {
    focus() {
      this.$el.focus();
    },
  },
};
