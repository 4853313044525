import {
  wrapGetters,
} from '../lib/util';

export const state = () => ({
  isInitialized: false, // old; not used by scene-space
  didAnimateOnce: false,
  didAnimateToCurrentStatusOnce: false,
});

export const mutations = {
  didAnimateOnce(localState) {
    localState.didAnimateOnce = true;
  },
  didAnimateToCurrentStatusOnce(localState) {
    localState.didAnimateToCurrentStatusOnce = true;
  },
  reset(localState) {
    localState.didAnimateOnce = false;
    localState.didAnimateToCurrentStatusOnce = false;
  },
};

export const getters = wrapGetters('scene', {
});

export const actions = {
};
