
import { Haptics } from '@capacitor/haptics';

import { Effect } from '../api/src/models/effect';
import { SystemUpgrade } from '../api/src/models/SystemUpgrade';
import actionMixin from '../mixins/action';
import effectsMixin from '../mixins/effects';
import uiMixin from '../mixins/ui';

export default {
  name: 'ActionPostponement',
  mixins: [
    actionMixin,
    effectsMixin,
    uiMixin,
  ],
  props: {
    itemId: {
      type: String,
      default: undefined,
    },
    effectName: {
      type: String,
      default: Effect.EffectName.enum.Postponement,
    },
    promptText: {
      type: String,
      default: '',
    },
    verb: {
      type: String,
      default: Effect.Setting[Effect.EffectName.enum.Postponement].verb,
    },
  },
  computed: {
    actionId() {
      return this.itemId;
    },
    effect() {
      return (this.effectName && Effect.EffectName.options.includes(this.effectName))
        ? Effect.Setting[this.effectName]
        : Effect.Setting[Effect.EffectName.enum.Postponement];
    },
    upgrade() {
      let upgrade;

      if (this.isDebugger && this.effect) {
        let name;

        switch (this.effect.name) {
          case Effect.EffectName.enum.Postponement:
            name = this.$store.state.systems.upgradeName.PostponementRange;
            break;
        }

        if (name) {
          upgrade = this.$store.getters['systems/upgrades'].find(i => i.name === name);
        }
      }

      return upgrade;
    },
    helpText() {
      return this.effect ? this.effect.description : undefined;
    },
    minValue() {
      return SystemUpgrade.Setting[SystemUpgrade.Name.enum.PostponementRange].values[0];
    },
    maxValue() {
      const { values } = SystemUpgrade.Setting[SystemUpgrade.Name.enum.PostponementRange];
      let val = values[values.length - 1];

      if (this.upgrade) {
        val = this.upgrade.value;
      }

      return val;
    },
    maxValueDisplay() {
      let display = SystemUpgrade.Setting[SystemUpgrade.Name.enum.PostponementRange].valueDisplay(this.minValue);

      if (!this.isDebugger) {
        display = SystemUpgrade.Setting[SystemUpgrade.Name.enum.PostponementRange].valueDisplay(this.maxValue);
      }

      if (this.upgrade) {
        display = this.upgrade.valueDisplay;
      }

      return display;
    },
    dateOptions() {
      const opts = [];

      if (Array.isArray(this.upgrade?.values)) {
        this.upgrade.values.filter(v => v <= this.maxValue).forEach((v) => {
          const d = this.$store.getters.virtualDay.add(v, 'days');

          if (d.isAfter(this.expiresAt)) {
            let label;

            if (v === 1) {
              label = 'Tomorrow';
            } else {
              label = `${d.format('dddd')} ${d.format('MM/DD')}`;
            }

            opts.push({
              label,
              value: v, // days from today
            });
          }
        });
      }

      if (!this.isDebugger && !this.upgrade) {
        const { values } = SystemUpgrade.Setting[SystemUpgrade.Name.enum.PostponementRange];
        values.forEach((v) => {
          const d = this.$store.getters.virtualDay.add(v, 'days');

          if (d.isAfter(this.expiresAt)) {
            let label;

            if (v === 1) {
              label = 'Tomorrow';
            } else {
              label = `${d.format('ddd')} ${d.format('MM/DD')}`;
            }

            opts.push({
              label,
              value: v, // days from today
            });
          }
        });
      }

      return opts;
    },
  },
  methods: {
    toggleDateOptionsMenu($event) {
      this.$refs['date-options-menu'].toggle($event);
    },
    async tryPostponeWithDay(option) {
      let value;
      let label;

      if (option instanceof Event) {
        value = Number(option.detail?.item?.value);
        label = this.dateOptions.find(o => o.value === value)?.label;
      } else {
        ({ value, label } = option);
      }

      if (isNaN(value) || !label || value < this.minValue || value > this.maxValue) {
        return false;
      }

      label = value <= 1 ? label.toLowerCase() : label;
      const msg = `Are you sure you want to ${this.verb.toLowerCase()} this action until ${label}?`
      const isPostponing = await this.uiMsgBoxConfirm(msg);

      if (!isPostponing) {
        return false;
      }

      const newExpiresAt = this.$store.getters.virtualDay.add(value, 'day').toDate();
      const newMaturesAt = newExpiresAt;

      const timeframe = [
        newMaturesAt,
        newExpiresAt,
      ];

      await this.$store.dispatch('actionsUser/editItem', {
        id: this.itemId,
        mods: {
          timeframe,
        },
      });

      this.uiNotify(`Postponed "${this.action.description}" until ${label}.`, {
        icon: ['fal', 'calendar-plus'],
      });

      Haptics.vibrate();

      this.$emit('close');
    },
    handleViewUpgrades() {
      this.$store.dispatch('ui/showModal', { name: 'systemUpgrades' });
      this.$emit('close');
    },
  },
};
