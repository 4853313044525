
import uiMixin from '~/mixins/ui';

export default {
  name: 'EcDrawer',
  mixins: [
    uiMixin,
  ],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    classes: {
      type: Array,
      default() {
        return [];
      },
    },
    placement: {
      type: String,
      default: 'top',
    },
    title: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: Array,
      default() {
        return [];
      },
    },
    conceptName: {
      type: String,
      default: undefined,
    },
    conceptsRelated: {
      type: Array,
      default() {
        return [];
      },
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    confirmClose: {
      type: Boolean,
      default: false,
    },
    confirmCloseWithMessage: {
      type: String,
      default: 'Are you sure you want to close this view?',
    },
    confirmCloseOkLabel: {
      type: String,
      default: 'OK',
    },
  },
  computed: {
    open() {
      return this.isOpen;
    },
  },
  methods: {
    handleHide() {
      this.$emit('hide');
    },
    async close(forceClose = false) {
      let isClosing = true;

      if (this.confirmClose && !forceClose) {
        isClosing = await this.uiMsgBoxConfirm(this.confirmCloseWithMessage, {
          acceptLabel: this.confirmCloseOkLabel,
        });
      }

      if (isClosing) {
        this.$refs.slDrawer.hide();
      }
    },
    requestClose(e) {
      if (this.confirmClose) {
        e?.preventDefault();
        this.close();
      }
      // else this will continue to sl-drawer @hide
    },
  },
};
