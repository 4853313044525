import _ from 'lodash';
import { Haptics } from '@capacitor/haptics';
import { z } from 'zod';

import { Action } from '../Action';
import { Resource } from '../Resource';
import { EffectStandard } from './EffectStandard';

export default {
  name: EffectStandard.EffectName.enum.Completion,
  label: 'Completion',
  verb: 'Complete',
  verbPast: 'Completed!',
  targets: [EffectStandard.Target.enum.ActionOnce, EffectStandard.Target.enum.ActionRepeating],
  icon: ['fal', 'circle-check'],
  color: 'var(--sl-color-success-500)',
  description: 'Get value from your actions by completing them.',
  confirmWithProps: {
    message: 'Are you sure you want to complete this action?',
    header: 'Complete',
  },
  effectCheck: EffectStandard.checkActionIsCompletable,
  getEffectData({ $store, props }) {
    const item = $store.getters.getActionById(props.itemId);
    let value;
    let valueAdd;

    if (item) {
      value = $store.getters.getValuePotentialForItem(item);
    }

    return Promise.resolve({
      item,
      value,
      valueAdd,
    });
  },

  async effect({ $store, effectData, effect }) {
    if (effectData.item && _.isFinite(effectData.value) && effectData.value >= 0) {
      const valueTotal = Math.ceil(effectData.value + (_.isFinite(effectData.valueAdd) ? Math.max(0, effectData.valueAdd) : 0));
      const mods = {
        ...effectData.item,
        timeSpent: effectData.item.timeSpent,
        lastProgressAt: effectData.lastProgressAt || new Date(),
        status: Action.Status.enum.Done,
        value: valueTotal,
      };

      let actionFinal;

      try {
        if (Action.isFrequencyRepeating(effectData.item.frequency)) {
          actionFinal = await $store.dispatch('actionsUser/addRepeatingItemOccurrenceCompleted', mods);
        } else {
          actionFinal = await $store.dispatch('actionsUser/editItem', {
            id: effectData.item.id,
            mods,
          });
        }
      } catch (e) {}

      if (!actionFinal) {
        throw new Error('Could not complete action.');
      }

      EffectStandard.logEvent({ $store, effectName: effect.name });
      return valueTotal;
    }

    return false;
  },

  success({ $store, effectData }: z.infer<typeof EffectStandard.SuccessArgs>) {
    const hasAdd = _.isFinite(effectData.valueAdd) && effectData.value > 0;

    $store.dispatch('ui/addToast', {
      type: 'ActionCompletion',
      text: `${hasAdd ? effectData.value + effectData.valueAdd : effectData.value}`,
      payload: {
        effectValueBonus: hasAdd ? `${effectData.value} + ${effectData.valueAdd} from ${effectData.label}` : false,
      },
      severity: 'success',
    });

    const upgrade = $store.getters['systems/upgrades']
      .find((i: any) => i.name === $store.state.systems.upgradeName.AetherCreation);

    if (upgrade && upgrade.value > 0 && upgrade.value <= 1) {
      const isGranted = $store.getters.getRandomInt(effectData.item.id, 100) <= upgrade.value * 100;

      if (isGranted) {
        const count = 1;
        const name = Resource.ResourceName.enum.Aether;
        $store.dispatch('resources/grant', { name, count });
        $store.dispatch('ui/addToast', {
          type: 'ResourceGrant',
          text: `${count} completion bonus`,
          payload: {
            resourceName: name,
          },
          severity: 'secondary',
        });
      }
    }

    Haptics.vibrate();
  },

  error({ $store }: z.infer<typeof EffectStandard.ErrorArgs>) {
    $store.dispatch('ui/addToast', {
      text: 'Could not complete action!',
      severity: 'warning',
      icon: ['fas', 'triangle-exclamation'],
    });
  },
} as z.infer<typeof EffectStandard.Model>;
