import { z } from 'zod';

export namespace Entitlement {
  export const GracePeriodHours = 24;

  export const EntitlementName = z.enum([
    'Debug',
    'Authenticated',
    'Initialized',
    'Basic',
  ]);

  export const EntitlementPurchaseName = z.enum([
    'basic',
  ]);

  export const EntitlementPurchaseNameMap: { [key: string]: z.infer<typeof EntitlementName> } = {
    basic: EntitlementName.enum.Basic,
  };

  export const PurchaselyProductToName: { [key: string]: z.infer<typeof EntitlementName> } = {
    '001': Entitlement.EntitlementName.enum.Basic,
  };

  export const EntitlementStatus = z.enum([
    'Missing',
    'Active',
    'Expired',
    'ExpiringSoon',
    'ExpiredGrace',
    'Lifetime',
  ]);

  export const EntitlementStatusHeaderDisplayMap: { [key: string]: string } = {
    [EntitlementStatus.enum.Missing]: 'Continue your journey.',
    [EntitlementStatus.enum.Active]: 'Great things are ahead.',
    [EntitlementStatus.enum.ExpiringSoon]: 'Great things are ahead.',
    [EntitlementStatus.enum.ExpiredGrace]: 'Don\'t stop now!',
    [EntitlementStatus.enum.Expired]: 'Continue your journey.',
    [EntitlementStatus.enum.Lifetime]: 'Great things are ahead.',
  };

  export const EntitlementStatusDisplayMap: { [key: string]: string } = {
    [EntitlementStatus.enum.Missing]: 'not subscribed',
    [EntitlementStatus.enum.Active]: 'active',
    [EntitlementStatus.enum.ExpiringSoon]: 'active',
    [EntitlementStatus.enum.ExpiredGrace]: 'expired; in grace period',
    [EntitlementStatus.enum.Expired]: 'expired',
    [EntitlementStatus.enum.Lifetime]: 'lifetime access',
  };

  export const EntitlementStatusExpiresDisplayMap: { [key: string]: string } = {
    [EntitlementStatus.enum.Missing]: 'Not Active',
    [EntitlementStatus.enum.Active]: 'Expires',
    [EntitlementStatus.enum.ExpiringSoon]: 'Expires',
    [EntitlementStatus.enum.ExpiredGrace]: 'Expired',
    [EntitlementStatus.enum.Expired]: 'Expired',
    [EntitlementStatus.enum.Lifetime]: 'Never Expires',
  };
}
