
import uiMixin from '../mixins/ui';

export default {
  name: 'AppUpdateMessage',
  mixins: [
    uiMixin,
  ],
  methods: {
    handleSubscribe() {
      this.$store.dispatch('displaySubscriptions');
    },
  },
};
