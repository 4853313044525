import { z } from 'zod';
import { convertTimestampToDate } from './util';

export namespace App {
  export const DataTableName = 'app';
  export const DbDocName = 'master';

  export const ModelDefault = {
    id: 'master',
    name: 'Eclpsr',
    url: 'https://www.eclpsr.com/',
    lastUpdatedAt: new Date('September 1, 2018 00:00:00'),
    commitRef: '1',
  };

  export const Model = z.object({
    id: z.string().default(ModelDefault.id),
    name: z.string().default(ModelDefault.name),
    url: z.string().url().default(ModelDefault.url),
    lastUpdatedAt: z.preprocess(
      convertTimestampToDate,
      z.date().default(ModelDefault.lastUpdatedAt),
    ),
    commitRef: z.optional(z.nullable(z.string())).default(ModelDefault.commitRef),
  });

  export namespace Concept {
    export const ConceptName = z.enum([
      'Value',
      'ActionAdd',
      'ActionComplete',
      'ActionsPlan',
      'ActionsPlanMilestone',
      'ActionsPlanSeries',
      'ActionsPlanSeriesCheckpoint',
      'Objective',
      'Action',
      'ActionTimeframe',
      'ActionScheduled',
      'ActionFrequencyRepeating',
      'Interest',
      'Idea',
      'Search',
      'User',
      'Effect',
      'SystemUpgrades',
      'Companion',
      'ResourceRadia',
      'ResourceAether',
      'ResourceShade',
    ]);

    export const Setting = {
      [ConceptName.enum.Value]: {
        name: ConceptName.enum.Value,
        label: 'value',
        labelPlural: 'values',
        icon: ['fas', 'bolt'],
      },
      [ConceptName.enum.ActionAdd]: {
        name: ConceptName.enum.ActionAdd,
        label: 'add an action',
        labelPlural: 'add actions',
        icon: ['fas', 'circle-plus'],
      },
      [ConceptName.enum.ActionComplete]: {
        name: ConceptName.enum.ActionComplete,
        label: 'complete an action',
        labelPlural: 'complete actions',
        icon: ['fas', 'circle-check'],
      },
      [ConceptName.enum.ActionsPlan]: {
        name: ConceptName.enum.ActionsPlan,
        label: 'mission',
        labelPlural: 'missions',
        icon: ['fas', 'eclipse'],
      },
      [ConceptName.enum.ActionsPlanMilestone]: {
        name: ConceptName.enum.ActionsPlanMilestone,
        label: 'milestone',
        labelPlural: 'milestones',
        icon: ['fas', 'circle-star'],
      },
      [ConceptName.enum.ActionsPlanSeries]: {
        name: ConceptName.enum.ActionsPlanSeries,
        label: 'run',
        labelPlural: 'runs',
        icon: ['fas', 'star-shooting'],
      },
      [ConceptName.enum.ActionsPlanSeriesCheckpoint]: {
        name: ConceptName.enum.ActionsPlanSeriesCheckpoint,
        label: 'location',
        labelPlural: 'locations',
        icon: ['fas', 'circle-star'],
      },
      [ConceptName.enum.Objective]: {
        name: ConceptName.enum.Objective,
        label: 'message',
        labelPlural: 'messages',
        icon: ['fas', 'hexagon-exclamation'],
      },
      [ConceptName.enum.Action]: {
        name: ConceptName.enum.Action,
        label: 'action',
        labelPlural: 'actions',
        icon: ['fas', 'circle-a'],
      },
      [ConceptName.enum.ActionTimeframe]: {
        name: ConceptName.enum.ActionTimeframe,
        label: 'timeframe',
        labelPlural: 'timeframes',
        icon: ['fas', 'calendar'],
      },
      [ConceptName.enum.ActionScheduled]: {
        name: ConceptName.enum.ActionScheduled,
        label: 'scheduled action',
        labelPlural: 'scheduled actions',
        icon: ['fas', 'calendar'],
      },
      [ConceptName.enum.ActionFrequencyRepeating]: {
        name: ConceptName.enum.ActionFrequencyRepeating,
        label: 'repeating action',
        labelPlural: 'repeating actions',
        icon: ['fas', 'arrows-repeat'],
      },
      [ConceptName.enum.Interest]: {
        name: ConceptName.enum.Interest,
        label: 'interest',
        labelPlural: 'interests',
        icon: ['fas', 'hashtag'],
      },
      [ConceptName.enum.Idea]: {
        name: ConceptName.enum.Idea,
        label: 'idea',
        labelPlural: 'ideas',
        icon: ['fas', 'lightbulb'],
      },
      [ConceptName.enum.Search]: {
        name: ConceptName.enum.Search,
        label: 'search',
        labelPlural: 'searches',
        icon: ['fas', 'magnifying-glass'],
      },
      [ConceptName.enum.User]: {
        name: ConceptName.enum.User,
        label: 'user',
        labelPlural: 'users',
        icon: ['fad', 'user-astronaut'],
      },
      [ConceptName.enum.Effect]: {
        name: ConceptName.enum.Effect,
        label: 'effect',
        labelPlural: 'effects',
        icon: ['fad', 'draw-circle'],
      },
      [ConceptName.enum.SystemUpgrades]: {
        name: ConceptName.enum.SystemUpgrades,
        label: 'system upgrade',
        labelPlural: 'system upgrades',
        icon: ['fad', 'bars-progress'],
      },
      [ConceptName.enum.Companion]: {
        name: ConceptName.enum.Companion,
        label: 'companion',
        labelPlural: 'companions',
        icon: ['fad', 'diagram-venn'],
      },
      [ConceptName.enum.ResourceRadia]: {
        name: ConceptName.enum.ResourceRadia,
        label: 'radia',
        labelPlural: 'radia',
        icon: ['fas', 'diamond'],
      },
      [ConceptName.enum.ResourceAether]: {
        name: ConceptName.enum.ResourceAether,
        label: 'æther',
        labelPlural: 'æther',
        icon: ['fas', 'triangle'],
      },
      [ConceptName.enum.ResourceShade]: {
        name: ConceptName.enum.ResourceShade,
        label: 'shade',
        labelPlural: 'shade',
        icon: ['fas', 'egg'],
      },
    };
  }
}
