import { z } from 'zod';
import { EffectStandard } from './EffectStandard';

export default {
  targets: [EffectStandard.Target.enum.ActionOnce, EffectStandard.Target.enum.ActionRepeating],
  name: EffectStandard.EffectName.enum.Focus,
  label: 'Focus',
  verb: 'Focus',
  icon: ['fal', 'play'],
  color: 'var(--sl-color-blue-300)',
  description: 'Focus on completing one action and record the time spent.',
  confirmWithProps: {
    message: 'Are you sure you want to focus on this action?',
    header: 'Focus',
  },
  effectCheck({ $store, itemId }) {
    return !$store.state.actionFocus.action && !!EffectStandard.checkActionIsCompletable({ $store, itemId });
  },
  getEffectData({ props }) {
    return Promise.resolve({
      itemId: props.itemId,
    });
  },
  effect({ $store, effectData, effect }) {
    $store.dispatch('ui/hideAllModals');
    $store.dispatch('actionFocus/startLocally', effectData.itemId);
    EffectStandard.logEvent({ $store, effectName: effect.name });
    return Promise.resolve(true);
  },
} as z.infer<typeof EffectStandard.Model>;
