import { z } from 'zod';

export namespace Avatar {
  export const NameNoSelection = 'NameNoSelection';

  export const Name = z.enum([
    '01/01',
    '01/02',
    '01/03',
    '01/04',
    '01/05',
    '01/06',
    '01/07',
    // '01/08',
    // '01/09',
    '01/10',
    '01/11',
    // '01/12',
    '01/13',
    '01/14',
    // '01/15',
    '01/16',
    '01/17',
    /*
    '02/01',
    '02/02',
    '02/03',
    '02/04',
    '02/05',
    '02/06',
    '02/07',
    '02/08',
    '02/09',
    '02/10',
    */
    '03/drone',
    /*
    '03/metal-slug',
    '03/observer',
    '03/sentinel',
    '03/steel-eagle',
    */
  ]);

  export const SettingModel = z.object({
    name: Name,
    // images must be square .png
    img: z.string().min(1),
  });

  export const Setting: Partial<Record<z.infer<typeof Name>, z.infer<typeof SettingModel>>> = Name.options.reduce(
    (o, key) => ({
      ...o,
      [key]: {
        name: key,
        img: `/avatars/${key}.png`,
      },
    }),
    {},
  );
}
