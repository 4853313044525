
import { Haptics } from '@capacitor/haptics';
import uiMixin from '../mixins/ui';
import ResourceInfo from './resource-info';

export default {
  name: 'ResourceGranted',
  components: {
    ResourceInfo,
  },
  mixins: [
    uiMixin,
  ],
  props: {
    name: {
      type: String,
      default: undefined,
    },
  },
  mounted() {
    Haptics.vibrate();
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
