import _ from 'lodash';
import { wrapGetters } from '../lib/util';

import { Action } from '../api/src/models/Action';
import { ActionsPlan } from '../api/src/models/ActionsPlan';

export const state = () => ({});

export const mutations = {};

export const getters = wrapGetters('actionsPlan', {
  getStandardizedItem: (_state, _getters, _rootState, rootGetters) => (i) => {
    const isOccurrenceOf = Boolean(i.occurrenceOf);
    let item = { ...i };

    // If this is a completion for a repeating action, display the original
    if (isOccurrenceOf && i.status === Action.Status.enum.Done) {
      const occurrenceOf = rootGetters['actionsUser/getItemById'](i.occurrenceOf);

      if (occurrenceOf) {
        item = {
          ...occurrenceOf,
          status: Action.Status.enum.Done,
        };
      }
    }

    return {
      ...item,
      value: i.status === Action.Status.enum.Done ? i.value : rootGetters.getValuePotentialForItem(item),
      lastProgressAt: i.lastProgressAt || 0,
    };
  },

  isItemEligible: (_state, _getters, _rootState, rootGetters) => (item, alreadySelectedIds) => {
    let isEligible = false;

    const alreadySelected = alreadySelectedIds.includes(item.id);

    if (!alreadySelected && !rootGetters['actionsUser/isItemIdea'](item)) {
      isEligible = true;
    }

    return isEligible;
  },

  itemsCompleted: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/itemsCompletedVirtualDay'];
  },
  itemsCompletedIds: (_state, getters) => {
    return getters.itemsCompleted.map(i => i.id);
  },
  itemsExpiring: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/itemsExpiringVirtualDay'] || [];
  },
  itemsExpiringIds: (_state, getters) => {
    return getters.itemsExpiring.map(i => i.id);
  },
  itemsRepeating: (_state, getters, _rootState, rootGetters) => {
    const itemsCompletedOccurrenceOfIds = getters.itemsCompleted.filter(i => Boolean(i.occurrenceOf)).map(i => i.occurrenceOf);
    const items = rootGetters['actionsUser/itemsRepeatingOnVirtualDay'].filter(i => !itemsCompletedOccurrenceOfIds.includes(i.id)) || [];

    return items;
  },
  itemsRepeatingIds: (_state, getters) => {
    return getters.itemsRepeating.map(i => i.id);
  },

  itemsRequiredIncomplete: (_state, getters) => {
    return getters.itemsExpiring
      .concat(getters.itemsRepeating.filter(i => i.importance === Action.EffortImportance.ImportanceMaxValue));
  },
  itemsRequired: (_state, getters) => {
    return getters.itemsCompleted
      .concat(getters.itemsRequiredIncomplete)
  },
  itemsRequiredIds: (_state, getters) => {
    return getters.itemsRequired.map(i => i.id);
  },
  itemsOptional: (_state, getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/itemsByRankIncomplete']
      // items for virtual day should already be included (required)
      .filter(i => !rootGetters['actionsUser/hasTimeframe'](i))
      .concat(getters.itemsRepeating.filter(i => i.importance < Action.EffortImportance.ImportanceMaxValue))
      .filter(i => !getters.itemsRequiredIds.includes(i.id));
  },
  itemsOptionalIds: (_state, getters) => {
    return getters.itemsOptional.map(i => i.id);
  },
  itemsUpcoming: (_state, getters, _rootState, rootGetters) => {
    const itemIds = getters.itemIds;
    return rootGetters['actionsUser/itemsByRankIncomplete']
      .filter(i => rootGetters['actionsUser/hasTimeframe'](i) && !itemIds.includes(i.id));
  },

  items: (_state, getters, _rootState, rootGetters) => {
    let valueTotal = 0;

    const ids = [];
    const items = [];

    const processItem = (item) => {
      if (getters.isItemEligible(item, ids)) {
        ids.push(item.id);
        items.push(item);

        valueTotal = valueTotal + (item.value || rootGetters.getValuePotentialForItem(item));
      }
    };

    getters.itemsRequired.forEach(processItem);
    const valueExpectedOriginal = getters.valueExpectedOriginal;

    if (valueTotal < valueExpectedOriginal) {
      const itemsOptional = getters.itemsOptional;

      let i = 0;

      // valueTotal *is* modified within processItem
      // eslint-disable-next-line no-unmodified-loop-condition
      while (valueTotal < valueExpectedOriginal && i < itemsOptional.length) {
        const item = itemsOptional[i];
        processItem(item);
        i++;
      }
    }

    return items;
  },
  itemIds: (_state, getters) => getters.items.map(i => i.id),

  itemsOrdered: (_state, getters) => {
    return _.orderBy(
      getters.items.map(i => getters.getStandardizedItem(i)),
      ['status', 'lastProgressAt', 'importance'], ['desc', 'desc', 'desc'],
    );
  },
  itemsUpcomingOrdered: (_state, getters) => {
    return _.orderBy(
      getters.itemsUpcoming.map(i => getters.getStandardizedItem(i)),
      ['status', 'lastProgressAt', 'importance'], ['desc', 'desc', 'desc'],
    );
  },

  valueOfItems: (_state, getters, _rootState, rootGetters) => {
    let vt = 0;

    getters.items.forEach((item) => {
      if (item.status === Action.Status.enum.Done) {
        const v = Number(item.value);
        vt = typeof v === 'number' ? vt + v : vt;
      } else {
        vt = vt + rootGetters.getValuePotentialForItem(item);
      }
    });

    return vt;
  },
  valueTotalVirtualDay: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/valueTotalVirtualDay'];
  },

  hash: (_state, getters) => {
    const ids = getters.itemIds;
    ids.sort();

    return ids.map(id => id.substring(0, 3)).join('-');
  },
  hasItems: (_state, getters) => getters.items.length > 0,

  valueExpectedOriginal: () => {
    return ActionsPlan.MinValueEasy;
  },
  valueExpectedAdjusted: (_state, getters) => {
    return Math.max(getters.valueExpectedOriginal, getters.valueOfItems);
  },
  isValueHigherThanExpected: (_state, getters) => {
    return getters.valueOfItems > getters.valueExpectedAdjusted;
  },
  isValueHigherThanExpectedAndNotMinimum: (_state, getters) => {
    return getters.valueOfItems > getters.valueExpectedAdjusted;
  },
  isValueLowerThanExpected: (_state, getters) => {
    return getters.valueOfItems < getters.valueExpectedAdjusted;
  },
  progressPercent: (_state, getters) => {
    const basis = 100;
    const p = getters.valueExpectedAdjusted <= 0 ? basis : 0;
    return getters.valueOfItems > 0 ? Math.min(basis, Math.round((getters.valueTotalVirtualDay / getters.valueExpectedAdjusted) * basis)) : p;
  },
  progress: (_state, getters) => {
    return getters.progressPercent / 100;
  },
  isComplete: (_state, getters) => {
    return getters.progress >= 1;
  },
  isCompleteMin: (_state, getters) => {
    return getters.valueTotalVirtualDay >= getters.valueExpectedOriginal;
  },
});

export const actions = {};
