import _ from 'lodash';
import dayjs from './dayjs';

export const TEST_START_DATE = dayjs().startOf('day').add(8, 'hours').toDate(); // 8am today
export const TEST_START_MS = TEST_START_DATE.getTime();
export const TEST_START_S = Math.round(TEST_START_MS / 1000);

export const hashCode = (str) => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
};

const intToHexColor = (i) => {
  const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
  return `#${c.padStart(6, '0')}`;
};

export const getHexColorForId = (id) => {
  return intToHexColor(hashCode(id));
};

export const wrapGetters = (moduleName, gettersObj) => {
  const gettersFinal = {};

  Object.keys(gettersObj).forEach((k) => {
    gettersFinal[k] = _.wrap(gettersObj[k], function(func, state, getters, rootState, rootGetters) {
      const key = `eclpsr.getters.${moduleName}.${k}`;

      window.performance.mark(`${key}.start`);
      const returning = func(state, getters, rootState, rootGetters);

      const isFunc = _.isFunction(returning);

      if (isFunc) {
        // Don't bother recording function getters
        window.performance.clearMarks(`${key}.start`);
      } else {
        window.performance.mark(`${key}.end`);
        window.performance.measure(`${key}`, `${key}.start`, `${key}.end`);
      }

      return returning;
    });
  });

  return gettersFinal;
};

export const getOxfordCommaList = (texts = []) => {
  let finalText;
  const newTexts = [...texts];

  if (newTexts.length > 1) {
    newTexts[newTexts.length - 1] = `and ${newTexts[newTexts.length - 1]}`;
  }

  if (newTexts.length > 2) {
    finalText = newTexts.join(', ');
  } else {
    finalText = newTexts.join(' ');
  }

  return finalText;
};
