
import uiMixin from '../mixins/ui';

export default {
  name: 'AppUpdateMessage',
  mixins: [
    uiMixin,
  ],
  methods: {
    handleAppUpdate() {
      window.location.reload();
    },
  },
};
