import { z } from 'zod';
import { Action } from '../Action';
import { EffectStandard } from './EffectStandard';

export default {
  name: EffectStandard.EffectName.enum.Division,
  label: 'Splitter',
  verb: 'Split',
  targets: [EffectStandard.Target.enum.ActionOnce],
  icon: ['fal', 'split'],
  color: 'var(--sl-color-red-300)',
  description: 'Split a one-time action into 2 parts—divide and conquer!',
  confirmWithProps: {
    message: 'Are you sure you want to split this action? (The original action\'s effort will be automatically reduced.)',
    header: 'Split Action',
  },
  effectCheck: EffectStandard.isAction,
  getEffectData({ $store, props }) {
    const item = $store.getters.getActionById(props.itemId);

    return Promise.resolve({
      item,
    });
  },
  async effect({ $store, effectData, effect }) {
    const effortIndex = Action.EffortImportance.FibonacciValues.indexOf(effectData.item.effort);
    const effort = Action.EffortImportance.FibonacciValues[Math.max(0, effortIndex - 1)];

    const itemMod = await $store.dispatch('actionsUser/editItem', {
      id: effectData.item.id,
      mods: {
        effort,
      },
    });

    const itemNew = await $store.dispatch('actionsUser/editItem', {
      id: undefined,
      mods: {
        ...itemMod,
        id: undefined,
        description: `[continue doing] ${itemMod.description}`,
      },
    });

    $store.dispatch('ui/showModal', {
      name: 'actionEdit',
      payload: {
        itemId: itemNew.id,
      },
    });

    EffectStandard.logEvent({ $store, effectName: effect.name });
  }
} as z.infer<typeof EffectStandard.Model>;
