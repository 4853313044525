
import uiMixin from '../mixins/ui';
import NavBottom from './nav-bottom';

export default {
  name: 'NavbarBottom',
  components: {
    NavBottom,
  },
  mixins: [
    uiMixin,
  ],
  computed: {
    isAdvancedUIEnabled: {
      get() {
        return this.$store.state.ui.isAdvancedUIEnabled;
      },
      set(val) {
        this.$store.commit('ui/isAdvancedUIEnabled', val);
      },
    },
    debugDateItems() {
      return [
        `Now ${this.$store.state.now.format('YYYY-MM-DD HH:mm:ss ZZ')}`,
        `Virtual Day ${this.$store.getters.virtualDay.format('YYYY-MM-DD HH:mm')}`,
      ];
    },
  },
  methods: {
    debugDateMenuToggle($event) {
      this.$refs.debugDateMenu.toggle($event);
    },
  },
};
