import { Companion } from '../api/src/models/Companion';
import { wrapGetters } from '../lib/util';
import dayjs from '../lib/dayjs';

export const state = () => ({
  companionName: Companion.Name.enum,
});

export const mutations = {};

export const getters = wrapGetters('companions', {
  companions: (_state, _getters, rootState, rootGetters) => {
    const cAllUserSetting = rootState.userSettings?.companions ?? [];
    const cActiveUserSetting = rootGetters['userSettings/companionActive'];

    return Object.keys(Companion.Setting).map((name) => {
      let c = {
        ...Companion.Setting[name],
        typeSetting: Companion.TypeSetting[Companion.Setting[name].type],
        raritySetting: Companion.RaritySetting[Companion.Setting[name].rarity],
        name,
        isActive: false,
        isNew: !cAllUserSetting.find(cu => cu.name === name),
        isKnown: !!cAllUserSetting.find(cu => cu.name === name),
      };

      if (cActiveUserSetting && cActiveUserSetting.name === name) {
        c = {
          ...c,
          ...cActiveUserSetting,
          isActive: true,
        };
      }

      return c;
    });
  },

  getCompanionByName: (_state, getters) => (name) => {
    return getters.companions.find(c => c.name === name);
  },

  companionActive: (_state, getters) => {
    return getters.companions.find(c => c.isActive);
  },

  getRarityTestArgs: () => ({ score, scoreEasy }) => {
    return { score, scoreEasy };
  },

  getTestArgs: (_state, getters, _rootState, rootGetters) => ({ score, scoreEasy }) => {
    return {
      ...getters.getRarityTestArgs({ score, scoreEasy }),
      hourOfDay: dayjs().hour(),
      actionsPlanProgress: rootGetters['actionsPlan/progressPercent'],
      actionsPlanValue: rootGetters['actionsPlan/valueOfItems'],
      actionsPlanValueComplete: rootGetters['actionsPlan/valueTotalVirtualDay'],
      actionsPlanCount: rootGetters['actionsPlan/items'].length,
      actionsPlanCountComplete: rootGetters['actionsPlan/itemsCompleted'].length,
    };
  },

  getHighestRarityForArgs: () => (rarityArgs) => {
    // we can assume the enum order is low->high
    const rarities = [...Companion.Rarity.options];
    rarities.reverse();

    return rarities.find(r => Companion.RaritySetting[r]?.test(rarityArgs));
  },

  getCompanionsOfRarity: (_state, getters) => (rarityName) => {
    return getters.companions.filter(c => c.rarity === rarityName);
  },

  defaultCompanion: (_state, getters) => {
    return getters.companions.find(c => c.name === Companion.DefaultCompanion.name);
  },
});

export const actions = {};
