const reminderIntervalMinutes = 15; // minutes; matches what can be set in action-edit

export default {
  computed: {
    lnItemsToRemind() {
      return this.$store.getters['actionsUser/itemsToRemind'].map((i) => {
        const remindAtSplit = i.remindAt.split(':');
        let remindAtOriginal = this.$store.getters.virtualDay.hour(remindAtSplit[0]).minute(remindAtSplit[1]).second(0);

        if (remindAtOriginal.isBefore(this.$store.getters.virtualDay)) {
          remindAtOriginal = remindAtOriginal.add(1, 'day');
        }

        // basically reminder time or lastRemindedAt
        const remindAtNext = this.$store.getters['actionsUser/getItemNextRemindAt'](i);
        const remindAtSortable = remindAtOriginal.unix();

        return {
          ...i,
          remindAtOriginal,
          remindAtNext,
          remindAtSortable,
        };
      });
    },
    lnReminders() {
      const intervals = [];
      const refDay = this.$store.getters.virtualDay.clone();
      const numIntervals = Math.ceil(60 / reminderIntervalMinutes) * 24;

      for (let i = 0; i < numIntervals; i++) {
        const day = refDay.add(i * reminderIntervalMinutes, 'minutes');

        if (day.isSameOrAfter(this.$store.state.now)) {
          const items = this.lnItemsToRemind.filter(i => day.isSameOrAfter(i.remindAtNext));
          let summary = '';

          if (items.length > 0) {
            summary = `${items[0].description}`;
          }

          if (items.length > 1) {
            summary = `${summary} and ${items.length - 1} more`;
          }

          intervals.push({
            day,
            items,
            itemIds: items.map(i => i.id),
            summary,
          });
        }
      }

      return intervals;
    },
    lnItemsToRemindDetailed() {
      return this.lnItemsToRemind.map((i) => {
        const nextReminder = this.lnReminders.find(r => r.itemIds.includes(i.id));
        const isRemindAtOriginalPast = i.remindAtOriginal.isBefore(this.$store.state.now);

        return {
          ...i,
          // Not lastRemindedAt, but the next actual reminder
          nextReminder: (nextReminder ? nextReminder.day : undefined),
          isRemindAtOriginalPast,
        };
      });
    },
    lnNotifications() {
      let notifications = [];
      const limit = 64;
      const reminders = this.lnReminders.filter(r => r.items.length > 0).slice(0, limit);

      if (reminders.length > 0) {
        notifications = reminders.map(r => ({
          id: Number(r.day.format('YYYYMMDDHHmm')),
          title: `${this.$store.state.app.name} Reminder`,
          body: r.summary,
          schedule: {
            at: r.day.toDate(),
          },
        }));
      }

      let didAddTomorrow = false;
      const itemsForTomorrow = this.$store.getters['actionsUser/itemsForTomorrow'];

      if (Array.isArray(itemsForTomorrow)) {
        let earliest = itemsForTomorrow[0];

        itemsForTomorrow.forEach((i) => {
          const earliestRemindAt = Number(earliest.remindAt.split(':').join(''));
          const remindAt = Number(i.remindAt.split(':').join(''));

          if (!isNaN(earliestRemindAt) && !isNaN(remindAt) && remindAt < earliestRemindAt) {
            earliest = i;
          }
        });

        if (earliest) {
          didAddTomorrow = true;
          const earliestRemindAtSplit = earliest.remindAt.split(':');
          const day = this.$store.getters.virtualTomorrow.hour(earliestRemindAtSplit[0]).minute(earliestRemindAtSplit[1]).second(0);
          const body = `It's a new day! Open the app to view "${earliest.description}" and other actions in your mission.`;
          const index = notifications.length >= limit ? notifications.length - 1 : notifications.length;

          notifications[index] = {
            id: Number(day.format('YYYYMMDDHHmm')),
            title: `${this.$store.state.app.name} Reminder`,
            body,
            schedule: {
              at: day.toDate(),
            },
          };
        }
      }

      if (notifications.length >= limit) {
        const index = didAddTomorrow ? notifications.length - 2 : notifications.length - 1;
        notifications[index] = {
          ...notifications[index],
          body: 'Pausing your reminders for now. Open the app to view your actions and resume your reminder notifications.',
        };
      }

      return notifications;
    },
  },
};
