import { z } from 'zod';
import { App } from './App';

export namespace Objective {
  export const ObjectiveName = z.enum([
    'Start',
    'ActionAdd',
    'ActionComplete',
    'AddRepeatingAction',
    'AddRepeatingActionX3',
    'AddRepeatingActionX5',
    'AddFutureAction',
    'AddFutureActionX3',
    'AddFutureActionX5',
    'ActionsPlanComplete',
    // actions plan -- pushing a planet
    // imperative tense
    // value
    // effort & importance
    // ideas
    // reminders
    // links
    // search
    // interests -- quantum entanglement
    // insight -- entropy
    // effects
    // postpone
    // split
    // forgiveness
    // resolve
    // focus
  ]);

  export const SenderName = z.enum([
    'Ellie',
  ]);

  export const SenderSetting = {
    [SenderName.enum.Ellie]: {
      name: SenderName.enum.Ellie,
      id: 'E404120211070103',
      label: 'ellie',
    },
  };

  export const LetterClosingName = z.enum([
    'Yours',
    'YoursTruly',
    'Sincerely',
    'Always',
    'Best',
    'AllTheBest',
    'InHealth',
    'InKindness',
    'WarmRegards',
    'Warmly',
    'Cheers',
    'Respectfully',
    'BestWishes',
    'BeWell',
    'StaySafe',
    'UntilNextTime',
    'TalkSoon',
  ]);

  export const LetterClosing = {
    [LetterClosingName.enum.Yours]: 'yours,',
    [LetterClosingName.enum.YoursTruly]: 'yours truly,',
    [LetterClosingName.enum.Sincerely]: 'sincerely,',
    [LetterClosingName.enum.Always]: 'always,',
    [LetterClosingName.enum.Best]: 'best,',
    [LetterClosingName.enum.AllTheBest]: 'all the best,',
    [LetterClosingName.enum.InHealth]: 'in health,',
    [LetterClosingName.enum.InKindness]: 'in kindness,',
    [LetterClosingName.enum.WarmRegards]: 'warm regards,',
    [LetterClosingName.enum.Warmly]: 'warmly,',
    [LetterClosingName.enum.Cheers]: 'cheers,',
    [LetterClosingName.enum.Respectfully]: 'respectfully,',
    [LetterClosingName.enum.BestWishes]: 'best wishes,',
    [LetterClosingName.enum.BeWell]: 'be well.',
    [LetterClosingName.enum.StaySafe]: 'stay safe.',
    [LetterClosingName.enum.UntilNextTime]: 'until next time.',
    [LetterClosingName.enum.TalkSoon]: 'talk soon.',
  };

  // In order of inclusion
  export const Setting = {
    // Day 0 -- these should be enough to get the user to their first complete Actions Plan
    [ObjectiveName.enum.Start]: {
      name: ObjectiveName.enum.Start,
      sender: SenderName.enum.Ellie,
      label: 'are you there?',
      sublabel: undefined,
      description: [
        'if you\'ve found this, then your footsteps echo mine. since my journey began, i\'ve traveled these stars along a trail of messages. the first one read, <em>we are what we do</em> -- did you see it, too?',
        'i was propelled into the cosmos on countless missions, lured by the brilliance of many suns, yet in a vessel driven by the dark...',
        'it is by the shade of the <app-concept name="ActionsPlan" custom-label="eclipse" /> that i see tomorrow.',
        'safe travels, friend. may your journey someday reach beyond my own!',
      ],
      closing: LetterClosingName.enum.Respectfully,
      unlockActionsPlanMinValueDaysCount: 0,
      unlockCondition: 'conditions/true',
      successCondition: 'conditions/true',
      concepts: [
        App.Concept.ConceptName.enum.ActionsPlan,
      ],
    },
    [ObjectiveName.enum.ActionAdd]: {
      name: ObjectiveName.enum.ActionAdd,
      sender: SenderName.enum.Ellie,
      label: 'do something',
      sublabel: 'adding actions',
      description: [
        'the basis of all things is action, without which time and space would cease. actions give everything meaning.',
        'if you <app-concept name="ActionAdd" />, you\'ll add some <app-concept name="Value" /> to today\'s <app-concept name="ActionsPlan" />. it can be something easy... something you want to do today, or maybe something you\'ve already done. the point is to get you off the ground.',
      ],
      closing: LetterClosingName.enum.BeWell,
      unlockActionsPlanMinValueDaysCount: 0,
      unlockCondition: 'conditions/true',
      successCondition: 'conditions/hasActions',
      concepts: [
        App.Concept.ConceptName.enum.ActionsPlan,
        App.Concept.ConceptName.enum.ActionAdd,
        App.Concept.ConceptName.enum.Value,
      ],
    },
    [ObjectiveName.enum.ActionComplete]: {
      name: ObjectiveName.enum.ActionComplete,
      sender: SenderName.enum.Ellie,
      label: 'get moving',
      sublabel: 'completing actions',
      description: [
        'by tracking what we do every day, we become more aware of how we spend our time. what you do with that knowledge is up to you.',
        'open an action to <app-concept name="ActionComplete" custom-label="complete" /> it and get some <app-concept name="Value" />, bringing you closer to finishing today\'s <app-concept name="ActionsPlan" />.',
      ],
      closing: LetterClosingName.enum.WarmRegards,
      unlockActionsPlanMinValueDaysCount: 0,
      unlockCondition: 'conditions/hasActions',
      successCondition: 'conditions/hasActionsComplete',
      concepts: [
        App.Concept.ConceptName.enum.ActionsPlan,
        App.Concept.ConceptName.enum.ActionComplete,
        App.Concept.ConceptName.enum.Value,
      ],
    },
    [ObjectiveName.enum.AddRepeatingAction]: {
      name: ObjectiveName.enum.AddRepeatingAction,
      sender: SenderName.enum.Ellie,
      label: 'the power of routine',
      sublabel: 'habits and repeating actions',
      description: [
        'growing up, my father sometimes seemed like a robot. every day, he\'d make his bed, take a walk to the corner store, and buy a newspaper. by the time i woke up, he knew the outcomes of yesterday\'s pro sports games and enough about current events to chat by the water cooler at work.',
        'still, i\'m not blessed with his early bird-discipline. i actually used to revel in my scatterbrained spontaneity.',
        'before i joined the prime initiative, i thought routine was the death of freedom. it\'s counterintuitive, really, that the opposite is true: by creating routines, i am free to focus on what matters most. like good conversation, and seeing your dad live the best life he could piece together.',
        '<app-concept name="ActionAdd" custom-label="add" /> a <app-concept name="ActionFrequencyRepeating" /> that can be <app-concept name="ActionComplete" custom-label="completed" /> today. start with just one thing, like making the bed or even just getting up. make it easy and attainable. i think that\'s how dad did it.',
      ],
      closing: LetterClosingName.enum.AllTheBest,
      unlockActionsPlanMinValueDaysCount: 0,
      unlockCondition: 'conditions/true',
      successCondition: 'conditions/hasActionsRepeatingVirtualDay',
      concepts: [
        App.Concept.ConceptName.enum.ActionAdd,
        App.Concept.ConceptName.enum.ActionComplete,
        App.Concept.ConceptName.enum.ActionFrequencyRepeating,
      ],
    },
    [ObjectiveName.enum.AddFutureAction]: {
      name: ObjectiveName.enum.AddFutureAction,
      sender: SenderName.enum.Ellie,
      label: 'through time and space',
      sublabel: 'scheduling actions',
      description: [
        'can you grasp infinity? it\'s impossible to fathom. imagining tomorrow is much easier.',
        '<app-concept name="ActionAdd" /> that is <app-concept name="ActionTimeframe" custom-label="scheduled" /> for tomorrow, or beyond. off-load actions to your future <app-concept name="ActionsPlan" is-plural /> and find peace.',
      ],
      closing: LetterClosingName.enum.Cheers,
      unlockActionsPlanMinValueDaysCount: 0,
      unlockCondition: 'conditions/true',
      successCondition: 'conditions/hasActionsWithTimeframeFuture',
      concepts: [
        App.Concept.ConceptName.enum.ActionAdd,
        App.Concept.ConceptName.enum.ActionsPlan,
        App.Concept.ConceptName.enum.ActionTimeframe,
      ],
    },
    [ObjectiveName.enum.ActionsPlanComplete]: {
      name: ObjectiveName.enum.ActionsPlanComplete,
      sender: SenderName.enum.Ellie,
      label: 'endless patience',
      sublabel: 'completing missions',
      description: [
        'the universe is vast. there may even be universes inside our own, and invisible universes surrounding us. dimensions we can\'t perceive, stacked like pages in a book around our brief phrases.',
        'when faced with endless space and endless possibility, what does a <app-concept name="ActionsPlan" /> even mean? one way out of this paradox, i believe: steady pace and balance.',
        'getting an <app-concept name="ActionsPlan" custom-label="eclipse" /> doesn\'t have to be the end of your day--keep <app-concept name="ActionAdd" custom-label="adding" /> and <app-concept name="ActionComplete" custom-label="completing" /> actions if you want! learn what you can achieve while making your goal <em>consistency</em>, not raw <app-concept name="Value" />.',
      ],
      closing: LetterClosingName.enum.Yours,
      unlockActionsPlanMinValueDaysCount: 0,
      unlockCondition: 'actionsPlan/isComplete',
      successCondition: 'conditions/true',
      concepts: [
        App.Concept.ConceptName.enum.ActionAdd,
        App.Concept.ConceptName.enum.ActionComplete,
        App.Concept.ConceptName.enum.ActionsPlan,
        App.Concept.ConceptName.enum.Value,
      ],
    },
    // end Day 0

    // Day 1 -- these should teach the user how to establish a foundation for success
    [ObjectiveName.enum.AddRepeatingActionX3]: {
      name: ObjectiveName.enum.AddRepeatingActionX3,
      sender: SenderName.enum.Ellie,
      label: 'pillars of creation',
      sublabel: 'multiple repeating actions',
      description: [
        'for all its beauty, this journey also has a lot of emptiness.',
        'in the vast space between the stars, it\'s important that we reflect on the beauty we\'ve witnessed, and drink from the fountains of positive routine we\'ve created.',
        'i\'ve found that <app-concept name="ActionAdd" custom-label="adding" /> at least 3 <app-concept name="ActionFrequencyRepeating" is-plural /> to do every day gives me a solid foundation to work from. hope it does the same for you.',
      ],
      closing: LetterClosingName.enum.Always,
      unlockActionsPlanMinValueDaysCount: 1,
      unlockCondition: 'conditions/hasActionsRepeatingVirtualDay',
      successCondition: 'conditions/hasActionsRepeatingVirtualDay3',
      concepts: [
        App.Concept.ConceptName.enum.ActionAdd,
        App.Concept.ConceptName.enum.ActionFrequencyRepeating,
      ],
    },
    [ObjectiveName.enum.AddFutureActionX3]: {
      name: ObjectiveName.enum.AddFutureActionX3,
      sender: SenderName.enum.Ellie,
      label: 'what tomorrow holds',
      sublabel: 'planning ahead by scheduling actions',
      description: [
        'you\'ve made it this far--i imagine you\'re getting the hang of it. me? i\'m still not sure what "it" is.',
        'how much longer should i go on, in search of the unknown? or is it the search itself i crave, chasing our tails in an endless orbit?',
        'sometimes, we must inject new energy into our <app-concept name="ActionsPlan" is-plural /> to reach new paths. ensure you always have at least 3 actions that are <app-concept name="ActionTimeframe" custom-label="scheduled" /> for the future. best to keep moving forward.',
      ],
      closing: LetterClosingName.enum.UntilNextTime,
      unlockActionsPlanMinValueDaysCount: 1,
      unlockCondition: 'conditions/hasActionsWithTimeframeFuture',
      successCondition: 'conditions/hasActionsWithTimeframeFuture3',
      concepts: [
        App.Concept.ConceptName.enum.ActionTimeframe,
        App.Concept.ConceptName.enum.ActionsPlan,
      ],
    },
    // end Day 1

    // Day 2 -- these should continue to reinforce the foundation
    [ObjectiveName.enum.AddRepeatingActionX5]: {
      name: ObjectiveName.enum.AddRepeatingActionX5,
      sender: SenderName.enum.Ellie,
      label: 'the five-pointed star',
      sublabel: 'strong habits and routines',
      description: [
        'from a distance, every star looks like a single point. it looks perfect and bright, especially when the only equipment you\'re using is your eyes.',
        'when you get closer, you start to notice things. how the star subtly wobbles. how it has fits of anger, and spits out hot plasma to anything within an earshot. how it steadily anchors an entire system of planets and other bodies. some stars share this duty with another; most do not. they are strong and alone.',
        'we all have anchors, and we all must anchor others. you could choose to drift endlessly through the galaxy, waiting for purpose to cross your path. or you could consider that your purpose is your existence, because your gravity matters, even at a great distance.',
        'when i feel adrift, i choose to anchor myself. a good, ambitious goal to have: 5 <app-concept name="ActionFrequencyRepeating" is-plural /> for every day.',
      ],
      closing: LetterClosingName.enum.Yours,
      unlockActionsPlanMinValueDaysCount: 2,
      unlockCondition: 'conditions/hasActionsRepeatingVirtualDay3',
      successCondition: 'conditions/hasActionsRepeatingVirtualDay5',
      concepts: [
        App.Concept.ConceptName.enum.ActionFrequencyRepeating,
      ],
    },
    [ObjectiveName.enum.AddFutureActionX5]: {
      name: ObjectiveName.enum.AddFutureActionX5,
      sender: SenderName.enum.Ellie,
      label: 'playing the long game',
      sublabel: 'stay on track with scheduling',
      description: [
        'something has happened. my journey is untethered, unanchored. i\'m facing... it\'s a moment. a tough one. that\'s all i want to share for now.',
        'i\'ll be honest with you: this isn\'t for the faint of heart. i hope you\'re still getting these messages. it may be a while before i am able to leave another.',
        'please, take care out here, whether in the void or in the aether. we all get lost sometimes; we must follow our intuition back to the path. leave some breadcrumbs for yourself.',
        'some parting advice: try to always have 5 or 6 actions that are <app-concept name="ActionTimeframe" custom-label="scheduled" /> for the future. breadcrumbs.',
        'balance.',
      ],
      closing: LetterClosingName.enum.YoursTruly,
      unlockActionsPlanMinValueDaysCount: 2,
      unlockCondition: 'conditions/hasActionsWithTimeframeFuture3',
      successCondition: 'conditions/hasActionsWithTimeframeFuture5',
      concepts: [
        App.Concept.ConceptName.enum.ActionTimeframe,
      ],
    },
    // end Day 2
  };
}
