
import { Haptics } from '@capacitor/haptics';

import { Effect } from '../api/src/models/effect';
import { SystemUpgrade } from '../api/src/models/SystemUpgrade';
import actionMixin from '../mixins/action';
import effectsMixin from '../mixins/effects';
import uiMixin from '../mixins/ui';

export default {
  name: 'ActionReminderDelay',
  mixins: [
    actionMixin,
    effectsMixin,
    uiMixin,
  ],
  props: {
    itemId: {
      type: String,
      default: undefined,
    },
    effectName: {
      type: String,
      default: Effect.EffectName.enum.ReminderDelay,
    },
    promptText: {
      type: String,
      default: '',
    },
    verb: {
      type: String,
      default: Effect.Setting[Effect.EffectName.enum.ReminderDelay].verb,
    },
  },
  computed: {
    actionId() {
      return this.itemId;
    },
    effect() {
      return (this.effectName && Effect.EffectName.options.includes(this.effectName))
        ? Effect.Setting[this.effectName]
        : Effect.Setting[Effect.EffectName.enum.ReminderDelay];
    },
    upgrade() {
      let upgrade;

      if (this.isDebugger && this.effect) {
        let name;

        switch (this.effect.name) {
          case Effect.EffectName.enum.ReminderDelay:
            name = this.$store.state.systems.upgradeName.ReminderDelayRange;
            break;
        }

        if (name) {
          upgrade = this.$store.getters['systems/upgrades'].find(i => i.name === name);
        }
      }

      return upgrade;
    },
    helpText() {
      return this.effect ? this.effect.description : undefined;
    },
    minValue() {
      return SystemUpgrade.Setting[SystemUpgrade.Name.enum.ReminderDelayRange].values[0];
    },
    maxValue() {
      const { values } = SystemUpgrade.Setting[SystemUpgrade.Name.enum.ReminderDelayRange];
      let val = values[values.length - 1];

      if (this.upgrade) {
        val = this.upgrade.value;
      }

      return val;
    },
    maxValueDisplay() {
      let display = SystemUpgrade.Setting[SystemUpgrade.Name.enum.ReminderDelayRange].valueDisplay(this.minValue);

      if (!this.isDebugger) {
        display = SystemUpgrade.Setting[SystemUpgrade.Name.enum.ReminderDelayRange].valueDisplay(this.maxValue);
      }

      if (this.upgrade) {
        display = this.upgrade.valueDisplay;
      }

      return display;
    },
    snoozeOptions() {
      const opts = [];

      if (Array.isArray(this.upgrade?.values)) {
        this.upgrade.values.filter(v => v <= this.maxValue).forEach((v) => {
          opts.push({
            label: `in ${SystemUpgrade.Setting[SystemUpgrade.Name.enum.ReminderDelayRange].valueDisplay(v)}`,
            command: () => {
              this.handleSnooze(this.$dayjs().add(v, 'minutes').toDate());
            }
          });
        });
      }

      if (!this.isDebugger && !this.upgrade) {
        const { values } = SystemUpgrade.Setting[SystemUpgrade.Name.enum.ReminderDelayRange];
        values.forEach((v) => {
          opts.push({
            label: `in ${SystemUpgrade.Setting[SystemUpgrade.Name.enum.ReminderDelayRange].valueDisplay(v)}`,
            command: () => {
              this.handleSnooze(this.$dayjs().add(v, 'minutes').toDate());
            }
          });
        });
      }

      opts.push({
        label: 'stop reminders today',
        command: async () => {
          let msg = `Are you sure you want to stop reminders for "${this.action.description}" today?`;

          if (this.isInActionsPlan) {
            msg += ' (This action is part of your current mission.)';
          }

          const isSkipping = await this.uiMsgBoxConfirm(msg);

          if (isSkipping && this.action && this.action.remindAt) {
            const remindAtSplit = this.action.remindAt.split(':');
            const remindAt = this.$dayjs().add(1, 'day').hour(remindAtSplit[0]).minute(remindAtSplit[1]).second(0);

            this.handleSnooze(remindAt.toDate());
          }
        },
      });

      return opts;
    },
  },
  methods: {
    async handleSnooze(customDate) {
      await this.$store.dispatch('actionsUser/editItem', {
        id: this.itemId,
        mods: {
          lastRemindedAt: customDate || this.$dayjs().add(10, 'minutes').toDate(),
        },
      });

      this.uiNotify('Action reminder snoozed.', {
        icon: this.effect.icon,
      });

      Haptics.vibrate();
      this.$emit('close');
    },
    handleViewUpgrades() {
      this.$store.dispatch('ui/showModal', { name: 'systemUpgrades' });
      this.$emit('close');
    },
  },
};
