import _ from 'lodash';
import debug from '../lib/debug';

export default ({ route, store }) => {
  if (route?.name === 'terms') {
    return;
  }

  const dbg = debug('effects');
  const { isUserInitialized } = store.getters;
  const canEdit = store.getters['userSettings/canEdit'];

  const cleanupEffectCustomCollection = _.debounce(() => {
    dbg('dispatch cleanupEffectCustomCollection');
    store.dispatch('userSettings/cleanupEffectCustomCollection');
  }, 1000);

  dbg('watching state.now');

  if (isUserInitialized && canEdit) {
    cleanupEffectCustomCollection();
  }

  store.watch(
    (state, getters) => {
      return state.now && getters.isUserInitialized && getters['userSettings/canEdit'];
    },
    cleanupEffectCustomCollection,
  );
};
