import Vue from 'vue';

import {
  wrapGetters,
  getHexColorForId,
} from '../lib/util';

import { Interest } from '../api/src/models/Interest';
import { bindToDocsForUserId, upsertDoc, deleteDoc } from '../lib/data';
import { StoreHelperMutation, storeHelperMutations } from '../lib/store-helpers';

let unsub;

export const state = () => ({
  all: [],
});

export const mutations = {
  ...storeHelperMutations,
  editItem(localState, item) {
    const index = localState.all.findIndex(i => i.id === item.id);

    if (index >= 0) {
      Vue.set(localState.all, index, item);
    } else {
      localState.all.push(item);
    }
  },
};

export const getters = wrapGetters('interests', {
  itemNameExists: state => (name) => {
    const interest = state.all.find(i => i.name === name);

    return Boolean(interest);
  },
  getColorForItem: () => (item) => {
    return getHexColorForId(item.id);
  },
  // rename to getItemById
  getInterestById: state => (id) => {
    return state.all.find(i => i.id === id);
  },
  getInterestByName: state => name => state.all.find(i => i.name === name),
  defaultItem: (state) => {
    return state.all.find(i => i.isDefault);
  },
});

export const actions = {
  async init({ commit, dispatch }) {
    const userId = await this.$auth.getUserId();

    if (!userId) {
      throw new Error('Could not bind interests for invalid userId');
    }

    this.$dbg('store:interests:init')(userId);

    unsub = bindToDocsForUserId(Interest.Model, Interest.DataTableName, userId, {
      onInit: () => {
        dispatch('setDataSourceSync', { name: 'interests', isRemote: true }, { root: true });
      },
      onUpdate: ({ docs, docsRemoved }) => {
        this.$dbg('store:interests:init')('onUpdate', docs);

        if (Array.isArray(docsRemoved)) {
          docsRemoved.forEach((doc) => {
            commit(StoreHelperMutation.RemoveFromAll, doc)
          });
        }

        commit(StoreHelperMutation.SetOnAll, docs);
      },
    });
  },

  async reset({ commit }) {
    this.$dbg('store:interests')('reset');

    if (unsub) {
      unsub();
    }

    // Get userId directly from auth to ensure they DO NOT still have a session!
    // Otherwise, we will risk wiping the user's data
    const userId = await this.$auth.getUserId();

    if (!userId) {
      commit(StoreHelperMutation.ResetAll);
    }
  },

  async editItem({ state, commit, dispatch, rootGetters }, { id, mods }) {
    try {
      // Get userId directly from auth to ensure they still have a session
      const userId = await this.$auth.getUserId();

      if (!userId) {
        throw new Error('Cannot edit interest; invalid userId');
      }

      if (!rootGetters.isAllowedToUseApp) {
        throw new Error('Entitlement required.');
      }

      this.$dbg('store:interests')('editItem', mods);

      const interest = state.all.find(i => i.id === id) || {};
      const data = await upsertDoc(Interest.Model, Interest.DataTableName, id, {
        ...interest,
        ...mods,
        userId,
      });

      // because bindToDoc.onUpdate only fires if online?
      commit(StoreHelperMutation.SetOnAll, [data]);

      if (id) {
        dispatch('logEvent', { name: 'interest_edit' }, { root: true });
      } else {
        dispatch('logEvent', { name: 'interest_add' }, { root: true });
      }

      return data;
    } catch (e) {
      this.$dbg('store:interests')('editItem error', e);
      return false;
    }
  },

  async deleteItem({ commit }, item) {
    try {
      // Get userId directly from auth to ensure they still have a session
      const userId = await this.$auth.getUserId();

      if (!userId) {
        throw new Error('Could not delete item, invalid userId');
      }

      this.$dbg('store:interests')('deleteItem', item.id);
      deleteDoc(Interest.DataTableName, item.id);
      commit(StoreHelperMutation.RemoveFromAll, item);

      return true;
    } catch (e) {
      this.$dbg('store:interests')('deleteItem error', item.id, e);
      return false;
    }
  },

  async deleteAllItems({ state, dispatch, rootGetters }) {
    if (rootGetters.isDemoAccount) {
      try {
        const proms = [];

        state.all.forEach((item) => {
          proms.push(dispatch('deleteItem', item));
        });

        await Promise.all(proms);

        return true;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return false;
      }
    }
  },

  async addItemWithProps({ getters, dispatch }, props) {
    let interest = getters.getInterestByName(props.name);

    if (!interest) {
      interest = await dispatch('editItem', {
        id: undefined,
        mods: {
          ...props,
        },
      });
    }

    return interest;
  },

  addDefaultItems({ dispatch }) {
    const prom = [];

    Interest.DefaultInterests.forEach((props) => {
      prom.push(dispatch('addItemWithProps', props));
    });

    return Promise.all(prom);
  },
};
