
import _ from 'lodash';

import { Action } from '../api/src/models/Action';
import { Interest } from '../api/src/models/Interest';
import uiMixin from '../mixins/ui';

export default {
  name: 'InterestEdit',
  mixins: [
    uiMixin,
  ],
  props: {
    interestId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editInterest: {},
    };
  },
  computed: {
    interest() {
      return this.$store.state.interests.all.find(i => i.id === this.interestId);
    },
    defaultInterest() {
      return this.$store.getters['interests/defaultItem'];
    },
    actionsCount() {
      return this.interest
        ? this.$store.state.actionsUser.all.filter(i => (i.interest.includes(this.interest.id) && i.status !== Action.Status.enum.Done)).length
        : 0;
    },
  },
  watch: {
    interest(newVal, oldVal) {
      this.$dbg('interest-edit:watch:interest')(`interest ${oldVal?.id} deleted`);

      if (oldVal && !newVal) {
        this.$emit('canceled');
      }
    },
  },
  mounted() {
    this.$bindKeys.bind('interest-edit', {}, true);
    this.editInterest = _.cloneDeep(this.interest ? this.interest : Interest.ModelDefault);
  },
  destroyed() {
    this.$bindKeys.unbind('interest-edit');
  },
  methods: {
    handleChange(key, val) {
      if (Object.keys(this.editInterest).includes(key)) {
        this.$set(this.editInterest, key, val);
      }
    },
    handleEmojiSelect(evt) {
      this.handleChange('icon', `${evt.detail.unicode}`);
      this.$refs.interestEditEmojiPicker.hide();
    },
    handleEmojiClear() {
      this.editInterest.icon = undefined;
      this.$refs.interestEditEmojiPicker.hide();
    },
    async handleSubmit() {
      const originalInterest = this.interest;
      const name = this.editInterest.name ? this.editInterest.name.trim() : '';

      if ((!originalInterest || name !== originalInterest.name) && this.$store.getters['interests/itemNameExists'](name)) {
        this.uiMsgBoxOk('You already have an interest with this name.');
        return;
      }

      if (name === '') {
        this.uiMsgBoxOk('Interest name cannot be empty.');
        return;
      }

      if (this.editInterest.isDefault && this.defaultInterest && (!this.interest || this.defaultInterest.id !== this.interest.id)) {
        const msg = `"${this.defaultInterest.name}" is currently your default interest. Are you sure you want to change it to "${this.editInterest.name}"?`;
        const isSettingToDefault = await this.uiMsgBoxConfirm(msg);

        if (!isSettingToDefault) {
          return;
        } else {
          await this.$store.dispatch('interests/editItem', {
            id: this.defaultInterest.id,
            mods: {
              isDefault: false,
            },
          });
        }
      }

      let toastMsg;
      const toastOpts = {
        severity: 'success',
        icon: ['fas', 'hashtag'],
      };

      const result = await this.$store.dispatch('interests/editItem', {
        id: this.editInterest.id,
        mods: {
          name,
          icon: this.editInterest.icon,
          isDefault: this.editInterest.isDefault === true,
        },
      });

      toastMsg = `Interest ${(this.interest ? 'edited' : 'added')}`;

      if (result === false) {
        toastMsg = 'Interest could not be saved.';
        toastOpts.severity = 'warning';
        toastOpts.icon = ['fas', 'exclamation'];
      }

      this.uiNotify(toastMsg, toastOpts);

      this.$emit('submitted');
    },
    doDeleteInterest() {
      if (this.interest) {
        const actionsCount = this.actionsCount;

        if (actionsCount > 0) {
          this.uiMsgBoxOk(`There are ${actionsCount} incomplete actions associated with this interest. You must edit or delete those actions before this interest can be deleted.`);
          return;
        }

        let msg = `Are you sure you want to delete your interest "${this.interest.name}"?`;
        msg = `${msg} This action cannot be undone.`;

        this.uiMsgBoxConfirm(msg, {
          header: `Delete "${this.interest.name}"`,
          okTitle: 'Delete',
          okVariant: 'danger',
        }).then((isDeleting) => {
          if (isDeleting) {
            const actions = this.$store.state.actionsUser.all.filter(i => i.interest && i.interest.includes(this.interest.id));

            actions.forEach((i) => {
              const interestsWithout = i.interest.filter(interestId => interestId !== this.interest.id);

              const mods = {
                interest: interestsWithout,
              };

              this.$store.dispatch('actionsUser/editItem', { id: i.id, mods });
            });

            this.$store.dispatch('interests/deleteItem', this.interest);

            this.uiNotify('Interest deleted', {
              severity: 'success',
              logMessage: true,
            });

            this.$emit('submitted');
          }
        });
      }
    },
  },
};
