
import { mapGetters } from 'vuex';
import { TrinityRingsSpinner } from 'epic-spinners';

import uiMixin from '../mixins/ui';
import navMixin from '../mixins/nav';

export default {
  name: 'NavbarView',
  components: {
    TrinityRingsSpinner,
  },
  mixins: [
    uiMixin,
    navMixin,
  ],
  data() {
    return {
      btnClasses: ['sl-button-text-default', 'icon-highlight-2', 'ec-py-3'],
      btnClassesUser: ['sl-button-text-default', 'icon-highlight-2'],
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'gotInitialData',
    ]),
    ...mapGetters('companions', [
      'companionActive',
    ]),
    dateDisplay() {
      return `${this.$store.state.now.format('D MMM YYYY')} CE`;
    },
    ideas() {
      return this.$store.getters.ideasFresh;
    },
    hasAvatar() {
      return !!this.$store.getters['userSettings/avatarSelected'];
    },
    hasAetherCreation() {
      return !!this.$store.getters['systems/getValueForUpgradeByName'](this.$store.state.systems.upgradeName.AetherCreation);
    },
    hasShadeCreation() {
      return !!this.$store.getters['systems/getValueForUpgradeByName'](this.$store.state.systems.upgradeName.ShadeCreation);
    },
    shouldPromptEffectsView() {
      return this.hasAetherCreation && !!this.$store.getters['userSettings/shouldPromptEffectsView'];
    },
  },
  watch: {
    isAuthenticated() {
      this.toggleMenu();
    },
  },
  methods: {
    toggleMenu($event) {
      if ($event) {
        this.$refs['navbar-menu'].toggle($event);
      } else {
        this.$refs['navbar-menu'].hide();
      }
    },
    handleAppName() {
      this.$store.commit('ui/toggleForegroundHidden');
      this.$store.dispatch('logEvent', { name: 'scene_view' });
    },
  },
};
