import { mapState, mapGetters } from 'vuex';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

export default {
  data() {
    return {
      didGetData: false,
    };
  },
  computed: {
    ...mapState([
      'isAuthenticationRequested',
    ]),
    ...mapState('scene', [
      'didAnimateOnce',
    ]),
    ...mapState('ui', [
      'didViewIntro',
      'isBackgroundEnabled',
    ]),
    ...mapGetters([
      'isAuthenticated',
      'gotInitialData',
      'virtualDay',
    ]),
  },
  watch: {
    $route() {
      if (this.isAuthenticated) {
        this.handlePostAuth();
      } else {
        this.requestAuth();
      }
    },
    didViewIntro(newVal) {
      this.$dbg('init')('didViewIntro');

      if (newVal) {
        if (!this.isAuthenticated) {
          this.requestAuth();
        }

        this.$store.dispatch('ui/hideModal', 'intro');
      }
    },
    didAnimateOnce(newVal, oldVal) {
      this.$dbg('init')('didAnimateOnce');

      if (newVal && !oldVal && !this.isAuthenticated && !this.isAuthenticationRequested) {
        this.$store.dispatch('ui/showModal', { name: 'intro' });
      }
    },
    isAuthenticationRequested() {
      if (!this.isAuthenticated && this.isAuthenticationRequested) {
        this.requestAuth();
      }
    },
    isAuthenticated(newVal) {
      this.$dbg('init')('isAuthenticated');

      if (newVal === true) {
        this.handlePostAuth();
      } else {
        this.didGetData = false;
        this.requestAuth();
      }
    },
    gotInitialData() {
      if (this.gotInitialData) {
        this.$dbg('init')('gotInitialData');
        this.$dbLocalHelper.setHasSession(true);

        if (Capacitor.isNativePlatform()) {
          this.initPush();
        }

        this.$root.$once('scene-animate-end', () => {
          this.$store.commit('ui/allowForegroundHiddenOnSceneWatch', true);
        });

        // if it's already been loaded (user logged in manually), this needs to run
        if (this.isBackgroundEnabled) {
          this.$root.$emit('scene-animate-to-progress');
        } else {
          this.monitorUserInteraction();
        }
      }
    },
    isBackgroundEnabled() {
      if (this.isBackgroundEnabled) {
        this.unmonitorUserInteraction();
      }
    },
    virtualDay() {
      this.$store.dispatch('quotes/setForDay');
    },
  },
  mounted() {
    this.$dbg('init')('mounted');
    this.$store.dispatch('logEvent', { name: 'app_init' });
    this.$store.dispatch('ui/hideAllModals');
    this.$store.commit('ui/allowForegroundHiddenOnSceneWatch', false);

    // these only really happen on remount during HMR
    if (this.isAuthenticated) {
      this.handlePostAuth();
    } else if (this.didAnimateOnce || this.isAuthRoute()) {
      this.requestAuth();
    } else if (this.$dbLocalHelper.getHasSession() === false) {
      // this case means that no previous session was detected, so we should enable the
      // bg scene animation sooner and not wait for data init.
      // the flow will continue after didAnimateOnce or isAuthenticated
      this.$dbg('init')('let animate');
      this.$store.dispatch('ui/enableBackgroundImmediately');
    }
  },
  methods: {
    tryGetData() {
      this.$dbg('init')('tryGetData', this.didGetData, this.gotInitialData);
      if (!this.didGetData && !this.gotInitialData) {
        this.didGetData = true;
        this.$dbg('init')('tryGetData');
        this.$store.dispatch('getData');
      }
    },
    handlePostAuth() {
      if (this.isAuthenticated) {
        this.$dbg('init')('handlePostAuth');
        this.$store.commit('ui/didViewIntro');
        // this doesn't hide the auth modal; that is handled in store/ui
        this.$router.push({ name: 'index' });
        this.$store.commit('isAuthenticationRequested', false);
        this.tryGetData();
      }
    },
    isAuthRoute() {
      return Number(this.$route.query.auth) === 1;
    },
    requestAuth() {
      if (!this.isAuthenticated) {
        if (this.isAuthRoute() && !this.isAuthenticationRequested) {
          this.$dbg('init')('requestAuth');

          this.$store.dispatch('ui/hideAllModals');
          this.$store.commit('isAuthenticationRequested', true);
          this.$store.dispatch('ui/enableBackgroundImmediately');
        } else {
          this.$router.push({ name: 'index', query: { auth: 1 } });
        }
      }
    },
    initPush() {
      this.$dbg('init')('initPush');
      PushNotifications.removeAllDeliveredNotifications();

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token) => {
        if (token && token.value && !this.$store.state.userSettings.notificationTokens.includes(token.value)) {
          const notificationTokens = this.$store.state.userSettings.notificationTokens.concat([token.value]);

          this.$store.dispatch('userSettings/edit', {
            notificationTokens,
          });
        }
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (/* error */) => {
        this.uiMsgBoxOk('We were unable to register your device for push notifications.');
      });

      this.registerNotifications();

      // Show us the notification payload if the app is open on our device
      /*
      PushNotifications.addListener('pushNotificationReceived',
        (notification) => {
          alert('Push received: ' + JSON.stringify(notification));
        }
      );
      */

      // Method called when tapping on a notification
      /*
      PushNotifications.addListener('pushNotificationActionPerformed', ({ notification }) => {
        this.handlePush(notification);
      });
      */
    },
    async registerNotifications() {
      this.$dbg('init')('registerNotifications');
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        let msg = `${this.$store.state.app.name} can use push notifications to remind you when actions are due. This happens only if you set a reminder for an action.`;
        msg = `${msg} Please choose "Allow" in the following prompt if you'd like to use this important feature.`;
        await this.uiMsgBoxOk(msg);

        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive === 'granted') {
        this.$store.commit('ui/isPushAllowed');
        await PushNotifications.register();
      }
    },
    enableBackgroundWait() {
      this.$store.dispatch('ui/enableBackgroundWait');
    },
    monitorUserInteraction() {
      this.$dbg('init')('monitorUserInteraction');

      this.$store.dispatch('ui/enableBackgroundWait');

      ['mousedown', 'mousemove', 'keypress', 'touchstart'].forEach((e) => {
        window.addEventListener(e, this.enableBackgroundWait);
      });
    },
    unmonitorUserInteraction() {
      this.$dbg('init')('unmonitorUserInteraction');

      ['mousedown', 'mousemove', 'keypress', 'touchstart'].forEach((e) => {
        window.removeEventListener(e, this.enableBackgroundWait);
      });
    },
  },
};
