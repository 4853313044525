import debug from 'debug';

const DEFAULT_NAMESPACE = 'eclpsr';

if (process.client) {
  localStorage.debug = '*';
}

const instances: Record<string, debug.Debugger> = {
  [DEFAULT_NAMESPACE]: debug(DEFAULT_NAMESPACE),
};

// required to properly init this instance and all that extend it
instances[DEFAULT_NAMESPACE]('debug init');

export default (namespace: string) => {
  if (namespace) {
    if (!instances[namespace]) {
      instances[namespace] = instances[DEFAULT_NAMESPACE].extend(namespace);
    }

    return instances[namespace];
  } else {
    return instances[DEFAULT_NAMESPACE];
  }
};
