
import { Badge } from '@capawesome/capacitor-badge';
import { LocalNotifications } from '@capacitor/local-notifications';
import { App as CapacitorApp } from '@capacitor/app';

import { mapState, mapGetters } from 'vuex';

import { Entitlement } from '../api/src/models/Entitlement';

import actionsPlanMixin from '../mixins/actionsPlan';
import dataMixin from '../mixins/data';
import localNotificationsMixin from '../mixins/localNotifications';
import uiMixin from '../mixins/ui';

export default {
  name: 'UIView',
  mixins: [
    actionsPlanMixin,
    dataMixin,
    localNotificationsMixin,
    uiMixin,
  ],
  data() {
    return {
      isDeviceReady: false,
      isActive: true,
      isConfirmingCloseActionEdit: false,
    };
  },
  computed: {
    ...mapState('actionsUser', {
      actions: 'all',
    }),
    ...mapGetters('actionsUser', [
      'itemsWithReminders',
    ]),
    ...mapGetters('app', [
      'shouldPromptToUpdate',
    ]),
    ...mapState('ui', {
      modalState: 'modal',
      isPushAllowed: 'isPushAllowed',
    }),
    ...mapGetters('ui', [
      'modalOverride',
      'modalClass',
    ]),
    ...mapGetters('purchases', [
      'isSubscribed',
    ]),
    appShouldPromptToUpdate() {
      return !this.isNative && this.shouldPromptToUpdate === true;
    },
    needsCookieConsent() {
      return this.gotInitialData && !this.$store.state.userSettings.didAcceptCookiePolicy;
    },
    messagesCount() {
      return this.$store.getters['ui/messages'].length;
    },
    badgeCount() {
      return this.messagesCount;
    },
    isDisplayingDialogAlert: {
      get() {
        return !!this.modalState.dialogAlert.payload.message && this.modalState.dialogAlert.isVisible;
      },
      set() {
        this.handleDialogAlertClose();
        this.$store.dispatch('ui/hideModal', 'dialogAlert');
      },
    },
    isDisplayingDialogConfirm: {
      get() {
        return !!this.modalState.dialogConfirm.payload.message && this.modalState.dialogConfirm.isVisible;
      },
      set() {
        this.$store.dispatch('ui/hideModal', 'dialogConfirm');
      },
    },
    newlyFulfilledItemName() {
      return this.newlyFulfilledItem ? this.newlyFulfilledItem.name : undefined;
    },
    isNeedingSubscriptionRenewal() {
      return !this.$store.getters.hasEntitlement(Entitlement.EntitlementName.enum.Basic) ||
        this.$store.getters['userSettings/isEntitlementExpiredGrace'](Entitlement.EntitlementName.enum.Basic);
    },
  },
  watch: {
    appShouldPromptToUpdate() {
      if (this.appShouldPromptToUpdate) {
        this.$store.commit('ui/addMessageAlertOfType', {
          type: this.$store.state.ui.MessageType.AppUpdate,
          severity: 'info',
          closable: false,
        });
      }
    },
    actions() {
      const toRemove = this.$store.state.actionSelect.list.filter((id) => {
        /* There's a quirk in that a completed action will move from one firestore query/subscription
         * to the other (completed actions), but may appear to be deleted briefly. To counter this,
         * at least locally, we can keep track of actions we've recently completed and prevent the
         * action selector from disappearing on action completion.
         */
        let keep = this.$store.state.actionsUser.itemsRecentlyCompletedLocally.includes(id);

        if (!keep) {
          keep = this.actions.findIndex(i => i.id === id) >= 0;
        }

        return keep === false;
      });

      toRemove.forEach((id) => {
        this.$store.commit('actionSelect/deleteFromList', id);
      });

      if (this.$store.state.actionSelect.list.length < 1) {
        this.uiHideActionSelector();
      }
    },
    isActive() {
      this.setBadgeCount();
    },
    badgeCount() {
      this.setBadgeCount();
    },
    modalOverride() {
      if (this.modalOverride) {
        this.$store.dispatch('logEvent', { name: 'screen_view', params: { firebase_screen: this.modalOverride } });
      }
    },
    /* How to prevent this from firing on page load?
    isSubscribed() {
      if (this.isSubscribed) {
        this.uiNotify('Subscription activated!', {
          severity: 'success',
          icon: ['fas', 'face-sunglasses'],
          logMessage: 'subscription activated',
        });
      } else {
        this.uiNotify('Subscription deactivated.', {
          severity: 'warning',
          icon: ['fas', 'triangle-exclamation'],
          logMessage: 'subscription deactivated',
        });
      }
    },
    */
    isNeedingSubscriptionRenewal() {
      this.handleSubscriptionRenewal();
    },
  },
  async mounted() {
    this.handleSubscriptionRenewal();

    this.$root.$on('show-modal', (name) => {
      if (this.modal[name]) {
        if (this.modal[name].hideOthers) {
          Object.keys(this.modal).forEach((mName) => {
            this.$set(this.modal[mName], 'show', false);
          });
        }

        this.$set(this.modal[name], 'show', true);
      }
    });

    this.$root.$on('hide-modal', (name) => {
      this.$set(this.modal[name], 'show', false);
    });
    this.$root.$on('hide-all-modals', () => {
      Object.keys(this.modal).forEach((mName) => {
        this.$set(this.modal[mName], 'show', false);
      });
    });

    if (this.isNative) {
      document.addEventListener('deviceready', () => {
        this.isDeviceReady = true;
      }, false);

      /*
      const info = await Device.getInfo();

      if (info.isVirtual) { // simulator
        this.$store.commit('ui/isAdvancedUIEnabled', false);
      }
      */
    }

    this.$bindKeys.bind('ui', {
      'KeyE KeyN': (e) => {
        if (e) {
          // This shortcut opens a modal with autofocus on an input, so prevent the key from being entered there
          e.preventDefault();
        }

        this.uiShowActionEdit();
      },
      'KeyE KeyI': (e) => {
        if (e) {
          // This shortcut opens a modal with autofocus on an input, so prevent the key from being entered there
          e.preventDefault();
        }

        this.$store.dispatch('ui/showModal', { name: 'actionIdea' });
      },
      'KeyE KeyM': () => {
        this.$store.dispatch('ui/showModal', { name: 'actionsPlan' });
      },
      'KeyE KeyA': () => {
        this.$store.dispatch('ui/showModal', { name: 'actions' });
      },
    });

    CapacitorApp.addListener('appStateChange', async ({ isActive }) => {
      this.$dbg('ui')('appStateChange', isActive);
      this.isActive = isActive;
      await this.handleLocalNotifications();
    });

    await this.handleLocalNotifications(true);
  },
  destroyed() {
    this.$bindKeys.unbind('ui');
  },
  methods: {
    async setBadgeCount() {
      if (!this.isUserInitialized) {
        return;
      }

      this.$dbg('ui')('setBadgeCount', this.badgeCount);
      const isBadgeSupported = await Badge.isSupported();

      if (isBadgeSupported && this.badgeCount > 0 && this.isPushAllowed && this.isDeviceReady) {
        await Badge.set({ count: this.badgeCount });
        this.$dbg('ui')('setBadgeCount', `set to ${this.badgeCount}`);
      } else {
        await Badge.clear();
        this.$dbg('ui')('setBadgeCount', 'clear!');
      }
    },
    handleSubscriptionRenewal() {
      if (this.isUserInitialized && this.isNeedingSubscriptionRenewal) {
        this.$store.commit('ui/addMessageAlertOfType', {
          type: this.$store.state.ui.MessageType.Subscribe,
          severity: 'info',
          closable: false,
        });
      } else {
        this.$store.commit('ui/dismissFirstMessageOfType', this.$store.state.ui.MessageType.Subscribe);
      }
    },
    async handleLocalNotifications() {
      if (!this.isActive && this.isPushAllowed && this.lnNotifications.length > 0) {
        this.$dbg('ui')('handleLocalNotifications:schedule', this.lnNotifications.length);
        await LocalNotifications.schedule({ notifications: this.lnNotifications });
      } else {
        LocalNotifications.getPending().then((pending) => {
          if (pending && Array.isArray(pending.notifications)) {
            this.$dbg('ui')('handleLocalNotifications:cancel', pending.notifications.length);

            if (pending.notifications.length > 0) {
              LocalNotifications.cancel({
                notifications: pending.notifications.map(n => ({ id: n.id }))
              });
            }
          }
        });
      }
    },
    handleModalVisibilityUpdate(modalName, isVisible) {
      if (modalName && !isVisible) {
        if (this.modalState[modalName].payload && typeof this.modalState[modalName].payload.onClose === 'function') {
          this.modalState[modalName].payload.onClose();
        }

        this.$store.dispatch('ui/hideModal', modalName);

        if (modalName === 'actionEdit') {
          this.isConfirmingCloseActionEdit = false;
        }
      }
    },
    async handleSuggestionBoxClose() {
      let result = false;

      if (this.$refs.suggestionBox) {
        result = await this.$refs.suggestionBox.handleSuggestion();
      }

      if (result) {
        this.handleModalVisibilityUpdate('suggestionBox', false);
      }
    },
    handleDialogAlertClose() {
      if (typeof this.modalState.dialogAlert.payload.onClose === 'function') {
        this.modalState.dialogAlert.payload.onClose();
      }
    },
    getFunction(f) {
      return typeof f === 'function' ? f : () => {};
    }
  },
};
