import { render, staticRenderFns } from "./action-list-item.vue?vue&type=template&id=06e6836f&"
import script from "./action-list-item.vue?vue&type=script&lang=js&"
export * from "./action-list-item.vue?vue&type=script&lang=js&"
import style0 from "./action-list-item.vue?vue&type=style&index=0&id=06e6836f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EffectApplyButton: require('/opt/build/repo/components/effect-apply-button.vue').default,EffectsButton: require('/opt/build/repo/components/effects-button.vue').default,ActionRecentCompletions: require('/opt/build/repo/components/action-recent-completions.vue').default,ActionDescription: require('/opt/build/repo/components/action-description.vue').default,ActionImportanceEffort: require('/opt/build/repo/components/action-importance-effort.vue').default,Value: require('/opt/build/repo/components/value.vue').default})
