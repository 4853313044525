import { z } from 'zod';

export namespace Sprite {
  export const Name = z.enum([
    // FX
    /*
    'Darkness01',
    'Darkness02',
    'Darkness03',
    'Darkness04',
    'Darkness05',
    'Energy01',
    'Energy02',
    'Energy03',
    'Energy04',
    'Energy05',
    'Energy06',
    'Energy07',
    'Energy08',
    'Energy09',
    'Energy10',
    'Energy11',
    'Energy12',
    'Field01',
    'Field02',
    */
    'Flare01',
    // 'Mantra01',
    'Particles01',
    'Particles02',
    // 'Portal01',
    'Ring01',
    /*
    'Ring02',
    'Shield01',
    'Shimmer01',
    'Simple10',
    'Simple17',
    'Starfish01',
    */
    'Swirl01',
    'Swirl02',
  ]);

  export const Setting = {
    /*
    [Name.enum.Darkness01]: {
      imgSrc: '/sprites/Darkness01.png',
      xCount: 5,
      yCount: 5,
      animSeconds: 1,
    },
    [Name.enum.Darkness02]: {
      imgSrc: '/sprites/Darkness02.png',
      xCount: 5,
      yCount: 8,
      animSeconds: 1,
    },
    [Name.enum.Darkness03]: {
      imgSrc: '/sprites/Darkness03.png',
      xCount: 5,
      yCount: 4,
      animSeconds: 1,
    },
    [Name.enum.Darkness04]: {
      imgSrc: '/sprites/Darkness04.png',
      xCount: 5,
      yCount: 6,
      animSeconds: 1,
    },
    [Name.enum.Darkness05]: {
      imgSrc: '/sprites/Darkness05.png',
      xCount: 5,
      yCount: 6,
      animSeconds: 1,
    },
    [Name.enum.Energy01]: {
      imgSrc: '/sprites/Energy01.png',
      xCount: 5,
      yCount: 3,
      animSeconds: 1,
    },
    [Name.enum.Energy02]: {
      imgSrc: '/sprites/Energy02.png',
      xCount: 5,
      yCount: 3,
      animSeconds: 1,
    },
    [Name.enum.Energy03]: {
      imgSrc: '/sprites/Energy03.png',
      xCount: 5,
      yCount: 4,
      animSeconds: 1,
    },
    [Name.enum.Energy04]: {
      imgSrc: '/sprites/Energy04.png',
      xCount: 5,
      yCount: 3,
      animSeconds: 1,
    },
    [Name.enum.Energy05]: {
      imgSrc: '/sprites/Energy05.png',
      xCount: 5,
      yCount: 4,
      animSeconds: 1,
    },
    [Name.enum.Energy06]: {
      imgSrc: '/sprites/Energy06.png',
      xCount: 5,
      yCount: 7,
      animSeconds: 1,
    },
    [Name.enum.Energy07]: {
      imgSrc: '/sprites/Energy07.png',
      xCount: 5,
      yCount: 3,
      animSeconds: 1,
    },
    [Name.enum.Energy08]: {
      imgSrc: '/sprites/Energy08.png',
      xCount: 5,
      yCount: 7,
      animSeconds: 1,
    },
    [Name.enum.Energy09]: {
      imgSrc: '/sprites/Energy09.png',
      xCount: 5,
      yCount: 4,
      animSeconds: 1,
    },
    [Name.enum.Energy10]: {
      imgSrc: '/sprites/Energy10.png',
      xCount: 5,
      yCount: 7,
      animSeconds: 1,
    },
    [Name.enum.Energy11]: {
      imgSrc: '/sprites/Energy11.png',
      xCount: 5,
      yCount: 5,
      animSeconds: 1,
    },
    [Name.enum.Energy12]: {
      imgSrc: '/sprites/Energy12.png',
      xCount: 10,
      yCount: 3,
      animSeconds: 1,
    },
    [Name.enum.Field01]: {
      imgSrc: '/sprites/Field01.png',
      xCount: 5,
      yCount: 6,
      animSeconds: 1,
    },
    [Name.enum.Field02]: {
      imgSrc: '/sprites/Field02.png',
      xCount: 5,
      yCount: 6,
      animSeconds: 1,
    },
    */
    [Name.enum.Flare01]: {
      imgSrc: '/sprites/Flare01.png',
      xCount: 5,
      yCount: 4,
      animSeconds: 1,
    },
    /*
    [Name.enum.Mantra01]: {
      imgSrc: '/sprites/Mantra01.png',
      xCount: 10,
      yCount: 3,
      animSeconds: 1,
    },
    */
    [Name.enum.Particles01]: {
      imgSrc: '/sprites/Particles01.png',
      xCount: 5,
      yCount: 6,
      animSeconds: 1,
    },
    [Name.enum.Particles02]: {
      imgSrc: '/sprites/Particles02.png',
      xCount: 5,
      yCount: 6,
      animSeconds: 1,
    },
    /*
    [Name.enum.Portal01]: {
      imgSrc: '/sprites/Portal01.png',
      xCount: 5,
      yCount: 3,
      animSeconds: 1,
    },
    */
    [Name.enum.Ring01]: {
      imgSrc: '/sprites/Ring01.png',
      xCount: 5,
      yCount: 4,
      animSeconds: 1,
    },
    /*
    [Name.enum.Ring02]: {
      imgSrc: '/sprites/Ring02.png',
      xCount: 5,
      yCount: 4,
      animSeconds: 1,
    },
    [Name.enum.Shield01]: {
      imgSrc: '/sprites/Shield01.png',
      xCount: 5,
      yCount: 4,
      animSeconds: 1,
    },
    [Name.enum.Shimmer01]: {
      imgSrc: '/sprites/FA01_06_Shimmer.png',
      xCount: 5,
      yCount: 12,
      animSeconds: 1,
    },
    [Name.enum.Simple10]: {
      imgSrc: '/sprites/Simple10.png',
      xCount: 5,
      yCount: 2,
      animSeconds: 1,
    },
    [Name.enum.Simple17]: {
      imgSrc: '/sprites/Simple17.png',
      xCount: 5,
      yCount: 2,
      animSeconds: 1,
    },
    [Name.enum.Starfish01]: {
      imgSrc: '/sprites/Starfish01.png',
      xCount: 5,
      yCount: 6,
      animSeconds: 1,
    },
    */
    [Name.enum.Swirl01]: {
      imgSrc: '/sprites/Swirl01.png',
      xCount: 5,
      yCount: 6,
      animSeconds: 1,
    },
    [Name.enum.Swirl02]: {
      imgSrc: '/sprites/Swirl02.png',
      xCount: 5,
      yCount: 6,
      animSeconds: 1,
    },
  };
}
