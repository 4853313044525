// import { Store } from 'vuex';
import { z } from 'zod';

import { Action } from '../Action';

// const StoreType: z.ZodType<typeof Store> = z.any();

export namespace EffectStandard {
  export const LogEventArgs = z.object({
    $store: z.any(), // StoreType,
    effectName: z.string().min(1),
  });

  export const CheckActionArgs = z.object({
    $store: z.any(), // StoreType,
    itemId: z.string().min(1),
  });

  export const SuccessArgs = z.object({
    $store: z.any(), // StoreType,
    effectData: z.optional(z.any()),
    effectResult: z.any(),
  });

  export const ErrorArgs = z.object({
    $store: z.any(), // StoreType,
  });

  export const logEvent = function({ $store, effectName }: z.infer<typeof LogEventArgs>) {
    if ($store && effectName) {
      $store.dispatch('logEvent', { name: 'effect_effect', params: { name: effectName } });
    }
  };

  export const checkAction = function({ $store, itemId }: z.infer<typeof CheckActionArgs>) {
    const action = $store.getters.getActionById(itemId);

    if (!action) {
      $store.dispatch('ui/addToast', {
        text: 'Action not found.',
        severity: 'warning',
        icon: ['fas', 'triangle-exclamation'],
      });

      return false;
    }

    return action;
  };

  export const isAction = function({ $store, itemId }: z.infer<typeof CheckActionArgs>) {
    return !!checkAction({ $store, itemId });
  };

  export const checkActionNotComplete = function({ $store, itemId }: z.infer<typeof CheckActionArgs>) {
    const item = checkAction({ $store, itemId });

    if (item) {
      const isItemComplete = $store.getters['actionsUser/isItemComplete'](item);

      if (isItemComplete) {
        $store.dispatch('ui/addToast', {
          text: 'This action is already complete.',
          severity: 'success',
          icon: ['fal', 'circle-check'],
        });

        return false;
      }
    }

    return item;
  };

  export const checkActionIsCompletable = function({ $store, itemId }: z.infer<typeof CheckActionArgs>) {
    const item = checkActionNotComplete({ $store, itemId });

    if (item && Action.isFrequencyRepeating(item.frequency)) {
      const isForVirtualDay = $store.getters['actionsUser/isRepeatingItemForVirtualDay'](item);

      if (!isForVirtualDay) {
        $store.dispatch('ui/addToast', {
          text: 'This action cannot be completed today.',
          severity: 'info',
          icon: ['fas', 'triangle-exclamation'],
        });

        return false;
      }
    }

    return !!item;
  };

  export const checkActionIsReminding = function({ $store, itemId }: z.infer<typeof CheckActionArgs>) {
    const isReminding = !!$store.getters['actionsUser/isItemReminding']({ id: itemId });

    if (!isReminding) {
      $store.dispatch('ui/addToast', {
        text: 'This action does not have an active reminder.',
        severity: 'info',
        icon: ['fas', 'triangle-exclamation'],
      });

      return false;
    }

    return isReminding;
  };

  // these affect position in effects-select
  export const EffectName = z.enum([
    'Custom',
    'Completion',
    'Focus',
    'Resolve',
    'Postponement',
    'Division',
    'Forgiveness',
    'Recovery',
    'ReminderDelay',
  ]);

  export const Target = z.enum([
    'AppInterface',
    'ActionOnce',
    'ActionRepeating',
    'ActionReminding',
    'ActionsPlan',
  ]);

  export const Model = z.object({
    name: EffectName,
    label: z.string().min(1),
    verb: z.string().min(1),
    verbPast: z.string().min(1).optional(),
    targets: z.optional(z.array(Target)),
    icon: z.array(z.string().min(1)),
    color: z.string().min(1),
    description: z.optional(z.string().min(1)),
    descriptionInEffect: z.optional(z.string().min(1)),

    confirmWithProps: z.optional(
      z.object({
        message: z.string().min(1),
        header: z.string().min(1),
      }),
    ),

    effectCheck: z.optional(
      z.function()
        .args(CheckActionArgs)
        .returns(z.boolean()),
    ),

    getEffectData: z.optional(
      z.function()
        .args(
          z.object({
            $store: z.any(), // StoreType,
            props: z.optional(z.any()),
          }),
        )
        .returns(z.promise(z.any())),
    ),

    effect: z.optional(
      z.function()
        .args(
          z.object({
            $store: z.any(), // StoreType,
            effectData: z.optional(z.any()),
            effect: z.optional(z.any()),
          }),
        )
        .returns(z.promise(z.any())),
    ),

    effectRemove: z.optional(
      z.function()
        .args(
          z.object({
            $store: z.any(), // StoreType,
            effectData: z.optional(z.any()),
          }),
        )
        .returns(z.promise(z.any())),
    ),

    success: z.optional(
      z.function()
        .args(SuccessArgs)
    ),

    error: z.optional(
      z.function()
        .args(ErrorArgs)
    ),
  });
}
