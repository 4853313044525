import _ from 'lodash';
import {
  collection,
  deleteDoc as fbDeleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  onSnapshotsInSync,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import debug from './debug';
const dbg = debug('datajs');

// import { isDate, getTimestampFromDateOrNull } from '../api/src/models/util';

/*
const getValueForLocalDb = (val) => {
  if (isDate(val)) {
    return getTimestampFromDateOrNull(val);
  } else {
    return _.cloneDeep(val);
  }
};
*/

/*
const getRecursiveValueForLocalDb = (val) => {
  // allow 2 levels of coercion for some known sub-collections that contain dates
  if (Array.isArray(val)) {
    return val.map((val2) => {
      const returnVal = getValueForLocalDb(val2);
      const isObject = typeof returnVal === 'object' && returnVal !== null;

      if (isObject) {
        Object.keys(returnVal).forEach((k3) => {
          returnVal[k3] = getValueForLocalDb(returnVal[k3]);
        });
      }

      return returnVal;
    });
  } else {
    return getValueForLocalDb(val);
  }
};
*/

/*
const LocalDb = {
  bulkInsert: async ($storeContext, table, docs = []) => {
    dbg('LocalDb:bulkInsert', table, docs);
    const writeDocs = [];

    docs.forEach((doc) => {
      const writeData = {};

      Object.keys(doc).forEach((k) => {
        writeData[k] = getRecursiveValueForLocalDb(doc[k]);
      });

      writeDocs.push(writeData);
    });

    return await $storeContext.$db[table.toLowerCase()].bulkInsert(writeDocs);
  },

  upsertDoc: async ($storeContext, table, id, data) => {
    if (!id) {
      dbg('LocalDb:upsertDoc', 'invalid id', table, id, data);
      return;
    }

    dbg('LocalDb:upsertDoc', table, id, data);
    const writeData = {};

    Object.keys(data).forEach((k) => {
      writeData[k] = getRecursiveValueForLocalDb(data[k]);
    });

    return await $storeContext.$db[table.toLowerCase()].upsert({
      id,
      ...writeData,
    });
  },

  bulkRemove: async ($storeContext, table, ids = []) => {
    dbg('LocalDb:bulkRemove', table, ids);
    const docsMap = await $storeContext.$db[table.toLowerCase()].findByIds(ids);

    if (docsMap && docsMap.size > 0) {
      return await $storeContext.$db[table.toLowerCase()].bulkRemove(docsMap.keys());
    }
  },

  removeDoc: async ($storeContext, table, id) => {
    if (!id) {
      dbg('LocalDb:removeDoc', 'invalid id', table, id);
      return;
    }

    dbg('LocalDb:removeDoc', table, id);
    const dbDoc = await $storeContext.$db[table.toLowerCase()].findOne({
      selector: {
        id,
      },
    }).exec();

    if (dbDoc) {
      await dbDoc.remove();
    }
  },

  getDoc: async ($storeContext, table, id) => {
    if (!id) {
      dbg('LocalDb:getDoc', 'invalid id', table, id);
      return;
    }

    dbg('LocalDb:getDoc', table, id);
    let doc;

    const dbDoc = await $storeContext.$db[table.toLowerCase()].findOne({
      selector: {
        id,
      },
    }).exec();

    if (dbDoc) {
      doc = {
        id,
        ...dbDoc.toJSON(),
      };
    }

    return doc;
  },

  getDocsForUserId: async ($storeContext, table, userId) => {
    if (!userId) {
      dbg('LocalDb:getDocsForUserId', 'invalid userId', table, userId);
    }

    dbg('LocalDb:getDocsForUserId', table, userId);
    let docs;

    const dbDocs = await $storeContext.$db[table.toLowerCase()].find({
      selector: {
        userId,
      },
    }).exec();

    if (Array.isArray(dbDocs)) {
      docs = [];

      dbDocs.forEach((d) => {
        docs.push(d.toJSON());
      });
    }

    return docs;
  },
};
*/

const getRefForValue = (db, refCollection, id) => {
  return db.doc(`${refCollection}/${id}`);
};

const getRefsFromArray = (db, refCollection, refIds) => {
  return refIds.map(id => getRefForValue(db, refCollection, id));
};

const snapshotsInSyncCallbacks = {};
let snapshotsInSyncUnsub;

const RemoteDb = {
  getWriteData: (data, keyToTableMap) => {
    let returnData = data;

    if (data) {
      dbg('RemoteDb:getWriteData', data, keyToTableMap);
      returnData = {};

      Object.keys(data).forEach((k) => {
        let val = data[k];

        if (keyToTableMap && keyToTableMap[k] && val) {
          val = Array.isArray(val)
            ? getRefsFromArray(getFirestore(), keyToTableMap[k], val)
            : getRefForValue(getFirestore(), keyToTableMap[k], val);
        } else if (typeof val === 'undefined') {
          val = null;
        }

        returnData[k] = val;
      });
    }

    return returnData;
  },

  upsertDoc: (table, id, data) => {
    dbg('RemoteDb:upsertDoc', table, id, data);
    const writeData = { ...data };
    delete writeData.id;

    // if id is undefined, this will create a new doc with a new id
    let docRef;

    if (id) {
      docRef = doc(getFirestore(), table, id);
    } else {
      docRef = doc(collection(getFirestore(), table));
    }

    // not awaiting success in case we are offline
    setDoc(docRef, writeData, { merge: true });

    return {
      ...data,
      id: docRef.id,
    }
  },

  deleteDoc: (table, id) => {
    if (!id) {
      dbg('RemoteDb:deleteDoc', 'invalid id', table, id);
      return;
    }

    dbg('RemoteDb:deleteDoc', table, id);
    // not awaiting success in case we are offline
    fbDeleteDoc(doc(getFirestore(), table, id));
  },

  getDocsForUserId: async (table, userId) => {
    if (!userId) {
      dbg('RemoteDb:getDocsForUserId', 'invalid userId', table, userId);
      return;
    }

    dbg('RemoteDb:getDocsForUserId', table, userId);
    const data = [];

    const q = query(collection(getFirestore(), table), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnapshot) => {
      data.push({
        ...docSnapshot.data(),
        id: docSnapshot.id,
      });
    });

    return data;
  },

  getDoc: async (table, id) => {
    dbg('RemoteDb:getDoc', table, id);

    if (!id) {
      return;
    }

    let data;
    const docRef = doc(getFirestore(), table, id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot && docSnapshot.exists()) {
      data = {
        ...docSnapshot.data(),
        id: docSnapshot.id,
      };
    }

    return data;
  },

  bindToDoc: (table, id, onSnapshotCB) => {
    if (!_.isFunction(onSnapshotCB)) {
      dbg('RemoteDb:bindToDoc', 'invalid callback for onSnapshot', table, id, onSnapshotCB);
      return;
    }

    if (!id) {
      dbg('RemoteDb:bindToDoc', 'invalid id', table, id);
      return;
    }

    dbg('RemoteDb:bindToDoc', table, id);
    return onSnapshot(doc(getFirestore(), table, id), (docSnapshot) => {
      dbg('RemoteDb:bindToDoc', 'onSnapshot', table, id);
      let data;

      if (docSnapshot.exists()) {
        data = {
          ...docSnapshot.data(),
          id: docSnapshot.id,
        };
      }

      onSnapshotCB(data);
    });
  },

  bindToDocsForUserId: (table, userId, filters = [], onSnapshotCB) => {
    if (!_.isFunction(onSnapshotCB)) {
      dbg('RemoteDb:bindToDocsForUserId', 'invalid callback for onSnapshot', table, userId, onSnapshotCB);
      return;
    }

    if (!userId) {
      dbg('RemoteDb:bindToDocsForUserId', 'invalid userId', table, userId);
      return;
    }

    dbg('RemoteDb:bindToDocsForUserId', table, userId);
    const queryParams = [
      collection(getFirestore(), table),
      where('userId', '==', userId),
    ];
    const filtersStr = filters.map(f => Object.values(f).join('')).join(' && ');
    filters.forEach((f) => {
      queryParams.push(where(f.field, f.operator, f.value));
    });
    const q = query.apply(this, queryParams);
    return onSnapshot(q, (querySnapshot) => {
      dbg('RemoteDb:bindToDocsForUserId', 'onSnapshot', table, userId, filtersStr);
      const docs = [];
      const docsRemoved = [];

      querySnapshot.docChanges().forEach((docChangeSnapshot) => {
        if (['added', 'modified'].includes(docChangeSnapshot.type) && docChangeSnapshot.doc.exists()) {
          docs.push({
            ...docChangeSnapshot.doc.data(),
            id: docChangeSnapshot.doc.id,
          });
        } else {
          docsRemoved.push({
            id: docChangeSnapshot.doc.id,
          });
        }
      });

      onSnapshotCB({
        docs,
        docsRemoved,
      });
    });
  },

  addSnapshotsInSyncCallback: (ref, callback) => {
    if (_.isFunction(callback)) {
      dbg('RemoteDb:addSnapshotsInSyncCallback', ref);
      snapshotsInSyncCallbacks[ref] = callback;
    }

    if (!snapshotsInSyncUnsub) {
      snapshotsInSyncUnsub = onSnapshotsInSync(getFirestore(), () => {
        dbg('RemoteDb:onSnapshotsInSync', 'fire all callbacks');
        Object.values(snapshotsInSyncCallbacks).forEach(cb => cb());
      });
    }
  },

  removeSnapshotsInSyncCallback: (ref) => {
    if (_.isFunction(snapshotsInSyncCallbacks[ref])) {
      dbg('RemoteDb:removeSnapshotsInSyncCallback', ref);
      delete snapshotsInSyncCallbacks[ref];
    }

    if (Object.keys(snapshotsInSyncCallbacks).length < 1 && _.isFunction(snapshotsInSyncUnsub)) {
      dbg('RemoteDb:removeSnapshotsInSyncCallback', 'snapshotsInSyncUnsub');
      snapshotsInSyncUnsub();
      snapshotsInSyncUnsub = undefined;
    }
  },
};

export const upsertDoc = (model, table, id, data) => {
  // const { remoteKeyToTableMap } = options || {};
  let writeData = data;

  if (model) {
    const result = model.safeParse(data);

    if (!result.success) {
      dbg('data:upsertDoc', 'PARSE FAILED', table, id, result.error);
      return;
    }

    writeData = result.data;
  }

  // we need to ensure an id before upserting into the local db
  // const remoteData = RemoteDb.getWriteData(result.data, remoteKeyToTableMap);
  const docData = RemoteDb.upsertDoc(table, id, writeData);

  return { ...writeData, id: docData.id };
};

export const deleteDoc = (table, id) => {
  RemoteDb.deleteDoc(table, id);
};

const isBindInitialized = {};
// const isRemoteSnapshotInitialized = {};

export const bindToDoc = (model, table, id, { onInit, onUpdate, onUpdateEmpty }) => {
  let unsub = () => {};
  const ref = `${table}/${id}`;
  dbg('bindToDoc', ref);

  const handleUpdate = (doc, isRemoteUpdate = true) => {
    dbg('bindToDoc', 'handleUpdate', ref, doc, isRemoteUpdate);

    // it's possible the document doesn't exist, and that's ok -- don't parse it
    const result = typeof doc === 'undefined'
      ? { success: true, data: undefined }
      : model.safeParse(doc);

    if (!result.success) {
      dbg('bindToDoc', 'PARSE FAILED', table, id, result.error);
    }

    if (_.isFunction(onUpdate) && result.data) {
      onUpdate({ data: result.data, isRemoteUpdate });
    }

    if (_.isFunction(onUpdateEmpty) && !result.data) {
      onUpdateEmpty({ isRemoteUpdate });
    }

    if (_.isFunction(onInit) && !isBindInitialized[ref]) {
      isBindInitialized[ref] = true;
      onInit({ isEmpty: !result.data, isRemoteUpdate });
    }
  };

  const remoteUnsub = RemoteDb.bindToDoc(table, id, (remoteDoc) => {
    handleUpdate(remoteDoc);
  });

  unsub = () => {
    remoteUnsub();
  };

  return unsub;
};

export const bindToDocsForUserId = (
  model,
  table,
  userId,
  {
    // Data will be combined into one virtual bind
    remoteQueries = [[]],
    /* When listening to multiple queries, these callbacks ensure that a change has finished
     * propagating to all relevant listeners (all cases of onSnapshot have been called).
     */
    onInit,
    onUpdate,
  },
) => {
  const ref = `${table}/${userId}[]`;
  dbg('bindToDocsForUserId', ref);

  let queuedDocsUpdated = [];
  let queuedDocsRemoved = [];

  const handleUpdate = (isRemoteUpdate = true) => {
    dbg('bindToDocsForUserId', 'handleUpdate', ref, queuedDocsUpdated, queuedDocsRemoved);
    let docsUpdatedValid = [];

    if (Array.isArray(queuedDocsUpdated)) {
      docsUpdatedValid = queuedDocsUpdated
        .map((d) => {
          let docValid;

          try {
            // any document in the array should be defined, so it's ok to parse
            docValid = model.parse(d);
          } catch (e) {
            dbg('bindToDocsForUserId', 'PARSE FAILED', ref, d, e);
          }

          return docValid;
        })
        .filter(d => Boolean(d));
    }

    let docsRemovedValid = [];

    if (Array.isArray(queuedDocsRemoved)) {
      /* don't remove docs that also had an update (moved from one query to another)
       * don't use docsUpdatedValid -- if a doc was updated, but the parse failed, we shouldn't remove it!
       * this also assumes the doc has an id
       */
      docsRemovedValid = queuedDocsRemoved.filter(d => !queuedDocsUpdated.find(d2 => d.id === d2.id));
    }

    if (_.isFunction(onUpdate) && (docsUpdatedValid.length > 0 || docsRemovedValid.length > 0)) {
      dbg('bindToDocsForUserId', 'onUpdate', ref, docsUpdatedValid, docsRemovedValid);
      onUpdate({ docs: docsUpdatedValid, docsRemoved: docsRemovedValid, isRemoteUpdate });
    }

    queuedDocsUpdated = [];
    queuedDocsRemoved = [];

    if (_.isFunction(onInit) && !isBindInitialized[ref]) {
      isBindInitialized[ref] = true;
      onInit({ isRemoteUpdate });
    }
  };

  const enqueueUpdate = (changes) => {
    dbg('bindToDocsForUserId', 'enqueueUpdate', ref, changes);

    if (Array.isArray(changes.docs)) {
      changes.docs.forEach((d) => {
        // check for duplicates
        // this also assumes the doc has an id
        if (!queuedDocsUpdated.find(d2 => d.id === d2.id)) {
          queuedDocsUpdated.push(d);
        }
      });
    }

    if (Array.isArray(changes.docsRemoved)) {
      changes.docsRemoved.forEach((d) => {
        // check for duplicates
        // this also assumes the doc has an id
        if (!queuedDocsRemoved.find(d2 => d.id === d2.id)) {
          queuedDocsRemoved.push(d);
        }
      });
    }
  };

  RemoteDb.addSnapshotsInSyncCallback(ref, handleUpdate);

  const remoteUnsubs = [];
  remoteUnsubs.push(() => RemoteDb.removeSnapshotsInSyncCallback(ref));

  // This needs to run at least once for any bind to occur. The child query can be empty, though.
  remoteQueries.forEach((q) => {
    // If no documents match the query, the callback won't run.
    const unsub = RemoteDb.bindToDocsForUserId(table, userId, q, ({ docs, docsRemoved }) => {
      dbg('bindToDocsForUserId', 'onSnapshot', ref, docs, docsRemoved);

      if (Array.isArray(docs) && Array.isArray(docsRemoved)) {
        enqueueUpdate({ docs, docsRemoved });
      }
    });

    remoteUnsubs.push(unsub);
  });

  return () => {
    remoteUnsubs.forEach(unsub => unsub());
  };
};
