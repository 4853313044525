import { wrapGetters } from '../lib/util';
import { ActionsPlan } from '../api/src/models/ActionsPlan';

export const state = () => ({});

export const mutations = {};

export const getters = wrapGetters('conditions', {
  true: () => true,
  hasActions: (_state, _getters, rootState) => {
    return rootState.actionsUser.all.length > 0;
  },
  hasActionsIncomplete: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/itemsIncomplete'].length > 0;
  },
  hasActionsComplete: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/itemsComplete'].length > 0;
  },

  actionsRepeatingCount: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/itemsRepeating'].length;
  },
  actionsRepeatingVirtualDay: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/itemsRepeatingOnVirtualDay'];
  },
  hasActionsRepeatingVirtualDay: (_state, getters) => {
    return Array.isArray(getters.actionsRepeatingVirtualDay) && getters.actionsRepeatingVirtualDay.length > 0;
  },
  hasActionsRepeatingVirtualDay3: (_state, getters) => {
    return Array.isArray(getters.actionsRepeatingVirtualDay) && getters.actionsRepeatingVirtualDay.length >= 3;
  },
  hasActionsRepeatingVirtualDay5: (_state, getters) => {
    return Array.isArray(getters.actionsRepeatingVirtualDay) && getters.actionsRepeatingVirtualDay.length >= 5;
  },

  actionsWithTimeframe: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['actionsUser/itemsIncomplete'].filter(i => rootGetters['actionsUser/hasTimeframe'](i));
  },
  hasActionsWithTimeframe: (_state, getters) => {
    return Array.isArray(getters.actionsWithTimeframe) && getters.actionsWithTimeframe.length > 0;
  },

  actionsWithTimeframeFuture: (_state, getters, _rootState, rootGetters) => {
    return getters.hasActionsWithTimeframe
      ? rootGetters['actionsUser/itemsIncomplete']
        .filter((i) => {
          const expiresAt = rootGetters['actionsUser/getItemExpiresAt'](i);
          return expiresAt && expiresAt.isAfter(rootGetters.virtualDay.add(1, 'day'));
        })
      : [];
  },
  hasActionsWithTimeframeFuture: (_state, getters) => {
    return Array.isArray(getters.actionsWithTimeframeFuture) && getters.actionsWithTimeframeFuture.length > 0;
  },
  hasActionsWithTimeframeFuture3: (_state, getters) => {
    return Array.isArray(getters.actionsWithTimeframeFuture) && getters.actionsWithTimeframeFuture.length >= 3;
  },
  hasActionsWithTimeframeFuture5: (_state, getters) => {
    return Array.isArray(getters.actionsWithTimeframeFuture) && getters.actionsWithTimeframeFuture.length >= 5;
  },

  actionsPlanAnyValueDaysCount: (_state, _getters, _rootState, rootGetters) => {
    let count = 0;

    rootGetters['actionsUser/valueTotalsRecent'].forEach((v) => {
      if (v > 0) {
        count += 1;
      }
    });

    return count;
  },

  actionsPlanMinValueDaysCount: (_state, _getters, _rootState, rootGetters) => {
    let count = 0;

    rootGetters['actionsUser/valueTotalsRecent'].forEach((v) => {
      if (v >= ActionsPlan.MinValueEasy) {
        count += 1;
      }
    });

    return count;
  },

  actionsPlanMinValueDaysWithVirtualDayCount: (_state, getters, _rootState, rootGetters) => {
    const hasToday = rootGetters['actionsPlan/isCompleteMin'];
    return getters.actionsPlanMinValueDaysCount + (hasToday ? 1 : 0);
  },

  valueTotalsRecentAscending: (_state, _getters, _rootState, rootGetters) => {
    const totals = [...rootGetters['actionsUser/valueTotalsRecent']];
    totals.sort((a, b) => a - b);
    return totals;
  },

  isEligibleForMilestones: (_state, getters, rootState) => {
    return getters.actionsPlanAnyValueDaysCount >= rootState.recentDaysCount;
  },

  // mission milestones:

  // reached min
  didReachActionsPlanMinValue: (_state, getters, _rootState, rootGetters) => {
    return getters.isEligibleForMilestones && rootGetters['actionsPlan/isCompleteMin'];
  },

  // surpassed lowest day in the past week
  didReachActionsPlanRecentLowest: (_state, getters, _rootState, rootGetters) => {
    const valueTotal = rootGetters['actionsUser/valueTotalVirtualDay'];
    const lowest = getters.valueTotalsRecentAscending[0];

    return getters.isEligibleForMilestones && valueTotal > lowest;
  },

  // surpassed third-lowest day in the past week
  didReachActionsPlanRecentThirdLowest: (_state, getters, _rootState, rootGetters) => {
    const valueTotal = rootGetters['actionsUser/valueTotalVirtualDay'];
    const thirdLowest = getters.valueTotalsRecentAscending[2];

    return getters.isEligibleForMilestones && valueTotal > thirdLowest;
  },

  // reached weekly avg
  didReachActionsPlanRecentAverage: (_state, getters, _rootState, rootGetters) => {
    const valueTotal = rootGetters['actionsUser/valueTotalVirtualDay'];
    const avg = rootGetters['actionsUser/valueTotalRecentAverage'];

    return getters.isEligibleForMilestones && valueTotal > avg;
  },

  // surpassed highest day in the past week
  didReachActionsPlanRecentHigh: (_state, getters, _rootState, rootGetters) => {
    const valueTotal = rootGetters['actionsUser/valueTotalVirtualDay'];
    const highest = getters.valueTotalsRecentAscending[getters.valueTotalsRecentAscending.length - 1];

    return getters.isEligibleForMilestones && valueTotal > highest;
  },

  actionsPlanMilestones: (_state, getters) => {
    if (!getters.isEligibleForMilestones) {
      return [];
    }

    const milestones = [
      {
        isReached: getters.didReachActionsPlanMinValue,
        description: 'Achieved mission minimum value completed',
      },
      {
        isReached: getters.didReachActionsPlanRecentLowest,
        description: 'Surpassed lowest value completed recently',
      },
      {
        isReached: getters.didReachActionsPlanRecentThirdLowest,
        description: 'Surpassed third-lowest value completed recently',
      },
      {
        isReached: getters.didReachActionsPlanRecentAverage,
        description: 'Surpassed average value completed recently',
      },
      {
        isReached: getters.didReachActionsPlanRecentHigh,
        description: 'Achieved highest value completed recently',
      },
    ];

    let indexReached = -1;

    return milestones.map((m, i) => {
      const isReached = m.isReached && indexReached === i - 1;

      if (isReached) {
        indexReached = i;
      }

      return {
        ...m,
        isReached,
      };
    });
  },

  ratings: (_state, getters, _rootState, rootGetters) => {
    const getValueInBounds = (leftVal = 0, rightVal = 1, val = 0) => {
      return Math.max(
        -1,
        Math.min(
          1,
          ((val / (leftVal + rightVal)) * 2) - 1,
        ),
      );
    };

    return [
      {
        left: {
          label: 'spontaneous'
        },
        right: {
          label: 'calculated',
        },
        value: getValueInBounds(0, 10, getters.actionsWithTimeframeFuture.length),
      },
      {
        left: {
          label: 'fluid'
        },
        right: {
          label: 'rigid',
        },
        value: getValueInBounds(0, 30, getters.actionsRepeatingCount),
      },
      {
        left: {
          label: 'narrow'
        },
        right: {
          label: 'broad',
        },
        value: getValueInBounds(0, 10, Math.max(0, rootGetters.interestsWithRecentActionsCompleted.length - 1)),
      },
      {
        left: {
          label: 'certain'
        },
        right: {
          label: 'intrigued',
        },
        value: getValueInBounds(0, 10, Math.max(0, rootGetters.ideasFresh.length)),
      },
    ];
  },

  affinity: (_state, getters) => {
    const archetypes = [
      // Begin basic
      {
        label: 'The Visionary',
        description: 'conjures a bright future',
        descriptionBuff: 'actions that are scheduled have more value',
        img: 'TheVisionary.png',
        ratingsMap: [
          0.75,
          -1, // doesn't matter
          -1,
          -1,
        ]
      },
      {
        label: 'The Machine',
        description: 'moves ahead with purpose and precision',
        descriptionBuff: 'repeating actions have more value',
        img: 'TheMachine.png',
        ratingsMap: [
          -1, // doesn't matter
          0.75,
          -1,
          -1,
        ]
      },
      {
        label: 'The Savant',
        description: 'finds the path before it is traveled',
        descriptionBuff: 'actions that are scheduled or repeating have slightly more value',
        img: 'TheSavant.png',
        ratingsMap: [
          0.5,
          0.5,
          -1, // doesn't matter
          -1,
        ]
      },
      {
        label: 'The Explorer',
        description: 'embraces the vastness of space',
        descriptionBuff: 'the first action completion of the day for each interest is worth more',
        img: 'TheExplorer.png',
        ratingsMap: [
          -1, // doesn't matter
          -1,
          0.75,
          -1,
        ]
      },
      {
        label: 'The Dreamer',
        description: 'considers all possibilities',
        descriptionBuff: 'actions that started as ideas are worth more',
        img: 'TheDreamer.png',
        ratingsMap: [
          -1, // doesn't matter
          -1,
          -1,
          0.75,
        ]
      },
      {
        label: 'The Scout',
        description: 'finds their way through experimentation',
        descriptionBuff: '',
        img: 'TheScout.png',
        ratingsMap: [], // default affinity
      },
    ];

    return archetypes.find((a) => {
      let passes = true;

      a.ratingsMap.forEach((val, i) => {
        passes = passes && getters.ratings[i].value >= val;
      });

      return passes;
    });
  },
});

export const actions = {};
