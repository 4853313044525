
export default {
  name: 'InterestsTextDisplay',
  props: {
    interestIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    interestNames() {
      const names = [];

      this.interestIds.forEach((i, ii) => {
        const interest = this.$store.getters['interests/getInterestById'](i);

        if (interest) {
          let n = interest.icon ? `${interest.icon} ${interest.name}` : interest.name;

          if (ii < this.interestIds.length - 1) {
            n = `${n},`;
          }

          names.push(n);
        }
      });

      return names;
    },
  },
};
