// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Mask } from 'mixel';

export namespace EffectCustomSprite {
  export const Helmet: Mask = {
    width: 15,
    height: 11,
    mirrorX: true,
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 1, 1, 2, 1, 1, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 1, 1, 2, 1, 1, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, -2, -2, -2, -2, -2, 2, 0, 0, 0, 0,
      0, 0, 0, 1, 2, 0, 0, 1, 0, 0, 2, 1, 0, 0, 0,
      0, 0, 1, 2, 2, 1, 2, 2, 2, 1, 2, 2, 1, 0, 0,
      0, 1, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 1, 0,
      0, 1, 0, 0, 1, 2, 1, 2, 1, 2, 1, 0, 0, 1, 0,
      0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
  };

  export const Robot: Mask = {
    width: 9,
    height: 13,
    mirrorX: true,
    data: [
      0, 0, 0, 0, 1, 0, 0, 0, 0,
      0, 1, 1, 1, 1, 1, 1, 1, 0,
      0, 1, 2, 2, 2, 2, 2, 1, 0,
      0, 0, 1, 2, 2, 2, 1, 0, 0,
      1, 0, 0, 1, 2, 1, 0, 0, 1,
      1, 1, 1, 2, 2, 2, 1, 1, 1,
      0, 1, 1, 2, 2, 2, 1, 1, 0,
      0, 0, 0, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 2, 2, 2, 0, 0, 0,
      0, 1, 2, 2, 0, 2, 2, 1, 0,
      0, 2, 0, 0, 0, 0, 0, 2, 0,
      0, 2, 0, 0, 0, 0, 0, 2, 0,
      1, 2, 1, 0, 0, 0, 1, 2, 1
    ],
  };

  export const AlienAlphabet: Mask = {
    width: 6,
    height: 7,
    data: [
      0, 0, 0, 0, 0, 0,
      0, -1, 1, 1, 1, 0,
      0, 1, 0, 2, 1, 0,
      0, 1, 1, 1, -1, 0,
      0, 1, 2, 0, 2, 0,
      0, -1, 1, 1, 1, 0,
      0, 0, 0, 0, 0, 0,
    ],
  };

  export const Humanoid: Mask = {
    width: 15,
    height: 17,
    mirrorX: true,
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, 1, 2, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 1, 2, 2, 2, 2, 2, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 1, 2, 2, 2, 1, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 0, 2, 2, 2, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 0, 1, 2, 1, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 0, 1, 2, 1, 0, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 1, 0, 2, 2, 2, 0, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, 0, 2, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, 0, 2, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, 0, 2, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, 0, 2, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 1, 2, 0, 2, 1, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
  };

  export const Alien: Mask = {
    width: 15,
    height: 19,
    mirrorX: true,
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0,
      0, 0, 0, 1, 1, 2, 2, 1, 2, 2, 1, 1, 0, 0, 0,
      0, 0, 0, 1, 2, 1, 1, 1, 1, 1, 2, 1, 0, 0, 0,
      0, 0, 0, 0, 0, 2, 1, 0, 1, 2, 0, 0, 0, 0, 0,
      0, 0, 0, 1, 0, 0, 2, 1, 2, 0, 0, 1, 0, 0, 0,
      0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 0,
      0, 0, 0, 2, 1, 2, 2, 1, 2, 2, 1, 2, 0, 0, 0,
      0, 0, 2, 1, 0, 1, 2, 1, 2, 1, 0, 1, 2, 0, 0,
      0, 1, 2, 0, 0, 1, 2, 2, 2, 1, 0, 0, 2, 1, 0,
      0, 2, 0, 0, 0, 1, 2, 2, 2, 1, 0, 0, 0, 2, 0,
      0, 2, 0, 0, 0, 2, 1, 2, 1, 2, 0, 0, 0, 2, 0,
      0, 2, 0, 0, 0, 2, 1, 0, 1, 2, 0, 0, 0, 2, 0,
      1, 0, 1, 0, 0, 2, 0, 0, 0, 2, 0, 0, 1, 0, 1,
      0, 0, 0, 0, 2, 1, 0, 0, 0, 1, 2, 0, 0, 0, 0,
      0, 0, 0, 0, 2, 1, 0, 0, 0, 1, 2, 0, 0, 0, 0,
      0, 0, 0, 1, 1, 1, 0, 0, 0, 1, 1, 1, 0, 0, 0,
      0, 0, 0, 1, 2, 0, 0, 0, 0, 0, 2, 1, 0, 0, 0,
      0, 0, 1, 2, 1, 0, 0, 0, 0, 0, 1, 2, 1, 0, 0
    ],
  };

  export const Mecha: Mask = {
    width: 16,
    height: 14,
    mirrorX: true,
    data: [
      0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 1, 1, 2, -1, -1, 2, 1, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 1, -1, 2, -1, -1, 2, -1, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, -1, -1, 2, 0, 0, 0, 0, 0, 0,
      0, 0, 1, 1, 1, 1, 2, -1, -1, 2, 1, 1, 1, 1, 0, 0,
      0, 1, 1, 2, 2, 1, 2, -1, -1, 2, 1, 2, 2, 1, 1, 0,
      0, 0, 1, 1, 0, 1, 1, 2, 2, 1, 1, 0, 1, 1, 0, 0,
      0, 0, 0, 0, 1, 1, 1, 2, 2, 1, 1, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 1, 1, 1, -2, -2, 1, 1, 1, 0, 0, 0, 0,
      0, 0, 0, 0, -1, 1, 0, 0, 0, 0, 1, -1, 0, 0, 0, 0,
      0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0,
      0, 0, 0, 1, 2, 1, 0, 0, 0, 0, 1, 2, 1, 0, 0, 0,
      0, 0, 0, 1, 2, 1, 0, 0, 0, 0, 1, 2, 1, 0, 0, 0,
      0, 0, 0, -1, 2, -2, 0, 0, 0, 0, -2, 2, -1, 0, 0, 0,
    ],
  };

  export const Castle: Mask = {
    width: 15,
    height: 18,
    mirrorX: true,
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 2, 1, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, -2, -2, 1, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, -2, 2, -2, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, -2, 1, -2, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 1, 0, -2, 2, -2, 0, 0, 0, 0, 0, 0, 0,
      0, 1, 0, 2, 0, -2, 1, -2, 0, 1, 0, 0, 0, 0, 0,
      0, 1, 0, 2, 0, -2, 2, -2, 0, 1, 0, 1, 0, 0, 0,
      0, 2, 0, 2, 1, -2, 0, -2, 1, 2, 1, 2, 0, 0, 0,
      0, 2, 1, 2, 1, -2, 2, -2, 1, 2, 1, 2, 0, 0, 0,
      0, 2, 1, 2, 2, -2, -2, -2, 2, 2, 1, 2, 0, 0, 0,
      0, 2, 2, 2, 1, 1, 1, 1, 1, 2, 2, 2, 0, 0, 0,
      0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0,
      0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
  };

  export const House: Mask = {
    width: 15,
    height: 10,
    mirrorX: true,
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 1, 2, 2, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 1, -2, -2, 2, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, -2, 2, -2, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, -2, 1, -2, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 1, 0, -2, 2, -2, 0, 0, 0, 0, 0, 0, 0,
      0, 1, 0, 2, 0, -2, 1, -2, 0, 1, 0, 0, 0, 0, 0
    ],
  };

  export const Spaceship: Mask = {
    width: 6,
    height: 12,
    mirrorX: true,
    data: [
      0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 1, 1,
      0, 0, 0, 0, 1, -1,
      0, 0, 0, 1, 1, -1,
      0, 0, 0, 1, 1, -1,
      0, 0, 1, 1, 1, -1,
      0, 1, 1, 1, 2, 2,
      0, 1, 1, 1, 2, 2,
      0, 1, 1, 1, 2, 2,
      0, 1, 1, 1, 1, -1,
      0, 0, 0, 1, 1, 1,
      0, 0, 0, 0, 0, 0
    ],
  };

  export const Axe: Mask = {
    width: 16,
    height: 8,
    mirrorY: true,
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0,
      0, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0,
    ],
  };

  export const Dragon: Mask = {
    width: 12,
    height: 12,
    data: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0,
      0, 0, 0, 1, 1, 2, 2, 1, 1, 0, 0, 0,
      0, 0, 1, 1, 1, 2, 2, 1, 1, 1, 0, 0,
      0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0,
      0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0,
      0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0,
      0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0,
      0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0,
      0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0,
      0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
  };
}
