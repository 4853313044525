import Vue from 'vue';
import _ from 'lodash';
import { z } from 'zod';

import { wrapGetters } from '../lib/util';

import { App } from '../api/src/models/App';
import { Entitlement } from '../api/src/models/Entitlement';

const ToastType = z.enum([
  'Default',
  'ActionCompletion',
  'ResourceGrant',
  'ResourceUse',
]);

const ToastSeverity = z.enum([
  'primary',
  'secondary',
  'info',
  'help',
  'success',
  'warning',
  'danger',
]);

const ToastModel = z.object({
  id: z.preprocess(
    () => `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
    z.string().min(1),
  ),
  type: ToastType.default(ToastType.enum.Default),
  text: z.string().min(1),
  conceptName: App.Concept.ConceptName.nullable().default(null),
  icon: z.array(z.string()).nullable().default(['fas', 'circle-info']),
  payload: z.unknown().default(null),
  severity: ToastSeverity.default(ToastSeverity.enum.primary),
  isDismissable: z.boolean().default(true),
  autoDismissAfter: z.number().min(1).nullable().default(5000),
});

const MessageType = {
  AppUpdate: 'appUpdate',
  Focus: 'focus',
  Reminder: 'reminder',
  Subscribe: 'subscribe',
};

const MessageTypeSingular = [
  MessageType.AppUpdate,
  MessageType.Focus,
  MessageType.Subscribe,
];

export const state = () => ({
  isDebuggerUIEnabled: false,
  isAdvancedUIEnabled: !window.Cypress,
  isBackgroundEnabled: false,
  isForegroundHidden: false,
  didViewIntro: false,
  allowForegroundHiddenOnSceneWatch: true,
  isPushAllowed: false,

  modalStack: [],
  modal: {
    about: {
      isVisible: false,
    },
    auth: {
      isVisible: false,
    },
    actionEdit: {
      isVisible: false,
      /* If an entitlement is only possible after others have been granted,
       * (i.e., Authenticated->Initialized->Basic), it is safe to use the
       * most privileged value.
       */
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
      payload: {
        itemId: undefined,
        makeNewRepeatingItem: undefined,
        postType: undefined,
        addAnother: undefined,
      },
    },
    actionFocus: {
      isVisible: false,
    },
    actionIdea: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
    },
    actionLogCompletion: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
      payload: {
        itemId: undefined,
        effectName: undefined,
        verb: undefined,
        titleIcon: undefined,
        promptText: undefined,
      },
    },
    actionPostponement: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
      payload: {
        itemId: undefined,
        effectName: undefined,
        verb: undefined,
        titleIcon: undefined,
        promptText: undefined,
      },
    },
    actionReminderDelay: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
      payload: {
        itemId: undefined,
        effectName: undefined,
        verb: undefined,
        titleIcon: undefined,
        promptText: undefined,
      },
    },
    actions: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
    },
    actionSelector: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
    },
    actionsPlan: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Initialized],
    },
    actionsPlanCycle: {
      isVisible: false,
    },
    companionsList: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Debug],
    },
    companionStatus: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Debug],
      payload: {
        name: undefined,
      },
    },
    dataRepair: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Initialized],
    },
    dayStart: {
      isVisible: false,
      isBackdropDisabled: true,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Initialized],
    },
    demoData: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Initialized],
    },
    dialogAlert: {
      isVisible: false,
      payload: {
        message: undefined,
        isTemplate: undefined,
        header: undefined,
        okLabel: undefined,
        variant: undefined,
        onClose: undefined,
      },
    },
    dialogConfirm: {
      isVisible: false,
      payload: {
        message: undefined,
        cancelLabel: undefined,
        acceptLabel: undefined,
        acceptVariant: undefined,
        onCancel: undefined,
        onAccept: undefined,
      },
    },
    intro: {
      isVisible: false,
      isBackdropDisabled: true,
    },
    effectsList: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
    },
    effectsSelect: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
      payload: {
        effectTargets: undefined,
        itemId: undefined,
        description: undefined,
        onSelect: undefined,
      },
    },
    genLocationNames: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Debug],
    },
    help: {
      isVisible: false,
    },
    insight: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
    },
    interestEdit: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
      payload: {
        interestId: undefined,
      },
    },
    interests: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
    },
    localNotifications: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic, Entitlement.EntitlementName.enum.Debug],
    },
    messages: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Initialized],
    },
    milestonesList: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Debug],
    },
    objective: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
      payload: {
        name: undefined,
      },
    },
    objectivesList: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
      payload: {
        appConcepts: undefined,
      },
    },
    quoteSelect: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
    },
    resourceGranted: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Debug],
      payload: {
        name: undefined,
      },
    },
    resourceInfo: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Debug],
      payload: {
        name: undefined,
      },
    },
    resourceTransform: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Debug],
      payload: {
        name: undefined,
      },
    },
    spriteCollection: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Debug],
    },
    suggestionBox: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Initialized],
    },
    systemUpgrades: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic, Entitlement.EntitlementName.enum.Debug],
    },
    userSettings: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Initialized],
    },
    userSettingsAvatar: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Basic],
      payload: {
        onSelect: undefined,
      },
    },
    userSubscriptions: {
      isVisible: false,
      requiresEntitlements: [Entitlement.EntitlementName.enum.Initialized],
    },
  },

  ToastType: ToastType.enum,
  toastMessages: [],

  MessageType,
  messages: [],

  logMessages: [],
});

export const mutations = {
  isDebuggerUIEnabled(localState, isEnabled) {
    localState.isDebuggerUIEnabled = isEnabled === true;
  },
  isAdvancedUIEnabled(localState, isEnabled) {
    localState.isAdvancedUIEnabled = isEnabled === true;
  },
  isBackgroundEnabled(localState, isEnabled) {
    localState.isBackgroundEnabled = isEnabled === true;
  },
  toggleForegroundHidden(localState) {
    localState.isForegroundHidden = !localState.isForegroundHidden;
  },
  allowForegroundHiddenOnSceneWatch(localState, allow) {
    localState.allowForegroundHiddenOnSceneWatch = allow === true;
  },
  didViewIntro(localState) {
    localState.didViewIntro = true;
  },
  isPushAllowed(localState, isAllowed = true) {
    localState.isPushAllowed = isAllowed === true;
  },
  modalStackAdd(localState, modalName) {
    localState.modalStack.push(modalName);
  },
  modalStackRemove(localState, modalName) {
    const i = localState.modalStack.findIndex(n => n === modalName);

    if (i >= 0) {
      localState.modalStack.splice(i, 1);
    }
  },
  showModal(localState, name) {
    if (localState.modal[name]) {
      Vue.set(localState.modal[name], 'isVisible', true);
    }
  },
  hideModal(localState, name) {
    if (localState.modal[name]) {
      Vue.set(localState.modal[name], 'isVisible', false);
    }
  },
  setModalPayload(localState, { name, payload }) {
    if (localState.modal[name] && _.isPlainObject(payload)) {
      const validPayloadKeys = Object.keys(localState.modal[name].payload);

      Object.keys(payload).forEach((k) => {
        if (validPayloadKeys.includes(k)) {
          Vue.set(localState.modal[name].payload, k, payload[k]);
        }
      });
    }
  },
  resetModalPayload(localState, name) {
    if (localState.modal[name] && localState.modal[name].payload) {
      Object.keys(localState.modal[name].payload).forEach((k) => {
        Vue.set(localState.modal[name].payload, k, undefined);
      });
    }
  },
  addToastMessage(localState, msg) {
    localState.toastMessages.push(msg);
  },
  removeToastMessageByIndex(localState, index) {
    if (typeof index === 'number') {
      localState.toastMessages.splice(index, 1);
    }
  },
  removeToastMessageById(localState, id) {
    if (id) {
      const index = localState.toastMessages.findIndex(i => i.id === id);

      if (index >= 0) {
        localState.toastMessages.splice(index, 1);
      }
    }
  },
  removeAllToasts(localState) {
    localState.toastMessages = [];
  },
  addMessageAlertText(localState, { text, severity, closable }) {
    localState.messages.push({
      text,
      severity,
      closable: closable !== false,
    });
  },
  addMessageAlertOfType(localState, { type, severity, closable, props }) {
    const skip = MessageTypeSingular.includes(type) && localState.messages.find(m => m.type === type);

    if (!skip) {
      localState.messages.push({
        type,
        severity,
        closable,
        props,
      });
    }
  },
  dismissTopMessage(localState) {
    localState.messages.pop();
  },
  dismissMessageByIndex(localState, index) {
    if (typeof index === 'number') {
      localState.messages.splice(index, 1);
    }
  },
  dismissFirstMessageOfType(localState, type) {
    const index = localState.messages.findIndex(m => m.type === type);

    if (typeof index === 'number') {
      localState.messages.splice(index, 1);
    }
  },
  dismissAllMessages(localState) {
    localState.messages = [];
  },
  addLogMessage(localState, msgObj) {
    localState.logMessages.push(msgObj);
  },
  hideLogMessageAtIndex(localState, i) {
    Vue.set(localState.logMessages[i], 'isHidden', true);
  },
  removeLogMessageAtIndex(localState, i) {
    localState.logMessages.splice(i, 1);
  },
};

export const getters = wrapGetters('ui', {
  getModalEntitlementsSolved: (state, _getters, _rootState, rootGetters) => (name) => {
    const entitlements = { isEntitled: true };
    const mEntitlements = state.modal[name].requiresEntitlements;

    if (Array.isArray(mEntitlements)) {
      mEntitlements.forEach((e) => {
        entitlements[e] = typeof e === 'string' && rootGetters.hasEntitlement(e);
        entitlements.isEntitled = entitlements.isEntitled === true && entitlements[e] === true;
      });
    }

    return entitlements;
  },
  isEntitledToModal: (_state, getters) => (name) => {
    const entitlements = getters.getModalEntitlementsSolved(name);
    return entitlements.isEntitled;
  },
  modalOverride: (state, getters, rootState, rootGetters) => {
    const { isAuthenticationRequested } = rootState;
    const { isAuthenticated, needsDataRepair } = rootGetters;

    if (!isAuthenticated && isAuthenticationRequested && getters.isEntitledToModal('auth')) {
      return 'auth';
    }

    if (needsDataRepair && getters.isEntitledToModal('dataRepair')) {
      return 'dataRepair';
    }

    const shouldPromptDayStart = rootGetters['userSettings/shouldPromptDayStart'];

    if ((shouldPromptDayStart || state.modal.dayStart.isVisible) && getters.isEntitledToModal('dayStart')) {
      return 'dayStart';
    }

    if (rootGetters['actionFocus/shouldDisplayFocus'] && getters.isEntitledToModal('actionFocus')) {
      return 'actionFocus';
    }
  },
  topModalName: (state, getters) => {
    return getters.modalOverride || state.modalStack[state.modalStack.length - 1];
  },
  secondTopModalName: (state, getters) => {
    return getters.modalOverride || state.modalStack[state.modalStack.length - 2];
  },
  modalClass: (state, getters) => {
    const cls = {};
    const topModalName = getters.topModalName;

    Object.keys(state.modal).forEach((modalName) => {
      const { isBackdropDisabled } = state.modal[modalName];
      const isTopModal = topModalName === modalName;

      if (state.modalStack.includes(modalName) || getters.modalOverride === modalName) {
        cls[modalName] = isTopModal ? ['modal-over'] : ['modal-under'];
      } else {
        cls[modalName] = ['modal-hidden'];
      }

      cls[modalName].push('modal');

      if (isBackdropDisabled) {
        cls[modalName].push('modal-backdrop-disabled');
      }
    });

    return cls;
  },
  messages: (state, _getters, rootState, rootGetters) => {
    const messages = [];

    rootGetters['actionsUser/itemsToRemindImmediately'].forEach((i) => {
      messages.push({
        type: state.MessageType.Reminder,
        severity: 'info',
        closable: false,
        props: {
          id: i.id,
        },
      });
    });

    if (Boolean(rootState.actionFocus.action) && rootGetters['actionFocus/hasNonLocalFocus']) {
      messages.push({
        type: state.MessageType.Focus,
        severity: 'info',
        closable: false,
      });
    }

    return messages.concat(state.messages);
  },
  topMessage: (state, getters) => {
    let top;

    if (getters.messages.length > 0) {
      top = getters.messages[getters.messages.length - 1];

      const focus = getters.messages.find(m => m.type === state.MessageType.Focus);

      if (focus) {
        top = focus;
      } else {
        const reminder = getters.messages.find(m => m.type === state.MessageType.Reminder);

        if (reminder) {
          top = reminder;
        }
      }
    }

    return top;
  },
});

export const actions = {
  showModal({ state, commit, getters, dispatch }, { name, payload, hideOthers }) {
    if (!_.isPlainObject(state.modal[name])) {
      this.$dbg('store:ui')('showModal', `modal "${name}" does not exist`);
      return;
    }

    if (hideOthers) {
      dispatch('hideAllModals');
    }

    if (state.modal[name].isVisible) {
      return;
    }

    if (getters.isEntitledToModal(name)) {
      if (payload) {
        commit('setModalPayload', {
          name,
          payload,
        });
      }

      // modalOverride logEvent being handled in components/ui
      commit('showModal', name);
      commit('modalStackAdd', name);
      dispatch('logEvent', { name: 'screen_view', params: { firebase_screen: name } }, { root: true });
      return;
    }

    const entitlements = getters.getModalEntitlementsSolved(name);

    // Only prompt to subscribe if the Basic entitlement fails
    if (Object.keys(entitlements).includes(Entitlement.EntitlementName.enum.Basic) && entitlements[Entitlement.EntitlementName.enum.Basic] !== true) {
      dispatch('displaySubscriptions', undefined, { root: true });
    }
  },
  hideModal({ commit }, name) {
    commit('hideModal', name);
    commit('resetModalPayload', name);
    commit('modalStackRemove', name);
  },
  hideAllModals({ state, dispatch }) {
    const modals = Object.keys(state.modal).filter(mName => state.modal[mName].isVisible);

    modals.forEach((mName) => {
      dispatch('hideModal', mName);
    });
  },

  enableBackgroundWait: _.debounce(function({ commit }) {
    commit('isBackgroundEnabled', true);
  }, 2000),

  enableBackgroundImmediately({ commit }) {
    commit('isBackgroundEnabled', true);
  },

  addToast({ commit }, toast) {
    const result = ToastModel.safeParse(toast);

    if (result.error) {
      this.$dbg('store:ui')('addToast', `could not add toast "${text}" due to error`, error);
    } else if (result.success) {
      commit('addToastMessage', result.data);

      if (result.data.autoDismissAfter) {
        setTimeout(() => {
          commit('removeToastMessageById', result.data.id);
        }, result.data.autoDismissAfter);
      }

      return true;
    }

    return false;
  },

  logMessage({ state, commit }, msg) {
    commit('addLogMessage', {
      message: msg,
      isHidden: false,
    });

    setTimeout(() => {
      const i = state.logMessages.findIndex(m => m.message === msg);

      if (i >= 0) {
        commit('hideLogMessageAtIndex', i);
      }

      setTimeout(() => {
        commit('removeLogMessageAtIndex', i);
      }, 1000);
    }, 5000);
  },
};
