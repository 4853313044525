import { z } from 'zod';

export namespace Interest {
  export const DataTableName: string = 'interests';

  const InvalidInterestNames = [
    'all',
  ];

  export const ModelDefault = {
    id: null,
    userId: undefined,
    name: undefined,
    icon: null,
    isDefault: false,
  };

  export const Model = z.object({
    // id is nullable so Firestore can create new ids
    id: z.nullable(z.string().min(1)).default(ModelDefault.id),
    userId: z.string().min(1),
    name: z.string().min(1)
      .refine(val => !InvalidInterestNames.includes(val), {
        message: 'Invalid interest name.',
      }),
    icon: z.nullable(z.string()).default(ModelDefault.icon),
    isDefault: z.optional(z.boolean().default(ModelDefault.isDefault)),
  });

  export const DefaultInterests = [
    {
      name: 'personal',
      icon: '👤',
      isDefault: true,
    },
    {
      name: 'work',
      icon: '🗒',
    },
    {
      name: 'family & friends',
      icon: '🧡',
    },
    {
      name: 'community',
      icon: '🤝',
    },
  ];
}
