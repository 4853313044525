
import { Effect } from '../api/src/models/effect';

import effectsMixin from '../mixins/effects';

export default {
  name: 'EffectsButton',
  mixins: [
    effectsMixin,
  ],
  props: {
    action: {
      type: Object,
      default() {
        return undefined;
      },
    },
    isRepeating: {
      type: Boolean,
      default: false,
    },
    isComplete: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'neutral',
    },
    size: {
      type: String,
      default: 'medium',
    },
    classes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    effectTargets() {
      let targets = [Effect.Target.enum.ActionOnce];

      if (this.isRepeating) {
        targets = [Effect.Target.enum.ActionRepeating];
      }

      if (this.isReminding) {
        targets.push(Effect.Target.enum.ActionReminding);
      }

      return targets;
    },
    canUseEffects() {
      return this.effectTargets.length > 0 && (!this.isComplete || this.isRepeating);
    },
    isIndicatingEffectCustom() {
      return this.canUseEffects && !this.isComplete && this.isAnyEffectCustomApplicable(this.action);
    },
    classesLocal() {
      let c = [];

      if (Array.isArray(this.classes)) {
        c = [
          ...this.classes,
        ];
      }

      return c;
    },
  },
  methods: {
    showEffects() {
      if (this.effectTargets.length > 0) {
        this.$store.dispatch('ui/showModal', {
          name: 'effectsSelect',
          payload: {
            effectTargets: this.effectTargets,
            itemId: this.action.id,
            description: this.action.description,
            onSelect: (effect) => {
              this.$store.dispatch('ui/hideModal', 'effectsSelect');
              this.effectPrompt(effect, { itemId: this.action.id });
            },
          },
        });
      }
    },
  },
};
