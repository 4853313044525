import dayjs from '../lib/dayjs';
import { StoreHelperMutation, storeHelperMutations } from '../lib/store-helpers';
import {
  bindToDocsForUserId,
  upsertDoc,
  deleteDoc,
} from '../lib/data';
import { wrapGetters } from '../lib/util';

import { Action } from '../api/src/models/Action';
import { Interest } from '../api/src/models/Interest';
import { isDate } from '../api/src/models/util';

const pad = n => String(n).padStart(2, '0');
let unsub;

const remoteKeyToTableMap = {
  interest: Interest.DataTableName,
  occurrenceOf: Action.DataTableName,
};

export const state = () => ({
  all: [],
  itemsRecentlyCompletedLocally: [],
});

export const mutations = {
  ...storeHelperMutations,
  itemRecentlyCompletedLocally(localState, id) {
    // We only need to keep track of the last one (for now)
    localState.itemsRecentlyCompletedLocally = [id];
  },
};

export const getters = wrapGetters('actionsUser', {
  items: (state, getters) => {
    return state.all.filter(item => !getters.isItemIdea(item));
  },
  ideas: (state, getters) => {
    return state.all.filter(item => getters.isItemIdea(item));
  },
  itemsIncomplete: (state) => {
    return state.all.filter(i => i.status === Action.Status.enum.Todo);
  },
  itemsComplete: (state) => {
    return state.all.filter(i => i.status === Action.Status.enum.Done);
  },

  itemsRepeating: (_state, getters) => {
    return getters.itemsIncomplete.filter(i => Action.isFrequencyRepeating(i.frequency));
  },
  itemsRepeatingDaily: (_state, getters) => {
    return getters.itemsRepeating.filter(i => getters.isItemFrequencyDaily(i));
  },
  itemsRepeatingComplete: (_state, getters) => {
    return getters.itemsComplete.filter(i => Boolean(i.occurrenceOf));
  },
  itemsRepeatingCompleteOnVirtualDate: (_state, getters) => (d) => {
    return getters.itemsRepeatingComplete.filter(i => dayjs(i.lastProgressAt).isBetween(d, d.add(1, 'day')));
  },
  itemsRepeatingCompleteOnVirtualDateTest: (_state, getters) => (d, test) => {
    return getters.itemsRepeatingCompleteOnVirtualDate(d).filter((i) => {
      const item = getters.getItemById(i.occurrenceOf);
      return Boolean(item) && test(item);
    });
  },

  valuePotentialTotal: (_state, getters, _rootState, rootGetters) => {
    let vt = 0;

    getters.itemsIncomplete.forEach((item) => {
      vt = vt + rootGetters.getValuePotentialForItem(item);
    });

    return vt;
  },
  valueTotal: (_state, getters) => {
    let vt = 0;

    getters.itemsComplete.forEach((item) => {
      const v = Number(item.value);
      vt = typeof v === 'number' ? vt + v : vt;
    });

    return vt;
  },
  valueTotalForVirtualDate: (_state, getters) => (d) => {
    let vt = 0;
    const d2 = d.subtract(1, 'second');
    const dPlus = d.add(1, 'day');

    getters.itemsComplete.forEach((item) => {
      const lastProgressAt = dayjs(item.lastProgressAt);

      if (lastProgressAt.isBetween(d2, dPlus)) {
        vt = vt + Number(item.value || 0);
      }
    });

    return vt;
  },
  valueTotalVirtualDay: (_state, getters, _rootState, rootGetters) => getters.valueTotalForVirtualDate(rootGetters.virtualDay),
  valueTotalYesterday: (_state, getters, _rootState, rootGetters) => getters.valueTotalForVirtualDate(rootGetters.virtualYesterday),
  valueTotalsRecent: (_state, getters, rootState, rootGetters) => {
    const days = [];
    let i;

    // Ordered earliest -> latest
    // Does not include today
    for (i = rootState.recentDaysCount; i > 0; i--) {
      const d = rootGetters.virtualDay.subtract(i, 'days');
      days.push(getters.valueTotalForVirtualDate(d));
    }

    return days;
  },
  valueTotalRecentAverage: (_state, getters, rootState) => {
    return Math.ceil(getters.valueTotalsRecent.reduce((accum, val) => accum + val) / rootState.recentDaysCount);
  },
  valueTotalRecentAverage3: (_state, getters, rootState) => {
    return Math.ceil(getters.valueTotalsRecent.slice(-3).reduce((accum, val) => accum + val) / rootState.recentDaysCount);
  },

  itemsWithReminders: (_state, getters, _rootState, rootGetters) => {
    if (!rootGetters.isAuthenticated) {
      return [];
    }

    return getters.items.filter(i => Boolean(i.remindAt));
  },
  itemsForNow: (_state, getters) => {
    return getters.itemsWithReminders.filter((i) => {
      return getters.isItemForNow(i) || (getters.isItemExpiringVirtualDay(i) && i.status === Action.Status.enum.Todo);
    });
  },
  itemsForTomorrow: (_state, getters) => {
    return getters.itemsWithReminders.filter((i) => {
      return getters.isItemForTomorrow(i) || (getters.isItemExpiringTomorrow(i) && i.status === Action.Status.enum.Todo);
    });
  },
  itemsToRemind: (_state, getters) => {
    return getters.itemsForNow.filter((item) => {
      const remindAt = getters.getItemNextRemindAt(item);
      return Boolean(remindAt);
    });
  },
  itemsToRemindImmediately: (_state, getters, rootState) => {
    return getters.itemsForNow.filter((item) => {
      const remindAt = getters.getItemNextRemindAt(item);
      return rootState.now.isSameOrAfter(remindAt);
    });
  },
  itemsCompletedOnVirtualDate: (_state, getters) => (d) => {
    const d2 = d.subtract(1, 'second');
    return getters.itemsComplete.filter(i => dayjs(i.lastProgressAt).isBetween(d2, d.add(1, 'day')));
  },
  itemsCompletedVirtualDay: (_state, getters, _rootState, rootGetters) => {
    return getters.itemsCompletedOnVirtualDate(rootGetters.virtualDay);
  },

  itemsWithExpiration: (_state, getters) => {
    return getters.items.filter(i => i.status === Action.Status.enum.Todo && Boolean(getters.getItemExpiresAt(i)));
  },
  itemsByExpiration: (_state, getters, _rootState, rootGetters) => {
    const today = rootGetters.virtualDay.format('YYYYMMDD');
    const dates = {};

    getters.itemsWithExpiration.forEach((item) => {
      const expiresAt = getters.getItemExpiresAt(item);

      if (expiresAt) {
        // Expires at is always at the end of the virtual day. We are ignoring time here, so we need to subtract one day
        let d = expiresAt.subtract(1, 'day').format('YYYYMMDD');

        if (Number(d) < Number(today)) {
          d = today;
        }

        if (!dates[d]) {
          dates[d] = [];
        }

        dates[d].push(item);
      }
    });

    return dates;
  },
  itemsExpiringOnOrBeforeVirtualDate: (_state, getters) => (d) => {
    return getters.itemsWithExpiration.filter(i => getters.getItemExpiresAt(i).isBefore(d.add(1, 'day')));
  },
  itemsExpiringOnVirtualDate: (state, getters) => (d, includeComplete = false) => {
    const items = includeComplete ? state.all : getters.items;

    return items.filter((item) => {
      if (getters.hasTimeframe(item)) {
        const expiresAt = getters.getItemExpiresAt(item);
        return expiresAt && expiresAt.isBetween(d, d.add(1, 'day'));
      } else if (Action.isFrequencyRepeating(item.frequency)) {
        return getters.isRepeatingItemForVirtualDate(item, d);
      }

      return false;
    });
  },
  itemsExpiringVirtualDay: (_state, getters, _rootState, rootGetters) => {
    return getters.itemsExpiringOnOrBeforeVirtualDate(rootGetters.virtualDay);
  },
  itemsRepeatingOnVirtualDay: (state, getters) => {
    return state.all.filter(i => getters.isRepeatingItemForVirtualDay(i));
  },
  itemsWithRecentProgress: (_state, getters, rootState, rootGetters) => {
    const earliest = rootGetters.virtualDay.subtract(rootState.recentDaysCount, 'day');
    const items = getters.items.filter((i) => {
      let isRecent = false;

      if (Action.isFrequencyRepeating(i.frequency)) {
        isRecent = getters.getItemCompletedOccurrencesForVirtualDay(i).length < 1 &&
          getters.isRepeatingItemForVirtualDay(i) &&
          isDate(i.lastProgressAt) &&
          getters.isRepeatingItemTimeSpentValid(i);
      } else {
        isRecent = isDate(i.lastProgressAt) && dayjs(i.lastProgressAt).isAfter(earliest);
      }

      return isRecent;
    });

    items.sort((a, b) => b.lastProgressAt - a.lastProgressAt);

    return items;
  },
  itemsRepeatingCompleteWithoutMissing: (_state, getters, rootState) => {
    const daysCount = rootState.recentDaysCount + 1; // plus today

    return getters.itemsRepeating.filter((item) => {
      const recentCompletionsCount = getters.getItemRecentlyCompletedOccurrences(item).filter(day => Boolean(day) && day.length > 0).length;

      // Most of these assume that we are only looking at the past week
      return (getters.isItemFrequencyDaily(item) && recentCompletionsCount === daysCount) ||
        (item.frequency === Action.Frequency.enum.Weekday && recentCompletionsCount === 5) ||
        (item.frequency === Action.Frequency.enum.Weekend && recentCompletionsCount === 2) ||
        (getters.isItemFrequencyWeekly(item) && recentCompletionsCount === 2);
    });
  },
  itemsByRankIncomplete: (_state, getters) => {
    const items = getters.itemsIncomplete.filter(i => !Action.isFrequencyRepeating(i.frequency));

    items.sort((a, b) => getters.getItemRankScore(b) - getters.getItemRankScore(a));

    return items;
  },
  itemCompletedLast: (_state, getters) => {
    let item;

    if (getters.itemsComplete.length > 0) {
      const items = [...getters.itemsComplete];
      items.sort((a, b) => b.lastProgressAt - a.lastProgressAt);
      item = items[0];
    }

    return item;
  },

  getItemById: state => (id) => {
    return state.all.find(i => i.id === id);
  },
  isItemIdea: () => (item) => {
    return item ? (item.status !== Action.Status.enum.Done && (!Array.isArray(item.interest) || item.interest.length < 1)) : false;
  },
  isItemFrequencyRepeating: () => (item) => {
    return Action.isFrequencyRepeating(item.frequency);
  },
  isItemFrequencyDaily: () => (item) => {
    return Action.isFrequencyDaily(item.frequency);
  },
  isItemFrequencyWeekly: () => (item) => {
    return Action.isFrequencyWeekly(item.frequency);
  },
  isItemFrequencyMonthDateForVirtualDay: (_state, _getters, _rootState, rootGetters) => (item) => {
    return item.frequency === Action.Frequency.enum.Month && Action.Model.shape.frequencyMonthDate.safeParse(item.frequencyMonthDate).success &&
      (rootGetters.dateOfTheMonth === item.frequencyMonthDate ||
        (rootGetters.dateOfTheMonth > rootGetters.dateEndOfTheMonth && item.frequencyMonthDate > rootGetters.dateEndOfTheMonth)
      );
  },
  isItemComplete: (_state, getters) => (item) => {
    let isComplete = false;

    if (item && !getters.isItemIdea(item)) {
      isComplete = getters.isItemFrequencyRepeating(item) ? getters.getItemCompletedOccurrencesForVirtualDay(item).length > 0 : item.status === Action.Status.enum.Done;
    }

    return isComplete;
  },
  isItemMissingRecentCompletions: (_state, getters) => (item) => {
    const items = getters.itemsMissingRecentCompletions;
    return Array.isArray(items) && Boolean(items.find(i => i.id === item.id));
  },
  isItemExpired: (_state, getters, rootState) => (item) => {
    const expiresAt = getters.getItemExpiresAt(item);
    return expiresAt ? dayjs(expiresAt).diff(rootState.now) <= 0 : false;
  },
  isItemExpiringVirtualDay: (_state, getters, _rootState, rootGetters) => (item) => {
    const expiresAt = getters.isItemFrequencyRepeating(item) ? getters.getRepeatingItemNextExpiresAt(item) : getters.getItemExpiresAt(item);
    return rootGetters.getDaysRemainingForExpiresAt(expiresAt) <= 1;
  },
  isItemExpiringTomorrow: (_state, getters, _rootState, rootGetters) => (item) => {
    const expiresAt = getters.getItemExpiresAt(item);
    return rootGetters.virtualTomorrow.isBefore(expiresAt) && rootGetters.virtualTomorrow.add(1, 'day').isAfter(expiresAt);
  },
  isRepeatingItemForVirtualDate: () => (item, virtualDate) => {
    return Action.isRepeatingItemForVirtualDate(item, virtualDate);
  },
  isRepeatingItemForVirtualDay: (_state, _getters, _rootState, rootGetters) => (item) => {
    return Action.isRepeatingItemForVirtualDate(item, rootGetters.virtualDay);
  },
  isItemForNow: (_state, getters, _rootState, rootGetters) => (item) => {
    let isForDay = getters.isRepeatingItemForVirtualDay(item);

    const tests = {
      [Action.Frequency.enum.Morning]: () => rootGetters.isMorning,
      [Action.Frequency.enum.Afternoon]: () => rootGetters.isAfternoon,
      [Action.Frequency.enum.Evening]: () => rootGetters.isEvening,
    };

    if (Object.keys(tests).includes(item.frequency)) {
      isForDay = tests[item.frequency]();
    }

    return isForDay;
  },
  isItemForTomorrow: (_state, getters, _rootState, rootGetters) => (item) => {
    let isForDay = getters.isRepeatingItemForVirtualDate(item, rootGetters.virtualTomorrow);

    const tests = {
      [Action.Frequency.enum.Morning]: () => rootGetters.isMorning,
      [Action.Frequency.enum.Afternoon]: () => rootGetters.isAfternoon,
      [Action.Frequency.enum.Evening]: () => rootGetters.isEvening,
    };

    if (Object.keys(tests).includes(item.frequency)) {
      isForDay = tests[item.frequency]();
    }

    return isForDay;
  },
  isRepeatingItemTimeSpentValid: (_state, getters, _rootState, rootGetters) => (item) => {
    let isValid = true;

    if (getters.isItemFrequencyRepeating(item) && isDate(item.lastProgressAt) && rootGetters.virtualDay.isAfter(item.lastProgressAt)) {
      isValid = false;
    }

    return isValid;
  },
  isItemReminding: (_state, getters) => (item) => {
    let isReminding = false;

    if (item && item.id) {
      isReminding = !!getters.itemsToRemindImmediately.find(i => i.id === item.id);
    }

    return isReminding;
  },

  itemCanBeCompleted: (_state, getters) => (item) => {
    return getters.getItemCompletedOccurrencesForVirtualDay(item).length < 1;
  },

  getItemFrequencyDisplay: (_state, getters) => (item) => {
    return getters.isItemFrequencyRepeating(item)
      ? Action.getFrequencyDisplayForItem(item)
      : undefined;
  },
  getRepeatingItemLastProgress: (_state, getters, _rootState, rootGetters) => (item) => {
    let last = 0;

    if (getters.isItemFrequencyRepeating(item) && item.lastProgressAt && rootGetters.virtualDay.isBefore(item.lastProgressAt)) {
      last = item.lastProgressAt;
    }

    return last;
  },
  getItemCompletedOccurrencesOnVirtualDate: (_state, getters, _rootState, rootGetters) => (item, d) => {
    const virtualDay = rootGetters.dayStartingAtUserResetTime(d);
    return getters.itemsComplete.filter(i => (
      (i.occurrenceOf === item.id || i.repeater === item.id) &&
      dayjs(i.lastProgressAt).isBetween(virtualDay.subtract(1, 'second'), virtualDay.add(1, 'day'))
    ));
  },
  getItemCompletedOccurrencesForVirtualDay: (_state, getters, _rootState, rootGetters) => (item) => {
    return getters.getItemCompletedOccurrencesOnVirtualDate(item, rootGetters.virtualDay);
  },
  getItemRecentlyCompletedOccurrences: (_state, getters, rootState, rootGetters) => (item) => {
    const occurrences = [];
    let i;

    if (item && getters.isItemFrequencyRepeating(item)) {
      // 0 is today (virtualDay); array grows the farther we go back in recentDaysCount
      for (i = 0; i <= rootState.recentDaysCount; i++) {
        const d = rootGetters.virtualDay.subtract(i, 'days');

        if (getters.isRepeatingItemForVirtualDate(item, d)) {
          const completions = getters.getItemCompletedOccurrencesOnVirtualDate(item, d);
          occurrences.push(completions);
        } else {
          occurrences.push(undefined);
        }
      }
    }

    return occurrences;
  },

  getItemNextRemindAt: (_state, getters, rootState, rootGetters) => (item) => {
    let remindAt;

    if (item.remindAt) {
      const isCompleted = item.status === Action.Status.enum.Done || getters.getItemCompletedOccurrencesForVirtualDay(item).length > 0;

      if (!isCompleted) {
        const expiresAtDate = getters.isItemFrequencyRepeating(item) ? getters.getRepeatingItemNextExpiresAt(item) : getters.getItemExpiresAt(item);
        const expiresAt = expiresAtDate ? dayjs(expiresAtDate) : undefined;

        if (expiresAt && expiresAt.isSameOrAfter(rootState.now)) { // Not yet expired
          const lastRemindedAt = isDate(item.lastRemindedAt) ? dayjs(item.lastRemindedAt) : rootGetters.virtualDay;

          const remindAtSplit = item.remindAt.split(':');
          remindAt = expiresAt.hour(remindAtSplit[0]).minute(remindAtSplit[1]).second(0);

          if (remindAt.isAfter(expiresAt)) {
            remindAt = remindAt.subtract(1, 'day');
          }

          remindAt = dayjs.max(rootState.now, lastRemindedAt, remindAt);
        }
      }
    }

    return remindAt;
  },
  getRepeatingItemNextExpiresAt: (_state, getters, _rootState, rootGetters) => (item) => {
    const dates = {
      [Action.Frequency.enum.Day]: () => rootGetters.virtualDayEnd,
      [Action.Frequency.enum.Morning]: () => rootGetters.virtualDayEnd,
      [Action.Frequency.enum.Afternoon]: () => rootGetters.virtualDayEnd,
      [Action.Frequency.enum.Evening]: () => rootGetters.virtualDayEnd,
      [Action.Frequency.enum.Sunday]: rootGetters.dayOfTheWeek === 0 ? () => rootGetters.virtualDayEnd : undefined,
      [Action.Frequency.enum.Monday]: rootGetters.dayOfTheWeek === 1 ? () => rootGetters.virtualDayEnd : undefined,
      [Action.Frequency.enum.Tuesday]: rootGetters.dayOfTheWeek === 2 ? () => rootGetters.virtualDayEnd : undefined,
      [Action.Frequency.enum.Wednesday]: rootGetters.dayOfTheWeek === 3 ? () => rootGetters.virtualDayEnd : undefined,
      [Action.Frequency.enum.Thursday]: rootGetters.dayOfTheWeek === 4 ? () => rootGetters.virtualDayEnd : undefined,
      [Action.Frequency.enum.Friday]: rootGetters.dayOfTheWeek === 5 ? () => rootGetters.virtualDayEnd : undefined,
      [Action.Frequency.enum.Saturday]: rootGetters.dayOfTheWeek === 6 ? () => rootGetters.virtualDayEnd : undefined,
      [Action.Frequency.enum.Month]: getters.isItemFrequencyMonthDateForVirtualDay(item) ? () => rootGetters.virtualDayEnd : undefined,
    };

    if (Action.Frequency.options.includes(item.frequency) && typeof dates[item.frequency] !== 'undefined') {
      return dates[item.frequency]().toDate();
    } else {
      return undefined;
    }
  },
  hasTimeframe: () => (item) => {
    let has = Array.isArray(item.timeframe) && item.timeframe.length > 0;

    if (has) {
      has = item.timeframe.filter(d => isDate(d)).length > 0;
    }

    return has;
  },
  getItemMaturesAt: (_state, getters, _rootState, rootGetters) => (item) => {
    let maturesAt;

    if (getters.hasTimeframe(item) && item.timeframe.length === 2 && item.timeframe.filter(d => isDate(d)).length === item.timeframe.length) {
      maturesAt = rootGetters.dayStartingAtUserResetTime(item.timeframe[0]);
    }

    return maturesAt;
  },
  getItemExpiresAt: (_state, getters, _rootState, rootGetters) => (item) => {
    let expiresAt;

    if (getters.hasTimeframe(item)) {
      expiresAt = isDate(item.timeframe[1]) ? item.timeframe[1] : item.timeframe[0];
      expiresAt = rootGetters.dayStartingAtUserResetTime(dayjs(expiresAt).add(1, 'day')).subtract(1, 'second');
    }

    return expiresAt;
  },

  getValuePotentialForInterest: (_state, getters, _rootState, rootGetters) => (interestId) => {
    let vp = 0;

    getters.itemsIncomplete
      .filter((item) => {
        return (item.interest && item.interest.includes(interestId));
      })
      .forEach((item) => {
        vp = vp + rootGetters.getValuePotentialForItem(item);
      });

    return vp;
  },
  getRepeatingItemNewOccurrence: () => (item) => {
    if (Action.isFrequencyRepeating(item.frequency)) {
      const occurrence = {
        ...item,
        id: undefined,
        occurrenceOf: item.id,
        frequency: Action.Frequency.enum.Once,
        frequencyMonthDate: undefined,
        remindAt: undefined,
      };

      return occurrence;
    }
  },
  getItemRankScore: (_state, getters, rootState) => (item) => {
    const rankProps = {
      isMatured: '1',
      hoursUntilExpired: 99,
      hoursSinceLastProgress: 99,
      importance: Action.EffortImportance.FibonacciValues.includes(item.importance) ? item.importance : 1,
      effort: Action.EffortImportance.FibonacciValues.includes(item.effort) ? item.effort : 1,
    };

    if (item.status !== Action.Status.enum.Done) {
      let hoursUntilMatured = 99; // default to a matured action

      if (getters.hasTimeframe(item)) {
        const maturesAt = getters.getItemMaturesAt(item);
        const expiresAt = getters.getItemExpiresAt(item);

        if (maturesAt) {
          hoursUntilMatured = maturesAt.diff(rootState.now, 'hour');

          if (hoursUntilMatured > 0) {
            rankProps.isMatured = '0';
          }
        }

        if (expiresAt) {
          rankProps.hoursUntilExpired = Math.min(99, Math.max(0, dayjs(expiresAt).diff(rootState.now, 'hour')));
        }
      }

      rankProps.hoursUntilExpired = 99 - rankProps.hoursUntilExpired;

      if (isDate(item.lastProgressAt)) {
        rankProps.hoursSinceLastProgress = Math.min(99, Math.max(1, rootState.now.diff(item.lastProgressAt, 'hour')));
      }

      rankProps.hoursSinceLastProgress = 99 - rankProps.hoursSinceLastProgress;

      if (rankProps.hoursUntilExpired > 0 && rankProps.hoursUntilExpired < 72) {
        rankProps.importance = rankProps.importance + (72 - rankProps.hoursUntilExpired);
      }

      rankProps.effort = 99 - rankProps.effort;
    }

    // Number.MAX_SAFE_INTEGER is              9,007,199,254,740,991
    // To be safe, we'll make our max             99,999,999,999,999
    // (max safe to use bitwise and shift operators is 2,147,483,647)
    // split into 7 sections: 99-99-99-99-99-99-99
    const rank = Number(`${Object.values(rankProps).map(val => pad(val)).join('')}0000`);

    return rank;
  },
  itemsCompletedVirtualDayTimestamps: (state, getters) => {
    const itemsLastProgress = getters.itemsCompletedVirtualDay.filter(i => isDate(i.lastProgressAt)).map(i => i.lastProgressAt.getTime());
    itemsLastProgress.sort((a, b) => a - b);

    return itemsLastProgress;
  },
  efficiencyOfDay: (state, getters) => {
    let efficiency = 0;
    const itemTimestamps = getters.itemsCompletedVirtualDayTimestamps;

    if (itemTimestamps.length > 1) {
      // reasonable workday
      const startEndGapMinMs = 28800000; // 8 * 60 * 60 * 1000; // at least 8 hours of action
      const startEndGapMaxMs = 57600000; // 16 * 60 * 60 * 1000; // at most 16 hours of action
      const startEndGapRangeMs = startEndGapMaxMs - startEndGapMinMs; // efficiency is measure in this range -- how close to the min the user is

      const startEndGapMs = itemTimestamps[itemTimestamps.length - 1] - itemTimestamps[0]; // actual gap between earliest & latest completions in the day
      efficiency = startEndGapMs > startEndGapMaxMs // if the gap is larger than max, just return 0
        ? 0
        : 1 - (Math.max(0, startEndGapMs - startEndGapRangeMs) / startEndGapRangeMs);
    }

    return efficiency;
  },
  efficiencyOfCompletions: (state, getters) => {
    let avgGapsEfficiency = 0;
    const itemTimestamps = getters.itemsCompletedVirtualDayTimestamps;

    if (itemTimestamps.length > 1) {
      // looking for a steady pace
      // use the same index to get previous in itemTimestamps because we skipped the first one!
      const allGapsMs = itemTimestamps.slice(1)
        .map((ts, idx) => ts - itemTimestamps[idx])
        .filter(gap => gap > 180000); // don't punish gaps less than 3 minutes (probably grouped actions)

      const avgGapMs = allGapsMs.reduce((total, ts) => total + ts, 0) / allGapsMs.length;
      const allGapsEfficiency = allGapsMs.map((gapTime) => {
        const diff = Math.abs(avgGapMs - gapTime);
        const e = Math.min(1, diff / avgGapMs);
        return 1 - e;
      });
      const allGapsEfficiencyTotal = allGapsEfficiency.reduce((total, e) => total + e, 0);
      avgGapsEfficiency = (!isNaN(allGapsEfficiencyTotal) && allGapsEfficiency.length > 0) ? allGapsEfficiencyTotal / allGapsEfficiency.length : 0;
    }

    return avgGapsEfficiency;
  },
  efficiency: (state, getters) => {
    return (getters.efficiencyOfDay / 2) + (getters.efficiencyOfCompletions / 2);
  },
});

export const actions = {
  async init({ rootState, rootGetters, commit, dispatch }) {
    const userId = await this.$auth.getUserId();

    if (!userId) {
      throw new Error('Could not bind interests for invalid userId');
    }

    this.$dbg('store:actionsUser')('init');

    const oldest = rootGetters.virtualDay.subtract(rootState.recentDaysCount, 'days');
    const remoteQueries = [
      [
        {
          field: 'status',
          operator: '==',
          value: Action.Status.enum.Todo,
        },
      ],
      [
        {
          field: 'status',
          operator: '==',
          value: Action.Status.enum.Done,
        },
        {
          field: 'lastProgressAt',
          operator: '>=',
          value: oldest.toDate(),
        },
      ],
    ];

    unsub = bindToDocsForUserId(Action.Model, Action.DataTableName, userId, {
      remoteQueries,
      onInit: () => {
        dispatch('setDataSourceSync', { name: 'actionsUser' }, { root: true });
      },
      onUpdate: ({ docs, docsRemoved }) => {
        if (Array.isArray(docsRemoved)) {
          docsRemoved.forEach((doc) => {
            commit(StoreHelperMutation.RemoveFromAll, doc);
          });
        }

        commit(StoreHelperMutation.SetOnAll, docs);
        dispatch('setDataSourceSync', { name: 'actionsUser' }, { root: true });
      },
    });
  },

  async reset({ commit }) {
    this.$dbg('store:actionsUser')('reset');

    if (typeof unsub === 'function') {
      unsub();
    }

    // Get userId directly from auth to ensure they DO NOT still have a session!
    // Otherwise, we will risk wiping the user's data
    const userId = await this.$auth.getUserId();

    if (!userId) {
      commit(StoreHelperMutation.ResetAll);
    }
  },

  async editItem({ getters, rootGetters, commit, dispatch }, { id, mods }) {
    try {
      // Get userId directly from auth to ensure they still have a session
      const userId = await this.$auth.getUserId();

      if (!userId) {
        throw new Error('Cannot edit action; invalid userId');
      }

      if (!rootGetters.isAllowedToUseApp) {
        throw new Error('Entitlement required.');
      }

      this.$dbg('store:actionsUser:editItem')(mods);

      const action = getters.getItemById(id) || {};
      const isMod = Boolean(action.id);

      const data = upsertDoc(Action.Model, Action.DataTableName, id, {
        ...action,
        ...mods,
        createdAt: (action && isDate(action.createdAt)) ? action.createdAt : new Date(),
        lastModifiedAt: isMod ? new Date() : undefined,
        userId,
      }, { remoteKeyToTableMap });

      // because bindToDocsForUserId.onUpdate only fires if online?
      commit(StoreHelperMutation.SetOnAll, [data]);

      if (isMod) {
        dispatch('logEvent', { name: 'action_edit' }, { root: true });

        // If moving from Todo to Done, keep a reference so it doesn't flicker out of the app
        if (action.status === Action.Status.enum.Todo && data.status === Action.Status.enum.Done) {
          dispatch('logEvent', { name: 'action_complete', params: { value: action.value } }, { root: true });

          /*
          if (data.occurrenceOf) {
            commit('itemRecentlyCompletedLocally', data.occurrenceOf);
          } else {
            commit('itemRecentlyCompletedLocally', data.id);
          }
          */
        }
      } else {
        dispatch('logEvent', { name: 'action_add' }, { root: true });
      }

      return data;
    } catch (e) {
      this.$dbg('store:actionsUser:editItem')('error', e);
      return false;
    }
  },

  async deleteItem({ commit }, item) {
    try {
      // Get userId directly from auth to ensure they still have a session
      const userId = await this.$auth.getUserId();

      if (!userId) {
        throw new Error('Could not delete item, invalid userId');
      }

      this.$dbg('store:actionsUser')('deleteItem', item.id);
      deleteDoc(Action.DataTableName, item.id);
      commit(StoreHelperMutation.RemoveFromAll, item);
      return true;
    } catch (e) {
      this.$dbg('store:actionsUser')('deleteItem error', item.id, e);
      return false;
    }
  },

  async deleteAllItems({ state, dispatch, rootGetters }) {
    if (rootGetters.isDemoAccount) {
      try {
        const proms = [];

        state.all.forEach((item) => {
          proms.push(dispatch('deleteItem', item));
        });

        await Promise.all(proms);

        return true;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return false;
      }
    }
  },

  async addRepeatingItemOccurrenceCompleted({ getters, rootGetters, dispatch }, item) {
    const occurrence = getters.getRepeatingItemNewOccurrence(item);

    if (occurrence) {
      const i = await dispatch('editItem', {
        id: undefined,
        mods: {
          ...occurrence,
          status: Action.Status.enum.Done,
          lastProgressAt: item.lastProgressAt || new Date(),
          value: item.value || rootGetters.getValuePotentialForItem(item),
        },
      });

      return i;
    }
  },
};
