import { z } from 'zod';
import { Resource } from './Resource';

export namespace Companion {
  const LoyaltyDescriptionParams = z.object({
    label: z.string().min(1),
    resourceLabel: z.string().min(1),
    cost: z.number().min(0),
  });

  export const Type = z.enum([
    'Android',
    'Bot',
    'Humanoid',
    'Phenomenon',
    'Reptilian',
  ]);

  export const TypeSettingModel = z.object({
    type: Type,
    label: z.string().min(1),
  });

  export const TypeSetting: Partial<Record<z.infer<typeof Type>, z.infer<typeof TypeSettingModel>>> = {
    [Type.enum.Android]: {
      type: Type.enum.Android,
      label: 'Android',
    },
    [Type.enum.Bot]: {
      type: Type.enum.Bot,
      label: 'Bot',
    },
    [Type.enum.Humanoid]: {
      type: Type.enum.Humanoid,
      label: 'Humanoid',
    },
    [Type.enum.Phenomenon]: {
      type: Type.enum.Phenomenon,
      label: 'Phenomenon',
    },
    [Type.enum.Reptilian]: {
      type: Type.enum.Reptilian,
      label: 'Reptilian',
    },
  };

  export const Name = z.enum([
    'Android01',
    'Android02',
    'Bot01',
    'Bot02',
    'Bot03',
    'Bot04',
    'Humanoid01',
    'Humanoid02',
    'Phenomenon01',
    'Phenomenon02',
    'Phenomenon03',
    'Phenomenon04',
    'Phenomenon05',
    'Reptilian01',
    'Reptilian02',
    'Reptilian03',
    'Reptilian04',
    'Reptilian05',
    'Reptilian06',
  ]);

  export const Rarity = z.enum([
    'Friendly',
    'Skeptical',
    'Elusive',
    'Supernatural',
  ]);

  export const RarityTestArgs = z.object({
    score: z.preprocess(
      (val) => {
        return Math.max(0, Math.min(100, Number(val)));
      },
      z.number().nonnegative().default(100),
      // val type is still unknown. see https://github.com/colinhacks/zod/pull/1752
    ),
    scoreEasy: z.preprocess(
      function(val) {
        return Math.max(0, Math.min(100, Number(val)));
      },
      z.number().nonnegative().default(100),
      // val type is still unknown. see https://github.com/colinhacks/zod/pull/1752
    ),
  });

  export const RaritySettingModel = z.object({
    rarity: Rarity,
    label: z.string().min(1),
    starCount: z.number().positive(),
    test: z.function().args(RarityTestArgs).returns(z.boolean()),
  });

  export const RaritySetting: Partial<Record<z.infer<typeof Rarity>, z.infer<typeof RaritySettingModel>>> = {
    [Rarity.enum.Friendly]: {
      rarity: Rarity.enum.Friendly,
      label: 'Friendly',
      starCount: 1,
      test() {
        return true;
      },
    },
    [Rarity.enum.Skeptical]: {
      rarity: Rarity.enum.Skeptical,
      label: 'Skeptical',
      starCount: 2,
      test(args) {
        const { scoreEasy } = RarityTestArgs.parse(args);
        return scoreEasy < 5;
      },
    },
    [Rarity.enum.Elusive]: {
      rarity: Rarity.enum.Elusive,
      label: 'Elusive',
      starCount: 3,
      test(args) {
        const { score } = RarityTestArgs.parse(args);
        return score < 3;
      },
    },
    [Rarity.enum.Supernatural]: {
      rarity: Rarity.enum.Supernatural,
      label: 'Supernatural',
      starCount: 4,
      test(args) {
        const { score } = RarityTestArgs.parse(args);
        return score < 1;
      },
    },
  };

  export const TestArgs = RarityTestArgs.extend({
    hourOfDay: z.number().min(0).max(23).default(0),
    actionsPlanProgress: z.number().min(0).max(100).default(0),
    actionsPlanValue: z.number().nonnegative().default(0),
    actionsPlanValueComplete: z.number().nonnegative().default(0),
    actionsPlanCount: z.number().nonnegative().default(0),
    actionsPlanCountComplete: z.number().nonnegative().default(0),
    // count scheduled for future
    // count repeating today
    // count repeating w/ streak
    // count completed w/ effort >=
    // count completed w/ effort <=
    // count completed w/ importance >=
    // count completed w/ importance <=
    // count ideas
    // count custom effects
    // count shade
    // count radia
    // count aether
  });

  export const SettingModel = z.object({
    label: z.string().min(1),
    imgPath: z.string().min(1),
    description: z.string().min(1),
    type: Type,
    rarity: Rarity,
    loyaltyBase: z.number().positive(),
    loyaltyResourceName: Resource.ResourceName,
    loyaltyPerResource: z.number().positive(),
    loyaltyDescription: z.function().args(LoyaltyDescriptionParams).returns(z.string()),
    summonResourceName: Resource.ResourceName,
    summonResourceCost: z.number().nonnegative(),
    test: z.function().args(TestArgs).returns(z.boolean()),
  });

  export const Setting: Partial<Record<z.infer<typeof Name>, z.infer<typeof SettingModel>>> = {
    [Name.enum.Android01]: {
      label: 'Temporal Cooperative',
      imgPath: 'Android01.jpg',
      description: 'Increases action completion value by 5% when there are 3 or more repeating actions in your mission.',
      type: Type.enum.Android,
      rarity: Rarity.enum.Friendly,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 0.5,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `Though they consider you a friend, the ${label} insists that accompanying you any further will cost ${cost} ${resourceLabel.toLowerCase()}.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Android02]: {
      label: 'The Transmogrifier',
      imgPath: 'Android02.jpg',
      description: 'Increases action completion value by 10% while at 50% or less mission completion.',
      type: Type.enum.Android,
      rarity: Rarity.enum.Skeptical,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 0.5,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `${label} humbly asks for a gift: ${cost} ${resourceLabel.toLowerCase()} to remain by your side.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },

    [Name.enum.Bot01]: {
      label: 'Automaton 0-03',
      imgPath: 'Bot01.jpg',
      description: 'Increases the value of the first action you complete in your mission by 30%.',
      type: Type.enum.Bot,
      rarity: Rarity.enum.Friendly,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 1,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `${label} requires ${cost} ${resourceLabel.toLowerCase()} to extend operations.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Bot02]: {
      label: 'Chronobot',
      imgPath: 'Bot02.jpg',
      description: 'Increases the value of actions completed ahead of schedule by 20%.',
      type: Type.enum.Bot,
      rarity: Rarity.enum.Elusive,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 1,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `${label} requires ${cost} ${resourceLabel.toLowerCase()} to extend operations.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Bot03]: {
      label: 'Z1-N14',
      imgPath: 'Bot03.jpg',
      description: 'Increases the value of actions with multiple interests by 10%',
      type: Type.enum.Bot,
      rarity: Rarity.enum.Friendly,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 1,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `${label} requires ${cost} ${resourceLabel.toLowerCase()} to extend operations.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Bot04]: {
      label: 'Serpentine Mover',
      imgPath: 'Bot04.jpg',
      description: 'Increases the value of actions completed before their reminder time by 20%.',
      type: Type.enum.Bot,
      rarity: Rarity.enum.Skeptical,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 1,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `${label} requires ${cost} ${resourceLabel.toLowerCase()} to extend operations.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },

    [Name.enum.Humanoid01]: {
      label: 'Verona Celbari',
      imgPath: 'Humanoid01.jpg',
      description: 'Increases the value of actions completed after using Focus by 25%.',
      type: Type.enum.Humanoid,
      rarity: Rarity.enum.Elusive,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 0.5,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `In return for a gift of ${cost} ${resourceLabel.toLowerCase()}, the powerful ${label} will accompany you on an additional mission.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Humanoid02]: {
      label: 'M. Aela',
      imgPath: 'Humanoid02.jpg',
      description: 'Increases the value of actions scheduled for today by 20%.',
      type: Type.enum.Humanoid,
      rarity: Rarity.enum.Friendly,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 0.5,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `In return for a gift of ${cost} ${resourceLabel.toLowerCase()}, the powerful ${label} will accompany you on an additional mission.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },

    [Name.enum.Phenomenon01]: {
      label: 'Amma',
      imgPath: 'Phenomenon01.jpg',
      description: 'Increases the value of all action completions by 25%.',
      type: Type.enum.Phenomenon,
      rarity: Rarity.enum.Supernatural,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 0.5,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `The fiery ${label} likes gifts. ${cost} ${resourceLabel.toLowerCase()}, to be exact, to continue as your ally.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Phenomenon02]: {
      label: 'Nommo',
      imgPath: 'Phenomenon02.jpg',
      description: 'Increases the value of all action completions by 10%.',
      type: Type.enum.Phenomenon,
      rarity: Rarity.enum.Friendly,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 0.5,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `The fiery ${label} likes gifts. ${cost} ${resourceLabel.toLowerCase()}, to be exact, to continue as your ally.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Phenomenon03]: {
      label: 'Arcadian Seer',
      imgPath: 'Phenomenon03.jpg',
      description: 'Increases the value of all action completions by 20%.',
      type: Type.enum.Phenomenon,
      rarity: Rarity.enum.Elusive,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 0.5,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `If you give the ${label} ${cost} ${resourceLabel.toLowerCase()}, she will stay with you for another mission.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },

    [Name.enum.Reptilian01]: {
      label: 'Svahakti',
      imgPath: 'Reptilian01.jpg',
      description: 'Increases the value of all action completions by 20%.',
      type: Type.enum.Reptilian,
      rarity: Rarity.enum.Friendly,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 1,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `${label} would be honored to continue as your friend. ${cost} ${resourceLabel.toLowerCase()} should suffice.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Reptilian02]: {
      label: 'Nirret-Nirret',
      imgPath: 'Reptilian02.jpg',
      description: 'Increases the value of all action completions by 20%.',
      type: Type.enum.Reptilian,
      rarity: Rarity.enum.Friendly,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 1,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `How much is your friendship worth to ${label}? Exactly ${cost} ${resourceLabel.toLowerCase()}.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Reptilian03]: {
      label: 'Ramal',
      imgPath: 'Reptilian03.jpg',
      description: 'Increases the value of all action completions by 20%.',
      type: Type.enum.Reptilian,
      rarity: Rarity.enum.Elusive,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 0.25,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `Do not insult the mighty ${label}; ${cost} ${resourceLabel.toLowerCase()} is a fair price for her continued assistance.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Reptilian04]: {
      label: 'Vikant',
      imgPath: 'Reptilian04.jpg',
      description: 'Increases the value of all action completions by 20%.',
      type: Type.enum.Reptilian,
      rarity: Rarity.enum.Friendly,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 1,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `"${label} like you. ${label} want ${resourceLabel.toLowerCase()}! ${cost} ${resourceLabel.toLowerCase()} and ${label} stay."`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Reptilian05]: {
      label: 'Ranyani',
      imgPath: 'Reptilian05.jpg',
      description: 'Increases the value of all action completions by 20%.',
      type: Type.enum.Reptilian,
      rarity: Rarity.enum.Friendly,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 1,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `"Entitlement leads to suffering." ${label} the Wise blesses you with profound insight. ${label} demands ${cost} ${resourceLabel.toLowerCase()} for services rendered.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
    [Name.enum.Reptilian06]: {
      label: 'Mooka Paraja',
      imgPath: 'Reptilian06.jpg',
      description: 'Increases the value of all action completions by 20%.',
      type: Type.enum.Reptilian,
      rarity: Rarity.enum.Friendly,
      loyaltyBase: 1,
      loyaltyResourceName: Resource.ResourceName.enum.Radia,
      loyaltyPerResource: 1,
      loyaltyDescription({ label, resourceLabel, cost }: z.infer<typeof LoyaltyDescriptionParams>) {
        return `Do not underestimate ${label}. ${cost} ${resourceLabel.toLowerCase()} is a fair rate for such ability.`;
      },
      summonResourceName: Resource.ResourceName.enum.Radia,
      summonResourceCost: 0,
      test() {
        return true;
      },
    },
  };

  export const DefaultCompanion = Setting[Name.enum.Phenomenon02];
}
