export default {
  computed: {
    navUserItems() {
      let items = [];

      if (this.$store.getters.isDebugger) {
        const debugItems = [];

        debugItems.push({
          label: 'Toggle Debug UI',
          command: () => { this.$store.commit('ui/isDebuggerUIEnabled', !this.$store.state.ui.isDebuggerUIEnabled); this.navLogMessage('toggled debug UI'); },
        });

        debugItems.push({
          label: 'Update "Now" Value',
          command: () => { this.$store.dispatch('updateNowValue'); this.navLogMessage('updated "now"'); },
        });

        debugItems.push({
          label: 'Demo Data',
          command: () => { this.$store.dispatch('ui/showModal', { name: 'demoData' }) },
        });

        debugItems.push({
          label: 'Play Progress',
          command: () => { this.$root.$emit('scene-demo-progress'); this.navLogMessage('demo progress'); },
        });

        debugItems.push({
          label: 'Play Eclipse',
          command: () => { this.$root.$emit('scene-demo-eclipse'); this.navLogMessage('demo eclipse'); },
        });

        debugItems.push({
          label: 'Phenom Start',
          command: () => { this.$root.$emit('call-scene', 'load_attractor', 'lorenz', 1); this.navLogMessage('Phenomenom detected!'); },
        });

        debugItems.push({
          label: 'Phenom Stop',
          command: () => { this.$root.$emit('call-scene', 'unload_attractor'); this.navLogMessage('Phenomenom lost!'); },
        });

        debugItems.push({
          label: 'Gen Loc Names',
          command: () => { this.$store.dispatch('ui/showModal', { name: 'genLocationNames' }) },
        });

        debugItems.push({
          label: 'Sprites',
          command: () => { this.$store.dispatch('ui/showModal', { name: 'spriteCollection' }) },
        });

        debugItems.push({
          label: 'Toast',
          command: () => { this.uiNotify('Test on multiple lines hopefully this will do it', { autoDismissAfter: null }) },
        });

        /*
        debugItems.push({
          label: 'Add Message',
          command: () => { this.uiMessageAlertText('Test') },
        });
        */

        debugItems.push({
          label: 'Local Notifications',
          command: () => { this.$store.dispatch('ui/showModal', { name: 'localNotifications' }) },
        });

        /*
        debugItems.push({
          label: 'Add App Update Message',
          command: () => {
            this.$store.commit('ui/addMessageAlertOfType', {
              type: this.$store.state.ui.MessageType.AppUpdate,
              severity: 'info',
              closable: false,
            });
          },
        });
        */

        items.push({
          label: 'Debug Menu',
          disabled: true,
        });

        items = items.concat(debugItems);
      }

      items.push({
        separator: true,
      });

      items.push({
        label: this.$store.getters.versionString,
        disabled: true,
      });

      items.push({
        label: 'About',
        command: () => { this.$store.dispatch('ui/showModal', { name: 'about' }) },
      });

      items.push({
        label: 'Suggestion Box',
        command: () => { this.$store.dispatch('ui/showModal', { name: 'suggestionBox' }) },
      });

      items.push({
        label: 'View Intro',
        command: () => { this.$store.dispatch('ui/showModal', { name: 'intro' }) },
      });

      items.push({
        label: 'Help/FAQ',
        command: () => { this.$store.dispatch('ui/showModal', { name: 'help' }) },
      });

      items.push({
        label: 'Terms of Service & Privacy Policy',
        command: () => {
          window.open('/terms', this.isNative ? '_self' : '_blank');
        },
      });

      items.push({
        separator: true,
      });

      items.push({
        label: 'Profile & Settings',
        command: () => { this.$store.dispatch('ui/showModal', { name: 'userSettings' }) },
      });

      items.push({
        label: 'Manage Interests',
        command: () => { this.$store.dispatch('ui/showModal', { name: 'interests' }) },
      });

      items.push({
        label: 'Sign Out',
        command: () => { this.uiSignOut() },
      });

      return items;
    },
  },
  methods: {
    navLogMessage(msg) {
      this.$store.dispatch('ui/logMessage', msg);
    },
  },
};
