
import _ from 'lodash';
import { Resource } from '../api/src/models/Resource';
import uiMixin from '../mixins/ui';

export default {
  name: 'ResourceInfo',
  mixins: [
    uiMixin,
  ],
  props: {
    name: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    label() {
      let label = '';

      if (Resource.ResourceName.options.includes(this.name)) {
        ({ label } = Resource.Setting[this.name]);
      }

      return _.upperFirst(label);
    },
    description() {
      let description = '';

      if (Resource.ResourceName.options.includes(this.name)) {
        ({ description } = Resource.Setting[this.name]);
      }

      return description;
    },
    count() {
      return this.$store.getters[`resources/count${this.name}`];
    },
    spendLabel() {
      let spendLabel = '';

      if (Resource.ResourceName.options.includes(this.name)) {
        ({ spendLabel } = Resource.Setting[this.name]);
      }

      return spendLabel;
    },
    spendAtUIName() {
      let spendAtUIName = '';

      if (Resource.ResourceName.options.includes(this.name)) {
        ({ spendAtUIName } = Resource.Setting[this.name]);
      }

      return spendAtUIName;
    },
    spendAtUIPayload() {
      let spendAtUIPayload = {};

      if (Resource.ResourceName.options.includes(this.name)) {
        ({ spendAtUIPayload } = Resource.Setting[this.name]);
      }

      return spendAtUIPayload;
    },
  },
  methods: {
    increment() {
      if (this.$store.getters.isDebugger) {
        const setting = this.$store.getters['resources/getSettingForResource'](this.name);
        this.$store.dispatch('userSettings/editResources', [{
          count: Math.max(0, setting?.count || 0) + 1,
          lastGrantAt: this.$store.state.now.toDate(),
          name: this.name,
        }]);
      }
    },
    handleSpendAtView() {
      if (this.spendAtUIName) {
        this.$store.dispatch('ui/showModal', {
          name: this.spendAtUIName,
          payload: this.spendAtUIPayload,
        });
        this.$emit('close');
      }
    },
  },
};
