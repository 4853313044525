
export default {
  name: 'ActionDescription',
  props: {
    action: {
      type: Object,
      default() {
        return undefined;
      },
    },
    isRepeating: {
      type: Boolean,
      default: false,
    },
    isComplete: {
      type: Boolean,
      default: false,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    isIdea: {
      type: Boolean,
      default: false,
    },
    expiresAtDateDisplay: {
      type: String,
      default: '',
    },
    frequencyDisplay: {
      type: String,
      default: '',
    },
    remindAtDisplay: {
      type: String,
      default: '',
    },
    isOverflowAllowed: {
      type: Boolean,
      default: false,
    },
    brief: {
      type: Boolean,
      default: false,
    },
    hideInterests: {
      type: Boolean,
      default: false,
    },
  },
};
