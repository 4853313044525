// https://github.com/oatmealine/notitg-shaders/blob/master/shadertoy%20conversion%20guide.md
export const shaders = {
  starNest: {
    frag: `
    // Star Nest by Pablo Roman Andrioli

    // kind of like density
    #define iterations 12
    #define formuparam 0.55

    #define volsteps 20
    #define stepsize 0.2 // brightness, density, intensity

    // #define zoom 0.1

    #define tile 0.18 // some kind of tile size?
    // #define speed  -0.002 // negative will reverse

    // #define brightness 0.005
    #define darkmatter 0.100
    #define distfading 0.530
    #define saturation 1.

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
      float zoom = 0.99 - (importanceMultiplier * 0.8);
      float speed = (0.5 - rand) * 0.01;
      float brightness = 0.002 + (completeness * .008);

      //get coords and direction
      vec2 uv = fragCoord.xy / iResolution.xy - .5;
      uv.y *= iResolution.y / iResolution.x;
      vec3 dir = vec3(uv * zoom, 1.);
      float time = iTime * speed + .25;

      //mouse rotation
      float a1 = .5 + iMouse.x / iResolution.x * 2.;
      float a2 = .8 + iMouse.y / iResolution.y * 2.;
      mat2 rot1 = mat2(cos(a1), sin(a1), -sin(a1), cos(a1));
      mat2 rot2 = mat2(cos(a2), sin(a2), -sin(a2), cos(a2));
      dir.xz *= rot1;
      dir.xy *= rot2;
      vec3 from = vec3(1., .5, 0.5);
      from += vec3(time * 2., time, -2.);
      from.xz *= rot1;
      from.xy *= rot2;

      //volumetric rendering
      float s = 0.1, fade = 1.;
      vec3 v = vec3(0.);

      for (int r = 0; r < volsteps; r++) {
        vec3 p = from + s * dir * .5;
        p = abs(vec3(tile)-mod(p,vec3(tile*2.))); // tiling fold
        float pa,a=pa=0.;

        for (int i=0; i<iterations; i++) {
          p=abs(p)/dot(p,p)-formuparam; // the magic formula
          a+=abs(length(p)-pa); // absolute sum of average change
          pa=length(p);
        }

        float dm = max(0., darkmatter - a * a * .001); //dark matter
        a *= a * a; // add contrast

        if (r>6) fade*=1.-dm; // dark matter, don't render near

        //v+=vec3(dm,dm*.5,0.);
        v += fade;
        v += vec3(s, s * s, s * s * s *s) * a * brightness * fade; // coloring based on distance
        fade *= distfading; // distance fading
        s += stepsize;
      }

      v = mix(vec3(length(v)), v, saturation); //color adjust
      fragColor = vec4(v * .01, 1.);
    }
    `,
  },

  starNest2: {
    frag: `
    // https://glslsandbox.com/e#91516.0
    #ifdef GL_ES
    precision mediump float;
    #endif

    #define iterations 6
    #define formuparam2 0.79

    #define volsteps 7
    #define stepsize 0.290
    #define zoom .5
    #define tile   0.850
    #define speed2  0.05
    #define brightness 0.0015
    #define darkmatter 0.100
    #define distfading 0.560
    #define saturation 0.90

    #define transverseSpeed zoom

    #define cloud 0.04

    float triangle(float x, float a) {
      float output2 = 2.0*abs(  2.0*  ( (x/a) - floor( (x/a) + 0.5) ) ) - 1.0;
      return output2;
    }

    float field(in vec3 p) {
      float strength = 7. + .03 * log(1.e-6 + fract(sin(iTime) * 373.11));
      float accum = 0.;
      float prev = 0.;
      float tw = 0.;

      for (int i = 0; i < 6; ++i) {
        float mag = dot(p, p);
        p = abs(p) / mag + vec3(-.5, -.8 + 0.1*sin(-iTime*0.1 + 2.0), -1.1+0.3*cos(iTime*0.3));
        float w = exp(-float(i) / 7.);
        accum += w * exp(-strength * pow(abs(mag - prev), 2.3));
        tw += w;
        prev = mag;
      }
      return max(0., 5. * accum / tw - .7);
    }

    void mainImage( out vec4 fragColor, in vec2 fragCoord ) {
      vec2 uv2 = 2. * fragCoord.xy / vec2(512) - 1.;
      vec2 uvs = uv2 * vec2(512)  / 512.;

      float iTime2 = iTime;
      float speed = -speed2;
      speed = .005 * cos(iTime2*0.02 + 3.1415926/4.0);
      //speed = 0.0;
      float formuparam = formuparam2;

      //get coords and direction
      vec2 uv = uvs;
      //mouse rotation
      float a_xz = 0.9;
      float a_yz = -.6;
      float a_xy = 0.9 + iTime*0.08;

      mat2 rot_xz = mat2(cos(a_xz),sin(a_xz),-sin(a_xz),cos(a_xz));
      mat2 rot_yz = mat2(cos(a_yz),sin(a_yz),-sin(a_yz),cos(a_yz));
      mat2 rot_xy = mat2(cos(a_xy),sin(a_xy),-sin(a_xy),cos(a_xy));

      float v2 =1.0;
      vec3 dir=vec3(uv*zoom,1.);
      vec3 from = vec3(rand * 2. - 1., rand * 5. - 3., rand);

      vec2 mouseMod = iMouse.xy;
      from.x -= 2.0*(mouseMod.x - 0.5);
      from.y -= 2.0*(mouseMod.y - 0.5);

      vec3 forward = vec3(0.,0.,1.);
      from.x += transverseSpeed*(1.0)*cos(0.01*iTime) + 0.001*iTime;
      from.y += transverseSpeed*(1.0)*sin(0.01*iTime) +0.001*iTime;
      from.z += 0.003*iTime;

      dir.xy*=rot_xy;
      forward.xy *= rot_xy;
      dir.xz*=rot_xz;
      forward.xz *= rot_xz;
      dir.yz*= rot_yz;
      forward.yz *= rot_yz;

      from.xy*=-rot_xy;
      from.xz*=rot_xz;
      from.yz*= rot_yz;

      //zoom
      float zooom = (iTime2-3311.)*speed;
      from += forward* zooom;
      float sampleShift = mod( zooom, stepsize );

      float zoffset = -sampleShift;
      sampleShift /= stepsize; // make from 0 to 1

      //volumetric rendering
      float s=0.24;
      float s3 = s + stepsize/2.0;
      vec3 v=vec3(0.);
      float t3 = 0.0;

      vec3 backCol2 = vec3(0.);
      for (int r=0; r<volsteps; r++) {
        vec3 p2=from+(s+zoffset)*dir;// + vec3(0.,0.,zoffset);
        vec3 p3=from+(s3+zoffset)*dir;// + vec3(0.,0.,zoffset);

        p2 = abs(vec3(tile)-mod(p2,vec3(tile*2.))); // tiling fold
        p3 = abs(vec3(tile)-mod(p3,vec3(tile*2.))); // tiling fold
        #ifdef cloud
        t3 = field(p3);
        #endif

        float pa,a=pa=0.;
        for (int i=0; i<iterations; i++) {
          p2=abs(p2)/dot(p2,p2)-formuparam; // the magic formula
          //p=abs(p)/max(dot(p,p),0.005)-formuparam; // another interesting way to reduce noise
          float D = abs(length(p2)-pa); // absolute sum of average change
          a += i > 7 ? min( 12., D) : D;
          pa=length(p2);
        }

        //float dm=max(0.,darkmatter-a*a*.001); //dark matter
        a*=a*a; // add contrast
        //if (r>3) fade*=1.-dm; // dark matter, don't render near
        // brightens stuff up a bit
        float s1 = s+zoffset;
        // need closed form expression for this, now that we shift samples
        float fade = pow(distfading,max(0.,float(r)-sampleShift));
        //t3 += fade;
        v+=fade;
        //backCol2 -= fade;

        // fade out samples as they approach the camera
        if( r == 0 )
          fade *= (1. - (sampleShift));
        // fade in samples as they approach from the distance
        if( r == volsteps-1 )
          fade *= sampleShift;
        v+=vec3(s1,s1*s1,s1*s1*s1*s1)*a*brightness*fade; // coloring based on distance

        backCol2 += mix(.4, 1., v2) * vec3(1.8 * t3 * t3 * t3, 1.4 * t3 * t3, t3) * fade;

        s+=stepsize;
        s3 += stepsize;
      }//фор

      v=mix(vec3(length(v)),v,saturation); //color adjust

      vec4 forCol2 = vec4(v*.01,1.);
      #ifdef cloud
      backCol2 *= cloud;
      #endif
      backCol2.b *= 1.8;
      backCol2.r *= 0.05;

      backCol2.b = 0.5*mix(backCol2.g, backCol2.b, 0.8);
      backCol2.g = 0.0;
      backCol2.bg = mix(backCol2.gb, backCol2.bg, 0.5*(cos(iTime*0.01) + 1.0));
      fragColor = forCol2 + vec4(backCol2, 1.0);
    }
    `,
  },

  nebulaSmoke: {
    frag: `
    // simple nebula rendered using some volumetric noise and a hacky voronoi starfield - Del 06/10/2020
    // this shader is purely used for baking a procedural cubemap texture, please ignore the hackery & lack of speed - a realtime version would require some baked noise textures.
    // WaveletNoise by BigWigs, Voronoi by IQ.

    vec3 erot(vec3 p, vec3 ax, float ro)
    {
        return mix(dot(p,ax)*ax,p,cos(ro))+sin(ro)*cross(ax,p);
    }

    float WaveletNoise(vec3 p, float z, float k) {
        // https://www.shadertoy.com/view/wsBfzK
        float d=0.,s=1.,m=0., a;
        for(float i=0.; i<3.; i++) {
            vec3 q = p*s, g=fract(floor(q)*vec3(123.34,233.53,314.15));
          g += dot(g, g+23.234);
        a = fract(g.x*g.y)*1e3 +z*(mod(g.x+g.y, 2.)-1.); // add vorticity
            q = (fract(q)-.5);
            q = erot(q, normalize(tan(g+.1)), a);
            d += sin(q.x*10.+z)*smoothstep(.25, .0, dot(q,q))/s;
            p = erot(p,normalize(vec3(-1,1,0)),atan(sqrt(2.)))+i; //rotate along the magic angle
            m += 1./s;
            s *= k;
        }
        return d/m;
    }

    vec3 hash( vec3 x )
    {
      x = vec3( dot(x,vec3(127.1,311.7, 74.7)),
            dot(x,vec3(269.5,183.3,246.1)),
            dot(x,vec3(113.5,271.9,124.6)));
      return fract(sin(x)*43758.5453123);
    }

    // returns closest, second closest, and cell id
    vec3 voronoi( in vec3 x )
    {
        vec3 p = floor( x );
        vec3 f = fract( x );

      float id = 0.0;
        vec2 res = vec2( 100.0 );
        for( int k=-1; k<=1; k++ )
        for( int j=-1; j<=1; j++ )
        for( int i=-1; i<=1; i++ )
        {
            vec3 b = vec3( float(i), float(j), float(k) );
            vec3 r = vec3( b ) - f + hash( p + b );
            float d = dot( r, r );

            if( d < res.x )
            {
          id = dot( p+b, vec3(1.0,57.0,113.0 ) );
                res = vec2( d, res.x );
            }
            else if( d < res.y )
            {
                res.y = d;
            }
        }

        return vec3( sqrt( res ), abs(id) );
    }

    // starfield hack :)
    float stars(vec3 pp1)
    {
        float f;
        vec3 vv = voronoi(pp1);
        f = vv.x;
        float size = abs(sin((vv.z*32.3215))*9.0);
        f = 1.0-(f*(19.0+size));
        float twink = 0.5+sin(f+iTime*1.3+vv.z)*0.5;
        f = clamp(f+(twink*0.2),0.0,1.0);
        return f;
    }

    #define brightness -7.
    #define saturation 0.95

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
      vec2 uv=fragCoord.xy/iResolution.xy-.5;
      uv.y*=iResolution.y/iResolution.x;
      vec3 dir=vec3(uv * 0.8, 1.);
      float time=iTime * 0.02;

      //rotation
        vec2  mm = iMouse.xy/iResolution.xy;
      float a1=0.5+(mm.x*0.5);
      float a2=0.8+(mm.y*0.8);

      mat2 rot1=mat2(cos(a1),sin(a1),-sin(a1),cos(a1));
      mat2 rot2=mat2(cos(a2),sin(a2),-sin(a2),cos(a2));
      dir.xz*=rot1;
      dir.xy*=rot2;
      vec3 from=vec3(1.,.5,.75);
      from+=vec3(time*1.8,time,-5.);
      from.xz*=rot1;
      from.xy*=rot2;

      // volumetric
      float s=0.1;
        float fade=1.;
      vec3 v=vec3(0.);
      for (int r=0; r<25; r++)
        {
        vec3 p=from+s*dir*.5;
        vec3 p2 = p*2.9;
        float a = WaveletNoise(p2, 0.0, 1.9)*2.0 - 1.0;
        a *= a * a;
        v += vec3(s, s*s, s*s*s*s)* a * brightness * fade;
        fade *= 0.905;
        s += 0.032*1.55;
      }

        // Star hack
        float f = 1.0;
        vec3 pp1=from+dir*1.1;
      f = stars(pp1*20.0);
        pp1=from+dir*1.35;
      f = max(f,stars(pp1*20.0));
        pp1=from+dir*1.7;
      f = max(f,stars(pp1*20.0));

      v=mix(vec3(length(v)),v,saturation);

        v = clamp(v.gbr * 0.013, vec3(0.0), vec3(1.0));
        v+=v*f; // dirty starblend

      fragColor = vec4(v,1.);
    }
    `,
  },

  fog: {
    frag: `
    float random (in vec2 _st) {
        return fract(sin(dot(_st.xy,
                            vec2(12.9898,78.233)))*
            43758.5453123);
    }

    // Based on Morgan McGuire @morgan3d
    // https://www.shadertoy.com/view/4dS3Wd
    float noise (in vec2 _st) {
        vec2 i = floor(_st);
        vec2 f = fract(_st);

        // Four corners in 2D of a tile
        float a = random(i);
        float b = random(i + vec2(1.0, 0.0));
        float c = random(i + vec2(0.0, 1.0));
        float d = random(i + vec2(1.0, 1.0));

        vec2 u = f * f * (3.0 - 2.0 * f);

        return mix(a, b, u.x) +
                (c - a)* u.y * (1.0 - u.x) +
                (d - b) * u.x * u.y;
    }

    #define NUM_OCTAVES 5

    float fbm ( in vec2 _st) {
        float v = 0.0;
        float a = 0.5;
        vec2 shift = vec2(100.0);
        // Rotate to reduce axial bias
        mat2 rot = mat2(cos(0.5), sin(0.5),
                        -sin(0.5), cos(0.50));
        for (int i = 0; i < NUM_OCTAVES; ++i) {
            v += a * noise(_st);
            _st = rot * _st * 2.0 + shift;
            a *= 0.5;
        }
        return v;
    }

    void mainImage(out vec4 fragColor, in vec2 fragCoord) {
        vec2 st = fragCoord.xy / iResolution.xy * 3.;

        // st += st * abs(sin(u_time*0.1)*3.0);
        vec3 color = vec3(0.0);

        vec2 q = vec2(0.);
        q.x = fbm( st + 0.00*iTime);
        q.y = fbm( st + vec2(1.0));

        vec2 r = vec2(0.);
        r.x = fbm( st + 1.0*q + vec2(1.7,9.2)+ 0.15*iTime );
        r.y = fbm( st + 1.0*q + vec2(8.3,2.8)+ 0.126*iTime);

        float f = fbm(st+r);

        color = mix(vec3(0.101961,0.619608,0.666667),
                    vec3(0.666667,0.666667,0.498039),
                    clamp((f*f)*4.0,0.0,1.0));

        color = mix(color,
                    vec3(0,0,0.164706),
                    clamp(length(q),0.0,1.0));

        color = mix(color,
                    vec3(0.666667,1,1),
                    clamp(length(r.x),0.0,1.0));

        fragColor = vec4((f*f*f+.6*f*f+.5*f)*color,1.);
    }
    `,
  },

  symmetricOrigins: {
    frag: `
    // Symmetric Origins
    // A fractal that behaves like a Rubik's cube with a bit of a dyson sphere feel.
    // https://www.shadertoy.com/view/4dSXDd
    //--------------------------------------------------------------------------------------
    //License CC0 - http://creativecommons.org/publicdomain/zero/1.0/
    //To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
    //----------------------------------------------------------------------------------------
    //^ This means do ANYTHING YOU WANT with this code. Because we are programmers, not lawyers.
    //-Otavio Good
    //

    // This will lower the framerate, but looks kinda cool
    //#define TOO_MUCH_FRACTAL

    //#define MOVING_SUN
    float outerSphereRad = 3.5;

    // noise functions
    float Hash1d(float u)
    {
        return fract(sin(u)*143.9); // scale this down to kill the jitters
    }
    float Hash2d(vec2 uv)
    {
        float f = uv.x + uv.y * 37.0;
        return fract(sin(f)*104003.9);
    }
    float Hash3d(vec3 uv)
    {
        float f = uv.x + uv.y * 37.0 + uv.z * 521.0;
        return fract(sin(f)*110003.9);
    }
    float mixP(float f0, float f1, float a)
    {
        return mix(f0, f1, a*a*(3.0-2.0*a));
    }
    const vec2 zeroOne = vec2(0.0, 1.0);
    float noise2d(vec2 uv)
    {
        vec2 fr = fract(uv.xy);
        vec2 fl = floor(uv.xy);
        float h00 = Hash2d(fl);
        float h10 = Hash2d(fl + zeroOne.yx);
        float h01 = Hash2d(fl + zeroOne);
        float h11 = Hash2d(fl + zeroOne.yy);
        return mixP(mixP(h00, h10, fr.x), mixP(h01, h11, fr.x), fr.y);
    }
    float noise(vec3 uv)
    {
        vec3 fr = fract(uv.xyz);
        vec3 fl = floor(uv.xyz);
        float h000 = Hash3d(fl);
        float h100 = Hash3d(fl + zeroOne.yxx);
        float h010 = Hash3d(fl + zeroOne.xyx);
        float h110 = Hash3d(fl + zeroOne.yyx);
        float h001 = Hash3d(fl + zeroOne.xxy);
        float h101 = Hash3d(fl + zeroOne.yxy);
        float h011 = Hash3d(fl + zeroOne.xyy);
        float h111 = Hash3d(fl + zeroOne.yyy);
        return mixP(
            mixP(mixP(h000, h100, fr.x),
                mixP(h010, h110, fr.x), fr.y),
            mixP(mixP(h001, h101, fr.x),
                mixP(h011, h111, fr.x), fr.y)
            , fr.z);
    }

    float PI=3.14159265;

    // Variables for animating and rotating the sides of the object
    float chunkAnim = 0.0;
    mat3 rotMat;
    vec3 rotDir;
    float rotAmount;

    vec3 saturate(vec3 a) { return clamp(a, 0.0, 1.0); }
    vec2 saturate(vec2 a) { return clamp(a, 0.0, 1.0); }
    float saturate(float a) { return clamp(a, 0.0, 1.0); }

    // This function basically is a procedural environment map that makes the sun
    vec3 sunCol = vec3(258.0, 208.0, 100.0) / 4255.0;
    vec3 GetSunColorReflection(vec3 rayDir, vec3 sunDir)
    {
      vec3 localRay = normalize(rayDir);
      float dist = 1.0 - (dot(localRay, sunDir) * 0.5 + 0.5);
      float sunIntensity = 0.015 / dist;
      sunIntensity = pow(sunIntensity, 0.3)*100.0;

        sunIntensity += exp(-dist*12.0)*300.0;
      sunIntensity = min(sunIntensity, 40000.0);
      return sunCol * sunIntensity*0.0425;
    }
    vec3 GetSunColorSmall(vec3 rayDir, vec3 sunDir)
    {
      vec3 localRay = normalize(rayDir);
      float dist = 1.0 - (dot(localRay, sunDir) * 0.5 + 0.5);
      float sunIntensity = 0.05 / dist;
        sunIntensity += exp(-dist*12.0)*300.0;
      sunIntensity = min(sunIntensity, 40000.0);
      return sunCol * sunIntensity*0.025;
    }

    // This spiral noise works by successively adding and rotating sin waves while increasing frequency.
    // It should work the same on all computers since it's not based on a hash function like some other noises.
    // It can be much faster than other noise functions if you're ok with some repetition.
    const float nudge = 0.71; // size of perpendicular vector
    float normalizer = 1.0 / sqrt(1.0 + nudge*nudge); // pythagorean theorem on that perpendicular to maintain scale
    // Total hack of the spiral noise function to get a rust look
    float RustNoise3D(vec3 p)
    {
        float n = 0.0;
        float iter = 1.0;
        float pn = noise(p*0.125);
        pn += noise(p*0.25)*0.5;
        pn += noise(p*0.5)*0.25;
        pn += noise(p*1.0)*0.125;
        for (int i = 0; i < 7; i++)
        {
            //n += (sin(p.y*iter) + cos(p.x*iter)) / iter;
            float wave = saturate(cos(p.y*0.25 + pn) - 0.998);
          // wave *= noise(p * 0.125)*1016.0;
            n += wave;
            p.xy += vec2(p.y, -p.x) * nudge;
            p.xy *= normalizer;
            p.xz += vec2(p.z, -p.x) * nudge;
            p.xz *= normalizer;
            iter *= 1.4733;
        }
        return n*500.0;
    }

    vec3 camPos = vec3(0.0), camFacing;
    vec3 camLookat=vec3(0,0.0,0);

    // This is the big money function that makes the crazy fractally shape
    float DistanceToObject(vec3 p)
    {
        //p += (1.0/p.y)*0.6;

        // Rotate, but only the part that is on the side of rotDir
        if (dot(p, rotDir) > 1.0) p *= rotMat;

        // Repeat our position so we can carve out many cylindrical-like things from our solid
        vec3 rep = fract(p)-0.5;
        //final = max(final, -(length(rep.xz*rep.xz)*1.0 - 0.0326));
        float final = -(length(rep.xy*rep.xz) - 0.109);
        final = max(final, -(length(rep.zy) - 0.33));

        //final = max(final, -(length(rep.xz*rep.xz) - 0.03));
        //final = max(final, -(length(rep.yz*rep.yz) - 0.03));
        //final = max(final, -(length(rep.xy*rep.xy) - 0.030266));

        // Repeat the process of carving things out for smaller scales
        vec3 rep2 = fract(rep*2.0)-0.5;
        final = max(final, -(length(rep2.xz)*0.5 - 0.125));
        final = max(final, -(length(rep2.xy)*0.5 - 0.125));
        final = max(final, -(length(rep2.zy)*0.5 - 0.125));

        vec3 rep3 = fract(rep2*3.0)-0.5;
        final = max(final, -(length(rep3.xz)*0.1667 - 0.25*0.1667));
        final = max(final, -(length(rep3.xy)*0.1667 - 0.25*0.1667));
        final = max(final, -(length(rep3.zy)*0.1667 - 0.25*0.1667));

    #ifdef TOO_MUCH_FRACTAL
        vec3 rep4 = fract(rep3*3.0)-0.5;
        final = max(final, -(length(rep4.xz)*0.0555 - 0.25*0.0555));
        final = max(final, -(length(rep4.xy)*0.0555 - 0.25*0.0555));
        final = max(final, -(length(rep4.yz)*0.0555 - 0.25*0.0555));

        vec3 rep5 = fract(rep4*3.0)-0.5;
        final = max(final, -(length(rep5.xz)*0.0185 - 0.25*0.0185));
        final = max(final, -(length(rep5.xy)*0.0185 - 0.25*0.0185));
        final = max(final, -(length(rep5.yz)*0.0185 - 0.25*0.0185));
    #endif

        // Cut out stuff outside of outer sphere
        final = max(final, (length(p) - outerSphereRad));
        // Carve out inner sphere
        final = max(final, -(length(p) - 2.8));
        //final = max(final, abs(p.x) - 2.0); // for that space station look
        //final = (length(p) - outerSphereRad); // for debugging texture and lighting
        // Slice the object in a 3d grid so it can rotate like a rubik's cube
        float slice = 0.02;
        vec3 grid = -abs(fract(p.xyz)) + slice;
        final = max(final, grid.x);
        final = max(final, grid.y);
        final = max(final, grid.z);
        //final = min(final, abs(p.y));
        return final;
    }

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
      // ---------------- First, set up the camera rays for ray marching ----------------
      vec2 uv = fragCoord.xy/iResolution.xy * 2.0 - 1.0;

      // Camera up vector.
      vec3 camUp=vec3(0,1,0);

      // Camera lookat.
      camLookat=vec3(0,0.0,0);

        // debugging camera
        float mx=iMouse.x/iResolution.x*PI*2.0 + iTime * 0.166;
      float my=-iMouse.y/iResolution.y*10.0 + sin(iTime * 0.3)*0.8+0.1;//*PI/2.01;
        // move camera in and out of the sphere
        float smallTime = iTime*0.2;
        float inOut = pow(abs(-cos(smallTime)), 0.6)* sign(-cos(smallTime));
      camPos += vec3(cos(my)*cos(mx),sin(my),cos(my)*sin(mx))*(3.35+inOut*2.0);

        // add randomness to camera for depth-of-field look close up.
        //camPos += vec3(Hash2d(uv)*0.91, Hash2d(uv+37.0), Hash2d(uv+47.0))*0.01;

      // Camera setup.
      vec3 camVec=normalize(camLookat - camPos);
      vec3 sideNorm=normalize(cross(camUp, camVec));
      vec3 upNorm=cross(camVec, sideNorm);
      vec3 worldFacing=(camPos + camVec);
      vec3 worldPix = worldFacing + uv.x * sideNorm * (iResolution.x/iResolution.y) + uv.y * upNorm;
      vec3 relVec = normalize(worldPix - camPos);

      // -------------------------------- animate ---------------------------------------
        float localTime = iTime*0.5;
        float floorTime = floor(localTime);
        float zeroToOne = max(0.0,fract(localTime)*1.0-0.0);// *4.0-3.0);
        // This is the 0..1 for the rotation
        chunkAnim = smoothstep(0.0, 1.0, zeroToOne);
        // This is for brightening the outer sphere when a rotation happens
        float pulse = saturate(-log(zeroToOne*30.0)+2.0);

        //float mft = mod(floorTime, 6.0);
        // Let's make it rotate a random part every time
        float mft = Hash1d(floorTime * 2.34567);
        mft = floor(mft * 5.9999); // get a random [0..6) integer
        // randomize where the rotation slice is
        float uglyRand = Hash1d(floorTime*1.234567);
        uglyRand = floor(uglyRand*2.999); // get a random [0..3) integer
        uglyRand = 1.0 / (uglyRand + 1.0);

        // Check which axis we should rotate on and make a matrix for it.
        if (mft <= 1.0)
        {
            rotAmount = PI;
            float cos = cos(chunkAnim * rotAmount);
            float sin = sin(chunkAnim * rotAmount);
            rotMat = mat3(1.0, 0.0, 0.0,
                          0.0, cos, sin,
                          0.0, -sin, cos);
            rotDir = vec3(uglyRand, 0.0, 0.0);
        }
        else if (mft <= 3.0)
        {
            rotAmount = PI;
            float cos = cos(chunkAnim * rotAmount);
            float sin = sin(chunkAnim * rotAmount);
            rotMat = mat3(cos, 0.0, -sin,
                          0.0, 1.0, 0.0,
                          sin, 0.0, cos);
            rotDir = vec3(0.0, uglyRand, 0.0);
        }
        else
        {
            rotAmount = PI;
            float cos = cos(chunkAnim * rotAmount);
            float sin = sin(chunkAnim * rotAmount);
            rotMat = mat3(cos, sin, 0.0,
                          -sin, cos, 0.0,
                          0.0, 0.0, 1.0);
            rotDir = vec3(0.0, 0.0, uglyRand);
        }
        if (mod(floorTime, 2.0) == 0.0) rotDir = -rotDir;

      // --------------------------------------------------------------------------------
      float dist = 0.15;
      float t = 0.2 + Hash2d(uv)*0.1; // fade things close to the camera
      float inc = 0.02;
      float maxDepth = 11.0;
      vec3 pos = vec3(0,0,0);
        float glow = 0.0;
      // ray marching time
        for (int i = 0; i < 110; i++) // This is the count of the max times the ray actually marches.
        {
            if ((t > maxDepth) || (abs(dist) < 0.001)) break;
            pos = camPos + relVec * t;
            // *******************************************************
            // This is _the_ function that defines the "distance field".
            // It's really what makes the scene geometry.
            // *******************************************************
            dist = DistanceToObject(pos);
            // Do some tricks for marching so that we can march the inner glow sphere
            float lp = length(pos);
            //if (lp > outerSphereRad + 0.9) break;
            float inv = max(0.0, 0.1*dist / lp - 0.1);
            dist = min(max(0.15,lp*0.6 - 0.1), dist);
            glow += inv;//0.001
            glow += 0.0025;

            // no deformations messing up the distance function this time. Hurray for getting the math right!
            t += dist;//*0.9995; // because deformations mess up distance function.
        }

      // --------------------------------------------------------------------------------
      // Now that we have done our ray marching, let's put some color on this geometry.

    #ifdef MOVING_SUN
      vec3 sunDir = normalize(vec3(sin(iTime*0.047-1.5), cos(iTime*0.047-1.5), -0.5));
    #else
      vec3 sunDir = normalize(vec3(0.93, 1.0, -1.5));
    #endif
      vec3 finalColor = vec3(0.0);

      // If a ray actually hit the object, let's light it.
      if (abs(dist) < 0.75)
        //if (t <= maxDepth)
      {
            // calculate the normal from the distance field. The distance field is a volume, so if you
            // sample the current point and neighboring points, you can use the difference to get
            // the normal.
            vec3 smallVec = vec3(0.0025, 0, 0);
            vec3 normalU = vec3(dist - DistanceToObject(pos - smallVec.xyy),
                              dist - DistanceToObject(pos - smallVec.yxy),
                              dist - DistanceToObject(pos - smallVec.yyx));

            vec3 normal = normalize(normalU);

            // calculate 2 ambient occlusion values. One for global stuff and one
            // for local stuff
            float ambientS = 1.0;
            //ambientS *= saturate(DistanceToObject(pos + normal * 0.1)*10.0);
            ambientS *= saturate(DistanceToObject(pos + normal * 0.2)*5.0);
            ambientS *= saturate(DistanceToObject(pos + normal * 0.4)*2.5);
            ambientS *= saturate(DistanceToObject(pos + normal * 0.8)*1.25);
            float ambient = ambientS * saturate(DistanceToObject(pos + normal * 1.6)*1.25*0.5);
            ambient *= saturate(DistanceToObject(pos + normal * 3.2)*1.25*0.25);
            ambient *= saturate(DistanceToObject(pos + normal * 6.4)*1.25*0.125);
            //ambient = max(0.05, pow(ambient, 0.3)); // tone down ambient with a pow and min clamp it.
            ambient = saturate(ambient);

            // Trace a ray toward the sun for sun shadows
            float sunShadow = 1.0;
            float iter = 0.05;
        for (int i = 0; i < 30; i++)
            {
                vec3 tempPos = pos + sunDir * iter;
                //if (dot(tempPos, tempPos) > outerSphereRad*outerSphereRad+0.8) break;
                if (iter > outerSphereRad + outerSphereRad) break;
                float tempDist = DistanceToObject(tempPos);
              sunShadow *= saturate(tempDist*50.0);
                if (tempDist <= 0.0) break;
                //iter *= 1.5; // constant is more reliable than distance-based???
                iter += max(0.01, tempDist)*1.0;
            }
            sunShadow = saturate(sunShadow);

            // calculate the reflection vector for highlights
            vec3 ref = reflect(relVec, normal);

            // make sure the texture gets rotated along with the geometry.
            vec3 posTex = pos;
            if (dot(pos, rotDir) > 1.0) posTex = pos * rotMat;
            posTex = abs(posTex); // make texture symetric so it doesn't pop after rotation

            // make a few frequencies of noise to give it some texture
            float n =0.0;
            n += noise(posTex*32.0);
            n += noise(posTex*64.0);
            n += noise(posTex*128.0);
            n += noise(posTex*256.0);
            n += noise(posTex*512.0);
            n *= 0.8;
            normal = normalize(normal + n*0.1);

            // ------ Calculate texture color  ------
            vec3 texColor = vec3(0.95, 1.0, 1.0);
            vec3 rust = vec3(0.65, 0.25, 0.1) - noise(posTex*128.0);
            texColor *= smoothstep(texColor, rust, vec3(saturate(RustNoise3D(posTex*8.0))-0.2));

            // make outer edge a little brighter
        texColor += (1.0 - vec3(19.0, 5.0, 2.0) * length(normalU))*ambientS;
            // apply noise
            texColor *= vec3(1.0)*n*0.05;
            texColor *= 0.7;
            texColor = saturate(texColor);

            // ------ Calculate lighting color ------
            // Start with sun color, standard lighting equation, and shadow
            vec3 lightColor = vec3(0.6) * saturate(dot(sunDir, normal)) * sunShadow;
            // weighted average the near ambient occlusion with the far for just the right look
            float ambientAvg = (ambient*3.0 + ambientS) * 0.25;
            // a red and blue light coming from different directions
            lightColor += (vec3(1.0, 0.2, 0.4) * saturate(-normal.z *0.5+0.5))*pow(ambientAvg, 0.5);
            lightColor += (vec3(0.1, 0.5, 0.99) * saturate(normal.y *0.5+0.5))*pow(ambientAvg, 0.5);
            // blue glow light coming from the glow in the middle of the sphere
            lightColor += vec3(0.3, 0.5, 0.9) * saturate(dot(-pos, normal))*pow(ambientS, 0.3);
    //        lightColor *= ambient;
            lightColor *= 4.0;

            // finally, apply the light to the texture.
            finalColor = texColor * lightColor;
            // sun reflection to make it look metal
            finalColor += vec3(1.0)*pow(n,4.0)* GetSunColorSmall(ref, sunDir) * sunShadow;// * ambientS;
            // fog that fades to reddish plus the sun color so that fog is brightest towards sun
            //finalColor = mix(vec3(1.0, 0.41, 0.41)*skyMultiplier + min(vec3(0.25),GetSunColorSmall(relVec, sunDir))*2.0*sunSet, finalColor, exp(-t*0.003));
            // pulse the outer edge color when something is about to rotate
            if (dot(pos, rotDir) > 1.0) finalColor += vec3(0.2, 1.4, 0.8)*pulse*saturate(0.000001 / pow(abs(length(pos)-outerSphereRad), 2.0))*2.0;
      }
        else
        {
            // Our ray trace hit nothing, so draw sky.
            //finalColor = saturate(GetSunColorSmall(relVec, sunDir)*0.95-0.01);
        }
        // add the ray marching glow
        finalColor += vec3(0.3, 0.5, 0.9) * glow;

        // vignette?
        finalColor *= vec3(1.0) * saturate(1.0 - length(uv/2.5));
        finalColor *= 1.3;

      // output the final color with sqrt for "gamma correction"
      fragColor = vec4(sqrt(clamp(finalColor, 0.0, 1.0)),1.0);
    }
    `,
  },

  warpSpeed2: {
    frag: `
    // 'Warp Speed 2'
    // David Hoskins 2015.
    // License Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License.

    // Fork of:-   https://www.shadertoy.com/view/Msl3WH
    //----------------------------------------------------------------------------------------

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
      float s = 0.0, v = 0.0;
      vec2 uv = (fragCoord / iResolution.xy) * 2.0 - 1.;
        float time = (iTime-2.0)*58.0;
      vec3 col = vec3(0);
        vec3 init = vec3(sin(time * .0032)*.3, .35 - cos(time * .005)*.3, time * 0.002);
      for (int r = 0; r < 100; r++)
      {
        vec3 p = init + s * vec3(uv, 0.05);
        p.z = fract(p.z);
            // Thanks to Kali's little chaotic loop...
        for (int i=0; i < 10; i++) p = abs(p * 2.04) / dot(p, p) - .9;
        v += pow(dot(p, p), .7) * .06;
        col +=  vec3(v * 0.2+.4, 12.-s*2., .1 + v * 1.) * v * 0.00003;
        s += .025;
      }
      fragColor = vec4(clamp(col, 0.0, 1.0), 1.0);
    }
    `,
  },

  mouseRot: {
    frag: `
    //CLICK MOUSE AND MOVE
    void mainImage(out vec4 c,in vec2 o){
      c=vec4(0);o-=iResolution.xy/2.0;
      float t=iTime,dt=256.0;vec2 x,y,z;
      vec2 v2=vec2((iMouse.x-iResolution.x/2.0)/iResolution.x,(iMouse.y-iResolution.y/2.0)/iResolution.y)*3.1415;
      for (float i=0.0;i<=16.0;i+=0.25){t=iTime/16.0+i*dt;
          x=vec2(cos(t*1.0)*100.0,sin(t*1.0)*100.0)*v2;
          y=vec2(cos(t*10.0)*60.0,sin(t*10.0)*60.0)*v2;
          z=vec2(cos(t*15.0)*80.0,sin(t*15.0)*80.0);
          c+=vec4(0.0,0.6,0.8,1.0)*vec4(length(o)/length(x+y+z-o)/16.0)/(i+0.5)/2.0;
      }
    }
    `,
  },

  colorCompanions: {
    frag: `
    //philip.bertani@gmail.com

    //uncomment this if you want brighter, more contrasty colors, not sure which I like more
    #define brighter

    #define numOct 6.  //number of fbm octaves
    // float numOct  = 6. ;  //number of fbm octaves
    float focus = 0.;
    float focus2 = 0.;
    #define pi  3.14159265

    float random(vec2 p) {
        //a random modification of the one and only random() func
        return fract( sin( dot( p, vec2(12., 90.)))* 1e6 );
    }

    //this is taken from Visions of Chaos shader "Sample Noise 2D 4.glsl"
    float noise(vec3 p) {
        vec2 i = floor(p.yz);
        vec2 f = fract(p.yz);
        float a = random(i + vec2(0.,0.));
        float b = random(i + vec2(1.,0.));
        float c = random(i + vec2(0.,1.));
        float d = random(i + vec2(1.,1.));
        vec2 u = f*f*(3.-2.*f); //smoothstep here, it also looks good with u=f

        return mix(a,b,u.x) + (c-a)*u.y*(1.-u.x) + (d-b)*u.x*u.y;

    }

    float fbm3d(vec3 p) {
        float v = 0.;
        float a = .5;
        vec3 shift = vec3(focus - focus2);  //play with this

        float angle = pi/4. + .03*focus;      //play with this
        float cc=cos(angle), ss=sin(angle);
        mat3 rot = mat3( cc,  0., ss,
                          0., 1., 0.,
                        -ss, 0., cc );
        for (float i=0.; i<numOct; i++) {
            v += a * noise(p);
            p = rot * p * 2. + shift;
            a *= .2*(1.+focus+focus2);  //changed from the usual .5
        }
        return v;
    }

    mat3 rxz(float an){
        float cc=cos(an),ss=sin(an);
        return mat3(cc,0.,-ss,
                    0.,1.,0.,
                    ss,0.,cc);
    }
    mat3 ryz(float an){
        float cc=cos(an),ss=sin(an);
        return mat3(1.,0.,0.,
                    0.,cc,-ss,
                    0.,ss,cc);
    }

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
        float coord_scale = 2.;
        float tt = iTime / 8.;
        vec2 uv = (2.*fragCoord-iResolution.xy)/iResolution.y;
        vec2 mm = (2.*iMouse.xy-iResolution.xy)/iResolution.y;

        if ( iMouse.w == 0. ) mm.xy += vec2(2.3,.7);

        uv *= coord_scale;

        vec3 rd = normalize( vec3(uv, -1.2) );
        vec3 ro = vec3(0.,1.,0.);

        float delta = pi/100.;
        mat3 rot = rxz(-mm.x*delta) * ryz(-mm.y*delta);

        ro -= rot[2]*iTime/4.;

        vec3 p = ro + rot*rd;

        vec3 q;

        mm *= coord_scale;

        focus = length(uv-mm);
        focus = sqrt(focus);
        focus = 2./(1.+focus/2.);

        focus2 = length(uv+mm );
        focus2 = 1.5/(1.+focus2*focus2);

        q.x = fbm3d(p);
        q.y = fbm3d(p.yzx);
        q.z = fbm3d(p.zxy);

        float f = fbm3d(p + q);

        vec3 cc = q;
        cc *= 30.*f;

    #ifndef brighter
        cc.r += 6.*focus; cc.g+= 2.*focus; cc.b += 9.*focus2; cc.r-=5.*focus2;
        cc /=  25.;
    #else
        cc.r += 4.*focus; cc.g+= 2.*focus; cc.b += 7.*focus2; cc.r-=3.*focus2;
        cc /= 17.;
        cc = pow(cc, vec3(2.));
    #endif

        fragColor = vec4(cc,1.0);
    }
    `,
  },

  starfield: {
    frag: `
    #define M_2PI 6.28318530718
    vec2 polar(vec2 dPoint)
    {
        return vec2(sqrt(dPoint.x * dPoint.x + dPoint.y * dPoint.y), atan(dPoint.y, dPoint.x));
    }

    float rand2(vec2 co)
    {
        return fract(sin(dot(co.xy, vec2(12.9898,78.233))) * 43758.5453);
    }

    vec2 decart(vec2 pPoint)
    {
        return vec2(pPoint.x * cos(pPoint.y), pPoint.x * sin(pPoint.y));
    }

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
        vec2 screen = iResolution.xy;
        vec2 center = screen / 2.0;
        vec2 frag = fragCoord.xy - center;
        vec2 fragPolar = polar(frag);
        float lenCenter = length(center);

      const float bandPass = 720.0;
        const float angleDisp = M_2PI / (bandPass + 1.0);

        const float particlesCount = 200.0;
        const float particleLifetime = 10.0;
        const float particleMaxSize = 7.5;
        float particleMaxSizeNorm = particleMaxSize / lenCenter;

        float globTime = iTime / particleLifetime;
        float timeDelta = bandPass;

        const float polarRadiusClip = 0.05;
        const float polarRadiusMax = 0.75;
        float polarRadiusDelta = polarRadiusMax - polarRadiusClip;

        float presence = 0.0;
        vec2 pPoint;

        for (float i = 0.0; i < particlesCount; i += 1.0)
        {
            float phase = i / particlesCount;

            float localTime = globTime + timeDelta * (2.0 * phase - 1.0) + phase;
            float particleTime = fract(localTime);
            float spaceTransform = pow(particleTime, 8.0);

            pPoint.x = lenCenter * ((polarRadiusClip + polarRadiusDelta * phase) + spaceTransform);

            // +30 FPS :)
            if (abs(pPoint.x - fragPolar.x) > particleMaxSize) continue;

            pPoint.y = floor(particleTime + bandPass * rand2(vec2(floor(localTime), 1))) * angleDisp;

            vec2 dPoint = decart(pPoint);
            float particleSize = particleMaxSize * spaceTransform;
            float localPresence = particleSize * (1.0 - clamp(length(dPoint - frag), 0.0, 1.0));
            presence += localPresence;
        }
        presence = clamp(presence, 0.0, 1.0);
        fragColor = vec4(presence, presence, presence, 1.0);
    }
    `
  },
};
