
import { Effect } from '../api/src/models/effect';

import actionMixin from '../mixins/action';

export default {
  name: 'ActionListItem',
  mixins: [
    actionMixin,
  ],
  props: {
    actionId: {
      type: String,
      default: '',
    },
    brief: {
      type: Boolean,
      default: false,
    },
    isReminder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultEffectName: Effect.EffectName.enum.Completion,
      defaultEffect: Effect.Setting[Effect.EffectName.enum.Completion],
      reminderDelayEffectName: Effect.EffectName.enum.ReminderDelay,
    };
  },
  computed: {
    defaultEffectIcon() {
      return !this.isComplete
        ? ['fal', 'circle']
        : ['fas', this.defaultEffect.icon[1]];
    },
    isCompleteLocal() {
      return this.isComplete;
    },
    createdAtDaysSince() {
      let days = 0;

      if (this.action && this.action.createdAt && !this.isRepeating) {
        days = this.$dayjs().diff(this.action.createdAt, 'day');
      }

      return days;
    },
    ideaFadeClasses() {
      const classes = [];

      if (this.isIdea && this.createdAtDaysSince >= 1) {
        classes.push('action-list-item-description-fade');

        if (this.createdAtDaysSince >= 30) {
          classes.push('action-list-item-description-fade-3');
        } else if (this.createdAtDaysSince >= 14) {
          classes.push('action-list-item-description-fade-2');
        } else if (this.createdAtDaysSince >= 7) {
          classes.push('action-list-item-description-fade-1');
        }
      }

      return classes;
    },
  },
  methods: {
    handleActionSelected() {
      // Get actionSelected directly from state, not what the component passed to us (possibly modified)
      const actionSelected = this.$store.getters['actionsUser/getItemById'](this.action.id);
      this.$emit('select', actionSelected.id);
    },
    showActionEdit(itemId) {
      this.$store.dispatch('ui/showModal', {
        name: 'actionEdit',
        payload: {
          itemId,
        },
      });
    },
  },
};
