import { render, staticRenderFns } from "./ui.vue?vue&type=template&id=2898eeca&"
import script from "./ui.vue?vue&type=script&lang=js&"
export * from "./ui.vue?vue&type=script&lang=js&"
import style0 from "./ui.vue?vue&type=style&index=0&id=2898eeca&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {About: require('/opt/build/repo/components/about.vue').default,EcDialog: require('/opt/build/repo/components/ec/ec-dialog.vue').default,Help: require('/opt/build/repo/components/help.vue').default,SuggestionBox: require('/opt/build/repo/components/suggestion-box.vue').default,ModalFull: require('/opt/build/repo/components/modal-full.vue').default,ActionsPlanCycle: require('/opt/build/repo/components/actions-plan-cycle.vue').default,MessagesView: require('/opt/build/repo/components/messages-view.vue').default,EcDrawer: require('/opt/build/repo/components/ec/ec-drawer.vue').default,IdeaQuickEntry: require('/opt/build/repo/components/idea-quick-entry.vue').default,ResourceInfo: require('/opt/build/repo/components/resource-info.vue').default,ResourceGranted: require('/opt/build/repo/components/resource-granted.vue').default,ActionLogCompletion: require('/opt/build/repo/components/action-log-completion.vue').default,ActionPostponement: require('/opt/build/repo/components/action-postponement.vue').default,ActionReminderDelay: require('/opt/build/repo/components/action-reminder-delay.vue').default,ActionEdit: require('/opt/build/repo/components/action-edit.vue').default,InterestEdit: require('/opt/build/repo/components/interest-edit.vue').default,Value: require('/opt/build/repo/components/value.vue').default,DialogConfirm: require('/opt/build/repo/components/dialog-confirm.vue').default,ToastsView: require('/opt/build/repo/components/toasts-view.vue').default})
