import Vue from 'vue';
import { Capacitor } from '@capacitor/core';

import { Action } from '../api/src/models/Action';
import { App } from '../api/src/models/App';

import AppConcept from '../components/app-concept';
import ResourceValue from '../components/resource-value';
import Value from '../components/value';

export default Vue.extend({
  components: {
    AppConcept,
    ResourceValue,
    Value,
  },
  computed: {
    appName() {
      return this.$store.state.app.name;
    },
    appConceptName() {
      return App.Concept.ConceptName.enum;
    },
    appConcept() {
      return App.Concept.Setting;
    },
    isAuthenticated() {
      return !!this.$store.getters.isAuthenticated;
    },
    isUserInitialized() {
      return !!this.$store.getters.isUserInitialized;
    },
    isDebugger() {
      return !!this.$store.getters.isDebugger;
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    isDesktop() {
      return ['lg', 'xl'].includes(this.$screen.breakpoint);
    },
    uiTopModalName() {
      return this.$store.getters['ui/topModalName'];
    },
    uiInteractionVerb() {
      return this.isDesktop ? 'click' : 'tap';
    },
    uiInteractionVerbUpper() {
      return this.isDesktop ? 'Click' : 'Tap';
    },
  },
  methods: {
    uiMsgBoxOk(message, opts = {}) {
      return new Promise((resolve) => {
        this.$store.dispatch('ui/showModal', {
          name: 'dialogAlert',
          payload: {
            message,
            isTemplate: Boolean(opts.isTemplate),
            header: opts.header || 'Alert',
            okLabel: opts.okLabel || 'OK',
            variant: opts.variant || 'primary',
            onClose: () => {
              resolve();
            },
          },
        });
      });
    },
    uiMsgBoxConfirm(message, opts = {}) {
      return new Promise((resolve) => {
        this.$store.dispatch('ui/showModal', {
          name: 'dialogConfirm',
          payload: {
            message,
            cancelLabel: opts.cancelLabel || opts.rejectLabel || 'Cancel',
            acceptLabel: opts.acceptLabel || opts.okTitle || 'OK',
            acceptVariant: opts.acceptVariant || opts.okVariant,
            onCancel: () => {
              resolve(false);
            },
            onAccept: () => {
              resolve(true);
            },
          }
        });
      });
    },
    uiMessageAlertText(text, options = {}) {
      this.$store.commit('ui/addMessageAlertText', {
        text,
        severity: 'info',
        closable: true,
        ...options,
      });
    },
    uiNotify(simpleMsg, opts = {}) {
      this.$store.dispatch('ui/addToast', {
        text: `${simpleMsg}`,
        ...opts,
      });

      if (opts && opts.logMessage) {
        let logMsg = simpleMsg;

        if (typeof opts.logMessage === 'string' && opts.logMessage !== '') {
          logMsg = opts.logMessage;
        }

        this.$store.dispatch('ui/logMessage', logMsg.toLowerCase());
      }
    },
    uiDisplayMessagesForConcepts(concepts = []) {
      this.$store.dispatch('ui/showModal', {
        name: 'objectivesList',
        payload: {
          appConcepts: concepts,
        },
      });
    },
    async uiDeleteAction(actionId) {
      const item = this.$store.state.actionsUser.all.find(i => i.id === actionId);
      let msg = 'Are you sure you want to delete this action? This cannot be undone.';
      let isDeleting = false;

      if (item) {
        const isRepeating = Action.isFrequencyRepeating(item.frequency);

        if (isRepeating) {
          msg = 'Are you sure you want to delete this repeating action? This cannot be undone.';
        }

        isDeleting = await this.uiMsgBoxConfirm(msg, {
          okTitle: 'Delete',
          okVariant: 'danger',
          id: 'action-delete-modal',
        });

        if (isRepeating) {
          const recentCompletions = this.$store.getters['actionsUser/getItemRecentlyCompletedOccurrences'](item);

          if (recentCompletions && recentCompletions.filter(d => d && d.length > 0).length > 0) {
            const recentMsg = 'It looks like you have recent completions for this repeating action. They won\'t be deleted, but you\'ll lose track of your streak. Continue?';
            isDeleting = await this.uiMsgBoxConfirm(recentMsg, {
              okTitle: 'Continue Deleting',
              okVariant: 'danger',
            });
          }
        }

        if (isDeleting) {
          await this.$store.dispatch('actionsUser/deleteItem', item);

          this.uiNotify('Action deleted', {
            severity: 'success',
            logMessage: 'action deleted',
          });
        }
      }

      return isDeleting;
    },
    uiShowActionEdit(itemId, payload) {
      this.$store.dispatch('ui/showModal', {
        name: 'actionEdit',
        payload: {
          itemId,
          ...payload,
        },
      });
    },
    uiShowActionSelector(listOfIds, activeId) {
      if (activeId && listOfIds.includes(activeId)) {
        const i = listOfIds.indexOf(activeId);
        this.$store.commit('actionSelect/indexStart', i);
      }

      this.$store.commit('actionSelect/list', listOfIds || []);
      this.$store.dispatch('ui/showModal', { name: 'actionSelector' });
    },
    uiHideActionSelector() {
      this.$store.commit('actionSelect/indexStart', 0);
      this.$store.commit('actionSelect/list', []);
      this.$store.dispatch('ui/hideModal', 'actionSelector');
    },
    uiShowInterestEdit(interestId) {
      this.$store.dispatch('ui/showModal', {
        name: 'interestEdit',
        payload: {
          interestId,
        },
      });
    },
    uiShowOfflineMsg() {
      this.uiMsgBoxOk([
        'It looks like you\'re offline! Your data won\'t be synced until you\'re online again.',
        `If you use multiple devices with ${this.appName}, you can avoid errors by using only one device until all of your devices are able to be online again.`,
      ]);
    },
    async uiCopyToClipboard(txt) {
      await navigator.clipboard.writeText(txt);
      this.uiNotify(`Copied "${txt}"`);
    },
    async uiSignOut() {
      if (this.isAuthenticated) {
        const isSigningOut = await this.uiMsgBoxConfirm('Are you sure you want to sign out?');

        if (isSigningOut) {
          await this.$store.dispatch('actionFocus/stopProgress');
          await this.$auth.signOut();
          this.$store.dispatch('logEvent', { name: 'logout' });
        }
      }
    },
  },
});
