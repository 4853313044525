
import { z } from 'zod';
import uiMixin from '../mixins/ui';

export default {
  name: 'DialogPrompt',
  mixins: [
    uiMixin,
  ],
  props: {
    message: {
      type: String,
      default: 'Enter a value.',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isUrl: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      val: '',
    };
  },
  watch: {
    isOpen() {
      this.val = this.value || '';
    },
  },
  methods: {
    handleVal(e) {
      const newVal = e?.target?.value;

      if (typeof newVal === 'string') {
        this.val = newVal;
      }
    },
    async handleDelete() {
      const isDeleting = await this.uiMsgBoxConfirm('Are you sure you want to delete this value?');

      if (isDeleting) {
        this.val = undefined;
        this.$emit('submit', this.val);
        this.$refs.slDialog.hide();
      }
    },
    handleSubmit() {
      const result = z.string().url().safeParse(this.val);

      if (result.success) {
        this.$emit('submit', this.val);
        this.$refs.slDialog.hide();
      }
    },
  },
};
