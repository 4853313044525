import Vue from 'vue';
import _ from 'lodash';

import { wrapGetters } from '../lib/util';
import { StoreHelperMutation, storeHelperMutations } from '../lib/store-helpers';
import { bindToDoc, upsertDoc, deleteDoc } from '../lib/data';

import { ActionFocus } from '../api/src/models/ActionFocus';
import { isDate } from '../api/src/models/util';

let unsub;

const COUNTDOWN = 5;
const defaultState = {
  action: undefined,
  itemId: undefined,
  startedAt: undefined,
  isLocal: false,
  isStopping: false,
  lastSyncAt: undefined,
  countdown: COUNTDOWN,
  countdownInterval: undefined,
  progressInterval: undefined,
};

export const state = () => ({
  ...defaultState,
});

export const mutations = {
  ...storeHelperMutations,
  action(localState, item) {
    localState.action = item;
  },
  isLocal(localState, val) {
    localState.isLocal = val === true;
  },
  isStopping(localState, val) {
    localState.isStopping = val === true;
  },
  startedAt(localState, val) {
    localState.startedAt = val;
  },
  lastSyncAt(localState, val) {
    localState.lastSyncAt = val;
  },
  lastProgressAt(localState, val) {
    if (localState.action) {
      Vue.set(localState.action, 'lastProgressAt', val);
    }
  },
  timeSpentReset(localState) {
    Vue.set(localState.action, 'timeSpent', 0);
  },
  timeSpentIncrement(localState, val) {
    if (localState.action) {
      Vue.set(localState.action, 'timeSpent', Math.max(0, localState.action.timeSpent + val));
    }
  },
  countdownInterval(localState, val) {
    localState.countdownInterval = val;
  },
  countdownDecrement(localState) {
    localState.countdown = localState.countdown - 1;
  },
  countdownReset(localState) {
    localState.countdown = COUNTDOWN;
  },
  progressInterval(localState, val) {
    localState.progressInterval = val;
  },
  reset(localState) {
    Object.keys(defaultState).forEach((k) => {
      localState[k] = defaultState[k];
    });
  }
};

export const getters = wrapGetters('actionFocus', {
  msSinceStart: (state) => {
    let ms;

    if (state.action && state.startedAt) {
      ms = Math.max(0, state.action.lastProgressAt - state.startedAt);
    }

    return ms;
  },
  counterDisplay: (_state, getters, rootState) => {
    let time = '00:00:00';

    // this could probably be more efficient only using dayjs for the formatting and not the math
    if (typeof getters.msSinceStart === 'number' && getters.msSinceStart >= 0) {
      const diff = getters.msSinceStart;
      const today = rootState.now.startOf('day');
      time = today.add(diff, 'ms').format('HH:mm:ss');
    }

    return time;
  },
  shouldDisplayFocus: (state) => {
    return (Boolean(state.action) && state.isLocal === true) ||
      typeof state.countdownInterval !== 'undefined';
  },
  hasNonLocalFocus: (state) => {
    return Boolean(state.action) && !state.isLocal;
  },
});

export const actions = {
  async init({ commit, dispatch }) {
    // Get userId directly from auth to ensure they still have a session
    const userId = await this.$auth.getUserId();

    if (!userId) {
      throw new Error('Could not get action focus for invalid userId');
    }

    this.$dbg('store:actionFocus')('init');

    unsub = bindToDoc(ActionFocus.Model, ActionFocus.DataTableName, userId, {
      onInit: () => {
        dispatch('setDataSourceSync', { name: 'actionFocus' }, { root: true });
      },
      onUpdate: ({ data }) => {
        dispatch('primeLocalData', data);

        delete data.id;
        commit(StoreHelperMutation.SetOnRoot, data);
        dispatch('setDataSourceSync', { name: 'actionFocus' }, { root: true });
      },
      onUpdateEmpty: () => {
        dispatch('stopProgress');
        commit(StoreHelperMutation.SetOnRoot, {
          itemId: undefined,
          startedAt: undefined,
        });
        dispatch('setDataSourceSync', { name: 'actionFocus' }, { root: true });
      },
    });
  },

  async putActionFocus({ rootGetters }, { itemId, startedAt }) {
    try {
      // Get userId directly from auth to ensure they still have a session
      const userId = await this.$auth.getUserId();

      if (!userId) {
        throw new Error('Cannot sync action focus; invalid userId');
      }

      if (!rootGetters.isAllowedToUseApp) {
        throw new Error('Entitlement required.');
      }

      this.$dbg('store:actionFocus')('putActionFocus');

      await upsertDoc(ActionFocus.Model, ActionFocus.DataTableName, userId, {
        itemId,
        startedAt,
      });
    } catch (e) {
      this.$dbg('store:actionFocus')('putActionFocus error', itemId, e);
    }
  },

  async reset({ commit, dispatch }) {
    this.$dbg('store:actionFocus')('reset');

    if (unsub) {
      unsub();
    }

    dispatch('stopAllIntervals');

    // Get userId directly from auth to ensure they DO NOT still have a session!
    // Otherwise, we will risk wiping the user's data
    const userId = await this.$auth.getUserId();

    if (!userId) {
      commit('reset');
    }
  },

  async syncProgress({ state, dispatch }) {
    if (state.action && state.isLocal) {
      this.$dbg('store:actionFocus')('syncProgress');
      await dispatch('actionsUser/editItem', {
        id: state.action.id,
        mods: {
          lastProgressAt: state.action.lastProgressAt,
          timeSpent: state.action.timeSpent,
        },
      }, { root: true });
    }
  },

  updateProgress({ state, commit }) {
    if (state.action && state.isLocal) {
      this.$dbg('store:actionFocus')('updateProgress');
      const previousProgressAt = state.action.lastProgressAt;
      const lastProgressAt = new Date();
      commit('lastProgressAt', lastProgressAt);

      let timeSpentDiffSeconds = 0;

      if (isDate(previousProgressAt)) {
        timeSpentDiffSeconds = Math.floor((lastProgressAt.getTime() - previousProgressAt.getTime()) / 1000);
      }

      commit('timeSpentIncrement', timeSpentDiffSeconds);
    }
  },

  primeLocalData({ state, rootState, rootGetters, commit, dispatch }, { itemId, startedAt }) {
    if (itemId && startedAt && !state.action) {
      this.$dbg('store:actionFocus')('primeLocalData');
      const action = rootGetters['actionsUser/getItemById'](itemId);

      if (action) {
        commit('action', _.cloneDeep(action));

        if (!rootGetters['actionsUser/isRepeatingItemTimeSpentValid'](action)) {
          commit('timeSpentReset');
        }

        const d = new Date();
        commit('startedAt', d);
        commit('lastProgressAt', d);
        commit('lastSyncAt', d);
        dispatch('syncProgress');

        const progressId = setInterval(() => {
          dispatch('updateProgress');

          if (rootState.now.isAfter(state.lastSyncAt)) {
            commit('lastSyncAt', new Date());
            dispatch('syncProgress');
          }
        }, 1000);

        commit('progressInterval', progressId);
      }
    }
  },

  stopAllIntervals({ state, commit }) {
    this.$dbg('store:actionFocus')('stopAllIntervals');
    commit('countdownReset');

    if (typeof state.countdownInterval !== 'undefined') {
      clearInterval(state.countdownInterval);
      commit('countdownInterval', undefined);
    }

    if (typeof state.progressInterval !== 'undefined') {
      clearInterval(state.progressInterval);
      commit('progressInterval', undefined);
    }
  },

  startLocally({ state, commit, dispatch }, itemId) {
    this.$dbg('store:actionFocus')('startLocally');
    const countdownId = setInterval(() => {
      if (state.countdown === 1) {
        const startedAt = new Date();
        dispatch('stopAllIntervals');
        dispatch('primeLocalData', { itemId, startedAt });
        dispatch('makeLocal');
        dispatch('putActionFocus', { itemId, startedAt });
      } else {
        commit('countdownDecrement');
      }
    }, 1000);

    commit('countdownInterval', countdownId);
  },

  makeLocal({ state, commit }) {
    if (state.action) {
      this.$dbg('store:actionFocus')('makeLocal');
      commit('isLocal', true);
    }
  },

  async stopProgress({ commit, dispatch }) {
    this.$dbg('store:actionFocus')('stopProgress');
    dispatch('updateProgress');
    await dispatch('syncProgress');
    dispatch('stopAllIntervals');
    commit('reset');

    // Get userId directly from auth to ensure they still have a session
    const userId = await this.$auth.getUserId();
    deleteDoc(ActionFocus.DataTableName, userId);
  },
};
