
export default {
  name: 'ResourceValue',
  props: {
    name: {
      type: String,
      default: undefined,
    },
    customCount: {
      type: Number,
      default: undefined,
    },
    hideCount: {
      type: Boolean,
      default: false,
    },
    useImage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    resource() {
      return this.$store.getters['resources/getResourceByName'](this.name);
    },
  },
};
