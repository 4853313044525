
import Vue from 'vue';

export default Vue.extend({
  name: 'ValueView',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    valueUnknown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueDisplay() {
      return this.valueUnknown ? '?' : this.value;
    },
  },
});
