import { z } from 'zod';
import { App } from './App';

export namespace Resource {
  export const ResourceName = z.enum([
    'Radia',
    'Aether',
    'Shade',
  ]);

  export const GrantType = z.enum([
    'VirtualDayStart',
    'DayStart',
    'ActionCompletion',
    'Byproduct',
  ]);

  export const Setting = {
    [ResourceName.enum.Radia]: {
      name: ResourceName.enum.Radia,
      label: App.Concept.Setting[App.Concept.ConceptName.enum.ResourceRadia].label,
      description: 'The essence of light, captured at the start of your day. Used for system upgrades.',
      img: 'radia.png',
      icon: App.Concept.Setting[App.Concept.ConceptName.enum.ResourceRadia].icon,
      color: 'var(--sl-color-yellow-600)',
      grantType: GrantType.enum.DayStart,
      spendAtUIName: 'systemUpgrades',
      spendLabel: 'Go to System Upgrades',
    },
    [ResourceName.enum.Aether]: {
      name: ResourceName.enum.Aether,
      label: App.Concept.Setting[App.Concept.ConceptName.enum.ResourceAether].label,
      description: 'Matter and energy in its most basic form, created by your actions. Used to forge new effects.',
      img: 'aether.png',
      icon: App.Concept.Setting[App.Concept.ConceptName.enum.ResourceAether].icon,
      color: 'var(--sl-color-red-600)',
      grantType: GrantType.enum.ActionCompletion,
      spendAtUIName: 'effectsList',
      spendLabel: 'Go to Effects',
    },
    [ResourceName.enum.Shade]: {
      name: ResourceName.enum.Shade,
      label: App.Concept.Setting[App.Concept.ConceptName.enum.ResourceShade].label,
      description: 'Dark essence with unpredictable results.',
      helpText: 'Analysis shows that shade can be transformed into other states of matter. It is unknown what conditions may affect its transformation.',
      img: 'shade.png',
      icon: App.Concept.Setting[App.Concept.ConceptName.enum.ResourceShade].icon,
      color: 'var(--sl-color-purple-500)',
      grantType: GrantType.enum.Byproduct,
      spendAtUIName: 'resourceTransform',
      spendAtUIPayload: {
        name: ResourceName.enum.Shade,
      },
      spendLabel: 'Transform',
    },
  };
}
