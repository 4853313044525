import _ from 'lodash';
import { SystemUpgrade } from '../api/src/models/SystemUpgrade';
import { wrapGetters } from '../lib/util';

export const state = () => ({
  upgradeName: SystemUpgrade.Name.enum,
});

export const mutations = {};

export const getters = wrapGetters('systems', {
  upgrades: (_state, _getters, rootState) => {
    return Object.keys(SystemUpgrade.Setting).map((name) => {
      const u = {
        ...SystemUpgrade.Setting[name],
        name,
      };

      let valueIndex = 0;

      if (Array.isArray(rootState.userSettings.systemUpgrades)) {
        const setting = rootState.userSettings.systemUpgrades.find(i => i.name === name);

        if (setting && _.isFinite(setting.valueIndex)) {
          valueIndex = Math.max(0, Math.min(u.values.length - 1, setting.valueIndex));
        }
      }

      const value = u.values[valueIndex];
      const valueNext = u.values[valueIndex + 1];

      const valueDisplay = u.valueDisplay(value);
      const valueDisplayNext = typeof valueNext !== 'undefined' ? u.valueDisplay(valueNext) : undefined;
      const description = u.description(valueDisplay);

      return {
        ...u,
        value,
        valueIndex,
        valueDisplay,
        valueDisplayNext,
        description,
        cost: u.costFactor(valueIndex + 1),
      };
    });
  },

  getUpgradeByName: (_state, getters) => (name) => {
    return getters.upgrades.find(i => i.name === name);
  },

  getValueForUpgradeByName: (_state, getters) => (name) => {
    const item = getters.getUpgradeByName(name);
    return item ? item.value : undefined;
  },
});

export const actions = {};
